import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import Decision from "../../models/decision";
import { cancelFollowupDueDate, createNewDueDate, updateDueDate } from "../../store/decision/actions";
import "react-datepicker/dist/react-datepicker.css";
import { DecisionDueDateStyles } from "../../common/styles/decision_due_date_styles";
import Button from "react-bootstrap/Button";
import { isMobile } from "react-device-detect";
import {isDeciderReadOnly, isPublicVisitor, isVisitor} from "../../helpers/user_helpers";

const CancelButton = ({ onCancelDueDate, dueDate }) => {
  if (isMobile) {
    return <a className="btn btn-sm btn-sm-round btn-light" onClick={onCancelDueDate}>
      <i className="fas fa-times w-100" aria-hidden={"true"} />
    </a>
  }

  return <Button onClick={onCancelDueDate} bsPrefix="btn btn-light" hidden={isBlank(dueDate)}> Cancel </Button>
}

const ScheduledFollowupContext = ({ decision, current_user, updateDueDateData, createDueDateData, cancelDueDate }) => {
  const decisionObject = new Decision(decision)
  const initFollowupDate = isPresent(decisionObject.scheduledFollowup) ? decisionObject.scheduledFollowup.followup_due_date : null
  const initDate = isPresent(initFollowupDate) ? moment(initFollowupDate).toDate() : null
  const [dueDate, setDueDate] = useState(initDate)

  useEffect(() => {
    setDueDate(initDate)
  }, [decision.followups])

  const hideFollowup = () => {
    if (!decisionObject.isRecordedOrShowAsCompleted) return true
    if (decisionObject.isRecordedOrShowAsCompleted && decisionObject.isDecisionWithResults) return true

    return isPublicVisitor(current_user) || isVisitor(current_user) || isDeciderReadOnly(current_user)
  }

  if (hideFollowup()) {
    return null
  }
  const onCancelDueDate = () => {
    setDueDate(null);
    cancelDueDate();
  };
  const onChangeDate = (date) => {
    setDueDate(date);
    if(isBlank(date)) return cancelDueDate();

    const updateData = { decision_followup: { followup_due_date: isPresent(date) ? moment(date).toString() : null } }
    if (isPresent(decisionObject.scheduledFollowup)) {
      updateDueDateData(decisionObject.scheduledFollowup['slug'], updateData)
    } else {
      createDueDateData(updateData)
    }
  }

  return <div className="px-3 pb-3">
    <h3>Due date</h3>
    <div className={'d-flex align-items-center'}>
      <div style={DecisionDueDateStyles.arrowOnInputContainer()} className={'w-100 me-2'}>
        <DatePicker selected={dueDate}
                    className={`form-control bg-white pointer ${isMobile && isPresent(dueDate) ? 'px-1 text-center' : ''}`}
                    onChange={onChangeDate} name="due-date" id="due-date"
                    style={DecisionDueDateStyles.input()}
                    value={`${(isPresent(dueDate) ? `Decision results expected by ${moment(dueDate).format('DD MMM, yyyy')}` : 'Decision results not scheduled')}`}
                    minDate={new Date()}
                    popperPlacement="bottom-start"
        />
      </div>
      <CancelButton {...{ onCancelDueDate, dueDate }} />
    </div>
  </div>
}
const mapStateToProps = ({ decision, current_user }) => ({ decision, current_user });
export default connect(mapStateToProps, {
  updateDueDateData: updateDueDate, createDueDateData: createNewDueDate,
  cancelDueDate: cancelFollowupDueDate
})(ScheduledFollowupContext);
