import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "highcharts/highcharts-more"
import {
  formatDriverConfidence,
  formatSuccessDecisions
} from "../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { filterChartsData, xAxisByMonth } from "./TotalDecisions";
import { isPresent } from "../../../helpers/common";
import { filterStatisticPercentageData } from "./TreeCompletion";
import { CHART_COLORS, POINT_HEADER, SERIES_NAME_ROW } from "../../../models/d_sight/metric_chart_helpers";

const BASE_CHART_OPTIONS = {
  chart: {
    type: 'column',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  credits: { enabled: false },
  yAxis: {
    title: {
      text: null
    },
    labels: {
      format: '{text}%',
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    },
    max: 100
  },
  tooltip: {
    headerFormat: `${POINT_HEADER}<table>`,
    pointFormat: `<tr>${SERIES_NAME_ROW}<td style="padding:0"><b>{point.y:.0f}%</b></td></tr>`,
    footerFormat: '</table>',
    shared: true,
    style: {
      color: '#1F2F3F',
    },
    useHTML: true
  },
  legend: {
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '400',
      fontSize: '0.875rem'
    },
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    x: 0,
    y: 0
  },
  plotOptions: {
    series: {
      pointPadding: 0,
      borderWidth: 0
    }
  }
}

export const DecisionsSuccessName = ({ learn }) =>
  <div>
    <div className="icon no-pointer mb-2">
      <div className="d-flex justify-content-center align-items-center bg-light text-gray rounded-circle mx-auto">
        <i className="fas fa-thumbs-up fa-lg text-gray" />
      </div>
    </div>
    <h3 className="mb-0">{formatSuccessDecisions(learn.statistics?.all_statistics)} </h3>
    <div className="text-gray lh-sm mb-2">Successful decisions</div>
    <h3 className="mb-0">{formatDriverConfidence(learn.statistics?.all_statistics)} </h3>
    <div className="text-gray lh-sm">Driver confidence</div>
  </div>

export const DecisionsSuccessChart = ({ learn }) => {
  const dataByMonth = filterChartsData(learn.statistics.by_month)

  const successDecisionsData = dataByMonth.map(hash =>
    filterStatisticPercentageData(hash.statistics.total_results, hash.statistics.successful_decisions)
  )
  const driverConfidenceData = dataByMonth.map(hash =>
    isPresent(hash.statistics.driver_confidence) ?
      (parseFloat(hash.statistics.driver_confidence) * 100) :
      0
  )

  const chartOptions = {
    ...BASE_CHART_OPTIONS,
    colors: CHART_COLORS,
    xAxis: xAxisByMonth(dataByMonth),
    title: {
      text: 'Success vs confidence',
      align: 'left',
      style: {
        fontSize: '1.125rem',
        color: '#1F2F3F',
        fontWeight: 500
      }
    },
    series: [{
      name: 'Successful decisions',
      data: successDecisionsData
    }, {
      name: 'Driver confidence',
      data: driverConfidenceData
    }]
  }
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}
