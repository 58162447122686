import React, {useState} from 'react';
import { connect } from "react-redux";
import CloseIcon from "../../common/CloseIcon";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {openModal, resetModal} from "../../store/modals/actions";
import DecisionFlowRow from "../../tree_wizard/helpers/DecisionFlowRow";
import {flow} from "../../helpers/decision_flows";
import AssignToRow from "../../tree_view/modals/helpers/AssignToRow";
import {checkLoadingEffect} from "../../helpers/callbacks_helpers";
import {fetchContacts} from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import {loadContacts} from "../../store/contacts/actions";
import {isEmail, isPresent, uniqArray} from "../../helpers/common";
import * as moment from "moment/moment";
import {Typeahead} from "react-bootstrap-typeahead";
import {DEFAULT_TYPEHEAD_ATTRIBUTES} from "../../tree_view/modals/helpers/share_helpers";
import {onChangeInvites, renderContactItem} from "../../tree_view/side_panel/helpers/collaborators_helpers";
import {
  filteredContactsCallback,
} from "../../decision_set_view/side_panel/sections/InviteInput";
import {renderTag} from "../../tree_wizard/steps_wizard/steps/helpers/collaboration_helpers";
import {startNewDecision} from "../../store/homepage/actions";
import {useNavigate} from "react-router-dom";
import AdmissionShareInput from "./inputs/AdmisionShareInput";
import DecisionDate, {DATEPICKER_TYPES} from "../../common/DecisionDate";
import {isEnter} from "../../helpers/keys_helpers";

const AVAILABLE_FLOWS = [
  { label: 'Record what was decided', className: 'mb-2', value: flow.TREE, name: 'flow' },
  { label: 'Record a recommendation', className: 'mb-0', value: flow.TREE_RECOMMENDATION, name: 'flow' }
]

export const showUploadDecisionModal = (modal) => modal.type === "UploadDecisionModal" && modal.shown;
export const openUploadDecisionModal = ({openModal, ...opts}) => openModal({ type: 'UploadDecisionModal', ...opts });

const DateRow = ({historicalDecidedAt, setHistoricalDecidedAt}) => {
  return <div className="mt-3 mb-3">
    <DecisionDate id={DATEPICKER_TYPES.historical_decided_at}
                  name={DATEPICKER_TYPES.historical_decided_at}
                  maxDate={new Date()}
                  header='When was the decision made?'
                  date={historicalDecidedAt} onChange={(date) => setHistoricalDecidedAt(moment(date))} />
  </div>
}

export const onInviteKeyDown = (e, ref, collaborators, setCollaborators, filterNewInvites) => {
  if (isEnter(e.keyCode)) {
    const value = ref.current.getInput().value;
    if (isEmail(value)) {
      collaborators.push(value)
      setTimeout(() => { setCollaborators(filterNewInvites(collaborators)) }, 50)
      ref.current.clear()
    }
  }
}

const CollaboratorsRows = ({ contactsData, collaborators, setCollaborators }) => {
  const ref = React.createRef();

  const filteredContacts = filteredContactsCallback({
    contactsData, invites: collaborators, excludeCurrentUser: false, allowGuest: true
  })
  const filterNewInvites = (newInvites) => uniqArray(newInvites.filter(email => isEmail(email)))
  const onKeyDown = (e) => onInviteKeyDown(e, ref, collaborators, setCollaborators, filterNewInvites)

  return (
    <div className='mb-3'>
      <h3>Who else was involved in this decision?</h3>
      <div className="d-flex flex-column flex-sm-row mt-2">
        <div className="w-100">
          <Typeahead
            {...DEFAULT_TYPEHEAD_ATTRIBUTES} labelKey="value" filterBy={['value','full_name']}
            id="invite-input" selected={collaborators} ref={ref} allowNew={true}
            onChange={(selected) => onChangeInvites(selected, setCollaborators, filterNewInvites)}
            options={filteredContacts()}
            renderMenuItemChildren={renderContactItem} onKeyDown={onKeyDown}
            renderToken={(option, events, index) => renderTag(option, events, index, contactsData)}
          />
        </div>
      </div>
    </div>
  );
};

export const UploadDecisionDocumentModal = ({
                                              shown, contactsData, onClose = () => {},
                                              startNewDecision, modal, resetModal, current_org,
                                              loadContacts
                                            }) => {
  const [submit, setSubmit] = useState(false)
  const [flowEnum, setFlowEnum] = useState(current_org.default_uploaded_decisions_flow || flow.TREE)
  const [assignedToUser, setAssignedToUser] = useState(null)
  const [historicalDecidedAt, setHistoricalDecidedAt] = useState(moment())
  const [collaborators, setCollaborators] = useState([])
  const [decisionAdmissionsSelection, setDecisionAdmissionsSelection] = useState([])

  const navigate = useNavigate();

  checkLoadingEffect(contactsData, () => loadContacts(() => {}, {invite_yourself: true}));

  const contacts = fetchContacts({}, contactsData, true, true)

  const submitModal = () => {
    setSubmit(true)
    const collaboratorsArr = [...new Set([assignedToUser, ...collaborators])];
    const data = {
      description: 'Untitled decision',
      flow_enum: flowEnum,
      assigned_user_email: assignedToUser,
      historical_decided_at: historicalDecidedAt,
      collaborators: collaboratorsArr.filter(email => isPresent(email)),
      decision_admissions: decisionAdmissionsSelection.filter(admission => !collaborators.some(email => admission.email === email)),
      data_source_title: modal.uploaded_file.name,
      blob64: modal.uploaded_file.blob,
      decision_uploader: true
    }

    startNewDecision(data, slug => {
      close()
      if (isPresent(slug)) navigate(`/decisions/${slug}/tree_wizard`)
    })
  }

  const resetPlainInputs = () => {
    setFlowEnum(current_org.default_uploaded_decisions_flow || flow.TREE)
    setAssignedToUser(null)
    setHistoricalDecidedAt(moment())
    setDecisionAdmissionsSelection([])
    setCollaborators([])
    setSubmit(false)
  }
  const close = () => {
    resetPlainInputs()
    resetModal()
    onClose()
  }

  const onChangeAssignee = (option) => {
    const value = isPresent(option) && option.value ? option.value : '';
    setAssignedToUser(value);
  };

  const onCreateAssignee = (value) => {
    setAssignedToUser(value);
  };

  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close}/>
      <h2>Upload decision document</h2>
      <DecisionFlowRow {...{flowEnum, setFlowEnum, availableFlows: AVAILABLE_FLOWS, className: 'w-100 mb-3'}} />
      <AssignToRow{...{
        collaborators: contacts,
        assignedToUser,
        onChangeAssignee,
        onCreateAssignee,
        title: `Who is the ${flowEnum === flow.TREE ? 'decider' : 'recommender'}?`,
        className: 'mb-3'
      }} />
      {flowEnum === flow.TREE && <DateRow {...{historicalDecidedAt, setHistoricalDecidedAt}} /> }
      <CollaboratorsRows {...{contactsData, collaborators, setCollaborators}} />
      <AdmissionShareInput {...{contactsData, decisionAdmissionsSelection, setDecisionAdmissionsSelection}} />
      <Button onClick={submitModal} className="w-100 mt-3" disabled={submit}>Done</Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({ contacts, modal, current_org }) => ({ contactsData: contacts, modal, current_org });

const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  startNewDecision: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  resetModal: () => { dispatch(resetModal()) },
  loadContacts: (callback, data) => dispatch(loadContacts(callback, data))
});
export default connect(mapStateToProps, mapDispatchToProps)(UploadDecisionDocumentModal);
