import $ from "jquery";
import React, { useEffect, useRef } from "react";
import { beforeCollapseCallback, onUnmountZTree } from "../../../tree_editor/tree_view/ZTree";
import { isBlank, isPresent } from "../../../helpers/common";
import { connect, Provider } from "react-redux";
import { createRoot } from 'react-dom/client';
import EntryPoint from "../../../EntryPoint";
import { safeDescription } from "../../../helpers/decision_helpers";
import { useNavigate } from "react-router-dom";
import { isTemplateDraft } from "../../../helpers/template_helpers";
import { templateSetParams } from "../../../template_preview/header";
import DraftTitle from "../../../common/DraftTitle";
import TemplateSet from "../../../models/template_set";
import { calcSequenceNumber } from "../../../models/decision_set";
import SequenceTitle from "../../../common/SequenceTitle";
import {generateSetChildren} from "../../../tree_editor/set_decisions/ZTree";
import {SectionName} from "../../../tree_editor/set_decisions/nodes/ZTreeSectionNode";
import {DecisionAssignment} from "../../../tree_editor/set_decisions/nodes/ZTreeDecisionNode";
import {cleanExpiredCollapsedDrivers} from "../../../helpers/local_storage_helpers";
import {driverCollapse, driverExpand} from "../../../tree_editor/tree_view/nodes/actions/DriverExpandCollapse";
import {COLLAPSED_SET_DRIVERS_STORAGE_KEY} from "../../../tree_view/side_panel/sections/TreeSection";

export const BASE_ZTREE_SETTING = {
  view: { dblClickExpand: false, selectedMulti: false, showLine: true },
  data: { simpleData: { enable: true, idKey: "slug" } }
}

export const BASE_NODE_SETTINGS = {
  isRoot: true, isDecisionNode: false, isSectionNode: false, iconSkin: 'non-answered', isTemplate: true,
  children: undefined, slug: '', open: true, name: '', drag: true, drop: true, draft: false, active: false,
  assigned: false, assignedToUser: '', users: []
}

const ZTreeTemplateNode = ({ treeNode, isTemplate, preview = false, template_set }) =>
  <div className="d-flex align-items-center h-inherit justify-content-between">
    <span className={`selectable-area decision-order text-truncate w-100 ${treeNode.active ? 'active py-0' : ''}`}>
      <span hidden={treeNode.isRoot || treeNode.isSectionNode} className={`button non-answered`} />
      {
        treeNode.isRoot || treeNode.isSectionNode ?
          <SectionName treeNode={treeNode} pointer={isTemplate && preview} /> :
          <span id={`${treeNode.tId}_span`} className="node_name">
            <SequenceTitle treeNode={treeNode}  />
            <DraftTitle draft={treeNode.draft}/>
            {safeDescription({ description: treeNode.name })}
          </span>
      }
    </span>
    <DecisionAssignment treeNode={treeNode} set={template_set} />
  </div>


const addDiyDom = (treeId, treeNode, isTemplate, preview, template_set) => {
  const $templateRow = $(`#${treeNode.tId}_a`);
  if(isBlank($templateRow[0])) return;

  if(treeNode.active && !$templateRow.hasClass('curSelectedNode')) {
    $templateRow.addClass('curSelectedNode');
  }
  if (!treeNode.rendered) treeNode.root = createRoot($templateRow[0])
  treeNode.root.render(<Provider store={EntryPoint.instance.store}>
    <ZTreeTemplateNode key={`ztree-template-node-${treeNode.slug}`} preview={preview} treeNode={treeNode} isTemplate={isTemplate} template_set={template_set} />
  </Provider>);
  treeNode.rendered = true;
}

const clickOnTreeNode = (treeNode, navigate, template_set, preview) => {
  if (treeNode.active) return false;
  if (treeNode.isSectionNode) return false;

  const {
    templateSet, setTemplatePreview,
    decisionSet, isDecisionSet
  } = templateSetParams()

  if (treeNode.isRoot && (setTemplatePreview || isDecisionSet)) return false;

  const getParams = new URLSearchParams('');
  if (setTemplatePreview) getParams.set('template_set', templateSet)
  if (isDecisionSet) getParams.set('decision_set',  decisionSet)

  const redirectPath =
    treeNode.isRoot ?
      `/template_sets/${template_set.slug}/${preview ? 'preview' : ''}` :
      treeNode.draft ?
        `/templates/${treeNode.slug}/wizard` :
        `/templates/${treeNode.slug}/${ preview ? 'preview' : '' }${isPresent(getParams.toString()) ? `?${getParams.toString()}` : '' }`

  navigate(redirectPath);
  return true;
}

const staticZTreeSettings = (template_set, navigate, preview, isTemplate, collapsedDriversSlugs = {}) =>
  ({
    view: { addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, isTemplate, preview, template_set) },
    callback: {
      onExpand: (event, treeId, treeNode) => driverExpand(treeNode, template_set.slug, collapsedDriversSlugs, COLLAPSED_TEMPLATE_SET_DRIVERS_STORAGE_KEY),
      onCollapse: (event, treeId, treeNode) => driverCollapse(treeNode, template_set.slug, collapsedDriversSlugs, COLLAPSED_TEMPLATE_SET_DRIVERS_STORAGE_KEY),
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, template_set),
      beforeClick: (treeId, treeNode) => clickOnTreeNode(treeNode, navigate, template_set, preview)
    }
  })

const viewableTemplatesBy = (template_set, preview) =>
  template_set.templates.filter(t => preview ? !isTemplateDraft(t) : true )

const nodeObject = (node, isTemplate, template, collapsedDriversSlugs, set) => {
  const assignedToUser = node.assigned_collaborator_email;
  const isDriverCollapsed = isPresent(collapsedDriversSlugs) ?
    collapsedDriversSlugs[set.slug]?.find(entry => entry.slug === node.slug) :
    false;

  console.log('isDriverCollapsed', isDriverCollapsed)
  console.log(set?.slug)
  console.log(node.slug)
  console.log('---')
  return {
    ...BASE_NODE_SETTINGS,
    open: !isDriverCollapsed,
    template: node, isTemplate,
    isRoot: false,
    isDecisionNode: node.isDecisionNode,
    isSectionNode: node.isSectionNode,
    slug: node.slug,
    name: node.isSectionNode ? node.name : node.description,
    active: node.isDecisionNode && node.slug === template.slug,
    draft: node.isDecisionNode ? isTemplateDraft(node) : false,
    sequence_number: node.isDecisionNode && node.sequence_number,
    assigned: node.isDecisionNode && isPresent(assignedToUser),
    assignedToUser: assignedToUser,
    users: node.isDecisionNode ? node.users : [],
  }
}

const generateChildren = (template_set, isTemplate, currentTemplate, preview, collapsedDriversSlugs) => {
  const viewableTemplates = viewableTemplatesBy(template_set, preview);
  const viewableTemplateSlugs = viewableTemplates.map(({ slug }) => slug);
  const combinedNodes = generateSetChildren(template_set, true)
  const templateSetObj = new TemplateSet(template_set)
  const sections = templateSetObj.sections;
  return combinedNodes.filter(node => node.isSectionNode || viewableTemplateSlugs.includes(node.slug))
    .map(node => {
      let result = { ...nodeObject(node, isTemplate, currentTemplate, collapsedDriversSlugs, template_set) }
      if(node.isSectionNode) {
        const sectionTemplates = sections.find(s => s.slug === node.slug )?.templates?.map((template, sectionIndex) => {
          const templateNode = viewableTemplates.find(d => d.slug === template.slug)
          return templateNode && { ...templateNode, sectionIndex }
        })?.filter(hash => isPresent(hash))

        if(isPresent(sectionTemplates)) {
          result['children'] = sectionTemplates.map(template =>
            nodeObject({
              ...template, isDecisionNode: true, isSectionNode: false,
              sequence_number: calcSequenceNumber(template_set, node.startIndex, template.sectionIndex)
            }, isTemplate, currentTemplate)
          )
        }
      }
      return result
    })
}

const generateNodes = ({ template_set = {}, template = {}, isTemplate =  false, preview = false, collapsedDriversSlugs }) => {
  return {
    ...BASE_NODE_SETTINGS,
    template: template_set,
    children: generateChildren(template_set, isTemplate, template, preview, collapsedDriversSlugs),
    slug: template_set.slug,
    name: template_set.name,
    drag: false,
    drop: false
  };
}

const TreeSection = ({
                       zTreeId = 'decisionSetTree',
                       template_set, isTemplate,
                       preview = false, template, collapsedDriversSlugs
                    }) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isBlank(template_set.sections) && isBlank(template_set.templates)) return;

    cleanExpiredCollapsedDrivers(COLLAPSED_SET_DRIVERS_STORAGE_KEY);
    const setting = { ...BASE_ZTREE_SETTING, ...staticZTreeSettings(template_set, navigate, preview, isTemplate) }
    const nodes = generateNodes({ template_set, template, preview, isTemplate, collapsedDriversSlugs });
    $.fn.zTree.init($(ref.current), setting, nodes);
    return () => onUnmountZTree(() => {}, zTreeId)
  }, [template_set.templates, template_set.sections, template_set.name, template])

  return <div className="px-3">
    <div className={"tree-container decision-order"}>
      {
        isBlank(template_set.templates) ?
          <div className="fw-bolder py-2">{template_set.name}</div> :
          <ul id={zTreeId} className="ztree p-0" ref={ref} />
      }
    </div>
  </div>
}
const mapStateToProps = ({ template_set, template }) => ({
  template_set, template,
  collapsedDriversSlugs: JSON.parse(localStorage.getItem(COLLAPSED_SET_DRIVERS_STORAGE_KEY))
});
export default connect(mapStateToProps)(TreeSection);
