import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Decision from "../../../models/decision";
import { isDecisionSetPage } from "../../../helpers/home_helpers";
import {
  isDecisionWithResults,
  isDSightDecision,
  isFollowupOnResultsDecision,
  isInFlightDecision
} from "../../../helpers/home_decision_helpers";
import * as moment from "moment";
import UserAvatarImage from "../../../common/UserAvatarImage";
import DSightDecisionTile from '../dsight/tiles/DecisionTile';
import DecisionListRow from './DecisionListRow';
import BubbleTooltip from "../../../bubble_tooltip/BubbleTooltip";
import { isMobile } from "react-device-detect";
import { decisionLink, tileSectionsFlags } from "./helpers";
import DecisionTileContent from "./DecisionTileContent";
import {isPresent} from "../../../helpers/common";
import { isDraftDecision } from "../../../models/decision_state";
import {isLegacySlackMsTeamsFlow} from "../../../helpers/home_helpers";

const MAX_COLLABORATORS_IMAGES = 3;
const DECISION_SET_SEARCH_URL = /\/decision_sets\/[0-9a-fA-F]+\/search/;

const searchSectionButtonText = (decision) => {
  const decisionObj = new Decision(decision, [], isLegacySlackMsTeamsFlow(decision))

  if (isDecisionWithResults(decision))
    return decisionWithResultsButtonText(decisionObj);
  else if (decisionObj.isDecisionRecorded)
    return decisionRecordedButtonText(decision)
  else if (decisionObj.showAsCompleted) {
    return enteredRecommendationButtonText(decision);
  } else {
    return `Started ${moment(decision.created_at).format('D MMM, yyyy')}`;
  }
};

const startedButtonText = (decision, isSearchOrDSightHistory) =>
  `${isSearchOrDSightHistory ? 'Started' : 'Created'} ${moment(decision.created_at).format('D MMM, yyyy')}`

const followupDueDateButtonText = (decision) =>
  `Due ${moment(new Decision(decision).scheduledFollowup.followup_due_date).format('D MMM, yyyy')}`

const pollDueDateButtonText = (decision) =>
  `Due ${moment(decision.poll_due_date).format('D MMM, yyyy')}`

export const inFlightButtonText = (decision, driver) => {
  const decisionObj = new Decision(decision);
  if (decisionObj.isSlackFlows) return 'Slack decision'
  if (decisionObj.isMsTeamsFlows) return 'MS Teams decision'

  return dueButtonText(driver?.due_date || decision?.due_date);
}

const lastUpdatesButtonText = (decision) =>
  `Last updated ${moment(decision.updated_at).format('D MMM, yyyy')}`

const decisionRecordedButtonText = (decision) =>
  `Completed ${moment(decision.decided_at).format('D MMM, yyyy')}`;

const approvalNeededButtonText = (pendingApprovalInvite) =>
  `Requested ${moment(pendingApprovalInvite.created_at, 'YYYY-MM-DD HH:mm:ss [UTC]').format('D MMM, yyyy')}`;

const enteredRecommendationButtonText = (decision) =>
  `Completed ${moment(decision.recommendation.recommended_at).format('D MMM, yyyy')}`;

const decisionWithResultsButtonText = (decisionObj) =>
  `Results added ${moment(decisionObj.lastFollowup.followed_up_at || decisionObj.lastFollowup.created_at).format('D MMM, yyyy')}`;

const dueButtonText = (due_date) =>
  `Due ${moment(due_date).format('D MMM, yyyy')}`;

export const buttonTextColor = (decision, isSearch, isApprovalNeeded, driver, isPoll = false) => {
  const decisionObj = new Decision(decision);

  const location = useLocation();

  if (isSearch || isApprovalNeeded) return "text-dark";
  if (decisionObj.isSlackFlows || decisionObj.isMsTeamsFlows) return "text-dark";
  if (isPoll) return moment(decision.poll_due_date) > moment() ? "text-dark" : "text-danger";
  if (isPresent(driver?.due_date)) return moment(driver?.due_date) > moment() ? "text-dark" : "text-danger";
  if (isDecisionWithResults(decision)) return "text-dark";
  if (isDecisionSetPage(location) && decisionObj.isDecisionRecorded) return "text-dark";
  if (isFollowupOnResultsDecision(decision)) return "text-dark";
  if (decisionObj.isDecisionRecorded) return "text-dark";
  if (isInFlightDecision(decision)) return moment(decision.due_date) > moment() ? "text-dark" : "text-danger";

  return "text-dark";
}

export const decisionSetButtonText = (decision) => {
  const decisionObj = new Decision(decision)

  if (isDecisionWithResults(decision))
    return decisionWithResultsButtonText(decisionObj);
  else if (decisionObj.showAsCompleted)
    return enteredRecommendationButtonText(decision);
  else if (decisionObj.isDecisionRecorded)
    return decisionRecordedButtonText(decision);
  else
    return dueButtonText(decision.due_date);
};

export const approvalButtonText = (decision, current_user) => {
  const pendingApprovalInvite = decision.invites.find(i => i.type === 'ApprovalInvite' && i.email === current_user.email)
  return approvalNeededButtonText(pendingApprovalInvite);
};

export const ButtonText = ({ decision, isSearchSection, isDSightHistory, isApprovalNeeded, current_user, isPoll }) => {
  const isSearchOrDSightHistory = isSearchSection || isDSightHistory
  const location = useLocation();

  if (isDraftDecision(decision)) return startedButtonText(decision, isSearchOrDSightHistory);
  if (isApprovalNeeded) return approvalButtonText(decision, current_user);
  if (isSearchOrDSightHistory) return searchSectionButtonText(decision)
  if (isPoll) return pollDueDateButtonText(decision)
  if (isDecisionSetPage(location)) return decisionSetButtonText(decision)
  if (isFollowupOnResultsDecision(decision)) return followupDueDateButtonText(decision);
  if (isInFlightDecision(decision)) return inFlightButtonText(decision)

  return lastUpdatesButtonText(decision)
}
export const CollaboratorsImages = ({ object, collaborators }) => {
  if (collaborators.length > MAX_COLLABORATORS_IMAGES) {
    const displayUsers = collaborators.slice(0, MAX_COLLABORATORS_IMAGES)
    const hiddenUsers = collaborators.slice(MAX_COLLABORATORS_IMAGES)
    return [
      ...displayUsers.map((c, i) => <UserAvatarImage key={`${object.slug}-${c.email}-${i}`} user={c}/>),
      <div key={`${object.slug}-collaborators-others`} className="d-inline-block bg-secondary rounded-circle text-center p-1" title={hiddenUsers.map(u => u.full_name).join(',')}>
        +{hiddenUsers.length}
      </div>
    ]
  } else {
    return collaborators.map((c, i) => <UserAvatarImage key={`${object.slug}-${c.email}-${i}`} user={c} />)
  }
}

export const LinkWrapper = ({
                              children,
                              isSearchSection, isInDecisionSet = false,
                              decision,
                              hasAccess = true
                            }) => {
  const isDecisionSetSearch = DECISION_SET_SEARCH_URL.test(window.location.pathname);

  if (!hasAccess || isSearchSection || (!isDraftDecision(decision) && !isInDecisionSet)) {
    return <a href={decisionLink(decision, hasAccess, isInDecisionSet)} target={`${isDecisionSetSearch ? '_blank' : '_self'}`} className="text-dark text-center">
      {children}
    </a>
  } else {
    return <Link to={decisionLink(decision, hasAccess, isInDecisionSet)} className="text-dark text-center">
      {children}
    </Link>
  }
}

const DecisionTile = ({
                        decision, section = '',
                        isDecisionSet,
                        isApprovalNeeded = false,
                        children,
                        current_user,
                        hasAccess = true, isListLine = false
                      }) => {
  const [showChildren, setShowChildren] = useState(!!isMobile);
  const hideCollaborators = children && showChildren;
  const {
    isSearch, isDSightHistory, isInDecisionSet
  } = tileSectionsFlags(section)

  if (isListLine)
    return <DecisionListRow {...{decision, section, children, isDecisionSet, hideCollaborators, isSearch, isDSightHistory, isInDecisionSet}}/>

  if (isDSightDecision(decision))
    return <DSightDecisionTile {...{ decision, section, children, isDecisionSet, isApprovalNeeded, current_user }} />

  return (
    <div
      className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3"
      onMouseEnter={e => setShowChildren(true)}
      onMouseLeave={e => { isMobile || setShowChildren(false) }}>
      <BubbleTooltip {...{ object_hash: decision, decision_set_name: decision.decision_set_name, isDecisionSet, isInDecisionSet }} />
      <DecisionTileContent {...{ decision, isSearch, isInDecisionSet, isDSightHistory, hideCollaborators, hasAccess, isApprovalNeeded, current_user }}  />
      {
        hideCollaborators &&
          <div className="position-absolute bottom-0 ps-2 pe-3">
            {children}
          </div>
      }
    </div>
  )
}
export default DecisionTile;
