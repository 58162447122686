import React from 'react';
import { connect } from "react-redux";

const AiInstructionsSection = ({aiInstructions, setAiInstructions, className, current_org}) => {
  if (!current_org.allow_gpt)  return null;

  return <div className={className}>
    <h3>CloverpopAI instructions</h3>
    <input
      className="form-control"
      value={aiInstructions}
      onChange={(e) => setAiInstructions(e.target.value)}
      placeholder="Enter instructions to refine the results" />
  </div>
}

const mapStateToProps = ({current_org}) => ({current_org});
export default connect(mapStateToProps, {})(AiInstructionsSection);
