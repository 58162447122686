import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isFirstWizardStep } from "../../helpers/wizard_helpers";
import HamburgerDropdown from "./HamburgerDropdown";
import {
  prevReportWizardStep,
  resetWizard
} from "../../store/wizard/actions";
import { resetSidebars } from "../../store/sidebar/actions";
import { goToHomepageCallback } from "../../tree_wizard/header/helpers";
import {forceReloadHomepageReportInsights, resetSearchAndFilters} from "../../store/homepage/actions";
import OrgLogo from "../../tree_view/header/OrgLogo";
import Button from 'react-bootstrap/Button';
import DoneBtnW100 from '../../common/DoneButton';
import ReportPropertiesModal from '../modals/ReportPropertiesModal';

const WizardHeader = ({
                        wizard, report, prevWizardStep, resetSidebars, resetWizard, current_org,
                        stepRef, org_report_properties
                      }) => {
  const [showPropertiesModal, setShowPropertiesModal] = useState(false);
  const handleOpenPropertiesModal = () => setShowPropertiesModal(true);
  const handleClosePropertiesModal = () => setShowPropertiesModal(false);
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const processBackClick = useCallback(() => {
    if (wizard.submit) return;

    if (isFirstWizardStep(wizard)) {
      goToHomepageCallback([resetSidebars, resetWizard], navigate)()
    } else {
      prevWizardStep()
    }
  }, [wizard])

  const handleSubmit = () => {
    if (stepRef.current && stepRef.current.submitStep) {
      stepRef.current.submitStep();
    }
  };

  const anyAvailableReportProperties = useMemo(() => {
    return org_report_properties.available_report_properties.length > 0 || current_org.allow_report_cost;
  }, [org_report_properties.available_report_properties, current_org.allow_report_cost]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialized(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      setIsButtonDisabled(wizard.submit || wizard.disabledSubmit);
    }
  }, [wizard.submit, wizard.disabledSubmit, isInitialized]);

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        {
          isFirstWizardStep(wizard) ? <div className="logo-dropdown pointer d-none d-lg-flex me-2">
              <OrgLogo current_org={current_org} processBackClick={processBackClick}/>
            </div> :
            <span className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={processBackClick}>
              <i className="fas fa-lg fa-arrow-left"/>
            </span>
        }
        <div className="w-100">
          <div className="decision-description">
            <h5>Report details</h5>
          </div>
        </div>
      </div>
      <div className="navbar-nav ms-auto d-flex align-items-center">
        <div className='d-lg-none'>
          <HamburgerDropdown
          ref={stepRef}
          handleOpenPropertiesModal={handleOpenPropertiesModal}
          anyAvailableReportProperties={anyAvailableReportProperties}
          />
        </div>
        <ul className="navbar-nav d-none d-lg-flex align-items-center">
          {anyAvailableReportProperties && (
            <li className="nav-item ms-2">
              <Button onClick={handleOpenPropertiesModal} className="btn btn-secondary h-48">
                Report properties
              </Button>
            </li>
          )}
        <li className="nav-item ms-2">
          <DoneBtnW100 onClick={handleSubmit} disabled={isButtonDisabled} bsPrefix="btn-primary px-3 width-100 btn">
            Done
          </DoneBtnW100>
        </li>
        </ul>
      </div>
    </nav>
    <ReportPropertiesModal show={showPropertiesModal} handleClose={handleClosePropertiesModal} stepRef={stepRef} />
  </header>;
}

const mapStateToProps = ({ wizard, tree, report, contacts, current_org, org_report_properties }) => ({
  wizard, tree, report, current_org, org_report_properties,
  contactsData: contacts
});

export const headerDispatchProps = (dispatch) => ({
  prevWizardStep: () => dispatch(prevReportWizardStep()),
  resetSidebars: () => dispatch(resetSidebars()),
  resetWizard: () => dispatch(resetWizard()),
  resetSearchAndFilters: () => dispatch(resetSearchAndFilters()),
  forceReloadHomepageReportInsights: () => dispatch(forceReloadHomepageReportInsights()),
})

const mapDispatchToProps = (dispatch) => ({
  ...headerDispatchProps(dispatch),
});
const wrapper = React.forwardRef((props, ref) => <WizardHeader {...props} stepRef={ref}/>)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
