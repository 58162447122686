import React, { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../../common/Loader";
import AlertsSection from "../../alerts";
import TemplateAnalysisViewHeader from "./header";
import AnalysisContent from "./AnalysisContent";
import { isPresent } from "../../helpers/common";
import Analysis, { AnalysisGoal } from "../../models/d_sight/Analysis";
import DSightTreeViewerPanel from "./sidebar/DSightTreeViewerPanel";
import { loadAnalysis, updateAnalysisData } from "../../store/analysis/actions";
import { loadCommonGoal } from "../../store/d_sight_goal/actions";
import { resetSidebars } from "../../store/sidebar/actions";
import EntryPoint from "../../EntryPoint";
import Help from "./help";
import HelpPanel from "./side_panel/HelpPanel";
import { checkLoadingEffect, isStoresLoading } from "../../helpers/callbacks_helpers";
import SegmentationCriteriaModal from "./modals/SegmentationCriteriaModal";

export const Sidebars = () => <Fragment>
  <Help addClass={'sticky-help-button'} />
  <div className="sidebars">
    <div className="help-side-panels">
      <HelpPanel />
    </div>
  </div>
</Fragment>

const analysisLoadCommonGoalsCallbacks = ({ analysis, d_sight_goal, params, loadCommonGoal }) => {
  useEffect(() => {
    if (analysis.loaded && !analysis.loading && analysis.slug === params?.id) {
      if (!d_sight_goal.loaded && !d_sight_goal.loading && d_sight_goal.slug !== analysis.goal_slug) {
        loadCommonGoal(analysis.goal_slug)
      }
    }
  }, [analysis.loaded, d_sight_goal.slug, d_sight_goal.loaded])
}

export const analysisInitCallbacks = ({ analysis, loadAnalysis, updateAnalysisData, ...opts }) => {
  const params = useParams()
  useEffect(() => {
    if (isPresent(params?.id)) EntryPoint.instance.objectSlug = params?.id;
    if (isPresent(params?.section)) EntryPoint.instance.controllerName = params?.section;

    if (analysis.slug !== params?.id || analysis.section !== params?.section) {
      updateAnalysisData({ loaded: false, goal_slug: false })
    }
  }, [params?.section, params?.id])
  checkLoadingEffect(analysis, loadAnalysis)
  analysisLoadCommonGoalsCallbacks({ analysis, params, ...opts })
}

export const Wrapper = ({ children, breadcrumbs = null, compactHeader = true, currentTreeNode = null }) =>
  <Fragment>
    <AlertsSection/>
    <TemplateAnalysisViewHeader breadcrumbs={breadcrumbs} compactHeader={compactHeader}/>
    {children}
    <Sidebars currentTreeNode={currentTreeNode}/>
    <TreeSidebars currentTreeNode={currentTreeNode}/>
    <div className="modals">
      <SegmentationCriteriaModal />
    </div>
  </Fragment>

export const TreeSidebars = ({ currentTreeNode }) =>
  <Fragment>
    <div className="sidebars">
      <DSightTreeViewerPanel currentTreeNode={currentTreeNode}/>
    </div>
  </Fragment>

export const GoalBreadcrumb = ({ d_sight_goal, goalObj, signIn, resetSidebars }) =>
  <Fragment>
    {
      signIn.signedIn ?
        <Link to={goalObj.goalLink} onClick={() => resetSidebars()}>
          {[d_sight_goal?.product?.name, goalObj.goalName].filter(isPresent).join(' ')}
        </Link> :
        <span>{[d_sight_goal?.product?.name, goalObj.goalName].filter(isPresent).join(' ')}</span>
    }
  </Fragment>


export const BreadcrumbDelimiter = () => <span> > </span>

export const DSightTemplateAnalysis = ({
                                         analysis, d_sight_goal,
                                         ...opts
                                       }) => {
  analysisInitCallbacks({ analysis, d_sight_goal, ...opts })

  if (isStoresLoading(analysis, d_sight_goal)) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    const analysisObj = new Analysis(analysis)
    const goalObj = new AnalysisGoal(d_sight_goal.goal_chain)

    const breadcrumbs = <>
      <GoalBreadcrumb {...{ ...opts, d_sight_goal, goalObj }} />
      <BreadcrumbDelimiter/>
      <span>{analysisObj.insightData.display_name}</span>
    </>
    return <Wrapper breadcrumbs={breadcrumbs} compactHeader={false} currentTreeNode={analysisObj.id} >
      <AnalysisContent/>
    </Wrapper>;
  }
}
export const mapStateToProps = ({ analysis, signIn, d_sight_goal }) => ({ analysis, signIn, d_sight_goal });
export const mapDispatchToProps = (dispatch) => ({
  loadAnalysis: () => { dispatch(loadAnalysis()) },
  updateAnalysisData: (data) => { dispatch(updateAnalysisData(data)) },
  resetSidebars: () => dispatch(resetSidebars()),
  loadCommonGoal: (slug) => dispatch(loadCommonGoal(slug))
});
export default connect(mapStateToProps, mapDispatchToProps)(DSightTemplateAnalysis);
