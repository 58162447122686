import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import {removeReport, resetReportData} from "../../store/report/actions";
import { resetSidebars } from "../../store/sidebar/actions";
import { successActions } from "../../helpers/common";
import BaseHamburgerBtn, {BaseHamburgerItem, DeleteItemElement} from "../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../common/dropdowns";
import { deleteReportWarning, reportInEditModeWarning } from "../../helpers/alert_helpers";
import { createReportWizardData } from "../../store/wizard/actions";
import { forceReloadHomepageReportInsights } from "../../store/homepage/actions";

export const deleteReportCallback = ({ removeReport, slug, actions, navigate }) => () => {
  deleteReportWarning(confirmed => {
    if (!confirmed) return;

    removeReport(slug, (success) => {
      redirectToHomeCallback(success, navigate);
      successActions(success, actions);
    })
  })
}

export const editCallback = ({ navigate, slug, createReportWizard }) => () => {
  createReportWizard((success) => {
    if(success) {
      navigate(`/reports/${slug}/report_wizard`)
    } else {
      reportInEditModeWarning()
    }
  })
}

export const ActionsDropdown = ({
                                  btnId,
                                  report,
                                  removeReport,
                                  resetSidebars,
                                  resetReport,
                                  createReportWizard,
                                  forceReloadHomepageReportInsights,
                                  ...opts
                                }) => {

  const navigate = useNavigate();

  const deleteHandler = useCallback(
    deleteReportCallback({
      removeReport, navigate, slug: report.slug,
      actions: [resetSidebars, resetReport, forceReloadHomepageReportInsights]
    }), [report.slug] )

  const editHandler = editCallback( {
    navigate, slug: report.slug, createReportWizard,
    actions: [resetSidebars]
  })

  const iconDropdownEvents = {
    editHandler: 'editHandler',
    deleteHandler: 'deleteHandler'
  }

  const iconDropdownSelect = (eventKey) => {
    switch (eventKey) {
      case iconDropdownEvents.editHandler:
        return editHandler()
      case iconDropdownEvents.deleteHandler:
        return deleteHandler()
    }
  }

  return <IconDropdown id={btnId} bsPrefix="h5 m-0 btn-link pt-2"
                       className="btn btn-secondary btn-lg-round d-none d-lg-flex p-0"
                       onSelect={iconDropdownSelect}>
    <BaseHamburgerBtn eventKey={iconDropdownEvents.editHandler} title="Edit report"/>
    <BaseHamburgerItem eventKey={iconDropdownEvents.deleteHandler}>
      <DeleteItemElement name="report" />
    </BaseHamburgerItem>
  </IconDropdown>;
}
const mapStateToProps = ({ report }) => ({ report });
const mapDispatchToProps = (dispatch) => ({
  removeReport: (slug, callback) => dispatch(removeReport(slug, callback)),
  resetSidebars: () => dispatch(resetSidebars()),
  resetReport: () => dispatch(resetReportData()),
  createReportWizard: (callback) => dispatch(createReportWizardData(callback)),
  forceReloadHomepageReportInsights: () => dispatch(forceReloadHomepageReportInsights())
});
export default connect(mapStateToProps, mapDispatchToProps)(ActionsDropdown);
