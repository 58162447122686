import React, { useState, useEffect } from "react";
import { connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooterP0 } from "../../common/modals";
import { updateScenario } from "../../store/forecast_simulator_scenario/actions";
import CharCounter from "../../common/CharCounter";
import { DISPLAY_NAME_LIMIT } from "../../models/forecast/ForecastScenario";
import { isBlank } from "../../helpers/common";
import { resetForecastData } from "../../store/homepage/actions";

export const showEditNameModal = (modal) => modal.type === "EditNameModal" && modal.shown
export const openEditNameModal = ({ openModal, ...opts }) => openModal({ ...opts, type: 'EditNameModal' })

const EditNameModal = ({
                         forecast_simulator_scenario, show, key,
                         onClose, updateScenario, resetForecastData
                       }) => {

  const focusElement = React.useRef(null);
  useEffect(() => {
    if (show && focusElement.current) focusElement.current.focus()
  }, [show, focusElement])

  const [display_name, setDisplayName] = useState(forecast_simulator_scenario.scenario?.display_name);
  const [showChar, setShowChar] = useState(false);
  const [errors, setErrors] = useState({})
  const [submit, setSubmit] = useState(false);
  const dataChanged = () => display_name.trim() !== forecast_simulator_scenario.scenario?.display_name?.trim()

  useEffect(() => {
    setDisplayName(forecast_simulator_scenario.scenario?.display_name)
  }, [forecast_simulator_scenario.scenario, show])

  if(!forecast_simulator_scenario.loaded) return null;

  const onSubmit = () => {
    if(dataChanged()) {
      setSubmit(true);
      updateScenario(forecast_simulator_scenario.scenario_id, { display_name: display_name.trim(), tiny: true }, (status, errors) => {
        setSubmit(false);
        if (status) {
          resetForecastData()
          onClose();
        } else {
          setErrors(errors)
        }
      })
    } else {
      onClose();
    }
  }

  return <Modal backdrop="static" key={key} size="md" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Edit scenario name</h2>
      <h3>Scenario name</h3>
      <CharCounter show={showChar} field={display_name} limit={DISPLAY_NAME_LIMIT} id={`addBadgePill-${key}`}/>
      <div className="mb-3">
        <input className={`form-control ${errors['display_name'] ? 'is-invalid' : ''}`}
               placeholder="Enter the name of scenario"
               ref={focusElement} value={display_name}
               autoFocus={show} disabled={submit} maxLength={DISPLAY_NAME_LIMIT}
               onChange={(e) => setDisplayName(e.target.value)}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}
        />
        <span className={`d-block mt-1 ${errors['display_name'] ? 'text-danger' : 'hide-text'}`}>
          {errors['display_name']}
        </span>
      </div>
    </Modal.Body>
    <ModalDoneFooterP0 onClose={onSubmit} disabled={submit || isBlank(display_name)} />
  </Modal>
}
const mapStateToProps = ({ modal, forecast_simulator_scenario }) => ({ modal, forecast_simulator_scenario });
export default connect(mapStateToProps, { updateScenario, resetForecastData })(EditNameModal);
