import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {isPresent, successActions} from "../helpers/common";
import {
  loadDecisionSet,
  removeDecisionSet,
  updateDecisionSet
} from "../store/decision_set/actions";
import { closeModal } from "../store/modals/actions";
import EntryPoint, { updateEntryPointData } from "../EntryPoint";
import DecisionSetContent from "./DecisionSetContent";
import { Loader } from "../common/Loader";
import DecisionSet from "../models/decision_set";
import { forceReloadHomepageDecisions } from "../store/homepage/actions";
import {
  setRecommendationInputSidebarOpen, setSetDecisionsSidebarOpen, setDataSourcesSidebarOpen,
  setDecisionInputSidebarOpen, setDecisionSidebarOpen, setMembersSidebarOpen, setMoreActionsSidebarOpen,
} from "../store/sidebar/actions";
import { resetDecisionState } from "../store/decision/common_actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { resetTree } from "../store/tree/common_actions";
import DecisionSetWrapper from "../common/DecisionSetWrapper";
import { loadContacts } from "../store/contacts/actions";
import { resetDecisionSetData } from "../store/decision_set/common_actions";
import { resetDriverInputSidebar } from "../store/sidebar/common_actions";
import { decisionDataSources } from "../models/decision";
import { DecisionSetSourcesTypes } from "../models/data_source";
import { useParams } from "react-router-dom";

const DecisionSetView = ({
                           contactsData, decision, decision_set, current_user, loadDecisionSet, resetDecision,
                           resetTree, resetDriverInputSidebar, closeRecommendationInputSideBar,
                           closeDecisionInputSideBar, loadContacts, setDecisionSidebarOpen, setMembersSidebarOpen,
                           openDataSourcesSidebar, openMoreActionsSidebar,
                           ...opts
                         }) => {
  EntryPoint.instance.saveSearchParams()

  const params = useParams()
  useEffect(() => updateEntryPointData(params, 'decision_sets'), [params?.id]);
  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));
  checkLoadingEffect(decision_set, () => {
    const getParams = new URLSearchParams(document.location.search)
    switch (getParams.get('side_bar_open')) {
      case 'details':
        setDecisionSidebarOpen()
        break;
      case 'members':
        setMembersSidebarOpen()
        break;
      case 'data_sources':
        openDataSourcesSidebar()
        break;
      case 'more_actions':
        openMoreActionsSidebar()
        break;
    }

    loadDecisionSet()
  })
  useEffect(() => {
    if (contactsData.loaded && !contactsData.loading && !contactsData.invite_yourself) {
      setTimeout(loadContacts(() => {}, { invite_yourself: true }), 50)
    }
  }, [contactsData.loaded, contactsData.invite_yourself])
  useEffect(() => {
    if (isPresent(decision.slug))
      successActions(true, [
        resetDecision, resetTree, resetDriverInputSidebar
      ])
  }, [])
  const decisionSetObj = useMemo(() => new DecisionSet(decision_set, current_user), [decision_set, current_user])

  const isMember = useMemo(() => decisionSetObj.isMember, [decisionSetObj]);
  const isSetMember = useMemo(() => decisionSetObj.isSetMember, [decisionSetObj]);
  const isSetViewer = useMemo(() => decisionSetObj.isSetViewer, [decisionSetObj]);
  const isManager = useMemo(() => decisionSetObj.isManager, [decisionSetObj]);
  const isSetUser = useMemo(() => decisionSetObj.isSetUser, [decisionSetObj]);

  const isBlankDataSources = decisionDataSources(decision_set.data_sources, [], DecisionSetSourcesTypes).length === 0;

  return <DecisionSetWrapper {...{...opts, decision_set, isManager, isMember, isSetMember, isSetUser, isSetViewer, preview: !isSetViewer, isBlankDataSources}} >
    {
      isStoreLoading(decision_set) ?
        <Loader /> :
        <div className="w-100 wrapper-container width-fit-content">
          <div className="home container mt-3">
            <DecisionSetContent />
          </div>
        </div>
    }
  </DecisionSetWrapper>
}
const mapStateToProps = ({ contacts, home, decision_set, decision, modal, current_user }) =>
  ({ home, decision_set, decision, modal, current_user, contactsData: contacts });
const mapDispatchToProps = (dispatch) => ({
  loadDecisionSet: () => dispatch(loadDecisionSet()),
  updateDecisionSet: (value, callback) => { dispatch(updateDecisionSet(value, callback)) },
  removeDecisionSet: (callback) => dispatch(removeDecisionSet(callback)),
  closeRecommendationInputSideBar: () => dispatch(setRecommendationInputSidebarOpen(false)),
  closeDecisionInputSideBar: () => dispatch(setDecisionInputSidebarOpen(false)),
  resetDecisionSet: () => dispatch(resetDecisionSetData()),
  resetDecision: () => dispatch(resetDecisionState()),
  resetTree: () => dispatch(resetTree()),
  forceReloadHomepageDecisions: () => { dispatch(forceReloadHomepageDecisions()) },
  setDecisionSidebarOpen: () => { dispatch(setDecisionSidebarOpen(true)) },
  setMembersSidebarOpen: () => { dispatch(setMembersSidebarOpen(true)) },
  setSetDecisionsSidebarOpen: (value) => { dispatch(setSetDecisionsSidebarOpen(value)) },
  openDataSourcesSidebar: () => { dispatch(setDataSourcesSidebarOpen(true)) },
  openMoreActionsSidebar: () => { dispatch(setMoreActionsSidebarOpen(true)) },
  resetDriverInputSidebar: (value) => { dispatch(resetDriverInputSidebar()) },
  closeModal: () => { dispatch(closeModal()) },
  loadContacts: (callback, data) => dispatch(loadContacts(callback, data))
});
export default connect(mapStateToProps, mapDispatchToProps)(DecisionSetView);
