import React from 'react';
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../common/Loader";
import {
  analysisInitCallbacks,
  BreadcrumbDelimiter,
  GoalBreadcrumb,
  mapDispatchToProps,
  mapStateToProps,
  Wrapper
} from "../index";
import { isBlank } from "../../../helpers/common";
import ContributingInsightContent from "./ContributingInsightContent";
import Analysis, { AnalysisGoal } from "../../../models/d_sight/Analysis";
import { analysisLink } from "../../../helpers/dsight_helpers";
import { isStoresLoading } from "../../../helpers/callbacks_helpers";

export const DSightAnalysisInsight = ({
                                        analysis, d_sight_goal, ...opts
                                      }) => {
  analysisInitCallbacks({ analysis, d_sight_goal, ...opts })

  if (isStoresLoading(analysis, d_sight_goal)) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    const analysisObj = new Analysis(analysis)
    const goalObj = new AnalysisGoal(d_sight_goal.goal_chain)
    const params = useParams()
    const insightId = parseInt(params?.insight_id);

    const contributingInsightData = analysisObj.contributingInsights.find(record => record.id === insightId);
    if (isBlank(contributingInsightData)) {
      const navigate = useNavigate()
      navigate(analysisLink(analysisObj.link));
      return null;
    }

    const breadcrumbs = <>
      <GoalBreadcrumb {...{ ...opts, d_sight_goal, goalObj }} />
      <BreadcrumbDelimiter/>
      <Link to={analysisLink(analysisObj.link)}>
        {analysisObj.insightData.display_name}
      </Link>
      <BreadcrumbDelimiter/>
      <span>{contributingInsightData.insightData.display_name}</span>
    </>

    return <Wrapper breadcrumbs={breadcrumbs} currentTreeNode={contributingInsightData.id}>
      <ContributingInsightContent {...{ insight: contributingInsightData, analysisObj, d_sight_goal }}/>
    </Wrapper>;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DSightAnalysisInsight);
