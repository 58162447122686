import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ShareData from "../../models/share_data";
import { isBlank } from "../../helpers/common";
import { addShare, removeShare } from "../../store/share/actions";
import { formatShareItemData } from "../../helpers/share_helpers";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { ShareDropdownModal } from "../../tree_view/modals/ShareDropdownModal";
import { ADMISSIONS } from "../../models/decision_set";

const VISITOR_HASH = { role: ADMISSIONS.visitor }

const ShareDropdown = ({
                         dropdownClassName, btnClassName, btnId, title,
                         share_data, addShareAdmittable, removeShareAdmittable
                       }) => {
  const [modalShown, setModalShown] = useState(false);
  const shareObject = new ShareData(share_data)

  const shareTo = (item) => addShareAdmittable([{...formatShareItemData(item), ...VISITOR_HASH}])
  const removeShare = (item) => removeShareAdmittable(item)
  const closeModal = () => setModalShown(false)
  const submitModal = (items, invites, data, callback) =>
    addShareAdmittable([
      ...items.map(h => ({ ...h, ...VISITOR_HASH }))
    ], invites, data, (success) => {
      callback(success);
      if (success) closeModal()
    })

  return <Fragment>
    <DropdownButton id={btnId} title={title} className={dropdownClassName} bsPrefix={btnClassName}>
      <BaseHamburgerBtn hidden={!shareObject.couldShareOrg} onSelect={() => shareTo(shareObject.entireOrg)}>
        Share with {shareObject.entireOrgLabel}
      </BaseHamburgerBtn>
      <BaseHamburgerBtn onSelect={() => setModalShown(true)} title="Share with people or groups..."/>
      <Dropdown.Divider hidden={isBlank(shareObject.selectedItems)}/>
      <Dropdown.ItemText hidden={isBlank(shareObject.selectedItems)}>
        <small className="text-muted">Shared with</small>
      </Dropdown.ItemText>
      {shareObject.selectedItems.map(item =>
        <BaseHamburgerBtn key={`remove-share-${item.admittable_id}`} onSelect={() => removeShare(item)}>
          <i className="fas fa-times-circle text-danger"/>&nbsp;{item.label}
        </BaseHamburgerBtn>)
      }
    </DropdownButton>
    <ShareDropdownModal {...{
      share_data, allowNew: false,
      shown: modalShown, onClose: closeModal, onSubmit: submitModal,
      objTitle: 'decision flow', objUsersTitle: 'members',
      showNotification: false, placeholder: 'Enter a name or group'
    }} />
  </Fragment>
}
const mapStateToProps = ({ decision_set }) => ({ share_data: decision_set.share_data });
const mapDispatchToProps = (dispatch) => ({
  addShareAdmittable: (items, invites = null, additional_data = {}, callback = () => null) => {
    dispatch(addShare(items, invites, additional_data, callback, true))
  },
  removeShareAdmittable: (item) => dispatch(removeShare(item, true))
});
export default connect(mapStateToProps, mapDispatchToProps)(ShareDropdown);
