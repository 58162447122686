import {
  Footer, ImageRun,
  PageNumber,
  Paragraph, TabStopPosition, TabStopType,
  TextRun, Tab, VerticalAlign, convertInchesToTwip
} from "docx";
import {isBlank} from "../../../helpers/common";
import {mutedProps} from "../../styles/docx_styles";
import {getImgType} from "../../helpers/common";

export const MAX_LOGO_HEIGHT = 25

export const logoImageRow = (extraImages) => {
  if (isBlank(extraImages)) return new TextRun({
    ...mutedProps,
    text: "Logo"
  });

  return new ImageRun({
    type: getImgType(extraImages),
    data: Buffer.from(`${extraImages.base64}`, "base64"),
    transformation: {
      width: MAX_LOGO_HEIGHT * (extraImages.dimensions.width/extraImages.dimensions.height),
      height: MAX_LOGO_HEIGHT
    }
  })
}

export const pageFooter = (extraImages) => {
  const columnWidth = TabStopPosition.MAX / 3;
  const receiptTabStops = [
    { type: TabStopType.RIGHT, position: columnWidth * 1.75 },
    { type: TabStopType.RIGHT, position: columnWidth * 3.6 },
  ];

  const footerParagraph = new Footer({
    properties: {
      verticalAlign: VerticalAlign.CENTER
    },
    children: [
      new Paragraph({
        indent: {
          left: -convertInchesToTwip(18 / 72),
          right: -convertInchesToTwip(18 / 72),
        },
        tabStops: receiptTabStops,
        children: [
          logoImageRow(extraImages),
          new TextRun({
            style: 'numbering',
            ...mutedProps,
            highlight: false,
            children: [
              new Tab(),
              PageNumber.CURRENT,
            ],
          }),
          new TextRun({
            ...mutedProps,
            text: "\twww.cloverpop.com"
          })
        ],
      }),
    ],
  })

  return {
    footers: {
      default: footerParagraph,
      first: footerParagraph
    }
  }
}

