import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { saveWizardStepData } from "../../store/wizard/actions";
import { isBlank } from "../../helpers/common";
import { WIZARD_STEPS } from "../../helpers/wizard_helpers";
import { FramingDecisionForm } from "../../template_wizard/steps_wizard/steps/FramingDecisionStep";
import * as moment from "moment";
import { ModalBodyWithClose, ModalDoneFooterP0 } from "../../common/modals";
import { updateDecisionData } from "../../store/decision/common_actions";

export const showDecisionDetailsModal = (modal) => modal.type === "DecisionDetailsModal" && modal.shown

const DecisionDetailsModal = ({
                                shown = false, wizard, decision,
                                onClose, saveWizardStepData, updateDecisionData, ...opts
                              }) => {
  if (isBlank(shown) || isBlank(decision)) return null;

  const [submit, setSubmit] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const modalRef = useRef({})

  const submitData = () => {
    setSubmit(true)
    saveWizardStepData(WIZARD_STEPS.tree_framing.key, {
      response: { step_index: wizard.step_index },
      choices: modalRef.current.isOpenEnded() ? [] : modalRef.current.choices,
      assigned_collaborator: modalRef.current.assignedToUser,
      decision: {
        description: modalRef.current.description,
        choice_entry_widget_type: modalRef.current.responseType,
        due_date: moment(decision.due_date).toString(),
        collaboration_context: decision.collaboration_context,
        rate_compare_choices: modalRef.current.rateCompareChoices,
        flow_enum: modalRef.current.flowEnum,
        ai_instructions: modalRef.current.aiInstructions,
      },
      next_step: true,
      finish_later: true
    }, success => {
      setSubmit(false)
      if (success) {
        updateDecisionData({ assigned_collaborator_email: modalRef.current.assignedToUser })
        onClose();
      }
    })
  }

  return <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <ModalBodyWithClose bsPrefix="categories-modal" onClose={onClose} header="Edit decision">
      <FramingDecisionForm {...{ ...opts, decision, wizard, template: decision, submitState: submit, onChangeDisabled: setDisabled, stepRef: modalRef }} />
    </ModalBodyWithClose>
    <ModalDoneFooterP0 disabled={submit || disabled} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({tree, modal, wizard, playbook_notes}) => ({
  tree, modal, wizard, playbook_notes,
  decision: modal.decision
})
export default connect(mapStateToProps, { saveWizardStepData, updateDecisionData })(DecisionDetailsModal);
