import React from 'react';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { mapStateToProps } from "../index";
import MetricChart from "../analysis_metrics/charts/MetricChart";
import Analysis, {
  AnalysisKPI,
  AnalysisMetric,
  analysisSortValue,
  skipAnalysisRecord,
  uniqAnalysisRecords
} from "../../../models/d_sight/Analysis";
import { isBlank, qSortArray, uniqueBy, uniqueByCallback } from "../../../helpers/common";
import { RecordInsights, RelatedRecordBlock } from "../AnalysisContent";
import { analysisLink } from "../../../helpers/dsight_helpers";
import DemographicFilters, { availableDemographicsState } from "./DemographicFilters";
import MetricsSelect, { generateChartSwitchState } from "../../metrics/MetricsSelect";
import { transformsMetricsBy } from "./helpers";

const displaySidebarRecord = (record) => !(skipAnalysisRecord(record))

const prepareImpactedKPIs = (metrics) => uniqueByCallback(metrics.map(m => m.getKPIParent).filter(p => p instanceof AnalysisKPI).filter(displaySidebarRecord), m => m.id)
const prepareImpactedMetrics = (metrics) => uniqueByCallback(metrics.map(m => m.parent).filter(p => p instanceof AnalysisMetric).filter(displaySidebarRecord), m => m.id)
const prepareContributingMetrics = (metrics) => qSortArray(uniqAnalysisRecords(metrics.flatMap(m => m.chartMetrics)).filter(displaySidebarRecord), true, analysisSortValue)

const KPIImpactedBlock = ({ records }) => {
  if (isBlank(records)) return null;

  return <>
    <div className="mb-3">
      <h3>KPIs impacted</h3>
      { records.map(kpi => <RelatedRecordBlock record={kpi} key={`kpi-impacted-${kpi.id}`} /> )}
    </div>
  </>
}

const MetricsImpactedBlock = ({ records }) => {
  if (isBlank(records)) return null;

  return <>
    <h3>Metrics impacted</h3>
    { records.map(metric => <RelatedRecordBlock record={metric} key={`metrics-impacted-${metric.id}`} /> )}
  </>
}

const ContributingMetricsBlock = ({ records }) => {
  if (isBlank(records)) return null;

  return <>
    <div className="mt-3">
      <h3>Contributing metrics</h3>
      { records.map(metric => <RelatedRecordBlock record={metric} key={`contributing-metrics-${metric.id}`} /> )}
    </div>
  </>
}

const ContributingMetricContent = ({ analysis, d_sight_goal }) => {
  const params = useParams()
  const {
    availableDemographics, addAvailableOptions
  } = availableDemographicsState(params?.metric_id)

  const analysisObj = new Analysis(analysis, null, null, d_sight_goal.selected_demographic)
  const metrics = transformsMetricsBy(analysisObj.chartMetrics, analysisObj, d_sight_goal);
  const navigate = useNavigate();

  if (isBlank(metrics)) {
    navigate(analysisLink(analysisObj.link));
    return null;
  }

  const {
    metricId, metric,
    ...metricsStates
  } = generateChartSwitchState({
    metrics, navigate,
    allMetricsLink: analysisLink(analysisObj.allMetricsLink),
    generateMetricLink: analysisLink
  })

  const selectedMetricObjects = metrics.filter(record => record.id === parseInt(metricId))

  const impactedKPIs = prepareImpactedKPIs(selectedMetricObjects)
  const impactedMetrics = prepareImpactedMetrics(selectedMetricObjects)
  const contributingMetrics = prepareContributingMetrics(selectedMetricObjects)
  const orderedInsights = uniqueBy(selectedMetricObjects.flatMap(m => m.orderedInsights(true)), 'insight')

  const hideSideSection = isBlank(impactedKPIs) && isBlank(impactedMetrics) && isBlank(contributingMetrics)

  return <div className={`container mt-3`}>
    <div className={'d-flex flex-column flex-md-row mb-3'}>
      <div className={`w-100`}>
        <MetricsSelect {...metricsStates} metric={metric} metricId={metricId}  >
          <RecordInsights insights={orderedInsights} />
        </MetricsSelect>
      </div>
      {
        hideSideSection ? null :
          <div className={`mt-2 mt-md-0 ms-0 ms-md-3 sidepanel-minwidth`}>
            <div className={'h-100 bg-white rounded p-3'}>
              <KPIImpactedBlock records={impactedKPIs} />
              <MetricsImpactedBlock records={impactedMetrics} />
              <ContributingMetricsBlock records={contributingMetrics} />
            </div>
          </div>
      }
    </div>
    {
      isBlank(metric) ? null : <DemographicFilters {...{availableDemographics}}/>
    }
    <div className={'mb-3'}>
      {
        isBlank(metric) ? null : <MetricChart {...{ metric, addAvailableOptions, sourceLabels: true, availableDemographics, lever: analysisObj }} />
      }
    </div>
  </div>
}

export default connect(mapStateToProps)(ContributingMetricContent);
