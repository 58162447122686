import $ from "jquery";
import React, { useEffect, useRef } from "react";
import { connect, Provider } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import { updateTreeData } from "../../store/tree/common_actions";
import {
  BASE_ZTREE_SETTING,
  beforeCollapseCallback, onDragMove,
  onUnmountZTree,
  preventDropCallback
} from "../tree_view/ZTree";
import EntryPoint from "../../EntryPoint";
import ZTreeDriverNode from "./nodes/ZTreeDriverNode";
import ZTreeHoverDriverNode from "./nodes/ZTreeHoverDriverNode";
import { buildNewDriver } from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import Driver from "../../models/driver";
import Decision from "../../models/decision";
import { WIZARD_STEPS, wizardStepDataBy } from "../../helpers/wizard_helpers";
import { createRoot } from "react-dom/client";
import {cleanExpiredCollapsedDrivers} from "../../helpers/local_storage_helpers";
import {driverCollapse, driverExpand} from "../tree_view/nodes/actions/DriverExpandCollapse";

const COLLAPSED_WIZARD_STORAGE_KEY = 'collapsedWizardDrivers';

export const MOVE_TYPES = {
  inner: 'inner',
  next: 'next',
  prev: 'prev'
}

const buildDriversDataFromZTree = (zTreeNodes) => {
  return zTreeNodes.map((zTreeNode, index) => {
    let driverData = { driver: { ...zTreeNode.driverData, level_order: index+1 }, children: [], driver_sources_slugs: zTreeNode.driverSourcesSlugs }
    if(isPresent(zTreeNode.children)) {
      driverData.children = buildDriversDataFromZTree(zTreeNode.children);
    }
    driverData.children.push(buildNewDriver(driverData, driverData.children.length + 1));
    return driverData
  })
}

const onDropNode = (tree, stepRef, treeId, updateTreeData) => {
  const treeObj = $.fn.zTree.getZTreeObj(treeId);
  const newDrivers = buildDriversDataFromZTree(treeObj.getNodes()[0].children);
  newDrivers.push(buildNewDriver(null, newDrivers.length+1));
  stepRef.current.submitStep({ drivers: newDrivers, finish_later: true }, success => {
    if(success && tree.loaded) updateTreeData({ drivers: newDrivers });
  })
}
// zTree lib render node row
const addDiyDom = (treeId, treeNode, collaborators) => {
  const $driverRow = $(`#${treeNode.tId}_a`);
  if (!treeNode.rendered) treeNode.root = createRoot($driverRow[0])
  treeNode.root.render(<Provider store={EntryPoint.instance.store}>
    <ZTreeDriverNode key={`ztree-node-${treeNode.slug}`} {...{treeNode, collaborators}} />
  </Provider>);
  treeNode.rendered = true;
}
// zTree lib render node row on hover
const addHoverDom = (stepRef, drivers, treeId, treeNode, collaborators, isTemplate, onRemoveDriver, isHistoricalDecision) => {
  const $driverRow = $(`#${treeNode.tId}_a`);
  if (!treeNode.rendered) treeNode.root = createRoot($driverRow[0])
  treeNode.root.render(<Provider store={EntryPoint.instance.store}>
    <ZTreeHoverDriverNode key={`ztree-hover-node-${treeNode.slug}`}
                          stepRef={stepRef}
                          drivers={drivers}
                          treeNode={treeNode}
                          collaborators={collaborators}
                          onRemoveDriver={onRemoveDriver}
                          isHistoricalDecision={isHistoricalDecision}
                          isTemplate={isTemplate} />
  </Provider>);
  treeNode.rendered = true;
}

const removeHoverDom = (treeId, treeNode, collaborators) => addDiyDom(treeId, treeNode, collaborators);

// zTree lib settings params
const zTreeEditModeSettings = (stepRef, tree, drivers, decision, collaborators, isTemplate, updateTreeData,
                               onRemoveDriver, isHistoricalDecision, collapsedDriversSlugs = {}) => {
  return {
    edit: { enable: true, drag: { isCopy: true, isMove: true }, showRemoveBtn: false, showRenameBtn: false },
    view: {
      addDiyDom: (treeId, treeNode) => addDiyDom(treeId, treeNode, collaborators),
      addHoverDom: (treeId, treeNode) => addHoverDom(stepRef, drivers, treeId, treeNode, collaborators, isTemplate, onRemoveDriver, isHistoricalDecision),
      removeHoverDom: (treeId, treeNode) => removeHoverDom(treeId, treeNode, collaborators),
    },
    callback: {
      onExpand: (event, treeId, treeNode) => driverExpand(treeNode, decision.slug, collapsedDriversSlugs, COLLAPSED_WIZARD_STORAGE_KEY),
      onCollapse: (event, treeId, treeNode) => driverCollapse(treeNode, decision.slug, collapsedDriversSlugs, COLLAPSED_WIZARD_STORAGE_KEY),
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, decision),
      onDragMove: onDragMove,
      beforeDrag: (treeId, treeNodes) => !treeNodes.some((node) => !node.drag),
      beforeDrop: (treeId, treeNodes, targetNode, moveType) => !preventDropCallback(targetNode, moveType),
      beforeClick: (treeId, treeNode) => {
        updateTreeData({ selected_ztree_node: treeNode });
        return true;
      },
      onDrop: (event, treeId, treeNodes, targetNode, moveType, isCopy) => {
        if(isBlank(moveType)) return false;

        onDropNode(tree, stepRef, treeId, updateTreeData);
        return true;
      }
    }
  }
}

const generateDriverObject = ({
                                driver = {}, decisionObj = {}, driver_sources_slugs = [], pSlug = null, children = [], default_user_avatar_url = '',
                                isDecision = false, isTemplate =  false, isHistoricalDecision = false, collapsedDriversSlugs
                             }) => {
  const has_notes = !isDecision && isPresent(driver.notes);
  const assigned = isPresent(driver.assign_to_user) || isPresent(driver.assignedCollaboratorEmail);

  const filtered_children = (children || []).filter(hash => isPresent(hash['driver']['question']))
  const children_empty = (children || []).find(hash => isBlank(hash['driver']['question']))
  const isAnswered = !isDecision && isHistoricalDecision && new Driver(driver, [], {}, decisionObj).withEnteredAnswer;
  const inFlight = !isDecision && isHistoricalDecision && isPresent(driver.explanation) && !isAnswered
  const isDriverCollapsed = isPresent(collapsedDriversSlugs) ?
    collapsedDriversSlugs[decisionObj.decision.slug]?.find(entry => entry.slug === driver.slug) :
    false;
  const result = {
    isDecision, has_notes, assigned,
    isTemplate, default_user_avatar_url, isHistoricalDecision, inFlight,
    assignedToUser: isDecision ? driver.assignedCollaboratorEmail : driver.assign_to_user,
    notes: driver.notes,
    driverTypeSlug: driver.driver_type_slug,
    pSlug: pSlug,
    slug: driver.slug,
    driverData: { ...driver },
    driverSourcesSlugs: [...driver_sources_slugs],
    open: !isDriverCollapsed,
    question: driver.question,
    childrenEmptyDriver: children_empty,
    iconSkin: inFlight ? 'in-flight' : 'non-answered',
    drag: !isDecision,
    drop: !isDecision,
    answered: isAnswered
  };

  if (filtered_children.length > 0) result['children'] = filtered_children.map(hash =>
    generateDriverObject({ ...hash, pSlug: driver.slug, isTemplate, decisionObj, default_user_avatar_url, isHistoricalDecision, collapsedDriversSlugs: collapsedDriversSlugs })
  )
  return result;
}

const ZTree = ({
                 stepRef, zTreeId = 'decisionTree', tree, decision, wizard, isTemplate = false, drivers,
                 collaborators = [], updateTreeData, onRemoveDriver, isHistoricalDecision = false,
                 collapsedDriversSlugs
              }) => {
  if (isHistoricalDecision && drivers.length < 2) return null;

  const decisionObj = new Decision(decision);
  const assignedCollaboratorEmail =
    decisionObj.isRecordedOrRecommended ? null :
      wizard.loaded && isPresent(wizardStepDataBy(wizard, WIZARD_STEPS.tree_framing.key)?.assign_to) ?
        wizardStepDataBy(wizard, WIZARD_STEPS.tree_framing.key)?.assign_to :
        decisionObj.assignedCollaboratorUser;

  const ref = useRef(null)
  useEffect(() => {
    cleanExpiredCollapsedDrivers(COLLAPSED_WIZARD_STORAGE_KEY);
    const setting = { ...BASE_ZTREE_SETTING, ...zTreeEditModeSettings(stepRef, tree, drivers, decision, collaborators,
        isTemplate, updateTreeData, onRemoveDriver, isHistoricalDecision, collapsedDriversSlugs) }
    const nodes = generateDriverObject({
      driver: { ...decision, question: decision.description, assignedCollaboratorEmail: assignedCollaboratorEmail },
      children: drivers,
      default_user_avatar_url: wizard.default_user_avatar_url,
      pSlug: decision.slug,
      decisionObj: decisionObj,
      isDecision: true,
      isTemplate, isHistoricalDecision,
      collapsedDriversSlugs
    });
    $.fn.zTree.init($(ref.current), setting, nodes);
    return () => onUnmountZTree(() => updateTreeData({ selected_ztree_node: {}, copied_ztree_node: {} }), zTreeId);
  }, [tree.drivers, decision, drivers, wizard.default_user_avatar_url, assignedCollaboratorEmail])

  useEffect(() => {
    const decisionTree = $.fn.zTree.getZTreeObj(zTreeId)
    if(isBlank(tree.copied_ztree_node) && isPresent(decisionTree)) {
      decisionTree.cancelSelectedNode()
    }
  }, [tree.copied_ztree_node])

  return <ul id={zTreeId} className={`ztree ${isHistoricalDecision ? 'historical-decision-tree' : ''} p-0`} ref={ref} />
}
const mapStateToProps = ({ tree, decision, template, wizard },  { isTemplate }) => ({
  tree, decision: isTemplate ? template : decision, wizard,
  collapsedDriversSlugs: JSON.parse(localStorage.getItem(COLLAPSED_WIZARD_STORAGE_KEY))
});
const mapDispatchToProps = (dispatch) => ({
  updateTreeData: (data) => dispatch(updateTreeData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(ZTree);
