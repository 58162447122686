import {
  createComparisonRows,
  updateRowsOnOpen,
  enableHighlights,
  disableHighlights
} from "./common";
import { ROW_DRIVER_ID_KEY } from "./ag_grid_vars";
import { isPresent } from "../../helpers/common";
import  { visibleAndEditablePeriodsScope } from "./period_helpers";

let activeRequests = 0;
let updateTimeoutId;

const applyScrollBehavior = (gridReady, forecastBenchmarkScenario) => {
  return gridReady && isPresent(forecastBenchmarkScenario);
};

const combinedRows = (existingRows, newRows) => {
  return existingRows.map(rowData => {
    const newRow = newRows.find(row => row[ROW_DRIVER_ID_KEY] === rowData[ROW_DRIVER_ID_KEY]);
    return {...rowData, ...newRow};
  });
};

const applyRows = (api, newRows) => {
  api.applyTransaction({ update: newRows });
};

const anyColumnsToUpdate = (group, visiblePeriods) => {
  if (!group) return false;

  return visiblePeriods.some(period => group.added_rows.some(row => !row.hasOwnProperty(period.name)));
};

export const onBodyScroll = () => clearTimeout(updateTimeoutId);

const updateRows = ({ rowNode, gridRef, group, forecastScenario, forecastBenchmarkScenario, newRows, editedCells, runModelCells, updateOpenedGroups, updateTableCells }) => {
  updateRowsOnOpen({
    api: gridRef.current.api,
    node: rowNode
  }, forecastScenario, group.output, forecastBenchmarkScenario, newRows, editedCells, runModelCells, true, updateOpenedGroups, updateTableCells, false,(status) => {
    if(status) applyRows(gridRef.current.api, newRows);
    activeRequests--;
    if (activeRequests === 0) {
      enableHighlights(0);
    }
  });
};

const processRow = ({ config, rowNode, forecastScenario, forecastBenchmarkScenario, gridRef, expandedGroupIds, expandedGroups, visiblePeriods, editedCells, runModelCells, updateScenario, updateOpenedGroups, updateTableCells }) => {
  if (expandedGroupIds.includes(rowNode.id)) {
    const group = expandedGroups.find(g => g.id === rowNode.id);
    if (anyColumnsToUpdate(group, visiblePeriods)) {
      const createdRows = createComparisonRows(config, group.output, { node: rowNode }, forecastScenario, forecastBenchmarkScenario, visiblePeriods);
      const newRows = combinedRows(group.added_rows, createdRows);
      activeRequests++;
      if (activeRequests === 1) {
        disableHighlights();
      }
      updateRows({
        rowNode,
        gridRef,
        group,
        forecastScenario,
        forecastBenchmarkScenario,
        newRows,
        editedCells,
        runModelCells,
        updateScenario,
        updateOpenedGroups,
        updateTableCells
      });
    }
  }
};
const getRowNodes = (gridRef) => {
  let rowNodes = [];
  const firstVisibleRowIdx = gridRef.current.api.getFirstDisplayedRowIndex();
  const lastVisibleRowIdx = gridRef.current.api.getLastDisplayedRowIndex();
  for (let i = firstVisibleRowIdx; i <= lastVisibleRowIdx; i++) {
    rowNodes.push(gridRef.current.api.getDisplayedRowAtIndex(i));
  }
  return rowNodes;
};

export const onHorizontalScrollBody = ({
                                         gridRef,
                                         forecastScenario,
                                         forecastBenchmarkScenario,
                                         expandedGroupIds,
                                         expandedGroups,
                                         editedCells,
                                         runModelCells,
                                         updateOpenedGroups,
                                         updateTableCells
                                       }) => {
  if(expandedGroupIds.length === 0) return;
  const periodsScope = visibleAndEditablePeriodsScope(gridRef.current, forecastScenario);

  const rowNodes = getRowNodes(gridRef);
  rowNodes.forEach(rowNode => {
    processRow({
      config: forecastScenario.config,
      rowNode,
      forecastScenario,
      forecastBenchmarkScenario,
      gridRef,
      expandedGroupIds,
      expandedGroups,
      visiblePeriods: periodsScope,
      editedCells,
      runModelCells,
      updateOpenedGroups,
      updateTableCells
    });
  });
};

const onHorizontalScroll = ({ gridReady, gridRef, forecastBenchmarkScenario, ...opts }) => {
  if (!applyScrollBehavior(gridReady, forecastBenchmarkScenario)) return;

  // Clear any existing timeout to prevent multiple updates
  clearTimeout(updateTimeoutId);

  updateTimeoutId = setTimeout(() => {
    if(!gridRef.current) return;

    onHorizontalScrollBody({ gridRef, forecastBenchmarkScenario, ...opts });
  }, 200);
};

export const onBodyScrollEnd = ({ event, ...opts }) => {
  switch (event.direction) {
    case 'horizontal':
      return onHorizontalScroll({ event, ...opts });
  }
};
