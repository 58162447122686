import React, {Fragment, useState} from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { redirectToHomeCallback } from "../../../helpers/decision_helpers";
import { resetDSightsData, startNewDecision } from "../../../store/homepage/actions";
import { isBlank, isInIframeOfOwnOrigin, isPresent, successActions } from "../../../helpers/common";
import HamburgerDropdown from "./HamburgerDropdown";
import TreeIndexIcon from "../../../../../../assets/images/tree_index_icon.svg";
import { resetSidebars, setDSightTreeViewerOpen } from "../../../store/sidebar/actions";
import { resetAnalysis } from "../../../store/analysis/actions";
import Analysis from "../../../models/d_sight/Analysis";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { IconDropdown } from "../../../common/dropdowns";
import { isPreview } from "../../../helpers/dsight_helpers";
import OrgLogo from "../../../tree_view/header/OrgLogo";
import { isGuest } from "../../../helpers/home_helpers";

export const startDecisionFromTemplate = (startNewDecision, analysis, analysisObject, navigate, resetDSightsData, setDisabled = () => null) => {
  setDisabled(true)
  startNewDecision({ template_slug: analysis.slug, dsight: true, description: analysisObject.question }, slug => {
    if (isPresent(slug)) {
      setDisabled(false)
      navigate(`/dsight/decisions/${slug}/tree_viewer`)
      resetDSightsData();
    }
  })
}

const TemplateViewHeader = ({
                              analysis, current_org, current_user, sidebar,
                              resetAnalysis, resetSidebars, startNewDecision, openSideBar,
                              breadcrumbs = [], compactHeader = true, resetDSightsData
                            }) => {
  if(isInIframeOfOwnOrigin()) return null;
  if (!analysis.loaded) return <nav className="navbar"/>;

  const analysisObject = new Analysis(analysis)
  const [disabled, setDisabled] = useState(false)

  const preview = isPreview() ||
    analysis.section === 'decisions' || analysis.obsolete ||
    isBlank(current_user) || isGuest(current_user);

  const navigate = useNavigate();
  const processBackClick = () => {
    successActions(true, [resetAnalysis, resetSidebars]);
    redirectToHomeCallback(true, navigate);
  }

  const previewTemplate = () => {
    navigate(`/dsight/templates/${analysis.slug}/preview`)
  }

  const triggerTreeSidebar = () => openSideBar(!sidebar.dSightTreeSidebar)

  const CompactButtons = () =>
    <Fragment>
      <li className="nav-item ms-2">
        <Button onClick={triggerTreeSidebar} className="btn-secondary btn-tree-navigation btn">
          <img src={TreeIndexIcon} alt="Tree navigation"/>
        </Button>
      </li>
      {
        preview ? null :
          <li className="nav-item ms-2">
            <IconDropdown id="invite-menu-dropdown" bsPrefix="dropdown-toggle h5 m-0 btn-link pt-2"
                          className="btn btn-secondary btn-lg-round d-none d-lg-flex p-0">
              <BaseDropdownBtn onSelect={previewTemplate} title="Preview decision" />
              <BaseDropdownBtn onSelect={() => startDecisionFromTemplate(startNewDecision, analysis, analysisObject, navigate, resetDSightsData)} title="Start decision" />
            </IconDropdown>
          </li>
      }
    </Fragment>

  const AllButtons = () =>
    <Fragment>
      <li className="nav-item ms-2">
        <Button onClick={triggerTreeSidebar} className="btn-secondary btn-tree-navigation btn">
          <img src={TreeIndexIcon} alt="Tree navigation"/>
        </Button>
      </li>
      {
        preview ? null :
          <>
            <li className="nav-item ms-2">
              <Button onClick={previewTemplate} className="btn-secondary btn">
                Preview decision
              </Button>
            </li>
            <li className="nav-item ms-2">
              <Button disabled={disabled}
                      onClick={() => startDecisionFromTemplate(startNewDecision, analysis, analysisObject, navigate, resetDSightsData, setDisabled)}
                      className="btn-primary btn">
                <i className="fas fa-plus fa-lg m-0 align-middle"/>&nbsp;
                Start decision
              </Button>
            </li>
          </>
      }
    </Fragment>

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick}/>
        </div>
        <h5>{breadcrumbs}</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown preview={preview}/>
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          {compactHeader ? <CompactButtons/> : <AllButtons/>}
        </ul>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ current_org, analysis, sidebar, current_user }) => ({ analysis, current_org, sidebar, current_user });
const mapDispatchToProps = (dispatch) => ({
  startNewDecision: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  resetAnalysis: () => dispatch(resetAnalysis()),
  resetSidebars: () => dispatch(resetSidebars()),
  openSideBar: (options) => {
    dispatch(setDSightTreeViewerOpen(options));
  },
  resetDSightsData: () => dispatch(resetDSightsData())
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateViewHeader);
