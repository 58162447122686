import React, { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import { Loader } from "../../common/Loader";
import AlertsSection from "../../alerts";
import MetricsHeader from "./header";
import { loadCommonGoal } from "../../store/d_sight_goal/actions";
import { loadGoalMetrics, updateMetricsData } from "../../store/metrics/actions";
import MetricsPageContent from "./MetricsPageContent";
import { checkLoadingEffect, isStoresLoading } from "../../helpers/callbacks_helpers";
import SegmentationCriteriaModal from "../analysis/modals/SegmentationCriteriaModal";
import { useParams } from "react-router-dom";

const Wrapper = ({ children }) =>
  <Fragment>
    <AlertsSection />
    <MetricsHeader />
    {children}
    <div className="modals">
      <SegmentationCriteriaModal />
    </div>
  </Fragment>

export const metricsInitCallbacks = ({
                                       metrics, d_sight_goal,
                                       loadGoalMetrics, updateMetricsData, loadCommonGoal
                                    }) => {
  const params = useParams()
  useEffect(() => {
    if (metrics.slug !== params?.goal_id) {
      updateMetricsData({ loaded: false })
    }
    if (d_sight_goal.slug !== params?.goal_id && !d_sight_goal.loading) {
      loadCommonGoal(params?.goal_id)
    }
  }, [params?.goal_id, d_sight_goal.loaded])
  checkLoadingEffect(metrics, () => { loadGoalMetrics(params?.goal_id) })
  checkLoadingEffect(d_sight_goal, () => { loadCommonGoal(params?.goal_id) })
}

export const DSightMetrics = ({
                                metrics, d_sight_goal,
                                ...opts
                             }) => {
  metricsInitCallbacks({ metrics, d_sight_goal, ...opts })

  if (isStoresLoading(metrics, d_sight_goal)) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    return <Wrapper>
      <MetricsPageContent />
    </Wrapper>;
  }
}
export const mapStateToProps = ({ metrics, d_sight_goal }) => ({ metrics, d_sight_goal });
export default connect(mapStateToProps, { loadGoalMetrics, loadCommonGoal, updateMetricsData })(DSightMetrics);
