import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from "react-redux";
import AddReplyForm from "./AddReplyForm";
import EditMessageRow from "./EditMessageRow";
import * as moment from "moment";
import { Tooltip } from 'react-tooltip';
import { isBlank, isPresent } from "../../../helpers/common";
import {
  toggleDriverCommentLike,
  toggleMessageLike,
  toggleUserSupportCommentLike
} from "../../../store/decision/actions";
import {isDeciderReadOnly, isPublicVisitor, isVisitor, userName as userNameHelper} from "../../../helpers/user_helpers";
import {
  DECISION_TYPE,
  DRIVER_TYPE,
  formatMessageText, isResponseComment, RESPONSE_TYPE,
  USER_SUPPORT_REPLY_TYPE
} from "../../../helpers/discussion_helpers";
import { updateTreeData } from "../../../store/tree/common_actions";
import { useLocation } from 'react-router-dom'
import queryString from "query-string";
import { openModal } from "../../../store/modals/actions";
import { collectUserSupports } from "../../../helpers/decision_helpers";
import BuyInDetailsModal from "../../modals/BuyInDetailsModal";
import { RichTextSection } from "../../../common/RichTextEditor";
import { $driverInputSidebarWrapper, openDriverInput } from "../../accordion_tree/shared/helpers";
import { setDiscussionPanelOpen, setDriverInputSidebarOpen } from "../../../store/sidebar/actions";
import Driver from "../../../models/driver";
import { resetDriverInputSidebar } from "../../../store/sidebar/common_actions";
import Decision from "../../../models/decision";
import UserAvatarImage from "../../../common/UserAvatarImage";
import {is} from "ramda";

export const ADD_COMMENT_ACTION = 'add_comment'

const hideStaticReply = (message, current_user) => {
  if (isVisitor(current_user) || isDeciderReadOnly(current_user)) return true;
  if (isPresent(message.feedback_reply_id)) return true;

  return isBlank(message.replies);
}

const LikesIcon  = ({ message }) =>
  <React.Fragment>
    <span className="fa-stack icon-thumbs-up-stack">
      <i className="fa-solid fa-circle text-success fa-stack-2x fs-3"></i>
      <i className="fa-solid fa-thumbs-up fa-stack-1x fa-inverse"></i>
    </span>
    <span className="px-2">{message.likes.length}</span>
  </React.Fragment>

const EditMessageTile = ({ message, setEditMode }) =>
  <EditMessageRow message={message} onClose={() => { setEditMode(false) }} />

const MessageTile = ({
                       message, current_user, tree,
                       activeMessage, showReply, sidebar,
                       setEditMode, setShowReply,
                       toggleMessageLike, openModal, toggleDriverCommentLike, toggleUserSupportCommentLike,
                       resetDriverInputSidebar, setDriverInputSidebarOpen, setDiscussionPanelOpen,
                       updateTreeData, hideResponseCommentOn = false, isHideLike = false,
                       isHideDate = false, isHideReply = false
                    }) => {
  const isUserMessage = message.user_email === current_user.email;
  const hasUserLike = message.likes.some(user => user.user_email === current_user.email)
  const messageHasLikes = message.likes.length > 0
  const isDecisionFeedback = message.type === DECISION_TYPE
  const userName = isUserMessage ? 'You' : userNameHelper(message.user)
  const userFirstName = message.user?.first_name
  const isReply = isPresent(message.feedback_reply_id)
  const decisionObj = new Decision(tree.decision)
  const driverObj = new Driver(message.object, [], {}, decisionObj);

  const [shownBuyInDetailsModal, setShownBuyInDetailsModal] = useState(false)

  const hideEditLink = (user) => {
    if (isVisitor(user) || isDeciderReadOnly(user)) return true;
    if (!isUserMessage) return true;

    return !isDecisionFeedback;
  }
  const hideReply = () => {
    if (isVisitor(current_user) || isDeciderReadOnly(current_user)) return true;
    if (isReply || isHideReply) return true;
    if (showReply) return true;
    if (isPresent(message.replies)) return true;

    return isUserMessage;
  }
  const hideLike = () => isVisitor(current_user) || isDeciderReadOnly(current_user) || hasUserLike || isHideLike
  const editMessage = () => setEditMode(true)
  const toggleLike = () => {
    if (isPublicVisitor(current_user)) return;

    switch (message.type) {
      case DECISION_TYPE:
      case RESPONSE_TYPE:
        toggleMessageLike(message.id)
        break;
      case DRIVER_TYPE:
        toggleDriverCommentLike(message.object.slug, message.id)
        break;
      case USER_SUPPORT_REPLY_TYPE:
        toggleUserSupportCommentLike(message.id)
        break;
    }
  }
  const addReply = () => {
    if (message.type === DRIVER_TYPE) {
      showDriverDetailsModal({ trigger: ADD_COMMENT_ACTION })
      return false;
    }
    setShowReply(true)
  }

  const showDriverDetailsModal = (opts = {}) => {
    if (driverObj.isCompleted) {
      setDiscussionPanelOpen(false);
      updateTreeData({ scroll_to_driver_comment: `${message.object.slug}-${message.id}-${opts.trigger}` });
    } else if (!isVisitor(current_user) && !isDeciderReadOnly(current_user)) {
      openDriverInput(!$driverInputSidebarWrapper().length, message.object.slug, resetDriverInputSidebar, setDriverInputSidebarOpen, opts);
    }
  }
  const showBuyInDetailsModal = () => {
    setShownBuyInDetailsModal(true)
  }

  const showPollResults = () => {
    setDiscussionPanelOpen(false);
    updateTreeData({ expand_poll_results: true, feedback_message_id: message.id })
  }

  useEffect(() => {
    if (sidebar.driverInputSidebar && !driverObj.isCompleted) {
      const commentElement = document.getElementById(`comment-row-${message.id}`);
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [sidebar.driverInputSidebar]);

  return <div className={`message-group ${isUserMessage ? 'current-user-message' : ''}`}>
    <div className={`message-body rounded-top rounded-end p-2 ${activeMessage ? 'highlight' : ''}`}>
      <div className="header-row pb-1 d-flex">
        <div>{userName || message.user_email}</div>
        {
          !isHideDate && <div className="ms-auto">{moment(message.created_at).format('DD MMM, h:mm a')}</div>
        }
      </div>
      <div className="pb-1 " hidden={message.type !== DRIVER_TYPE || isReply}>
        <i>{userFirstName} commented on </i>
        "<span className="text-primary pointer" onClick={() => showDriverDetailsModal()}>{message.object.question}</span>"
      </div>
      <div className="pb-1" hidden={message.type !== USER_SUPPORT_REPLY_TYPE || isReply}>
        <i>{userFirstName} commented on their </i>
        <span className="text-primary pointer" onClick={showBuyInDetailsModal}>decision buy-in.</span>
      </div>
      <div className="pb-1" hidden={message.type !== RESPONSE_TYPE || isReply || hideResponseCommentOn}>
        <i>{userFirstName || message.user_email} commented on </i>
        <span className="text-primary pointer" onClick={showPollResults}>the decision poll.</span>
      </div>
      <div className="message-text">
        {
          message.type === USER_SUPPORT_REPLY_TYPE ?
            <RichTextSection text={message.description} /> :
            <div>{formatMessageText(message)}
              <span className={'text-muted'} hidden={!message.is_edited}> (edited) </span>
            </div>
        }
      </div>
    </div>
    <div className={`messages-actions d-flex ${isHideReply && isHideLike ? 'd-none' : '' }`}>
      <a className="pointer me-4" hidden={hideLike()} onClick={toggleLike}>Like</a>
      <a className="pointer me-4" hidden={hideReply()} onClick={addReply}>Reply</a>
      <a className="pointer me-1" hidden={hideEditLink(current_user)} onClick={editMessage}>Edit</a>
      <span>&nbsp;</span>
      <span className="likes-counter ms-auto"
            data-tooltip-id={`discussion-likes-tooltip-${message.id}`}
            data-tooltip-content={ message.likes.map(like => userNameHelper(like?.user)).join(', ') }
            hidden={message.likes.length < 1}>
          {
            hasUserLike ?
              <a className="btn border likes-link bg-white icon p-0" onClick={toggleLike}>
                <LikesIcon message={message} />
              </a> :
              <span className={`btn border likes-link bg-white icon p-0 ${isPublicVisitor(current_user) ? 'as-public-visitor' : ''}`} onClick={toggleLike}>
                <LikesIcon message={message} />
              </span>
          }
        </span>
      { messageHasLikes ? <Tooltip id={`discussion-likes-tooltip-${message.id}`}/> : null }
    </div>
    <div className="modals">
      {
        message.type !== USER_SUPPORT_REPLY_TYPE ? null:
          <BuyInDetailsModal userSupports={collectUserSupports(message.decision)} shown={shownBuyInDetailsModal} onClose={() => setShownBuyInDetailsModal(false)} />
      }
    </div>
  </div>
}

const MessageRow = ({
                      message, key, current_user, lastMessageId = null, avatarSize='md',
                      isHideLike = false, isHideDate = false, isHideReply = false, className = '',
                      ...opts
                    }) => {
  const [editMode, setEditMode] = useState(false)
  const [showReply, setShowReply] = useState(false)
  const [activeMessage, setActiveMessage] = useState(false)
  const location = useLocation();
  const messageIdInURL = parseInt(queryString.parse(location.search)['scroll_to_feedback']);
  const isMessageInUrl = useMemo(() => messageIdInURL === message.id, [messageIdInURL, message])

  const focusMessage = useRef(null);
  useEffect(() => {
    if (isMessageInUrl || (!messageIdInURL && lastMessageId)) {
      if (isMessageInUrl) {
        setActiveMessage(true)
      }
      focusMessage.current.scrollIntoView()
      setTimeout(()=>{
        setActiveMessage(false)
      }, 1000)
    }
  }, [focusMessage, showReply])

  return <div className={`${isPresent(className) ? className : 'mt-1'}`} key={key} ref={focusMessage}>
    <div className="d-flex">
      <div className={`icon pe-2 align-self-end no-pointer ${isHideReply && isHideLike ? 'pb-0' : 'pb-3'}`}>
        <UserAvatarImage user={message.user} size={avatarSize} />
      </div>
      <div className="w-100 message-input-max-width scroll-margin-tab" id={`message-row-${message.id}`}>
      {
        editMode ?
          <EditMessageTile message={message} setEditMode={setEditMode} /> :
          <MessageTile message={message} current_user={current_user}
                       activeMessage={activeMessage} showReply={showReply} isHideDate={isHideDate}
                       setEditMode={setEditMode} setShowReply={setShowReply} isHideLike={isHideLike} isHideReply={isHideReply}
                       {...opts} />
      }
      </div>
    </div>

    <div className="replies ms-5" hidden={(isBlank(message.replies) && !showReply) || isHideReply}>
      {
        message.replies.map(reply =>
          <ConnectedMessageRow message={reply} key={`${key}-reply-${reply.id}`} lastMessageId={reply.id} />
        )
      }
      <AddReplyForm message={message} hidden={!showReply} className="mb-2" onClose={() => { setShowReply(false) }} />
      <AddReplyForm message={message} hidden={hideStaticReply(message, current_user)} className="mb-2" />
    </div>
  </div>
};
const mapStateToProps = ({ current_user, tree, sidebar }) => ({ current_user, tree, sidebar });
const mapDispatchToProps = (dispatch) => ({
  toggleMessageLike: (slug) => dispatch(toggleMessageLike(slug)),
  toggleDriverCommentLike: (driverSlug, slug) => dispatch(toggleDriverCommentLike(driverSlug, slug)),
  toggleUserSupportCommentLike: (slug) => dispatch(toggleUserSupportCommentLike(slug)),
  resetDriverInputSidebar: () => dispatch(resetDriverInputSidebar()),
  setDriverInputSidebarOpen: (value, driverSlug, opts) => dispatch(setDriverInputSidebarOpen(value, driverSlug, opts)),
  setDiscussionPanelOpen: (value) => dispatch(setDiscussionPanelOpen(value)),
  openModal: (data) => dispatch(openModal(data)),
  updateTreeData: (data) => dispatch(updateTreeData(data))
})
const ConnectedMessageRow = connect(mapStateToProps, mapDispatchToProps)(MessageRow);
export default ConnectedMessageRow;
