import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Analysis, { AnalysisMetric } from "../../models/d_sight/Analysis";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { TABS_URLS } from "../../helpers/home_helpers";
import GoalMetricChart from "./GoalMetricChart";
import { RelatedRecordBlock } from "../analysis/AnalysisContent";
import DemographicFilters, { availableDemographicsState } from "../analysis/analysis_metrics/DemographicFilters";
import MetricsSelect, { generateChartSwitchState } from "./MetricsSelect";

const DecisionImpactedBlock = ({ records }) => {
  if (isBlank(records)) return null;

  return <>
    <h3>Decisions impacted</h3>
    { records.map(row => <RelatedRecordBlock record={row} key={`decision-impacted-${row.id}`} /> )}
  </>
}

const fetchMetricLevers = ({ metric, allMetrics }) => {
  if (isPresent(metric?.data?.levers)) return metric?.data?.levers;
  if (isBlank(metric?.data?.metrics)) return [];

  const childIds = metric?.data?.metrics?.map(c => c.chain_data.id);
  return allMetrics.find(m => childIds.includes(m.id) && isPresent(m.data?.levers) )?.data?.levers
}

const MetricsPageContent = ({ metrics }) => {
  const params = useParams()
  const {
    availableDemographics, addAvailableOptions
  } = availableDemographicsState(params?.metric_id)

  const navigate = useNavigate();

  const goalId = params?.goal_id
  const allMetrics = useMemo(() =>
    qSortArray(
      metrics.metrics.map(hash => new AnalysisMetric(hash)),
      true, (a) => a.name
    ), [metrics.metrics.length]
  )

  if (isBlank(allMetrics)) {
    navigate(`/homepage/${TABS_URLS.dsight}/${goalId}`);
    return null;
  }

  const leverObjects = metrics.levers.map(hash => new Analysis({ ...hash, section: 'templates', slug: hash.template_slug }))
  const goalMetricLink = (link) => `/dsight/goals/${goalId}/metrics/${link}`

  const {
    metricId, metric,
    ...metricsStates
  } = generateChartSwitchState({
    metrics: allMetrics, navigate,
    allMetricsLink: `/dsight/goals/${goalId}/metrics`,
    generateMetricLink: goalMetricLink
  })

  const metricLevers = fetchMetricLevers({ metric, allMetrics })

  const impactedLevers = qSortArray(
    metricLevers?.map(lever_id => leverObjects.find(obj => obj.id === lever_id)) || [],
  true, (a) => a.name)

  const hideSideSection = isBlank(impactedLevers)

  return <div className={`container mt-3`}>
    <div className={'d-flex flex-column flex-md-row mb-3'}>
      <div className={`w-100`}>
        <MetricsSelect {...metricsStates} metric={metric} metricId={metricId} />
      </div>
      {
        !hideSideSection &&
          <div className={`mt-2 mt-md-0 ms-0 ms-md-3 sidepanel-minwidth`}>
            <div className={'h-100 bg-white rounded p-3'}>
              <DecisionImpactedBlock records={impactedLevers} />
            </div>
          </div>
      }
    </div>
    {
      isPresent(metric) && <DemographicFilters {...{ availableDemographics }} />
    }
    <div className={'mb-3'}>
      {
        isPresent(metric) && <GoalMetricChart {...{ metric, addAvailableOptions, availableDemographics, sourceLabels: true }} />
      }
    </div>
  </div>
}
export const mapStateToProps = ({ metrics }) => ({ metrics });
export default connect(mapStateToProps)(MetricsPageContent);
