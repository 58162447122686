import React from "react";
import {connect} from "react-redux";
import {isBlank} from "../../../helpers/common";
import UserAvatarImage from "../../../common/UserAvatarImage";
import {isVisitor, userName} from "../../../helpers/user_helpers";
import {openModal} from "../../../store/modals/actions";

export const AssignmentSection = ({assignedUser, addClass = '', assignedCollaboratorEmail, modalType = '',
                                    openModal, isDSight = false, assignDriverEntry, user
                                  }) => {
  if (isBlank(assignedUser) && isBlank(assignedCollaboratorEmail)) return null
  if (assignDriverEntry && isVisitor(user)) return null;

  const name = userName(assignedUser, assignedCollaboratorEmail)

  return (
    <div className={`${addClass} mb-3`}>
      <div className={`d-inline-flex align-items-center ${isDSight ? "cursor-default" : "text-primary pointer"}`} onClick={isDSight ? null : () => openModal({ type: modalType })}>
        <UserAvatarImage user={assignedUser} pendingUserEmail={assignedCollaboratorEmail} />
        <div className='ms-2'>
          Assigned to {name}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ tree, current_user }) =>
  ({ default_user_avatar_url: tree.default_user_avatar_url, user: current_user });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignmentSection);
