import React, {useEffect} from 'react';
import OrgLogo from "../../tree_view/header/OrgLogo";
import {connect} from "react-redux";
import {AssignedDriversBanner} from "../../tree_view/header/Context";
import Decision from "../../models/decision";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useLocation, useNavigate} from "react-router-dom";
import {resetSidebars} from "../../store/sidebar/actions";
import {resetTree, updateTreeData} from "../../store/tree/common_actions";
import {reloadContacts} from "../../store/contacts/actions";
import {resetDecisionState} from "../../store/decision/common_actions";
import {resetChannelsData} from "../../store/channels/actions";
import {isPresent, successActions} from "../../helpers/common";
import {collectCollaborationInvites, redirectToHomeCallback} from "../../helpers/decision_helpers";
import {resetAssignedDriversData} from "../../store/assigned_drivers/actions";
import {forceReloadHomepageDecisions} from "../../store/homepage/actions";
import Button from "react-bootstrap/Button";
import {isExistingCollaborator, userAlreadyAddedAsAssignee, userHasAdmissions} from "../../helpers/user_helpers";
import {HamburgerMenuIconBtn} from "../../common/dropdowns";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import {driversToArray} from "../../helpers/drivers_helpers";

const SHOW_ALL_DRIVERS = 'See the full decision';

const AssignedDriversPageHeader = ({ tree, decision, current_org, activeLogo,
                                     resetSidebars, resetTree, resetAssignedDriversData,
                                     reloadContacts, resetDecisionState, resetChannelsData,
                                     forceReloadHomepageDecisions, updateTreeData, decision_admissions,
                                     current_user
                                  }) => {
  const { drivers } = tree;
  const decisionObject = new Decision(decision, drivers);
  const driversData = isPresent(drivers) ? driversToArray(drivers) : [];
  const showAssignedDriversButton = decision.sharable || userHasAdmissions(current_user, decision_admissions.decision_admissions);
  const navigate = useNavigate();
  const location = useLocation();
  const invites = isPresent(decision.invites) ? collectCollaborationInvites(decision) : []
  const isExistedCollaborator = isExistingCollaborator(current_user.email, invites, decision.user);

  const processBackClick = () => {
    if (!activeLogo) return;

    successActions(true, [forceReloadHomepageDecisions, resetSidebars, resetTree, reloadContacts, resetDecisionState, resetChannelsData, resetAssignedDriversData]);
    setTimeout(() => redirectToHomeCallback(true, navigate), 200);
  }

  const redirectToTree = () => {
    const path = location.pathname.replace('assigned_drivers', 'tree_viewer');
    navigate(path);
    successActions(true, [resetSidebars]);
    updateTreeData({ loaded: false, locationPath: location.pathname });
  }

  useEffect(() => {
    if (!tree.loaded) return;

    if(isExistedCollaborator && !userAlreadyAddedAsAssignee(current_user.email, driversData)) {
      redirectToTree();
    }
  }, [driversData, tree.loaded]);

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className={`logo-dropdown ${activeLogo ? 'pointer' : 'no-pointer'} d-none d-lg-flex me-2`}>
          <OrgLogo current_org={current_org} processBackClick={processBackClick}/>
        </div>
        <div className="w-100">
          <h5><AssignedDriversBanner decisionObject={decisionObject}/></h5>
        </div>
      </div>
      {
        showAssignedDriversButton && <div className="navbar-nav ms-auto">
          <div className='d-lg-none'>
            <HamburgerMenuIconBtn>
              <BaseHamburgerBtn onSelect={redirectToTree} title={SHOW_ALL_DRIVERS} />
              {activeLogo && <BaseHamburgerBtn onSelect={processBackClick} title="Go to homepage" />}
            </HamburgerMenuIconBtn>
          </div>
          <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
            <li className="nav-item ms-2">
              <Button onClick={redirectToTree}
                      className="btn btn-secondary w-100 d-none d-lg-flex align-items-center">
                {SHOW_ALL_DRIVERS}
              </Button>
            </li>
          </ul>
        </div>
      }
    </nav>
    <ProgressBar now={decisionObject.answeringProgress}/>
  </header>
}

const mapStateToProps = ({current_org, tree, decision, current_user, decision_admissions}) => ({
  current_org, tree, decision, current_user, decision_admissions
});

const mapDispatchToProps = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  reloadContacts: () => dispatch(reloadContacts()),
  resetDecisionState: () => dispatch(resetDecisionState()),
  resetChannelsData: () => dispatch(resetChannelsData()),
  resetAssignedDriversData: () => dispatch(resetAssignedDriversData()),
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  updateTreeData: (data) => dispatch(updateTreeData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignedDriversPageHeader);
