import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetSidebars } from "../../store/sidebar/actions";
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { successActions } from "../../helpers/common";
import { openModal } from "../../store/modals/actions";
import { resetSearchAndFilters } from "../../store/homepage/actions";
import OrgLogo from "../../tree_view/header/OrgLogo";
import AddDecisionView from "./AddDecisionView";
import HamburgerDropdown from "../../template_set_view/header/HamburgerDropdown";
import { isSummaryPending } from "../../helpers/set_helpers";
import ProgressMeter from "../../decision_set_view/ProgressMeter";
import DecisionsProgress from "../../decision_set_view/DecisionsProgress";
import SeeSummaryButton from "./SeeSummaryButton";

const DecisionFlowView = ({
                            isManager = true, isSetUser = false, isSetViewer = false,
                            resetSet, removeSet,
                            resetSidebars, openModal, reloadRecords, resetSearchAndFilters,
                            ...opts
                          }) => {
  const navigate = useNavigate();
  const processBackClick = useCallback(() => {
    successActions(true, [resetSet, resetSearchAndFilters, resetSidebars, reloadRecords]);
    redirectToHomeCallback(true, navigate);
  }, []);

  return <>
    <div className="d-flex align-items-center active">
      <div className="logo-dropdown pointer d-none d-lg-flex me-2">
        <OrgLogo {...{ ...opts, processBackClick }} />
      </div>
      <DecisionsProgress {...opts} />
    </div>
    <div className="navbar-nav ms-auto">
      <div className='d-lg-none'>
        <HamburgerDropdown {...{...opts, isManager, isSetUser, resetSet, removeSet, reloadRecords }} />
      </div>
      {
        (isManager || isSetUser || isSetViewer) &&
          <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
            <li className="nav-item ms-2">
              { !isSummaryPending(opts.set) && <SeeSummaryButton /> }
            </li>
          </ul>
      }
    </div>
  </>
};

const SetHeader = ({ addDecision = false, ...opts }) =>
  <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      {
        addDecision ? <AddDecisionView {...opts} isDecisionSet={true} /> : <DecisionFlowView {...opts} />
      }
    </nav>
    {!addDecision ? <ProgressMeter {...opts} /> : null}
  </header>

const mapStateToProps = ({ current_org, sidebar }) => ({ current_org, sidebar });
export default  connect(mapStateToProps, { openModal, resetSidebars, resetSearchAndFilters })(SetHeader);
