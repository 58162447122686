import { Font, StyleSheet } from '@react-pdf/renderer';
import fontRegular from "../../../../../assets/fonts/Montserrat/Montserrat-Regular.ttf";
import fontBold from "../../../../../assets/fonts/Montserrat/Montserrat-Medium.ttf";
import fontItalic from "../../../../../assets/fonts/Montserrat/Montserrat-Italic.woff";

Font.register({
  family: 'Montserrat',
  fonts: [
    {src: fontRegular},
    {src: fontBold, fontWeight: 'medium'},
    {src: fontItalic, fontStyle: 'italic' }
  ]
});

export const WHITE = "#FFFFFF";
export const PAGE_BACKGROUND = "#E5E5E5";
export const PRIMARY_COLOR = "#2487BF";
export const SECONDARY_COLOR = "#D0EEFF";
export const LIGHT_COLOR = "#F0F6FC";
export const LIGHT_GRAY = "#DCE8F3";
export const MUTED = "#8A949F";
export const DARK = "#1F2F3F";
export const GRAY_SEPARATING_LINE_COLOR = "#DCDFE2";
export const DARK_GRAY = '#677888';
export const BORDER_COLOR = '#CED4DA';


const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: PAGE_BACKGROUND,
    fontFamily: 'Montserrat'
  },
  section: {
    flexGrow: 1
  },
  body: {
    paddingHorizontal: 36,
    fontFamily: 'Montserrat'
  },
  container: {
    fontFamily: 'Montserrat',
    fontSize: 10.5,
    paddingTop: 64,
    paddingBottom: 54
  },
  pt0: {
    paddingTop: '0'
  },
  pt1: {
    paddingTop: '6'
  },
  pt2: {
    paddingTop: '10'
  },
  pt3: {
    paddingTop: '18'
  },
  pr0: {
    paddingRight: '0'
  },
  pr1: {
    paddingRight: '6'
  },
  pr2: {
    paddingRight: '10'
  },
  pr3: {
    paddingRight: '18'
  },
  pl0: {
    paddingLeft: '0'
  },
  pl1: {
    paddingLeft: '6'
  },
  pl2: {
    paddingLeft: '10'
  },
  pl3: {
    paddingLeft: '18'
  },
  mt0: {
    marginTop: '0'
  },
  mt1: {
    marginTop: '6'
  },
  mt2: {
    marginTop: '10'
  },
  mt3: {
    marginTop: '16'
  },
  mr0: {
    marginRight: '0'
  },
  mr1: {
    marginRight: '6'
  },
  mr2: {
    marginRight: '10'
  },
  mr3: {
    marginRight: '16'
  },
  mb0: {
    marginBottom: '0'
  },
  mb1: {
    marginBottom: '6'
  },
  mb2: {
    marginBottom: '10'
  },
  mb3: {
    marginBottom: '16'
  },
  ml0: {
    marginLeft: '0'
  },
  ml1: {
    marginLeft: '6'
  },
  ml2: {
    marginLeft: '10'
  },
  ml3: {
    marginLeft: '16'
  },
  h1: {
    fontSize: 16.5,
    fontFamily: 'Montserrat',
    color: DARK_GRAY,
    fontWeight: 500,
    lineHeight: 1.6,
    marginBottom: 18,
  },
  h2: {
    fontSize: 13.5,
    fontFamily: 'Montserrat',
    color: DARK,
    fontWeight: 500,
    lineHeight: 1.33,
    marginBottom: 6
  },
  h3: {
    fontSize: 13.5,
    fontFamily: 'Montserrat',
    color: DARK,
    fontWeight: 'normal',
    lineHeight: 1.33,
    marginBottom: 4.5
  },
  text: {
    fontSize: 10.5,
    fontWeight: 'normal',
    color: DARK,
    lineHeight: 1.55
  },
  textBold: {
    fontSize: 10.5,
    fontFamily: 'Montserrat',
    color: DARK,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold'
  },
  italic: {
    fontStyle: 'italic'
  },
  list: {
    paddingLeft: 10,
    overflow: "hidden"
  },
  header: {
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: 18,
    width: '100%'
  },
  pageNumber: {
    fontSize: 10.5,
    color: MUTED,
    fontFamily: 'Montserrat'
  },
  row: {
    flexDirection: 'row',
    fontFamily: 'Montserrat'
  },
  link: {
    color: PRIMARY_COLOR,
    fontFamily: 'Montserrat',
    fontSize: 10.5,
    textDecoration: 'none',
    marginBottom: 4.5,
    lineHeight: 1.5
  },
  linkText: {
    color: PRIMARY_COLOR,
    fontFamily: 'Montserrat',
    fontSize: 10.5,
    textDecoration: 'none'
  },
  muted: {
    color: MUTED,
    fontFamily: 'Montserrat'
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  avatarFont: {
    zIndex: 1,
    display: 'flex',
    alignSelf: 'center'
  }
});
export default styles
