import React, { Fragment, useEffect } from 'react';
import { connect } from "react-redux";
import AlertsSection, { checkFlashMessages } from "../alerts";
import HomepageHeader from "./header";
import { loadHomepageData, resetSearchAndFilters, updateHomepageData } from "../store/homepage/actions";
import { showAlert } from "../store/alerts/actions";
import { isBlank, isPresent } from "../helpers/common";
import EntryPoint from "../EntryPoint";
import TabManager from "./TabManager";
import HelpPanel from "./side_panel/HelpPanel";
import { Loader } from "../common/Loader";
import RequestAccess from './RequestAccess';
import { isOutOrgVisitor, isRequestAccessPage } from "../helpers/home_helpers";
import { updateUserData } from "../store/current_user/actions";
import { getScenarioIdFromParam } from "../forecast_simulator_scenario/helpers/helpers";
import { useSearchParams, useParams } from "react-router-dom";

const TABS_TO_TAB_NAMES = {
  'decisions': 'decisions',
  'create_decisions': 'create_decisions',
  'templates': 'templates',
  'search': 'search_decisions',
  'users': 'users',
  'groups': 'groups',
  'categories': 'categories',
  'dsight': 'dsight',
  'goals': 'dsight',
  'learn': 'learn',
  'report_insights': 'report_insights',
  'forecast_simulator': 'forecast_simulator'
}

const ERROR_MESSAGES = {
  'invalid_page': '<b>Sorry!</b> You don’t have permission to access that page.',
  'invalid_template': '<b>Sorry!</b> You don’t have permission to access that template.',
  'invalid_decision': '<b>Sorry!</b> You don’t have permission to access that decision.',
  'invalid_report': '<b>Sorry!</b> You don’t have permission to access that report.',
  'invalid_decision_set': '<b>Sorry!</b> You don’t have permission to access that decision flow.',
  'invalid_template_set': '<b>Sorry!</b> You don’t have permission to access that decision flow.'
}


const catchRequestedDecisionSlug = (searchParams, setSearchParams) => {
  const decisionSlug = searchParams.get('request_access_decision_slug');
  if(isPresent(decisionSlug)) {
    searchParams.delete('request_access_decision_slug')
    setSearchParams(searchParams);
    return decisionSlug;
  }
  return '';
};

const Sidebars = () =>
  <Fragment>
    <div className="sidebars">
      <div className="help-side-panels">
        <HelpPanel />
      </div>
    </div>
  </Fragment>

const Wrapper = ({children}) =>
  <Fragment>
    <AlertsSection />
    <HomepageHeader />
    {children}
    <Sidebars />
  </Fragment>

export const Homepage = ({
                           current_org, current_user, home,
                           loadHomepageData,
                           updateHomepageData, updateUserData,
                           showAlert, resetSearchAndFilters
                        }) => {
  const params = useParams()
  useEffect(() => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    if(isPresent(controllerName) && controllerName !== 'homepage' && !Object.keys(TABS_TO_TAB_NAMES).includes(objectSlug)) {
      EntryPoint.instance.objectSlug = '';
    }
    EntryPoint.instance.controllerName = 'homepage';
  }, [])
  useEffect(() => {
    if (isBlank(home.current_tab)) {
      if (isPresent(EntryPoint.instance.objectSlug)) {
        updateHomepageData({ current_tab: EntryPoint.instance.objectSlug })
      }
      if (!home.loaded) loadHomepageData()
    }
  }, [])
  useEffect(() => {
    if (isPresent(params?.tab)) {
      const newTab = TABS_TO_TAB_NAMES[params?.tab]
      if (isPresent(newTab)) {
        EntryPoint.instance.objectSlug = newTab
        updateHomepageData({ current_tab: newTab })
        resetSearchAndFilters()
      }
    }
  }, [params?.tab, home.loaded])
  useEffect(() => {
    if (isPresent(params?.tab)) {
      updateHomepageData({ request_access_decision_slug: catchRequestedDecisionSlug(searchParams, setSearchParams) })
    }
  }, [params?.tab])

  useEffect(() => {
    setTimeout(() => {
      checkFlashMessages(showAlert, ERROR_MESSAGES)
    }, 500)
  }, [])

  useEffect(() => {
    if (current_user.loaded && !current_user.loading && isOutOrgVisitor(current_user, current_org)) {
      updateUserData({ loaded: false })
    }
  }, [current_org, current_user])

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (current_org.loaded) {
      const forecast_scenario_id = getScenarioIdFromParam(document);
      if (isPresent(forecast_scenario_id) && !current_org.forecast_simulator_scenarios.some(hash => hash.id === forecast_scenario_id)) {
        searchParams.delete('forecast_scenario_id')
        setSearchParams(searchParams)
      }
    }
  }, [current_org.loaded, current_org.loading, searchParams]);

  if (home.loading) {
    return <Wrapper>
      <Loader />
    </Wrapper>;
  } else if(isRequestAccessPage(home)) {
    return <Wrapper>
      <RequestAccess decisionSlug={home.request_access_decision_slug} />
    </Wrapper>
  } else {
    return <Wrapper>
      <TabManager/>
    </Wrapper>;
  }
}
const mapStateToProps = ({ home, current_org, current_user }) => ({ home, current_org, current_user });
export default connect(mapStateToProps, {
  loadHomepageData, updateHomepageData, showAlert, resetSearchAndFilters, updateUserData
})(Homepage);
