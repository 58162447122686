import React, { useCallback } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isFirstWizardStep } from "../../helpers/wizard_helpers";
import {
  headerDispatchProps
} from "./index";
import {
  doneCallback,
  headerActionsDispatchProps,
} from "./ActionsDropdown";
import BaseHamburgerBtn from "../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../common/dropdowns";
import HelpHamburgerButton from "../../template_view/header/HelpHamburgerButton";
import {
  goToHomepageCallback,
  prevStepCallback,
} from "../../tree_wizard/header/helpers";

export const HamburgerDropdown = ({
                                    wizard, report,
                                    stepRef,
                                    removeReport,
                                    previewWizardTree, buildWizardTree, prevWizardStep, prevTemplateWizardStep,
                                    forceReloadHomepageReportInsights, updateTreeData,
                                    resetSidebars, resetWizard, resetTree, resetPlaybookNotes, resetSearchAndFilters, updateDecisionData,
                                    handleOpenPropertiesModal, anyAvailableReportProperties,
                                    ...opts
                                  }) => {
  const navigate = useNavigate();
  const goToHomepage = useCallback(goToHomepageCallback([resetSearchAndFilters, resetSidebars, resetWizard], navigate), [wizard])
  const doneHandler = useCallback(
    doneCallback({
      stepRef, wizard, navigate, slug: report.slug,
      actions: [resetSearchAndFilters, resetSidebars, resetWizard, forceReloadHomepageReportInsights]
    }), [wizard, report.slug])
  const prevStep = useCallback(prevStepCallback(false, resetSidebars, prevTemplateWizardStep, prevWizardStep), [wizard])

  return <HamburgerMenuIconBtn>
    {anyAvailableReportProperties && (
      <BaseHamburgerBtn onSelect={handleOpenPropertiesModal} title="Report properties" />
    )}
    <BaseHamburgerBtn onSelect={prevStep} hidden={isFirstWizardStep(wizard)} title="Back to previous step"/>
    <BaseHamburgerBtn onSelect={doneHandler}  disabled={wizard.submit || wizard.disabledSubmit} title={'Done'}/>
    <HelpHamburgerButton />
    <BaseHamburgerBtn onSelect={goToHomepage} title="Go to homepage"/>
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({wizard, report }) => ({ wizard, report });
const mapDispatchToProps = (dispatch) => ({
  ...headerDispatchProps(dispatch),
  ...headerActionsDispatchProps(dispatch)
});
const wrapper = React.forwardRef((props, ref) => <HamburgerDropdown {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
