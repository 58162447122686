import React, { useMemo } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import { JoditTextEditor } from '../../common/JoditTextEditor';
import { RichTextEditor } from "../../common/RichTextEditor";
import QuestionRow from "./helpers/QuestionRow";
import DriverTypesRow, { useDefaultDriverTypesEffect } from "./helpers/DriverTypesRow";
import WizardDataSources from "../../tree_wizard/data_sources";
import { saveUploadingSources } from  "../../store/modals/actions";
import Driver from "../../models/driver";
import {SelectedChoiceEntryType} from "../../tree_view/side_panel/driver_input/ChoicesDriverInput";
import ChoicesSection from "../../tree_view/side_panel/driver_input/ChoicesSection";
import NumericInput from "../../tree_view/side_panel/driver_input/NumericInput";
import {onEnterKeyDown} from "./DriverDetailsModal";
import AiInstructionsSection from "../../tree_view/modals/helpers/AiInstructionsSection";

const SummaryHeader = () => <h3>Summary</h3>

const SummaryRow = ({ answer, setAnswer }) =>
  <div className="mb-3">
    <SummaryHeader />
    <RichTextEditor theme='bubble'
                    quillId={'Summary'}
                    value={answer} setValue={setAnswer}
                    placeholder="Enter a brief summary"
                    className={'one-line'}
    />
  </div>

const NumericRow = ({ driver, answer, setAnswer }) =>
  <div className='mb-3'>
    <SummaryHeader />
    <NumericInput driver={driver}
                  value={answer}
                  setValue={setAnswer}
    />
  </div>

const ChoiceRow = ({ driver, setChoices, choices }) =>
  <div className='mb-2'>
    <h3>Choices</h3>
    <SelectedChoiceEntryType driver={driver} />
    <ChoicesSection {...{driver, isHistoricalDecision: true, setChoices, choices}} />
  </div>

const DetailsRow = ({ explanation, setExplanation, optional = true }) =>
  <div className="mb-3">
    <h3>
      Details
      { optional ?  <span className="h5 fw-normal text-muted">(optional)</span> : null }
    </h3>
    <JoditTextEditor className="jodit-container-default"
                     value={explanation}
                     placeholder="Enter any additional detail, explanation, or analysis"
                     setValue={setExplanation}
                     autofocus={false}/>
  </div>

const HistoricalDriverDetailsModal = ({
                                        title, submitState = false,
                                        question,
                                        orgDriverTypes, show,
                                        key = '', isDSight = false,
                                        onClose, onSubmitModal = null, setDriverTypeSlug,
                                        driverResponseType, setDriverResponseType,
                                        choices, setChoices, decision,
                                        driverData,
                                        answer, setAnswer,
                                        explanation, setExplanation,
                                        driverTypeSlug, onChangeDriverType,
                                        saveUploadingSources,
                                        numberFormat, setNumberFormat,
                                        dueDate, setDueDate,
                                        ratingLabels, setRatingLabels,
                                        ratingScale, setRatingScale,
                                        onSubmitDrivers, setNotes,
                                        aiInstructions, setAiInstructions,
                                        ...opts
                                      }) => {
  const availableDriverTypes = useMemo(() => orgDriverTypes.available_types, [orgDriverTypes]);
  const driver = driverData.driver
  const driverObj = new Driver(driver);

  useDefaultDriverTypesEffect(orgDriverTypes);

  return <Modal enforceFocus={false}  key={key} size="lg" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>{title || 'Edit driver'}</h2>
      <form>
        <QuestionRow onKeyDown={onEnterKeyDown} {...{ ...opts, question, isDSight }} />
        {
          driverObj.isOpen ?
            <SummaryRow {...{ answer, setAnswer }} /> :
            driverObj.isNumbering ?
              <NumericRow {...{ answer, setAnswer, driver }}  /> :
              <ChoiceRow {...{ driver, setChoices, choices }} />
        }
        <DetailsRow {...{ explanation, setExplanation, optional: false }} />
        <WizardDataSources {...{
          ...opts,
          driverData, saveUploadingSources, onSubmitDrivers,
          isDriverModal: true, isHistoricalDecision: true
        }} />
        <AiInstructionsSection {...{aiInstructions, setAiInstructions, className: 'mt-3'}} />
        <DriverTypesRow {...{ driverTypeSlug, onChangeDriverType, isDSight, availableDriverTypes }} />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={onSubmitModal || onClose} disabled={isBlank(question) || submitState} />
  </Modal>
};

const mapStateToProps = ({ org_driver_types, decision }) => {
  return {
    orgDriverTypes: { available_types: [ ...org_driver_types.available_types ] },
    decision
  }
};
export default connect(mapStateToProps, {
  saveUploadingSources
})(HistoricalDriverDetailsModal);
