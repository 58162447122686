import { useMemo, useEffect } from 'react';
import { useWorker } from 'react-hooks-worker';
import { isPresent, isBlank } from '../../helpers/common';
import { hideOverlay } from './custom_loading_overlay';
import { handleRunModelEffect } from './ag_grid_run_model';

export const WORKERS_COUNT = 5;

const scenarioRowsWorker = () => new Worker(
  new URL('../workers/forecastScenarioDataProcessor.worker.js', import.meta.url),
  {type: 'module', name: 'forecastScenarioDataProcessor'}
);


const handleResetEffect = ({ gridRef, forecast_simulator_scenario, preparedRows, updateCachedRows, updateScenarioData }) => {
  updateCachedRows(preparedRows);
  gridRef.current.api.applyTransaction({ update: preparedRows, remove: forecast_simulator_scenario.reset_all_delete_rows });
  gridRef.current.api.forEachNode((rowNode) => {
    if(rowNode.leafGroup) rowNode.setExpanded(false);
  });
  updateScenarioData({ reset_all_to_default: false, reset_all_new_rows: [], reset_all_new_rows_cmus: [], reset_all_delete_rows: [] });
}

export const useRowsWorkerOpts = (dependencies) => {
  const {
    allowWorkerRun,
    gridReady,
    scenario_id,
    config,
    scenario,
    scenarioRows,
    currentCmuGroups,
    timeScale,
    cachedAgGridRows
  } = dependencies;

  return useMemo(() => ({
    allowWorkerRun,
    gridReady,
    scenario,
    rows: scenarioRows,
    config,
    currentCmuGroups,
    timeScale,
    byCmuGroups: true,
    cachedData: cachedAgGridRows[scenario_id]
  }), [gridReady, allowWorkerRun, scenarioRows, currentCmuGroups, timeScale, cachedAgGridRows]);
};

export const handleRowsWorkers = ({
                                   allowWorkerRun,
                                   gridReady,
                                   forecast_simulator_scenario,
                                   forecastScenario,
                                    workerCmuGroups,
                                   cachedAgGridRows,
                                   setPreparedRowsPortion1,
                                   setPreparedRowsPortion2,
                                   setPreparedRowsPortion3,
                                   setPreparedRowsPortion4,
                                   setPreparedRowsPortion5
                                  }) => {

  const rowsWorkerOpts1 = useRowsWorkerOpts({
    allowWorkerRun,
    gridReady,
    config: forecast_simulator_scenario.config,
    scenario: forecast_simulator_scenario.scenario,
    scenario_id: forecast_simulator_scenario.scenario_id,
    scenarioRows: forecastScenario.scenario_rows,
    timeScale: forecastScenario.timeScale,
    currentCmuGroups: workerCmuGroups[0],
    cachedAgGridRows
  });

  const rowsWorkerOpts2 = useRowsWorkerOpts({
    allowWorkerRun,
    gridReady,
    config: forecast_simulator_scenario.config,
    scenario: forecast_simulator_scenario.scenario,
    scenario_id: forecast_simulator_scenario.scenario_id,
    scenarioRows: forecastScenario.scenario_rows,
    timeScale: forecastScenario.timeScale,
    currentCmuGroups: workerCmuGroups[1],
    cachedAgGridRows
  });

  const rowsWorkerOpts3 = useRowsWorkerOpts({
    allowWorkerRun,
    gridReady,
    config: forecast_simulator_scenario.config,
    scenario: forecast_simulator_scenario.scenario,
    scenario_id: forecast_simulator_scenario.scenario_id,
    scenarioRows: forecastScenario.scenario_rows,
    timeScale: forecastScenario.timeScale,
    currentCmuGroups: workerCmuGroups[2],
    cachedAgGridRows
  });

  const rowsWorkerOpts4 = useRowsWorkerOpts({
    allowWorkerRun,
    gridReady,
    config: forecast_simulator_scenario.config,
    scenario: forecast_simulator_scenario.scenario,
    scenario_id: forecast_simulator_scenario.scenario_id,
    scenarioRows: forecastScenario.scenario_rows,
    timeScale: forecastScenario.timeScale,
    currentCmuGroups: workerCmuGroups[3],
    cachedAgGridRows
  });

  const rowsWorkerOpts5 = useRowsWorkerOpts({
    allowWorkerRun,
    gridReady,
    config: forecast_simulator_scenario.config,
    scenario: forecast_simulator_scenario.scenario,
    scenario_id: forecast_simulator_scenario.scenario_id,
    scenarioRows: forecastScenario.scenario_rows,
    timeScale: forecastScenario.timeScale,
    currentCmuGroups: workerCmuGroups[4],
    cachedAgGridRows
  });

  const { result: preparedRows1 } = useWorker(scenarioRowsWorker, rowsWorkerOpts1);
  const { result: preparedRows2 } = useWorker(scenarioRowsWorker, rowsWorkerOpts2);
  const { result: preparedRows3 } = useWorker(scenarioRowsWorker, rowsWorkerOpts3);
  const { result: preparedRows4 } = useWorker(scenarioRowsWorker, rowsWorkerOpts4);
  const { result: preparedRows5 } = useWorker(scenarioRowsWorker, rowsWorkerOpts5);

  useEffect(() => {
    if(isPresent(preparedRows1)) setPreparedRowsPortion1(preparedRows1);
  }, [preparedRows1]);

  useEffect(() => {
    if(isPresent(preparedRows2)) setPreparedRowsPortion2(preparedRows2);
  }, [preparedRows2]);

  useEffect(() => {
    if(isPresent(preparedRows3)) setPreparedRowsPortion3(preparedRows3);
  }, [preparedRows3]);
  useEffect(() => {
    if(isPresent(preparedRows4)) setPreparedRowsPortion4(preparedRows4);
  }, [preparedRows4]);
  useEffect(() => {
    if(isPresent(preparedRows5)) setPreparedRowsPortion5(preparedRows5);
  }, [preparedRows5]);
};

export const useOnWorkerDoneEffect = (dependencies) => {
  const {
    gridRef,
    gridReady,
    changeCurrentCmuGroups,
    setChangeCurrentCmuGroups,
    preparedRows,
    forecastScenario,
    forecastBenchmarkScenario,
    editedCells,
    runModelCells,
    updateOpenedGroups,
    updateCachedRows,
    updateTableCells,
    rowsDataWithEditedCells,
    rowData,
    forecast_simulator_scenario,
    setRunModelActive,
    updateScenarioData,
    runModelRowsIds,
    editedCellsRowIds,
    setRowData,
    setInitialWorkerRun,
    prepareRowsOnChange,
    initialWorkerRun,
    afterRenderActionsWrapper
  } = dependencies;

  useEffect(() => {
    if (gridReady && isPresent(preparedRows)) {
      if (forecast_simulator_scenario.run_model) {
        handleRunModelEffect({
          gridRef,
          preparedRows,
          editedCells,
          runModelCells,
          forecastScenario,
          forecastBenchmarkScenario,
          updateCachedRows,
          setRunModelActive,
          updateScenarioData,
          runModelRowsIds,
          editedCellsRowIds,
          updateOpenedGroups,
          updateTableCells,
          rowsDataWithEditedCells
        });
      } else if (forecast_simulator_scenario.reset_all_to_default) {
        handleResetEffect({ gridRef, forecast_simulator_scenario, preparedRows, updateCachedRows, updateScenarioData });
      } else if (isBlank(rowData) || !initialWorkerRun || changeCurrentCmuGroups) {
        updateCachedRows(preparedRows);
        const newRowData = prepareRowsOnChange({ preparedRows, gridRef, forecastScenario });
        setRowData(newRowData);
        setChangeCurrentCmuGroups(false);
        setInitialWorkerRun(true);
        afterRenderActionsWrapper();
      }
      hideOverlay(gridRef.current.api);
    }
  }, [preparedRows]);
};
