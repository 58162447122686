import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadTemplate } from "../store/template/actions";
import { updateTemplateData } from "../store/template/common_actions";
import { loadOrgDriverTypes } from "../store/org_driver_types/actions";
import EntryPoint, { updateEntryPointData } from "../EntryPoint";
import AlertsSection from "../alerts";
import TemplatePreviewTree from "../tree_view/accordion_tree/TemplatePreviewTree";
import TemplatePreviewHeader, { getTemplateSetParamsFromTemplate, templateSetParams } from "./header";
import TemplateDetailsPanel from "./side_panel/TemplateDetailsPanel";
import TemplateTreePanel from "./side_panel/TemplateTreePanel";
import { loadOrgCategories } from "../store/org_categories/actions";
import { Loader } from "../common/Loader";
import { isPresent } from "../helpers/common";
import { isDSightTemplate } from "../helpers/template_helpers";
import { isTemplateLoaded, loadTemplateCallbacks } from "../template_view";
import PreviewDriverDetailsModal, {
  showPreviewDriverDetailsModal
} from "../tree_view/modals/PreviewDriverDetailsModal";
import PreviewDecisionDetailsModal, {
  showPreviewDecisionDetailsModal
} from "../tree_view/modals/PreviewDecisionDetailsModal";
import { closeModal } from "../store/modals/actions";
import TemplatePlaybookPanel from "../template_view/side_panel/TemplatePlaybookPanel";
import SidebarTreeNavigationPreview from "./navigation";
import DecisionSetModal, { showDecisionSetModal } from "../homepage/modals/DecisionSetModal";
import { loadTemplateSet } from "../store/template_set/actions";
import DecisionOrderPanel from "../template_set_view/side_panel/DecisionOrderPanel";
import DecisionSetPanel from "../tree_view/side_panel/DecisionSetPanel";
import { loadDecisionSet } from "../store/decision_set/actions";
import AddToSetModal, { showAddToSetModal } from "../template_set_view/modals/AddToSetModal";
import StartDecisionModal, { showStartDecisionModal } from "../homepage/modals/StartDecisionModal";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import StartUnstartedDecisionModal, {
  showStartUnstartedDecisionModal
} from "../decision_set_view/modals/StartUnstartedDecisionModal";
import RatingsAndWeightsPanel from "../tree_view/side_panel/RatingsAndWeightsPanel";
import { getScenarioIdFromParam } from "../forecast_simulator_scenario/helpers/helpers";
import CollaboratorsPanel from "../tree_view/side_panel/CollaboratorsPanel";
import DataSourcesPanel from "../tree_view/side_panel/DataSourcesPanel";
import CollaboratorsSection from "../template_view/side_panel/sections/CollaboratorsSection";
import {setCollaboratorsSidebarOpen, setDataSourcesSidebarOpen, setDecisionSidebarOpen} from "../store/sidebar/actions";
import DataSourcesSection from "./side_panel/sections/DataSourcesSection";
import {decisionDataSources} from "../models/decision";
import {TemplateOrDSightDataSourcesTypes} from "../models/data_source";
import {Template} from "../models/template";

export const checkLoadedTemplateInfo = (params, updateTemplateData, afterCallback = () => null) => {
  if (EntryPoint.instance.objectSlug !== params?.id) {
    updateEntryPointData(params, 'templates', afterCallback)
    updateTemplateData({loaded: false})
  }
}

export const Sidebars = ({ isParentTemplateSet = false, isUnstartedTemplateInSet = false }) =>
  <Fragment>
    <div className="sidebars">
      <RatingsAndWeightsPanel isTemplate={true} preview={true} hideEditDriverWeights={true}/>
      <TemplateDetailsPanel key="decision-details-side-panel" />
      <CollaboratorsPanel key="collaborators-side-panel" isTemplate={true}>
        <CollaboratorsSection preview={true} />
      </CollaboratorsPanel>
      <DataSourcesPanel>
        <DataSourcesSection addClass="px-3 pb-3" preview={true} />
      </DataSourcesPanel>
      <TemplateTreePanel key="template-tree-panel" preview={true} />
      <TemplatePlaybookPanel addClass={'left-side-panel-position tree-page'} preview={true} />
      {
        isUnstartedTemplateInSet ?
          <DecisionSetPanel preview={true} addClass="left-side-panel-position tree-page" /> :
          isParentTemplateSet ?
            <DecisionOrderPanel addClass="left-side-panel-position tree-page" isTemplate={true} preview={true}/> :
            null
      }
    </div>
  </Fragment>

export const Wrapper = ({
                          children, closeModal,
                          modal, template_set,
                          isDecisionSet, decisionSet,
                          ...opts
                       }) =>
  <Fragment>
    <AlertsSection />
    <TemplatePreviewHeader {...opts} />
    <div className="d-flex vertical-tree-container">
      <SidebarTreeNavigationPreview {...opts} />
      {children}
    </div>
    <Sidebars {...opts} />
    <div className="modals">
      <PreviewDriverDetailsModal shown={showPreviewDriverDetailsModal(modal)} onClose={closeModal}/>
      <PreviewDecisionDetailsModal shown={showPreviewDecisionDetailsModal(modal)} onClose={closeModal}/>
      <DecisionSetModal shown={showDecisionSetModal(modal)} onClose={closeModal}/>
      <StartDecisionModal shown={showStartDecisionModal(modal)} onClose={closeModal} {...{ isDecisionSet, decisionSetSlug: decisionSet }} />
      <AddToSetModal shown={showAddToSetModal(modal, template_set)} onClose={closeModal}/>
      <StartUnstartedDecisionModal shown={showStartUnstartedDecisionModal(modal)} onClose={closeModal}/>
    </div>
  </Fragment>

export const loadPreviewTemplateCallbacks = ({
                                               template, loadTemplate, openCollaboratorsSideBar,
                                               openDecisionDetailsSideBar, openDataSourcesSideBar, ...opts
                                             }) => {
  loadTemplateCallbacks(opts)
  checkLoadingEffect(template, () => {
    const opts = {}
    const forecast_scenario_id = getScenarioIdFromParam(document);
    if (isPresent(forecast_scenario_id)) opts.forecast_scenario_id = forecast_scenario_id

    const getParams = new URLSearchParams(document.location.search)
    switch (getParams.get('side_bar_open')) {
      case 'collaborators':
        openCollaboratorsSideBar();
        break;
      case 'details':
        openDecisionDetailsSideBar();
        break;
      case 'data_sources':
        openDataSourcesSideBar();
        break;
    }

    loadTemplate(opts)
  })
}

export const TemplatePreview = ({
                                  template, modal, template_set, decision_set,
                                  closeModal, loadTemplateSet, loadDecisionSet, tree,
                                  ...opts
                                }) => {
  loadPreviewTemplateCallbacks({ template, ...opts })

  const params = useParams()
  if (isTemplateLoaded(template, params) && isDSightTemplate(template)) {
    const navigate = useNavigate();
    navigate(`/dsight/templates/${template.slug}/preview`);
  }

  const { isParentTemplateSet, parentTemplateSet } = getTemplateSetParamsFromTemplate(template);
  const {
    decisionSet, isUnstartedTemplateInSet, isDecisionSet
  } = templateSetParams()
  const loadTemplateSetForTemplate = () => loadTemplateSet({
    scope: 'template_sets', setSlug: parentTemplateSet
  })

  useEffect(() => {
    if (template_set.loaded && template.loaded) {
      if (isParentTemplateSet && template_set.slug !== parentTemplateSet) {
        loadTemplateSetForTemplate()
      }
    }
  }, [template, template_set])

  checkLoadingEffect(template_set, loadTemplateSetForTemplate, {
    condition: ([template]) => isPresent(template.template_set_slug),
    another_stores: [template]
  })
  checkLoadingEffect(decision_set, () => loadDecisionSet({
    scope: 'decision_sets', slug: decisionSet
  }), {
    condition: () => isUnstartedTemplateInSet
  })

  const [collaboratorsInvites, setCollaboratorsInvites] = useState([]);
  const [sortedRelatedDataSources, setSortedRelatedDataSources] = useState([]);

  useEffect(() => {
    setCollaboratorsInvites(new Template(template).collaborator_admissions);
  }, [template?.admissions]);

  useEffect(() => {
    setSortedRelatedDataSources(decisionDataSources(tree.data_sources || [], tree.drivers, TemplateOrDSightDataSourcesTypes))
  }, [tree]);

  if (isStoreLoading(template)) {
    return <Wrapper {...{ modal, closeModal, template_set, isParentTemplateSet, tree }} >
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper {...{ modal, closeModal, template_set, isParentTemplateSet,
      isUnstartedTemplateInSet, isDecisionSet, decisionSet, template, isTemplateLoaded: true,
      collaboratorsInvites, sortedRelatedDataSources }}>
      <TemplatePreviewTree />
    </Wrapper>;
  }
}
export const mapStateToProps = ({ tree, template, org_categories, org_driver_types, modal, template_set, decision_set }) => ({
  template, org_categories, org_driver_types, modal, template_set, decision_set, tree
});
export const mapDispatchToProps = (dispatch) => ({
  loadTemplate: (data = {}) => { dispatch(loadTemplate(data)) },
  loadTemplateSet: (hash) => dispatch(loadTemplateSet(hash)),
  loadDecisionSet: (hash) => dispatch(loadDecisionSet(hash)),
  updateTemplateData: (data) => { dispatch(updateTemplateData(data)) },
  loadOrgCategories: () => { dispatch(loadOrgCategories()); },
  loadOrgDriverTypes: () => { dispatch(loadOrgDriverTypes()) },
  closeModal: () => { dispatch(closeModal()) },
  openCollaboratorsSideBar: () => {
    dispatch(setCollaboratorsSidebarOpen(true));
  },
  openDecisionDetailsSideBar: () => {
    dispatch(setDecisionSidebarOpen(true))
  },
  openDataSourcesSideBar: () => {
    dispatch(setDataSourcesSidebarOpen(true))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreview);

