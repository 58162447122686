import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { createMessage, loadConversation, updateConversation } from "../../../../../utils/Api";
import ChatInput from "./ChatInput";
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from "ramda";
import parse from 'html-react-parser';
import Button from "react-bootstrap/Button";
import SystemMessage from "./SystemMessage";
import UserMessage from "./UserMessage";
import { isBlank } from "../../../../../helpers/common";
import { ThreeDots } from "react-loader-spinner";
import { setSavedChats, useSavedChatsStore } from "../stores/saved-chats.store";

const Chat = () => {
  let { goal_id, conversation_id } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [disabledInput, setDisabledInput] = useState(false);
  const conversations = useSavedChatsStore((state) => state.conversations);
  const conversationsIds = conversations.map((c) => c.id);

  useEffect(() => {
    async function fetchConversation() {
      loadConversation(goal_id, conversation_id).then((res) => {
        if (res.status === 200) {
          const {data: { conversation }} = res;
          if(isBlank(conversation)) {
            setMessages([]);
            navigate(`/ask_cloverpop/goals/${goal_id}/conversations`, { replace: true })
            return;
          }
          setMessages(conversation.messages);
        }
      })
    };
    if(conversation_id) {
      fetchConversation();
    } else {
      setMessages([]);
    }
  }, [conversation_id]);

  const newMessage = (content) => {
    return {
      id: `new-${uuidv4()}`,
      content,
      role: 'user',
    }
  }

  const replaceUrl = (message) => {
    if(!conversation_id) {
      conversation_id = message.dsight_conversation_id;
      navigate.replace(`/ask_cloverpop/goals/${goal_id}/conversations/${conversation_id}`)
    }
  }

  const handleSubmit = async (content) => {
    if (isBlank(content)) return;

    setDisabledInput(true);
    const loadingID = `loading-${uuidv4()}`
    const loadingMessage = { id: loadingID, content: '...', role: 'system' }
    setMessages((prevMessages) => {
      return [...prevMessages, newMessage(content), loadingMessage]
    })

    const response = await createMessage(content, goal_id, conversation_id);
    if (response.status === 200) {
      const { data: { message } } = response;
      setMessages((prevMessages) => {
        return [...prevMessages.filter((m) => {
          return m.id !== loadingID
        }), message]
      })
      replaceUrl(message);
    }
    setDisabledInput(false);
  };

  const handleSave = () => {
    if(!conversation_id || conversationsIds.includes(conversation_id)) {
      return;
    }
    updateConversation(goal_id, conversation_id, {saved: true}).then((res) => {
      if(res.status !== 200) return;
      const newConversation = res.data.conversation;

      const updatedConversations = [newConversation, ...conversations];
      setSavedChats(updatedConversations);
    });

  }

  return <>
    {
      !isEmpty(messages) && messages.map(({id, content, role}) => {
        return role === 'system' ?
          <SystemMessage key={id} >
            {
              id.includes('loading') ?
                <ThreeDots height="30" width="30" radius="5" color="#8A949F" ariaLabel="three-dots-loading" visible={true}/> :
                parse(content)
            }
          </SystemMessage> :
          <UserMessage key={id}>{ parse(content) }</UserMessage>;
      })
    }

    <ChatInput defaultValue={''} submit={handleSubmit} askCloverpopPage={true} disabledInput={disabledInput}/>
    <div className="d-flex mt-3">
      <Button className="btn-secondary w-50 me-1" onClick={handleSave}>Save this chat</Button>
      <Button className="btn-secondary w-50 ms-1" onClick={() => navigate(`/ask_cloverpop/goals/${goal_id}/conversations`)}>Start new chat</Button>
    </div>
  </>
};

export default Chat;
