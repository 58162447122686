import React from 'react';
import { connect } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom'
import { resetWizard } from "../../store/wizard/actions";
import { updateTreeData } from "../../store/tree/common_actions";
import {
  isCompletedWizard, isDecisionUploader, isHistoricalDecision,
  isTreeBuilder,
  isTreeCollaborators,
  isTreeDecisionCategorization,
  isTreeFraming, isTreePreview
} from "../../helpers/wizard_helpers";
import FramingDecisionStep from "./steps/FramingDecisionStep";
import CollaboratorsDecisionStep from "./steps/CollaboratorsDecisionStep";
import TreeBuilderStep from "./steps/TreeBuilderStep";
import TreePreviewStep from "./steps/TreePreviewStep";
import TreeDecisionCategorizationStep from "./steps/TreeDecisionCategorizationStep";
import HistoricalDecisionStep from "./steps/HistoricalDecisionStep";
import EntryPoint from "../../EntryPoint";
import { isBlank, isPresent, successActions } from "../../helpers/common";
import { reloadContacts } from "../../store/contacts/actions";
import { isDecisionSetDecision } from "../../helpers/decision_helpers";
import { resetDecisionState } from "../../store/decision/common_actions";
import { showAlert } from "../../store/alerts/actions";
import { DURATION } from "../../alerts";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import DecisionUploaderStep from "./steps/DecisionUploaderStep";

const WizardStep = ({
                      wizard, decision,
                      resetWizard, reloadContacts, resetDecision, resetDecisionSet, updateTreeData,
                      showAlert, stepRef
                    }) => {
  const navigate = useNavigate()
  if (isPresent(wizard.error) && isDecisionSetDecision(decision)) {
    successActions(true, [
      resetWizard, resetDecision, resetDecisionSet,
      () => showAlert({
        text: 'Decision wizard processed by another flow member',
        type: 'danger',
        addClass: 'text-center',
        dismissible: false,
        duration: DURATION
      })
    ]);
    setTimeout(() => {
      navigate(`/decision_sets/${decision.decision_set_slug}`, { replace: true });
    }, 50)
    return null
  }
  if (isCompletedWizard(wizard)) {
    const { objectSlug, controllerName } = EntryPoint.instance;
    successActions(true, [
      reloadContacts, resetWizard, () => updateTreeData({ loaded: false }),
      ...(isDecisionSetDecision(decision) ? [resetDecision, resetDecisionSet] : [])
    ])
    const decisionSetParam = isDecisionSetDecision(decision) ? '?decision_set' : '';
    setTimeout(() => {
      navigate(`/${controllerName}/${objectSlug}/tree_viewer${decisionSetParam}`);
    }, 500);
    return null;
  }
  if (isTreePreview(wizard)) return <TreePreviewStep ref={stepRef} />
  if (isHistoricalDecision(wizard)) return <HistoricalDecisionStep ref={stepRef} />
  if (isTreeDecisionCategorization(wizard)) return <TreeDecisionCategorizationStep ref={stepRef} />
  if (isTreeFraming(wizard)) return <FramingDecisionStep ref={stepRef} />
  if (isTreeCollaborators(wizard)) return <CollaboratorsDecisionStep ref={stepRef} />
  if (isTreeBuilder(wizard)) return <TreeBuilderStep ref={stepRef} />
  if (isDecisionUploader(wizard)) return <DecisionUploaderStep ref={stepRef} />

  return null;
}

export const StepsWizard = ({ wizard,...opts }) =>
  <div className="container-fluid p-0">
    <span hidden={isBlank(wizard.error)}>Error: {wizard.error}</span>

    <WizardStep {...opts} wizard={wizard} />
  </div>

const mapStateToProps = ({ wizard, decision }) => ({ wizard, decision });
const mapDispatchToProps = (dispatch) => ({
  resetWizard: () => dispatch(resetWizard()),
  reloadContacts: () => dispatch(reloadContacts()),
  resetDecision: () => dispatch(resetDecisionState()),
  resetDecisionSet: () => dispatch(resetDecisionSetData()),
  updateTreeData: (data) => dispatch(updateTreeData(data)),
  showAlert: (data) => dispatch(showAlert(data)),
});
const wrapper = React.forwardRef((props, ref) => <StepsWizard {...props} stepRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);
