import React, { useCallback, useMemo } from 'react';
import { connect } from "react-redux";
import { Highlighter, Token, Typeahead } from 'react-bootstrap-typeahead';
import { loadContacts } from "../store/contacts/actions";
import { isGroupContact, isWebGroupContact } from "../helpers/decision_helpers";
import { isBlank, isEmail, isPresent, uniqueByCallback } from "../helpers/common";
import { isEnter } from "../helpers/keys_helpers";
import { allContacts } from "../helpers/filter_helpers";
import { autoFocusRef } from "./BaseTypehead";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";
import { FILTER_BY_FIELDS } from "../tree_view/modals/helpers/share_helpers";

const MEMBER_ROLE = 'member'

const renderNewItemTag = (option, { onRemove, tabIndex, disabled = false, org_role }, index) =>
  <Token {...{ onRemove, tabIndex, option, key: index }} disabled={disabled || (org_role === MEMBER_ROLE && index === 0)}>
    {option.full_name || option.email}
  </Token>

const renderNewItem = (option, { text }, _index) =>
  <Highlighter search={text}>{option.full_name || option.value}</Highlighter>

const ContactsTypehead = ({
                            allowGroups = false,
                            allowUsers = true,
                            allowNew = true,
                            multiple = true,
                            autoFocus = false,
                            disabled = false,
                            placeholder = '',
                            id="invites-input",
                            values = [],
                            excludeValues = [],
                            setValues = (_) => {},
                            setLoaded = () => {},
                            contactsData, loadContacts,
                            current_user,
                            minLength = 1,
                            errors = {},
                            setErrors = () => {},
                            flip = true
                          }) => {
  checkLoadingEffect(contactsData, loadContacts)

  const allContactsData = useMemo(() => allContacts(contactsData, current_user), [contactsData.contacts, current_user]);
  const ref = autoFocusRef({ autoFocus, setLoaded })

  const findItemByEmail = useCallback((email) =>
    (allContactsData.find(i => i.email === email) || { value: email, email })
  , [contactsData])

  const alreadyAddedItem = useCallback((item) =>
    (excludeValues.some(u => u.email === item.email.toLowerCase()))
  , [excludeValues])

  const filterNewItems = useCallback((newItems) =>
    uniqueByCallback(newItems.filter(item => isEmail(item.email) && !alreadyAddedItem(item)),
      (a) => a.value.toLowerCase())
  , [excludeValues])

  const parseInputText = useCallback(() => {
    const value = ref.current.getInput().value;
    if (isBlank(value)) return;
    const emails = [];
    const invalid_emails = [];
    const existing_emails = [];
    value.split(',').filter(val => val.trim()).forEach(row => {
      if (isEmail(row.trim())) {
        const item = findItemByEmail(row.trim())
        emails.push(item)
        values.push(item)
        if(alreadyAddedItem(item)) {
          existing_emails.push(row.trim())
        }
      } else {
        invalid_emails.push(row.trim());
      }
    })
    setErrors({ ...errors, invalid_emails: invalid_emails, existing_emails: existing_emails });
    if (isPresent(emails)) {
      setTimeout(() => setValues(filterNewItems(values)), 50)
      ref.current.clear()
    }
  }, [allowNew, ref, contactsData, values, setValues])

  const onKeyDown = useCallback((e) => {
    if (!allowNew) return e;

    if (isEnter(e.keyCode)) {
      parseInputText()
    }
  }, [allowNew, ref, contactsData, values, setValues])

  const onBlur = useCallback(parseInputText, [allowNew, ref, contactsData, values, setValues])

  const onChangeNewItems = useCallback((selected) => {
    const newItems = [];
    selected.forEach(item => {
      if (typeof item === "string") {
        newItems.push(findItemByEmail(item))
      } if (isBlank(item.value) && isPresent(item.email)) {
        newItems.push(findItemByEmail(item.email))
      } else {
        item.value.split(',').forEach(value => {
          if (isEmail(value)) {
            const user_item = findItemByEmail(value)
            user_item.email=value

            newItems.push(user_item)
          }
        })
      }
    })
    setValues(filterNewItems(newItems))
    setErrors({});
  }, [findItemByEmail, setValues, filterNewItems])

  const filteredNewUsersItems = useCallback(() =>
      allContactsData.filter(contact => {
        if (values.some(value => [contact.value, contact.slug].includes(value.toString()))) return false;
        if (isGroupContact(contact)) return isWebGroupContact(contact) && allowGroups;
        if (excludeValues.some(u => u.email === contact.value)) return false;

        return allowUsers;
      })
  , [contactsData, allowGroups, excludeValues, values])

  return <Typeahead
    {...{ multiple, allowNew, placeholder, id, ref, onKeyDown, onBlur, disabled, minLength, flip }}
    labelKey="value" filterBy={FILTER_BY_FIELDS}
    selected={values} options={filteredNewUsersItems()}
    onChange={onChangeNewItems}
    renderMenuItemChildren={renderNewItem}
    renderToken={renderNewItemTag} />
}
const mapStateToProps = ({ contacts, current_user }) => ({
  contactsData: contacts, current_user
});
export default connect(mapStateToProps, { loadContacts })(ContactsTypehead);
