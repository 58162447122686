import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CloseIcon from "../../common/CloseIcon";
import ContactsTypehead from "../../common/ContactsTypehead";
import CharCounter from "../../common/CharCounter";
import { GROUP_NAME_LIMIT } from './UpdateGroupModal'
import { resetErrorsEffect } from "../../helpers/callbacks_helpers";

export const AddGroupModal = ({
                                shown,
                                onClose = () => {},
                                onSubmit = () => {},
                                currentUser,
                                allGroups
                              }) => {
  const focusElement = React.useRef(null);
  useEffect(() => {
    if (shown && focusElement.current) focusElement.current.focus()
  }, [shown, focusElement])

  const [users, setUsers] = useState([{ ...currentUser, value: currentUser.email }])
  const [name, setName] = useState('')
  const [submit, setSubmit] = useState(false)
  const [errors, setErrors] = useState({})
  const [showChar, setShowChar] = useState(false);

  resetErrorsEffect(setErrors, {}, name);

  const setDefaultValues = useCallback(() => {
    setUsers([{ ...currentUser, value: currentUser.email }])
    setName('')
  }, [])

  const submitAddGroupModal = () => {
    if (duplicatedGroupEntered()) {
      setErrors({'name': 'The group name entered already exists. Please enter a new name.'})
    } else {
      setSubmit(true)
      onSubmit(name, users.map(u => u.value), (success, errors) => {
        setSubmit(false)
        if (success) {
          close();
        } else if (isPresent(errors) && isPresent(Object.keys(errors))) {
          setErrors(errors)
        }
      })
    }
  }

  const close = () => {
    setDefaultValues();
    onClose()
  }

  const duplicatedGroupEntered = () => {
    return allGroups.some(g => g.name.toLowerCase() === name.trim().toLowerCase())
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close} />
      <h2>Add group</h2>
      <h3>Group name</h3>
      <div className="mb-3">
        <CharCounter show={showChar} field={name} limit={GROUP_NAME_LIMIT}  />
        <input className={`form-control ${errors['name'] ? 'is-invalid' : ''}`}
               placeholder="Enter the name of this group"
               ref={focusElement} value={name}
               autoFocus={shown} disabled={submit} maxLength={GROUP_NAME_LIMIT}
               onChange={(e) => setName(e.target.value)}
               onBlur={() => setShowChar(false)}
               onFocus={() => setShowChar(true)}/>
        <span className={`d-block mt-1 ${ errors['name'] ? 'text-danger' : 'hide-text' }`}>
          {errors['name']}
        </span>
      </div>

      <h3>Add users</h3>
      <div className="mb-3">
        <ContactsTypehead
          disabled={submit}
          placeholder={"Enter users to add to this group"}
          values={users}
          setValues={setUsers}
          minLength={0}
        />
      </div>
      <Button onClick={submitAddGroupModal} className="w-100 mt-3" disabled={isBlank(name) || isBlank(users) || submit}>
        Create group
      </Button>
    </Modal.Body>
  </Modal>
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddGroupModal);
