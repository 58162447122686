import React, {useEffect, useMemo, useRef, useState} from 'react';
import { connect } from "react-redux";
import Decision, {DESCRIPTION_INPUT_LIMIT} from "../../../models/decision";
import {isBlank, isPresent, stripHtmlTags} from "../../../helpers/common";
import { RichTextEditor } from "../../../common/RichTextEditor";
import { JoditTextEditor } from '../../../common/JoditTextEditor';
import CharCounter from "../../../common/CharCounter";
import {focusToEnd} from "../../../helpers/rich_text_helpers";
import { hideListChoicesLink } from "../sections/DraftMoreActionsSection"

export const ANSWER_PLACEHOLDER = "Enter a brief summary";

export const ANSWER_DEFAULT_HEADER = "Answer";

export const Counter = ({ isAnswerField, showChar, value, isExceedLimit }) =>
  isAnswerField &&
    <CharCounter show={showChar} field={stripHtmlTags(value)}
                 limit={DESCRIPTION_INPUT_LIMIT}
                 extraClassName={`driver-badge-pill ${isExceedLimit ? 'warning' : ''}`}
    />

export const onDriverDoneCallback = (innerRef, onChangeValue, isUpdateRunning) => {
  if (innerRef) {
    innerRef.current = {
      onDone: (resolve) => {
        if(isUpdateRunning) {
          setTimeout(() => innerRef.current.onDone(resolve), 100);
        } else {
          if (!onChangeValue(resolve)) resolve(true)
        }
      }
    }
  }
}

export const onChangeDriverValue = (callback = __ => null, prevValue, value, updateDriver, setShowChar, driverData, field = 'answer', setIsUpdateRunning) => {
  setShowChar(false)
  if (prevValue.trim() === value.trim()) return false;

  setIsUpdateRunning(true);
  const updateData = { slug: driverData.driver.slug }
  updateData[field] = value
  updateData['draft'] = driverData.driver.draft;
  updateDriver(updateData, (success) => {
    if (success && (typeof callback === "function")) {
      callback()
    }
    setIsUpdateRunning(false);
  });
  return true;
}

const DriverRichTextInput = ({
                               innerRef,
                               driverData, decision, updateDriver,
                               header, field, placeholder = ANSWER_PLACEHOLDER,
                               hideForRateChoices = false, autoFocus = false, setAutoFocus
                             }) => {
  if (isBlank(driverData?.driver)) return null;

  const decisionObject = new Decision(decision);
  if (hideForRateChoices && !decisionObject.isRateAndCompareDriverChoicesHidden) return null;

  const [value, setValue] = useState((driverData?.driver || {})[field] || '');
  const [prevValue, setPrevValue] = useState(value);
  const [showChar, setShowChar] = useState(false);
  const [isUpdateRunning, setIsUpdateRunning] = useState(false);
  const focusElement = useRef(null);

  useEffect(() => {
    if(autoFocus && isPresent(focusElement.current)) focusToEnd(focusElement);
  }, [autoFocus])

  useEffect(() => {
    setPrevValue(value);
    setValue((driverData?.driver || {})[field] || '');
  }, [driverData?.driver?.[field]])

  const choices = useMemo(() => {
      if (decisionObject.isRecommendationFlow &&
         !decisionObject.isRecordedOrRecommended &&
          decisionObject.sortedRecommendationChoices(true).length > 0) {
        return decisionObject.sortedRecommendationChoices(true)
      } else {
        return decisionObject.sortedChoices(true)
      }
    }
    , [decisionObject]);
  const driverRichTextInputHeader = header === ANSWER_DEFAULT_HEADER ? driverData.driver.question : header

  const onChangeValue = (callback) => {
    const isTooltipOpened = document.querySelector('.ql-editing:not(.ql-hidden)');
    if (isTooltipOpened || isUpdateRunning) return false;

    onChangeDriverValue(callback, prevValue, value, updateDriver, setShowChar, driverData, field, setIsUpdateRunning);
  }
  onDriverDoneCallback(innerRef, onChangeValue, isUpdateRunning)

  const isAnswerField = useMemo(() => field === 'answer', [field])
  const isExceedLimit = useMemo(() => stripHtmlTags(value).length > DESCRIPTION_INPUT_LIMIT, [value])

  const onFocusField = () => {
    setAutoFocus(isPresent(focusElement.current.editingArea))
    setPrevValue(value)
    setShowChar(true)
  }

  return <div id={`driver_${field}`}
              className="mb-3 px-3"
              onBlur={onChangeValue}
              onFocus={onFocusField}>
    <div className={`${hideListChoicesLink(decision, decisionObject, choices) ? '' : 'd-flex'}`}>
      <h3 className="text-ellipsis">{ driverRichTextInputHeader }</h3>
    </div>
    <Counter {...{ isAnswerField, showChar, value, isExceedLimit }} />
    {isAnswerField ?
      <RichTextEditor theme='bubble'
                      placeholder={placeholder}
                      quillId={header}
                      value={value}
                      setValue={setValue}
                      ref={focusElement}
                      className={showChar ? 'primary-box-shadow' : ''} /> :
      <JoditTextEditor ref={focusElement}
                       className="jodit-container-default"
                       value={value}
                       placeholder={placeholder}
                       setValue={setValue}
                       hasOnChangeAction={true}
                       autofocus={false} />}
  </div>
}
const wrapper = React.forwardRef((props, ref) => <DriverRichTextInput {...props} innerRef={ref} />)
export default connect(null, null, null, { forwardRef: true })(wrapper);
