import React, {useEffect, useRef, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import { ModalDoneFooter } from "../../common/modals";
import { isPresent, successActions } from "../../helpers/common";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { forceReloadHomepageDecisions, startNewDecision } from "../../store/homepage/actions";
import { initAutofocusInput } from "../../homepage/modals/helpers";
import { resetSidebars, setDecisionSidebarOpen, setRecommendationInputSidebarOpen } from "../../store/sidebar/actions";
import { resetPlaybookNotes } from "../../store/playbook_notes/actions";
import CloseIcon from "../../common/CloseIcon";
import DecisionTitleRow from "../../tree_wizard/helpers/DecisionTitleRow";
import SameCollaboratorsInput from "./duplicate_decision/SameCollaboratorsInput";
import DuplicateResponsesInput from "./duplicate_decision/DuplicateResponsesInput";
import {resetModal} from "../../store/modals/actions";

export const showStartDuplicateDecisionModal = (modal) => modal.type === "StartDuplicateDecisionModal" && modal.shown;
export const openStartDuplicateDecisionModal = ({ openModal, decision, ...opts }) => openModal({ ...opts, type: 'StartDuplicateDecisionModal', decision })

const duplicateDecisionModalState = ({
                                       decision = {}, modal,
                                       shown, onClose = () => false,
                                       onSubmit,
                                       forceReloadHomepageDecisions, resetSidebars,
                                       resetPlaybookNotes, openDecisionDetailsSideBar,
                                       resetModal,
                                       setRecommendationInputSidebarOpen
                                     }) => {
  const [description, setDescription] = useState(decision?.description || '');
  const [playbook_notes, setPlaybookNotes] = useState(decision?.playbook_notes || null)
  const [sameCollaborators, setSameCollaborators] = useState(true)
  const [duplicateResponses, setDuplicateResponses] = useState(false)
  const [submit, setSubmit] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const currentLocationSearch = useRef('');
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (!hasInitialized.current) {
      const timer = setTimeout(() => {
        currentLocationSearch.current = location.search;
        hasInitialized.current = true;
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [location.search]);

  const inputRef = initAutofocusInput([shown])
  const resetDecisionData = () => {
    setDescription(decision?.description || '')
    setSameCollaborators(true)
    setDuplicateResponses(false)
    setPlaybookNotes(decision?.playbook_notes || null)
    setSubmit(false)
  }
  const resetActions = [
    forceReloadHomepageDecisions, resetPlaybookNotes, resetModal,
    () => openDecisionDetailsSideBar(false),
    () => setRecommendationInputSidebarOpen(false)
  ]
  useEffect(resetDecisionData, [decision])

  const onCloseModal = () => {
    onClose();
    resetDecisionData();
  }

  const isFormFilled = () => isPresent(description)

  const onCreate = () => {
    setSubmit(true)
    resetSidebars()
    const data = {
      description,
      same_collaborators: sameCollaborators,
      duplicate_responses: duplicateResponses,
      decision_set_slug: decision?.decision_set_slug,
      duplicated_decision_slug: decision?.slug
    }
    onSubmit(data, slug => {
      onCloseModal()
      if (isPresent(slug)) {
        successActions(true, resetActions);
        const path = currentLocationSearch.current.includes('decision_set')
          ? `/decisions/${slug}/tree_viewer?decision_set`
          : `/decisions/${slug}/tree_viewer`;
        navigate(path);
      }
    })
  }

  return {
    description, setDescription,
    sameCollaborators, setSameCollaborators,
    duplicateResponses, setDuplicateResponses,
    playbook_notes,
    submit, ref: inputRef,
    onCloseModal, onCreate, isFormFilled
  }
}

const StartDuplicateDecisionModal = ({ shown, ...opts }) => {
  const {
    description, setDescription,
    sameCollaborators, setSameCollaborators,
    duplicateResponses, setDuplicateResponses,
    playbook_notes,
    submit, ref,
    onCloseModal, onCreate, isFormFilled
  } = duplicateDecisionModalState({ ...opts, shown })

  return <Modal size="md" backdrop="static" show={shown} onHide={onCloseModal}>
    <Modal.Body>
      <CloseIcon onClose={onCloseModal} />
      <h2>Create a decision</h2>
      <DecisionTitleRow {...{ description, setDescription, submit, playbook_notes, ref }} />
      <SameCollaboratorsInput {...{ sameCollaborators, setSameCollaborators }} />
      <DuplicateResponsesInput {...{ duplicateResponses, setDuplicateResponses }} />
    </Modal.Body>
    <ModalDoneFooter onClose={onCreate} disabled={!isFormFilled() || submit}>
      <i className="fas fa-plus fa-lg me-2" />
      <span>Start decision</span>
    </ModalDoneFooter>
  </Modal>
}
const mapStateToProps = ({ current_user, modal, decision_set }) => ({
  current_user, decision_set, decision: modal.decision
});
export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  resetSidebars: () => dispatch(resetSidebars()),
  resetPlaybookNotes: () => dispatch(resetPlaybookNotes()),
  openDecisionDetailsSideBar: (flag) => dispatch(setDecisionSidebarOpen(flag)),
  setRecommendationInputSidebarOpen: (flag) => dispatch(setRecommendationInputSidebarOpen(flag)),
  resetModal: () => { dispatch(resetModal()) }
})
export default connect(mapStateToProps, mapDispatchToProps)(StartDuplicateDecisionModal);
