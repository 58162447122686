import React from 'react';
import { connect } from "react-redux";
import { updateTreeData } from "../../store/tree/common_actions";
import { isBlank } from "../../helpers/common";
import { saveWizardStep } from "../../utils/Api";
import { updateDecisionData } from "../../store/decision/common_actions";
import { fetchContacts } from "../steps_wizard/steps/helpers/tree_builder_step";
import DriverDetailsModalForm from "./DriverDetailsModalForm";
import { modelDriverProps } from "./helpers";

export const showDriverDetailsModal = (modal) => modal.type === "DriverDetailsModal" && modal.shown

const DriverDetailsModal = ({
                              shown = false, stepRef,
                              wizard, decision, driverData, contactsData,
                              updateDecisionData,
                              ...opts
                            }) => {
  if (isBlank(shown) || isBlank(driverData)) return null;

  const collaborators = fetchContacts(wizard, contactsData, true)
  const { submitStep, submitDrivers } = stepRef.current

  return <DriverDetailsModalForm
    {...{
      ...opts,
      collaborators, wizard, driverData, submitStep, submitDrivers,
      isTemplate: false, saveStepDataAction: saveWizardStep
    }}
  />
}

const mapStateToProps = ({tree, modal, wizard, contacts, org_driver_types }) => ({
  ...modelDriverProps({tree, modal, wizard}),
  contactsData: contacts,
  decision: modal.decision,
  orgDriverTypes: { available_types: [ ...org_driver_types.available_types ] }
})
const wrapper = React.forwardRef((props, ref) => <DriverDetailsModal {...props} stepRef={ref} />)
export default  connect(mapStateToProps, { updateTreeData, updateDecisionData }, null, { forwardRef: true })(wrapper);
