import React, {Fragment, useEffect, useState} from 'react';
import SetHeader from "../decision_set_search_view/header";
import { TABS_URLS } from "../helpers/home_helpers";
import SidebarTreeNavigationTemplateSet from "../template_set_view/navigation";
import AlertsSection from "../alerts";
import DecisionSetDecisionsPanel from "../decision_set_view/side_panel/DecisionSetDecisionsPanel";
import DecisionSetDetailsPanel from "../decision_set_view/side_panel/DecisionSetDetailsPanel";
import MembersPanel from "../template_set_view/side_panel/MembersPanel";
import SetNameModal, {showModal} from "../template_set_view/modals/SetNameModal";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import DecisionSetPanel from "../tree_view/side_panel/DecisionSetPanel";
import HelpPanel from "../decision_set_view/side_panel/HelpPanel";
import StartUnstartedDecisionModal, {
  showStartUnstartedDecisionModal
} from "../decision_set_view/modals/StartUnstartedDecisionModal";
import LinkPanel from "../tree_view/side_panel/link_panel/LinkPanel";
import DecisionSetSummaryPanel from "../decision_set_view/side_panel/DecisionSetSummaryPanel";
import SummaryDetailsModal, { showSummaryDetailsModal } from "../decision_set_view/modals/SummaryDetailsModal";
import {uniqueBy} from "../helpers/common";
import InvitesSection, {collectInvitesData} from "../decision_set_view/side_panel/sections/InvitesSection";
import DataSourcesPanel from "../tree_view/side_panel/DataSourcesPanel";
import DataSourcesSection from "../decision_set_view/side_panel/sections/DataSourcesSection";
import MoreActionsPanel from "../tree_view/side_panel/MoreActionsPanel";
import SetMoreActionsSection from "../template_set_view/side_panel/sections/SetMoreActionsSection";
import SequenceSection from "../decision_set_view/side_panel/sections/SequenceSection";

const Sidebars = ({...opts}) => {
  const [showMoreActions, setShowMoreActions] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const { left_side_bar_open } = queryString.parse(search)
    opts.setSetDecisionsSidebarOpen(left_side_bar_open === 'set_decisions');
  }, []);

  useEffect(() => {
    setShowMoreActions(opts.isManager || opts.isMember);
  }, [opts.isManager, opts.isMember]);

  return (
    <div className="sidebars">
      <DecisionSetDetailsPanel {...opts} />
      <MembersPanel>
        <InvitesSection {...opts} />
      </MembersPanel>
      <DataSourcesPanel>
        <DataSourcesSection addClass="px-3 pb-3" />
      </DataSourcesPanel>
      <DecisionSetPanel addClass="left-side-panel-position tree-page" showMenu={true} {...opts} openNewTab={true} />
      <DecisionSetDecisionsPanel addClass="left-side-panel-position tree-page"/>
      <DecisionSetSummaryPanel addClass="right-side-panel-position tree-page" />
      {
        showMoreActions && <MoreActionsPanel>
          <SetMoreActionsSection setTitle='decision' homeTab={TABS_URLS.create_decision} {...opts} />
          <SequenceSection/>
        </MoreActionsPanel>
      }
      <div className="help-side-panels">
        <HelpPanel isDecisionSet={true}/>
      </div>
      <LinkPanel key="link-side-panel" />
    </div>
  )
}

export default ({
                  modal, decision_set, children,
                  closeModal,
                  updateDecisionSet, removeDecisionSet, resetDecisionSet,
                  forceReloadHomepageDecisions, addDecision = false,
                  ...opts
                }) => {
  const setOptions = {
    homeTab: TABS_URLS.create_decision,
    reloadRecords: forceReloadHomepageDecisions,
    removeSet: removeDecisionSet,
    resetSet: resetDecisionSet,
    addDecision: addDecision,
    set: decision_set
  }
  const decisionMembers = decision_set && uniqueBy(collectInvitesData(decision_set), 'email');;
  const membersInvitesLength = decisionMembers?.length + 1 || 0;

  return <Fragment>
    <AlertsSection/>
    <SetHeader {...{ set: decision_set, setTitle: 'decision', ...setOptions, ...opts }} />
    <div className="d-flex">
      {
        !addDecision && <SidebarTreeNavigationTemplateSet isDecisionSet={true} addDecision={addDecision} {...{
          membersInvitesLength,
          set: decision_set
        }} {...opts}/>
      }
      {children}
    </div>
    <Sidebars {...setOptions} {...opts} />
    <div className="modals">
      <SetNameModal shown={showModal(modal, decision_set)} set={decision_set} set_key='decision_set' setTitle='decision'
                    onClose={closeModal} updateSet={updateDecisionSet} />
      <StartUnstartedDecisionModal shown={showStartUnstartedDecisionModal(modal)} onClose={closeModal} />
      <SummaryDetailsModal shown={showSummaryDetailsModal(modal)} onClose={closeModal} {...opts} />
    </div>
  </Fragment>
}
