import {isBlank, isPresent} from "../../helpers/common";
import Decision from "../../models/decision";
import Recommendation from "../../models/recommendation";
import EntryPoint from "../../EntryPoint";
import {
    copyRecommendationChoices,
    createDataSource,
    createDecisionChoice,
    createDecisionRecommendationChoice,
    createDecisionReportData,
    createRecommendationData,
    destroyDataSource,
    destroyDecisionChoice,
    destroyDecisionRecommendationChoice,
    destroyDecisionReportData,
    replaceDataSource,
    resetChoices,
    resetDecisionRecommendationChoices,
    updateDataSource,
    updateDecisionAssignedDeciderData,
    updateDecisionAssignedRecommenderData,
    updateDecisionChoice,
    updateDecisionFinalChoices,
    updateDecisionRecommendationChoice,
    updateDecisionRecommendationFinalChoices,
    updateDriverData,
    updateRecommendationData,
    updateRecordDecisionData,
    updateChoicesDraftRequest,
    updateRecommendationChoicesDraftRequest,
    addSourceToDecisionRequest,
    copyRecommendationInputFields,
    createRecommendationConsideredChoiceRequest,
    createDecisionConsideredChoiceRequest,
    createRecommendationConsideredChoicesRequest,
    updateChoiceVotesCallback,
    createDecisionConsideredChoicesRequest,
    updateDriverFinalChoicesCallback,
    createDecisionForecastScenarioData, addToDecisionReasons, addToRecommendationReasons, addToDriverExplanation
} from "../../utils/Api";
import {isResponseFailed, updateDecisionDataInStores} from "../../helpers/store_helpers";
import { FILTERED_RIGHT_PANELS, LEFT_PANELS, resetSideBarData, RIGHT_PANELS, saveSideBarData, CENTER_PANELS } from "./common_actions";
import {
  transferDecisionFromModal,
  transferDriverFromModal,
  transferDriversFromModal,
  updateSidebarDriversDataSourcesData
} from "../tree/modal_actions";
import {updateTreeData} from "../tree/common_actions";
import { TREE_MODES } from "../../helpers/drivers_helpers";
import { updateGptQueries } from "./gpt_actions";
import { recalculateDriversCompletion } from "../decision_set/helper_actions";
import {updateTemplateData} from "../template/common_actions";
const ERROR_MESSAGE = 'Something went wrong.';

const filteredRightPanels = (includeDiscussion = true) =>
  includeDiscussion ? RIGHT_PANELS : FILTERED_RIGHT_PANELS

export const isRightSidebarOpen = (sidebar, includeDiscussion = true) =>
  Object.keys(filteredRightPanels(includeDiscussion)).some(key => sidebar[key])

// Decision helper actions
export const transferDecisionFromSidebar = () => (dispatch, getState) => {
    dispatch(transferDecisionFromModal(getState().sidebar))
}
export const transferDriverFromSidebar = () => (dispatch, getState) => {
    dispatch(transferDriverFromModal(getState().sidebar))
}
export const updateDecisionAssignedDecider = (assigned_decider, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateDecisionAssignedDeciderData({ objectSlug, assigned_decider })
      .then(updateDecisionOnResponse(dispatch, getState, callback))
}
export const updateRecordDecision = (data, callback = () => {}, ignoreSideBarUpdate = false) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateRecordDecisionData({ objectSlug, data })
      .then(updateDecisionOnResponse(dispatch, getState, callback, ignoreSideBarUpdate))
}
export const updateRecommendation = (data, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    updateRecommendationData({ objectSlug, recommendationSlug, data })
      .then(updateDecisionOnResponse(dispatch, getState, callback))
}
export const copyRecommendationFields = () => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    dispatch(saveSideBarData({ loading: true }))
    copyRecommendationInputFields({ objectSlug }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { data_sources, decision } = data;
        dispatch(updateTreeData({ data_sources }));
        updateSidebarDecisionData(dispatch, getState, decision);
        dispatch(saveSideBarData({ loading: false }))
    })
}
export const addDataToDecisionReasons = (data, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    addToDecisionReasons({ objectSlug, data }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { decision } = data;
        updateSidebarDecisionData(dispatch, getState, decision);
        dispatch(saveSideBarData({ rationaleTimeStamp: new Date().toISOString() }))
        callback(true);
    })
}

export const createRecommendation = (data, callback = () => {}, decisionSlug = '') => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    createRecommendationData({ objectSlug: decisionSlug || objectSlug, data }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const { data } = response;
        const { decision, recommendation_slug } = data;
        updateSidebarDecisionData(dispatch, getState, decision);
        callback(true, recommendation_slug);
    })
}
export const updateDriver = (data, callback = () => {}) => (dispatch, getState) => {
    const slug = data.slug;
    const { objectSlug, controllerName } = EntryPoint.instance;

    updateDriverData({ controllerName, objectSlug, slug, data }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);
        const { data } = response;
        const { template, drivers, decision_chat_gpt_queries, recommendation_chat_gpt_queries } = data;
        updateSidebarDriverData(dispatch, getState, drivers)
        updateSidebarCollaborators(dispatch, getState, data)
        dispatch(updateTreeData({ data, drivers }));
        isPresent(template) && dispatch(updateTemplateData({ ...template }))
        dispatch(recalculateDriversCompletion({ drivers }));
        updateGptQueries(dispatch, getState, decision_chat_gpt_queries, recommendation_chat_gpt_queries)
        callback(true);
    })
}

export const addDataToDriverExplanation = (data, callback = () => {}) => (dispatch, getState) => {
    const slug = data.slug;
    const { objectSlug, controllerName } = EntryPoint.instance;
    addToDriverExplanation({ controllerName, objectSlug, slug, data }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);
        const { data } = response;
        const { drivers } = data;
        updateSidebarDriverData(dispatch, getState, drivers)
        dispatch(updateTreeData({ drivers }));
        dispatch(recalculateDriversCompletion({ drivers }));
        dispatch(saveSideBarData({ rationaleTimeStamp: new Date().toISOString() }))
        callback(true);
    })
}
export function replaceSource(slug, data, config = {}, callback = () => {}, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;

        replaceDataSource({ controllerName, objectSlug, slug, data, config }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

            const { data } = response;
            const { data_sources, drivers, decision } = data;
            updateDecisionDataInStores(dispatch, getState, {...decision})
            updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
            dispatch(updateTreeData({ data_sources, drivers }));
            dispatch(recalculateDriversCompletion({ drivers }));
        })
    }
}
// Data Sources
export function updateSource(slug, data, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;

        updateDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

            const { data } = response;
            const { data_sources, drivers, decision } = data;
            updateDecisionDataInStores(dispatch, getState, {...decision})
            updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
            dispatch(updateTreeData({ data_sources, drivers }));
            dispatch(recalculateDriversCompletion({ drivers }));
        })
    }
}
export function createSource(data, config = {}, callback = () => {}, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;

        createDataSource({ controllerName, objectSlug, data, config }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

            const { data } = response;
            const { data_sources, drivers, decision } = data;
            updateDecisionDataInStores(dispatch, getState, {...decision})
            updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
            dispatch(updateTreeData({ data_sources, drivers }));
            dispatch(recalculateDriversCompletion({ drivers }));
            callback(true);
        })
    }
}
export function destroySource(slug, data, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;

        destroyDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

            const { data } = response;
            const { data_sources, drivers, decision } = data;
            updateDecisionDataInStores(dispatch, getState, {...decision})
            updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
            dispatch(updateTreeData({ data_sources, drivers }));
            dispatch(recalculateDriversCompletion({ drivers }));
        })
    }
}

// Attach Report to Decision
export function attachReport(reportSlug, data, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;

        createDecisionReportData({ controllerName, objectSlug, reportSlug }, data).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, response)

            const { data } = response;
            const { data_sources, drivers } = data;
            updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
            dispatch(updateTreeData({ data_sources, drivers }));
            dispatch(recalculateDriversCompletion({ drivers }));
        })
    }
}

export function detachReport(reportSlug, data) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;
        destroyDecisionReportData({ controllerName, objectSlug, reportSlug }, data).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

            const { data } = response;
            const { data_sources, drivers } = data;
            updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources);
            dispatch(updateTreeData({ data_sources, drivers }));
            dispatch(recalculateDriversCompletion({ drivers }));
        })
    }
}


// Attach Forecast Simulator Scenario to Decision
export const attachScenario = (scenarioId, data, _) => (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    createDecisionForecastScenarioData({ controllerName, objectSlug }, { ...data, id: scenarioId }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response)

        const { data } = response;
        const { data_sources, drivers } = data;
        updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
        dispatch(updateTreeData({ data_sources, drivers }));
        dispatch(recalculateDriversCompletion({ drivers }));
    })
}

export function addSourceToDecision(slug, setSlug, data) {
    return (dispatch, getState) => {
        addSourceToDecisionRequest({ objectSlug: setSlug, slug, data }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

            const { data } = response;
            const { data_sources, drivers } = data;
            updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
            dispatch(updateTreeData({ data_sources, drivers }));
            dispatch(recalculateDriversCompletion({ drivers }));
        })
    }
}

export function saveUploadingSources(sources) {
    return (dispatch) => {
        dispatch(saveSideBarData({ uploading_sources: sources}));
    }
}

export const updateSidebarDecisionData = (dispatch, getState, decisionData, ignoreSideBarUpdate = false) => {
    const sidebarOpen = getState().sidebar.decisionInputSidebar || getState().sidebar.recommendationInputSidebar
    if(sidebarOpen && !ignoreSideBarUpdate) {
        dispatch(saveSideBarData({ decision: decisionData, leftError: '' }));
    }  else {
        dispatch(transferDecisionFromModal({ decision: decisionData }, sidebarOpen, getState().decision_set.loaded))
    }
}
const updateSidebarDriverData = (dispatch, getState, driversData) => {
    if(getState().sidebar.driverInputSidebar) {
        dispatch(saveSideBarData({ drivers: driversData, leftError: '' }));
    }  else {
        dispatch(transferDriversFromModal({ drivers: driversData }))
    }
}


const getSidebarDecisionData = (getState) => {
    if (getState().sidebar.decisionInputSidebar) return getState().sidebar.decision;

    return getState().decision;
}

// Decision choices
export const resetFinalChoices = (callback = () => {}) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    resetChoices({ objectSlug }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

        const { data } = response;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, ...data })
        callback({ ...decision, ...data })
    })
}
export const createChoice = (data, updateFinalChoicesCallback = () => {}, ignoreSidebarUpdate = false, draft = true) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    createDecisionChoice({ objectSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { choices, new_choice_slug } = data;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, choices }, ignoreSidebarUpdate)

        const decisionObject = new Decision(decision);
        runUpdateFinalChoices(updateFinalChoicesCallback, new_choice_slug, decisionObject, draft, ignoreSidebarUpdate)
    })
}
export const updateChoice = (slug, data, updateFinalChoicesCallback, ignoreSidebarUpdate = false) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateDecisionChoice({ objectSlug, slug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

        const { data } = response;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, ...data }, ignoreSidebarUpdate)
        const decisionObject = new Decision(decision);
        updateFinalChoicesCallback(slug, {
            final_decision: !decisionObject.isRankedList,
            reset_final: decisionObject.isTopChoice,
            ignore_line_breaks: true
        }, () => {}, ignoreSidebarUpdate)
    })
}
export const destroyChoice = (slug, data, ignoreSidebarUpdate = false) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    destroyDecisionChoice({ objectSlug, slug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, ...data }, ignoreSidebarUpdate)
    })
}
export const updateFinalChoices = (slug, data, callback = () => {}, ignoreSidebarUpdate) => (dispatch, getState) => {
    if (isBlank(slug)) return;

    const { objectSlug } = EntryPoint.instance;
    updateDecisionFinalChoices({ objectSlug, slug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const { data } = response;
        const decision = getSidebarDecisionData(getState)
        callback({ ...decision, ...data });

        updateSidebarDecisionData(dispatch, getState, { ...decision, ...data }, ignoreSidebarUpdate)
    })
}

export const updateChoiceVotes = (data, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateChoiceVotesCallback(objectSlug, data).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const {data} = response;
        const decision = getSidebarDecisionData(getState)

        updateSidebarDecisionData(dispatch, getState, {...decision, ...data})
        callback(true);
    })
}

export const updateDriverFinalChoices = (driverSlug, choiceSlug, data, callback = () => {}, ignoreSidebarUpdate) => (dispatch, getState) => {
    if (isBlank(driverSlug) || isBlank(choiceSlug)) return;

    const { objectSlug, controllerName } = EntryPoint.instance;
    updateDriverFinalChoicesCallback({controllerName, objectSlug, driverSlug, choiceSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const { data } = response;
        const { drivers, decision_chat_gpt_queries, recommendation_chat_gpt_queries } = data;
        updateSidebarDriverData(dispatch, getState, drivers)
        updateGptQueries(dispatch, getState, decision_chat_gpt_queries, recommendation_chat_gpt_queries)
    })
}

export const updateChoicesDraft = (data, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateChoicesDraftRequest({ objectSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const { data } = response;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, ...data })
    })
}

// Decision recommendation choices
export const updateDecisionAssignedRecommender = (assigned_recommender, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    updateDecisionAssignedRecommenderData({ objectSlug, recommendationSlug, assigned_recommender })
      .then(updateDecisionOnResponse(dispatch, getState, callback))
}
export const copyChoices = (callback = () => {}) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    copyRecommendationChoices({ objectSlug, recommendationSlug })
      .then(updateDecisionOnCopyChoices(dispatch, getState, callback, true))
}

export const resetRecommendationFinalChoices = (callback = () => {}) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    resetDecisionRecommendationChoices({ objectSlug, recommendationSlug }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

        const { data } = response;
        const { recommendation, followups } = data;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, recommendation, followups })
        callback({ ...decision, ...data })
    })
}

export const updateRecommendationChoicesDraft = (data, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    updateRecommendationChoicesDraftRequest({ objectSlug, recommendationSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const { data } = response;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, ...data })
    })
}

export const addDataToRecommendationReasons = (data, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    addToRecommendationReasons({ objectSlug, recommendationSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const { data } = response;
        const  { decision } = data;
        updateSidebarDecisionData(dispatch, getState, { ...decision, ...data })
        dispatch(saveSideBarData({ rationaleTimeStamp: new Date().toISOString() }))
        callback(true);
    })
}

export const createRecommendationChoice = (data, updateFinalChoicesCallback = () => {}, ignoreSidebarUpdate = false, draft = true) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    createDecisionRecommendationChoice({ objectSlug, recommendationSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { recommendation, new_choice_slug } = data;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, recommendation }, ignoreSidebarUpdate)

        const recommendationObject = new Recommendation(decision.recommendation);
        runUpdateFinalChoices(updateFinalChoicesCallback, new_choice_slug, recommendationObject, draft, ignoreSidebarUpdate)
    })
}
export const createConsideredChoice = (data, callback) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    createDecisionConsideredChoiceRequest({ objectSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { considered_choices } = data;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, considered_choices }, false)
        callback(true, considered_choices)
    })
}
export const createConsideredChoices = (data, callback) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    createDecisionConsideredChoicesRequest({ objectSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { considered_choices } = data;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, considered_choices }, false)
        callback(true, considered_choices)
    })
}
export const createRecommendationConsideredChoice = (data, callback) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    createRecommendationConsideredChoiceRequest({ objectSlug, recommendationSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { recommendation } = data;
        const { considered_choices } = recommendation;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, recommendation }, false)
        callback(true, considered_choices)
    })
}
export const createRecommendationConsideredChoices = (data, callback) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    createRecommendationConsideredChoicesRequest({ objectSlug, recommendationSlug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { recommendation } = data;
        const { considered_choices } = recommendation;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, recommendation }, false)
        callback(true, considered_choices)
    })
}
export const updateRecommendationChoice = (slug, data, updateFinalChoicesCallback, ignoreSidebarUpdate = false) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    updateDecisionRecommendationChoice({ objectSlug, recommendationSlug, slug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response);

        const { data } = response;
        const { recommendation } = data;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, recommendation }, ignoreSidebarUpdate)
        const recommendationObject = new Recommendation(decision.recommendation);
        updateFinalChoicesCallback(slug, {
            final_decision: !recommendationObject.isRankedList,
            reset_final: recommendationObject.isTopChoice,
            ignore_line_breaks: true
        }, () => {}, ignoreSidebarUpdate)
    })
}
export const destroyRecommendationChoice = (slug, data, ignoreSidebarUpdate = false) => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    destroyDecisionRecommendationChoice({ objectSlug, recommendationSlug, slug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true);

        const { data } = response;
        const { recommendation, followups } = data;
        const decision = getSidebarDecisionData(getState)
        updateSidebarDecisionData(dispatch, getState, { ...decision, recommendation, followups }, ignoreSidebarUpdate)
    })
}
export const updateRecommendationFinalChoices = (slug, data, callback = () => {}, ignoreSidebarUpdate) => (dispatch, getState) => {
    if (isBlank(slug)) return;

    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    updateDecisionRecommendationFinalChoices({ objectSlug, recommendationSlug, slug, data }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

        const { data } = response;
        const { recommendation, followups } = data;
        const decision = getSidebarDecisionData(getState)
        callback({ ...decision, recommendation });

        updateSidebarDecisionData(dispatch, getState, { ...decision, recommendation, followups }, ignoreSidebarUpdate)
    })
}

// Left side panels
export const setFinalDecisionSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: value, leftSection: 'decision' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...CENTER_PANELS,
        finalDecisionSidebar: value
    }))
}
export const setDecisionInputSidebarOpen = (value) => (dispatch, getState) => {
    updateUri({ leftAddParam: value, leftSection: 'decision_input' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    const decisionData = value ? { decision: getState().decision } : {}
    const driverData = value ? { drivers: getState().tree.drivers } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...LEFT_PANELS,
        ...decisionData,
        ...driverData,
        decisionInputSidebar: value
    }))
}
export const setRecommendationInputSidebarOpen = (value) => (dispatch, getState) => {
    updateUri({ leftAddParam: value, leftSection: 'recommendation_input' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    const decisionData = value ? { decision: getState().decision } : {}
    const driverData = value ? { drivers: getState().tree.drivers } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...LEFT_PANELS,
        ...decisionData,
        ...driverData,
        recommendationInputSidebar: value
    }))
}
export const setRecommendationDetailsSidebarOpen = (value) => (dispatch, getState) => {
    updateUri({ leftAddParam: value, leftSection: 'recommendation_details' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    const decisionData = value ? { decision: getState().decision } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...LEFT_PANELS,
        ...CENTER_PANELS,
        ...decisionData,
        recommendationDetailsSidebar: value
    }))
}
export const closeDriverInputSidebarBySlug = (driverSlug) => (dispatch, getState) => {
    if(getState().sidebar.driverSlug === driverSlug) dispatch(setDriverInputSidebarOpen(false, driverSlug))
    return false;
}
export const setDriverInputSidebarOpen = (value, driverSlug = null, opts = {}) => (dispatch, getState) => {
    updateUri({ leftAddParam: value, leftSection: 'driver_input' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    const driverData = value ? { drivers: getState().tree.drivers, data_sources: getState().tree.data_sources, driverSlug: driverSlug } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...LEFT_PANELS,
        ...driverData,
        ...opts,
        driverInputSidebar: value
    }))
}
export const setDecisionOrderSidebarOpen = (value) => (dispatch) => {
    updateUri({ rightAddParam: false });
    updateUri({ leftAddParam: value, leftSection: 'decision_order' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, decisionOrderSidebar: value }))
}

export const setDriverAiResponseSidebarOpen = (value, gptDriverSlug = null) => (dispatch) => {
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...RIGHT_PANELS,
        driverAiResponseSidebar: value,
        gptDriverSlug
    }))
}

export const setRecommendationAiResponseSidebarOpen = (value) => (dispatch) => {
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, recommendationAiResponseSidebar: value }))
}

export const setDecisionAiResponseSidebarOpen = (value) => (dispatch) => {
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, decisionAiResponseSidebar: value }))
}

export const setSetSectionsSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: value, leftSection: 'set_sections' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, setSectionsSidebar: value }))
}

export const setSetSummarySidebarOpen = (value) => (dispatch) => {
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS, ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...LEFT_PANELS, setSummarySidebar: value }))
}

export const setSetDecisionsSidebarOpen = (value) => (dispatch) => {
    updateUri({ rightAddParam: false });
    updateUri({ leftAddParam: value, leftSection: 'set_decisions' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, setDecisionsSidebar: value }))
}

export const setAssignDecisionsSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: value, leftSection: 'assign_decisions' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, assignDecisionsSidebar: value }))
}

export const setSimulationSettingsOpen = (value) => (dispatch) => {
    updateUri({ rightAddParam: value, rightSection: 'simulation_settings' });
    const resetAnotherSidebarObj = value ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...LEFT_PANELS, simulationSettingsSidebar: value }))
}

export const setLargeScalePanelOpen = (value, selectedDriverId = null) => (dispatch) => {
    updateUri({ rightAddParam: value, rightSection: 'large_scale_input' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...LEFT_PANELS,
        largeScaleInputSidebar: value,
        largeScaleInputSelectedDriver: selectedDriverId
    }))
}

export const setImportValuesPanelOpen = (value) => (dispatch) => {
    updateUri({ rightAddParam: value, rightSection: 'import_values' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...LEFT_PANELS,
        importValuesSidebar: value
    }))
}

// Right side panels
export const setLearnFiltersSidebarOpen = (value) => (dispatch) => {
    // updateUri({ rightAddParam: value, rightSection: 'details' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, filtersSidebar: value }))
}
export const setDecisionSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: false });
    updateUri({ rightAddParam: value, rightSection: 'details' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, decisionDetailsSidebar: value }))
}
export const setMembersSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: false });
    updateUri({ rightAddParam: value, rightSection: 'members' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, decisionMembersSidebar: value }))
}
export const setPlaybookSidebarOpen = (value) => (dispatch) => {
    updateUri({ rightAddParam: value, rightSection: 'playbook' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, playbookSidebar: value }))
}
export const setLinkPanelOpen = (value, dataSource = null) => (dispatch) => {
    updateUri();
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...RIGHT_PANELS,
        linkPanelSidebar: value,
        dataSource: dataSource
    }))
}
export const setTreeSidebarOpen = (value, mode = TREE_MODES.view) => (dispatch) => {
    updateUri({ leftAddParam: false })
    updateUri({ rightAddParam: value, rightSection: 'decision_tree' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, treeSidebar: value, treeMode: mode || TREE_MODES.edit }))
}
export const setDiscussionPanelOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: false });
    updateUri({ rightAddParam: value, rightSection: 'discussion' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, discussionSidebar: value }))
}
export const setCollaboratorsSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: false });
    updateUri({ rightAddParam: value, rightSection: 'collaborators' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, collaboratorsSidebar: value }))
}

export const setDataSourcesSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: false });
    updateUri({ rightAddParam: value, rightSection: 'data_sources' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, dataSourcesSidebar: value }))
}

export const setMoreActionsSidebarOpen = (value) => (dispatch) => {
    updateUri({ leftAddParam: false });
    updateUri({ rightAddParam: value, rightSection: 'more_actions' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, moreActionsSidebar: value }))
}

export const setHelpPanelOpen = (value) => (dispatch) => {
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, helpSidebar: value }))
}
export const setDSightTreeViewerOpen = (value) => (dispatch) => {
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, dSightTreeSidebar: value }))
}

export const setRatingsAndWeightsOpen = (value) => (dispatch) => {
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...LEFT_PANELS } : {}
    dispatch(saveSideBarData({ ...resetAnotherSidebarObj, ...RIGHT_PANELS, ratingsAndWeightsSidebar: value }))
}

export const setPollPanelOpen = (value) => (dispatch, getState) => {
    updateUri({ leftAddParam: value, leftSection: 'decision_poll_panel' });
    const resetAnotherSidebarObj = value && isNeedToResetAnotherSideSidebar() ? { ...RIGHT_PANELS } : {}
    const decisionData = value ? { decision: getState().decision } : {}
    dispatch(saveSideBarData({
        ...resetAnotherSidebarObj,
        ...LEFT_PANELS,
        ...decisionData,
        pollPanelSidebar: value,
    }))
}

export const setMaximizeIframe = (value) => (dispatch) => {
    value ?
      $('header.decision-tree-header').addClass('hidden') :
      $('header.decision-tree-header').removeClass('hidden')
    dispatch(saveSideBarData({maximizeIframe: value}))
}

export const resetSidebars = () => (dispatch) => { dispatch(resetSideBarData()) }

const updateUri = ({ rightAddParam = null, rightSection = '', leftAddParam = null, leftSection = '' } = {}) => {
    if (rightAddParam !== null) checkSectionParams(rightAddParam, rightSection, 'side_bar_open')
    if (leftAddParam !== null) checkSectionParams(leftAddParam, leftSection, 'left_side_bar_open')
}

const failedResponseHandler = (dispatch, { data }, isLeft = false, callback = () => {}) => {
    callback(false);
    dispatchError(dispatch, data, isLeft);
}

export const dispatchError = (dispatch, data, isLeft = false) => {
    const errorKey = isLeft ? 'leftError' : 'rightError'
    const errorData = {}
    if (data['status'] === 'error') {
        errorData[errorKey] = data['message'] || ERROR_MESSAGE
    } else {
        errorData[errorKey] = ERROR_MESSAGE
    }
    dispatch(saveSideBarData(errorData));
};

const isNeedToResetAnotherSideSidebar = () => window.innerWidth < 768

export const hideClassnameWithTimeout = (className) => setTimeout(() => $(className).addClass('hidden'), 300)

const checkSectionParams = (addParams, section, key) => {
    if (document.location.pathname !== EntryPoint.instance.location.pathname) return;

    const { searchParams, setSearchParams } = EntryPoint.instance
    if (addParams) {
        searchParams.set(key, section)
    } else {
        searchParams.delete(key);
    }
    setSearchParams(searchParams);
}

const updateDecisionOnResponse = (dispatch, getState, callback = () => {}, ignoreSideBarUpdate = false) => (response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

    const { data } = response;
    const { decision } = data;
    updateSidebarDecisionData(dispatch, getState, decision, ignoreSideBarUpdate);
    callback(true, decision.read_only);
}

const updateDecisionOnCopyChoices = (dispatch, getState, callback = () => {}, ignoreSideBarUpdate = false) => (response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, true, callback);

    const { data } = response;
    const { decision } = data;
    updateSidebarDecisionData(dispatch, getState, decision, ignoreSideBarUpdate);
    callback(true, decision);
}

const runUpdateFinalChoices = (updateFinalChoicesCallback = () => {}, new_choice_slug, object,
                               draft = true, ignoreSidebarUpdate = false) => {
    updateFinalChoicesCallback(new_choice_slug, {
        final_decision: !object.isRankedList,
        reset_final: object.isTopChoice,
        ignore_line_breaks: true,
        draft: draft
    }, () => {}, ignoreSidebarUpdate)
}

const updateSidebarCollaborators = (dispatch, getState, data) => {
    const { users, invites } = data;
    const decision = { ...getState().decision, users, invites }
    updateSidebarDecisionData(dispatch, getState, decision);
}