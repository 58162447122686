import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "highcharts/highcharts-more"
import {
  basesChartHash,
  CHART_COLORS, chartBasesValueFor, chartValueFor,
  filteredProcessData,
  filteredProcessDataRecord, findProcessDataFor,
  processDataForTypeNode
} from "../../../../../models/d_sight/metric_chart_helpers";
import { isPresent } from "../../../../../helpers/common";
import { findNodeName } from "../../../../../models/d_sight/nodes";
import { reFlowCharts } from "../../helpers";
import { baseCompOptions } from "../brand_funnel/helpers";
import { chartYAxis } from "../../../../../helpers/metric_helpers";

const dataRowWithSignificance = (lastSurvey, node_id, { metric, process_data }) => {
  const brandProcessData = findProcessDataFor(process_data, node_id, lastSurvey.node_index)
  return {
    y: chartValueFor(lastSurvey, brandProcessData, metric),
    ...basesChartHash(chartBasesValueFor(lastSurvey, brandProcessData))
  }
}

const demographicSegmentCompactCHartOptions = ({
                                                 d_sight_goal, selectedSurvey, sortedNodeNames, metric,
                                                 demographicSegmentData, allSegmentsProcessData
                                               }) => {
  const demographicsWithData = demographicSegmentData.options.filter(({ id }) =>
    allSegmentsProcessData.some(p => filteredProcessDataRecord(p, { selected_demographic: id }))
  )
  const series = sortedNodeNames.map(node_id => {
    const name = findNodeName(d_sight_goal, node_id)
    const brandMetricsData = demographicsWithData.map(({ id }) => {
      const process_data = filteredProcessData({ process_data:allSegmentsProcessData }, { selected_demographic: id })
      return dataRowWithSignificance(selectedSurvey, node_id, { process_data, metric })
    })
    return {
      name,
      custom: { measure: metric.measure },
      data: brandMetricsData
    }
  }).filter(({ data }) => isPresent(data));

  return {
    ...baseCompOptions(metric),

    colors: CHART_COLORS,

    ...chartYAxis(metric, {
      min: metric.isPercentage ? 0 : null,
      title: null
    }, {
      style: {
        textAnchor: 'middle',
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    }),

    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: { enabled: false }
      }
    },

    xAxis: {
      categories: demographicsWithData.map(({ display_name }) => display_name),
      crosshair: true,
      labels: {
        style: {
          fontSize: '0.75rem',
          color: '#1F2F3F'
        }
      }
    },

    series
  };
}

export default ({
                  chartData, timeNode, d_sight_goal, selectedDemographicSegment,
                  metric, selectedSurvey,
                  ...opts
                }) => {
  const ref = useRef(null)
  reFlowCharts(ref, useEffect)

  const allSegmentsProcessData = processDataForTypeNode(chartData, timeNode).filter(p => isPresent(p.demographic_id))
  const demographicSegmentData = d_sight_goal.demographics.find(({ id }) => parseInt(id) === parseInt(selectedDemographicSegment))

  const chartOptions = demographicSegmentCompactCHartOptions({
    demographicSegmentData, selectedSurvey, allSegmentsProcessData, d_sight_goal, metric, ...opts
  })

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={ref} key={`metric-filtered-column-chart-${metric.id}`} />
}
