import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
  addDataToDecisionReasons,
  addDataToDriverExplanation,
  addDataToRecommendationReasons,
  setDecisionAiResponseSidebarOpen,
  setDecisionInputSidebarOpen,
  setDriverAiResponseSidebarOpen,
  setDriverInputSidebarOpen,
  setRecommendationAiResponseSidebarOpen,
  setRecommendationInputSidebarOpen,
} from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import {isMobile} from "react-device-detect";
import {findDriverData} from "../accordion_tree/shared/helpers";
import {isBlank, isPresent, qSortArray} from "../../helpers/common";
import {isDSightDecision} from "../../helpers/home_decision_helpers";
import {showAlert} from "../../store/alerts/actions";
import {saveSideBarData} from "../../store/sidebar/common_actions";
import AiResponseSection from "./sections/ai_response/AiResponseSection";
import {
  aiResponseRequest,
  chatGptDriverSummarizeRequest,
  vectorStoreUploadFilesRequest
} from "../../store/decision/actions";
import * as moment from "moment/moment";
import {loadDecisionsFailure, updateDecisionData} from "../../store/decision/common_actions";
import {filterAndSortDataSources, getFilteredDataSources} from "./driver_input/AiResponseButton";
import {
  apiAiResponseRequest,
  getInitContext, getInitInstruction,
  gptApiSummarizeRequest, parseAssistantContent
} from "../../helpers/ai_helpers";
import {
  aiObjectSlug,
  answeredChildrenDrivers,
  answeredChildrenRatingDrivers, copyAssistantSuggestion,
  handleAddToDetails, shouldCloseSummarizeSidebarCallback, summarizedDrivers
} from "../../helpers/gpt_helpers";
import Decision from "../../models/decision";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PanelHeader from "./PanelHeader";
import {CopyIcon} from "../../common/CopyIcon";
import {MIN_DRIVERS_FOR_AI_ANALYSIS} from "../../helpers/drivers_helpers";

const getSidebarOpenStatus = (type, sidebar) => {
  switch (type) {
    case 'recommendation':
      return sidebar.recommendationAiResponseSidebar;
    case 'decision':
      return sidebar.decisionAiResponseSidebar;
    case 'driver':
      return sidebar.driverAiResponseSidebar;
  }
};

const ChatGptRefine = ({
                         setLoaded, apiAiResponseCallback, refineMessage, setRefineMessage, disabled
                       }) => {
  const submissionCallback = (e) => {
    e.preventDefault()

    setLoaded(false)
    apiAiResponseCallback(refineMessage)
  };

  return <div className='d-flex mb-2'>
    <form className='me-2 w-100'>
      <Form.Control
        as="input"
        autoFocus
        disabled={disabled}
        value={refineMessage}
        onKeyDown={(e) => e.key === 'Enter' && !isBlank(refineMessage) && submissionCallback(e)}
        onChange={(e) => setRefineMessage(e.target.value)}
        placeholder="Enter instructions to refine the results"
      />
    </form>
    <Button disabled={isBlank(refineMessage) || disabled} className="btn btn-primary px-3" onClick={submissionCallback}>Done</Button>
  </div>
}

const ChatGptSummarizePanelHeader = ({
                                       openSideBar, showAlert, disabled, detailsData,
                                       setError, headerName = "CloverpopAI response"
                                     }) => {
  const closeSideBar = () => {
    setError('');
    openSideBar(false);
  }

  return <PanelHeader headerName={headerName} openSideBar={closeSideBar} preview={true}
                      children={<CopyIcon disabled={disabled} onClick={() => copyAssistantSuggestion(showAlert, disabled, detailsData)} />} />
}

const AddToDetailsButton = ({explanation, disabled, addToDetails, type, disabledAddButton}) =>
  <Button className="w-100 btn-secondary" disabled={disabled || isBlank(explanation) || disabledAddButton} onClick={addToDetails}>
    {
      type === 'driver' ? 'Add to driver' : `Add to ${type}`
    }
  </Button>

const getDetailsData = (expandedList, expandedFilesAnalysis, short_summary, response, file_analysis, childrenDrivers) => {
  const summarizedDriversContent = summarizedDrivers(childrenDrivers);
  const fileAnalysisContent = isPresent(file_analysis) && expandedFilesAnalysis
    ? expandedList
      ? `<br>${file_analysis}<br>${summarizedDriversContent}`
      : `<br>${file_analysis}<br>`
    : '';

  const responseData = isPresent(fileAnalysisContent)
    ? response
    : expandedList
      ? `${response}<br>${summarizedDriversContent}`
      : response;

  return {
    short_summary,
    response: responseData,
    file_analysis: fileAnalysisContent
  }
};

const CombinedAiResponsePanelBody = ({
                                       sidebar, openSideBar, driverData, decision, showAlert, type,
                                       addDataToDriverExplanation, openDriverInputSidebar, aiResponseRequest,
                                       addDataToRecommendationReasons, addDataToDecisionReasons, openDecisionInputSidebar,
                                       openRecommendationInputSidebar, dataSources, vectorStoreUploadFilesRequest,
                                       chatGptDriverSummarizeRequest, updateDecisionData,
                                       assignDriverEntry, isSidebarOpen,
                                       decisionObject,
                                       aiUseOnlyDrivers, countAnsweredChildren
                                     }) => {
  const isRated = !decisionObject.isRateAndCompareDriverChoicesHidden;
  const isRoot = isBlank(sidebar.driverSlug);

  const [loaded, setLoaded] = useState(aiUseOnlyDrivers);
  const [loadedVs, setLoadedVs] = useState(false);
  const [error, setError] = useState('');
  const disabled = isPresent(error) || !loaded || !loadedVs;
  const [context, setContext] = useState(getInitContext(type, decision, driverData));
  const [index, setIndex] = useState(0);
  const assistantContent = context?.filter(c => c.role === 'assistant');
  const instructionsContent = context?.filter(c => c.role === 'user');
  const explanation = assistantContent[index]?.content;
  const instruction = index > 0 ? instructionsContent[index]?.content : getInitInstruction(type, decision, driverData);
  const [disabledAddButton, setDisabledAddButton] = useState(false);
  const [expandedList, setExpandedList] = useState(false);
  const [expandedFilesAnalysis, setExpandedFilesAnalysis] = useState(false);
  const [refineMessage, setRefineMessage] = useState('');
  const objectSlug = aiObjectSlug(type, driverData, decision);
  const { short_summary, response, file_analysis } = isPresent(assistantContent[index]?.content)
    ? parseAssistantContent(assistantContent[index]?.content)
    : { short_summary: '', response: '', file_analysis: '' };

  const {recommendationInputSidebar, decisionInputSidebar, driverInputSidebar, driverSlug, gptDriverSlug} = sidebar;
  const childrenDrivers = isRated ?
    answeredChildrenRatingDrivers(driverData, isRoot) :
    answeredChildrenDrivers(driverData, isRoot);

  const aiFilesAndDriversCallback = (refineMessage) => apiAiResponseRequest(
    aiResponseRequest, setLoaded, setError, context, refineMessage, setContext,objectSlug,
    setRefineMessage, type, vectorStoreUploadFilesRequest, setLoadedVs, assignDriverEntry, decision.slug
  );

  const aiDriverSummarizeCallback = (refineMessage) => gptApiSummarizeRequest(
    chatGptDriverSummarizeRequest, setLoaded, setError, context, refineMessage, setContext, type,
    objectSlug, setRefineMessage, setLoadedVs, assignDriverEntry, decision.slug
  );
  const aiRequestCallback = dataSources.length === 0 ? aiDriverSummarizeCallback : aiFilesAndDriversCallback;

  const detailsData = getDetailsData(expandedList, expandedFilesAnalysis, short_summary, response, file_analysis, childrenDrivers);
  const addToDetails = () => handleAddToDetails({
    setDisabledAddButton, detailsData, type, openRecommendationInputSidebar, openDecisionInputSidebar,
    openDriverInputSidebar, driverData, addDataToRecommendationReasons, addDataToDecisionReasons,
    addDataToDriverExplanation
  });

  useEffect(() => {
    if (isSidebarOpen) {
      setError('');
      setDisabledAddButton(false);
      updateDecisionData({ error: '' });
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (loadedVs && loaded && isSidebarOpen) {
      openSideBar(false);
    }
  }, [context.length, dataSources.length, dataSources.map(ds => ds.title).join(',')]);

  useEffect(() => {
    if (loaded && isPresent(file_analysis)) {
      setExpandedList(false);
      setExpandedFilesAnalysis(false);
    }
  }, [loaded]);

  useEffect(() => {
    if (driverInputSidebar || recommendationInputSidebar || decisionInputSidebar) {
      setContext(getInitContext(type, decision, driverData));
    }
  }, [driverInputSidebar, recommendationInputSidebar, decisionInputSidebar,
    getInitContext(type, decision, driverData).length, decision.slug, type]);

  shouldCloseSummarizeSidebarCallback(
    recommendationInputSidebar, decisionInputSidebar, driverInputSidebar, type,
    driverSlug, gptDriverSlug, sidebar, openSideBar, setError, isSidebarOpen
  );

  return <>
    <ChatGptSummarizePanelHeader {...{ openSideBar, showAlert, disabled, setError, detailsData }} />
    <AiResponseSection {...{
      loaded, setLoaded, error, context, setContext, assistantContent, index, setIndex,
      explanation, apiAiResponseCallback: aiRequestCallback, driverData, type, loadedVs,
      setLoadedVs, expandedList, setExpandedList, childrenDrivers, instruction,
      short_summary, response, file_analysis: file_analysis || '', aiUseOnlyDrivers,
      countAnsweredChildren, expandedFilesAnalysis, setExpandedFilesAnalysis
    }} />
    <div className={`${isMobile ? 'mb-5' : 'side-panel bottom h-auto'} p-3`}>
      <ChatGptRefine {...{ setLoaded, apiAiResponseCallback: aiRequestCallback, refineMessage, setRefineMessage, disabled }} />
      {
        type === 'driver' && <AddToDetailsButton {...{ explanation, disabled, addToDetails, type, disabledAddButton }} />
      }
    </div>
  </>
}

const CombinedAiResponsePanel = ({
                                   type, sidebar,
                                   decision, driverData, dataSources,
                                   assignDriverEntry = false,
                                   openSideBar, saveSideBarData,
                                   ...opts
                                 }) => {
  if (isBlank(driverData) && type === 'driver') return null;
  if (isBlank(decision)) return null;
  if (isDSightDecision(decision)) return null;

  const isSidebarOpen = getSidebarOpenStatus(type, sidebar);
  const decisionObject = new Decision(decision, sidebar.drivers);
  const isRated = !decisionObject.isRateAndCompareDriverChoicesHidden;
  const isRoot = isBlank(sidebar.driverSlug);
  const aiUseOnlyDrivers = dataSources.length === 0;
  const countAnsweredChildren = isRated ? answeredChildrenRatingDrivers(driverData, isRoot).length : answeredChildrenDrivers(driverData, isRoot).length;

  if (aiUseOnlyDrivers && countAnsweredChildren < MIN_DRIVERS_FOR_AI_ANALYSIS) {
    isSidebarOpen && openSideBar(false);
    return null;
  }

  return (
    <SlidingPanel
      type="left"
      isOpen={isSidebarOpen}
      panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper decision-panel details-panel-width left-side-panel-position ${assignDriverEntry ? 'begin-0' : 'tree-page'}  ${isMobile ? 'pb-5 mb-5' : ''}`}
      panelClassName={`${isMobile ? '' : 'd-flex flex-column'} sidebar-panel-wrapper-content`}
      onClosed={() => {
        !sidebar.driverInputSidebar && saveSideBarData({ driverSlug: null, isOpened: false });
      }}
      size={52}
    >
      {
        isSidebarOpen &&
          <CombinedAiResponsePanelBody {...{
            ...opts,
            type, sidebar,
            assignDriverEntry, isSidebarOpen,
            decision, driverData, dataSources,
            decisionObject, aiUseOnlyDrivers, countAnsweredChildren,
            openSideBar
          }} />
      }
    </SlidingPanel>
  );
};

const mapStateToProps = ({ sidebar, decision, current_org, tree }, { type }) => {
  const allDataSources = (tree.data_sources) || [];
  const allowedDataSources = filterAndSortDataSources(allDataSources);
  const driverData = sidebar.driverSlug ? findDriverData(sidebar, sidebar.driverSlug || '') : sidebar.drivers
  const driverSourcesSlugs = driverData.driver_sources_slugs || [];
  const sortedDriverDataSources = qSortArray(allowedDataSources.filter(source => driverSourcesSlugs.includes(source.slug)), true, (o) => moment(o.created_at));

  const dataSources = type === 'recommendation'
    ? getFilteredDataSources(allowedDataSources, 'hide_in_recommendation_panel')
    : type === 'decision'
      ? getFilteredDataSources(allowedDataSources, 'hide_in_decision_panel')
      : sortedDriverDataSources;

  return {
    decision, current_org, sidebar,  dataSources,
    driverData: sidebar.driverSlug ? findDriverData(sidebar, sidebar.driverSlug || '') : tree.drivers
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const setSideBarOpenAction = ownProps.type === 'recommendation'
    ? setRecommendationAiResponseSidebarOpen
    : ownProps.type === 'decision'
      ? setDecisionAiResponseSidebarOpen
      : setDriverAiResponseSidebarOpen;

  return {
    openSideBar: (data) => dispatch(setSideBarOpenAction(data)),
    saveSideBarData: (data = {}) => dispatch(saveSideBarData(data)),
    showAlert: (data) => dispatch(showAlert(data)),
    addDataToDriverExplanation: (data, callback) => dispatch(addDataToDriverExplanation(data, callback)),
    addDataToRecommendationReasons: (data, callback) => dispatch(addDataToRecommendationReasons(data, callback)),
    addDataToDecisionReasons: (data, callback) => dispatch(addDataToDecisionReasons(data, callback)),
    openRecommendationInputSidebar: () => dispatch(setRecommendationInputSidebarOpen(true)),
    openDecisionInputSidebar: () => dispatch(setDecisionInputSidebarOpen(true)),
    openDriverInputSidebar: (slug) => dispatch(setDriverInputSidebarOpen(true, slug)),
    aiResponseRequest: (data, callback, setContext, decisionSlug) => dispatch(aiResponseRequest(data, callback, setContext, decisionSlug)),
    vectorStoreUploadFilesRequest: (data, callback, decisionSlug) => dispatch(vectorStoreUploadFilesRequest(data, callback, decisionSlug, loadDecisionsFailure)),
    chatGptDriverSummarizeRequest: (data, callback, setContext, decisionSlug) => dispatch(chatGptDriverSummarizeRequest(data, callback, setContext, decisionSlug)),
    updateDecisionData: (data) => dispatch(updateDecisionData(data)),
  };
};

const RecommendationAiResponsePanelWrapper = (props) => <CombinedAiResponsePanel {...props} type="recommendation" />;
const DecisionAiResponsePanelWrapper = (props) => <CombinedAiResponsePanel {...props} type="decision" />;
const DriverAiResponsePanelWrapper = (props) => <CombinedAiResponsePanel {...props} type="driver" />;

export const RecommendationAiResponsePanel = connect(
  (state, ownProps) => mapStateToProps(state, { ...ownProps, type: 'recommendation' }), mapDispatchToProps
)(RecommendationAiResponsePanelWrapper);

export const DecisionAiResponsePanel = connect(
  (state, ownProps) => mapStateToProps(state, { ...ownProps, type: 'decision' }), mapDispatchToProps
)(DecisionAiResponsePanelWrapper);

export const DriverAiResponsePanel = connect(
  (state, ownProps) => mapStateToProps(state, { ...ownProps, type: 'driver' }), mapDispatchToProps
)(DriverAiResponsePanelWrapper);
