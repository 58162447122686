import React from 'react';
import { connect } from "react-redux";
import {setMoreActionsSidebarOpen} from "../../store/sidebar/actions";
import SlidingPanel from "react-sliding-side-panel";
import PanelHeader from "./PanelHeader";
import { isMobile } from "react-device-detect";

const MoreActionsPanel = ({ sidebar, openSideBar, children }) =>
  <SlidingPanel
    type="left"
    isOpen={sidebar.moreActionsSidebar}
    panelContainerClassName={`sidebar-panel-wrapper decision-details-panel-wrapper decision-panel details-panel-width left-side-panel-position tree-page ${isMobile ? 'pb-5 mb-5' : ''}`}
    panelClassName={`sidebar-panel-wrapper-content decision-details-panel-content`}
    size={52}
  >
    <PanelHeader headerName={'More actions'} openSideBar={openSideBar} preview={true} />
    {children}
  </SlidingPanel>
const mapStateToProps = ({ sidebar }) => ({ sidebar });
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (value) => { dispatch(setMoreActionsSidebarOpen(value)); }
});
export default connect(mapStateToProps, mapDispatchToProps)(MoreActionsPanel);
