import * as React from 'react';
import Content from "../content";

export class MainPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Content />
    );
  }
}
export default MainPage;
