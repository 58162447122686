import React from 'react';
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import HamburgerDropdown, { mapDispatchToProps } from './HamburgerDropdown'
import { redirectToHomeCallback } from "../../helpers/decision_helpers";
import { successActions } from "../../helpers/common";

const TemplateViewHeader = ({
                              tree, template, current_org,
                              resetSidebars, resetTree, resetTemplate, resetPlaybookNotes, setTreeSidebarOpen,
                              ...opts
                            }) => {
  const { error } = tree;
  if (error || !template.loaded) return <nav className="navbar"/>;

  const navigate = useNavigate();
  const location = useLocation();

  const isFromTemplateSet = location.search.includes('template_set');
  const setSlug = template.template_set_slug ? template.template_set_slug : '';

  const processBackClick = () => {
    if (isFromTemplateSet){
      navigate(`/template_sets/${setSlug}`);
    } else {
      redirectToHomeCallback(true, navigate);
    }
    successActions(true, [resetSidebars, resetTree, resetTemplate, resetPlaybookNotes]);
  }

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <span className="btn btn-secondary btn-lg-round d-none d-lg-flex justify-content-around me-2" onClick={processBackClick}>
          <i className="fas fa-lg fa-arrow-left"/>
        </span>
        <h5>Decision preview</h5>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown {...opts} />
        </div>
      </div>
    </nav>
  </header>;
}

const mapStateToProps = ({ tree, current_user, current_org, template }) => ({
  tree, current_user, template, current_org
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateViewHeader);
