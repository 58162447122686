import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../common/Loader";
import {
  isTemplateLoaded,
  loadTemplateCallbacks,
  mapDispatchToProps,
  mapStateToProps,
  showTemplateEntry,
  Sidebars,
  templateViewAdminPermissionsCallback,
  templateViewSidebarCallbacks
} from "../../template_view";
import { isDSightTemplate } from "../../helpers/template_helpers";
import AlertsSection from "../../alerts";
import TemplateViewHeader from "./header";
import TemplateUsersModal from "../../template_view/modals/TemplateUsersModal";
import DSightDecisionModal, { showDSightDecisionModal } from "./modals/DSightDecisionModal";
import DSightDriverModal, { showDSightDriverModal } from "./modals/DSightDriverModal";
import SidebarTreeNavigationTemplate from "../../template_view/navigation";
import { isStoreLoading } from "../../helpers/callbacks_helpers";
import DSightTemplateTree from "../../tree_view/accordion_tree/DSightTemplateTree";

export const Wrapper = ({children, closeModal, modal}) =>
  <Fragment>
    <AlertsSection />
    <TemplateViewHeader />
    <div className="d-flex vertical-tree-container">
      <SidebarTreeNavigationTemplate isDsight={true}/>
      {children}
    </div>
    <Sidebars isDSight={true} />
    <div className="modals">
      <TemplateUsersModal shown={showTemplateEntry(modal)} onClose={closeModal} />
      <DSightDecisionModal shown={showDSightDecisionModal(modal)} onClose={closeModal} scope={'templates'} />
      <DSightDriverModal shown={showDSightDriverModal(modal)} onClose={closeModal} scope={'templates'} edit={true} />
    </div>
  </Fragment>

export const DSightTemplateView = ({
                                     template, modal, closeModal,
                                     ...opts
                                  }) => {
  loadTemplateCallbacks({ ...opts })
  templateViewSidebarCallbacks({ template, ...opts, checkDetailsPanel: false })
  templateViewAdminPermissionsCallback({ ...opts })

  const params = useParams()
  if (isTemplateLoaded(template, params) && !isDSightTemplate(template)) {
    const navigate = useNavigate();
    navigate(`/templates/${template.slug}`);
  }

  if (isStoreLoading(template)) {
    return <Wrapper modal={modal} closeModal={closeModal}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper modal={modal} closeModal={closeModal}>
      <DSightTemplateTree />
    </Wrapper>;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DSightTemplateView);

