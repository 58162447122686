import React from "react";
import { TAB_TITLES, TABS } from "../index";
import { useNavigate } from "react-router-dom";
import { isStoreLoading } from "../../helpers/callbacks_helpers";

const Tabs = ({ activeTab, forecast_simulator_scenario }) => {
  const scenario_id = forecast_simulator_scenario.scenario_id;
  const navigate = useNavigate();
  const onChangeTab = (tab) => {
    if(isStoreLoading(forecast_simulator_scenario, 'scenario') ||
       isStoreLoading(forecast_simulator_scenario, 'benchmark')) return;

    const getParams = new URLSearchParams(document.location.search)
    getParams.set('activeTab', tab)
    navigate(`/forecast_simulator/scenarios/${scenario_id}?${getParams.toString()}`)
  };
  return <>
    <ul className="nav nav-tabs">
      <li className="nav-item me-1">
        <a className={`nav-link d-flex align-items-center justify-content-center ${activeTab === TABS.table ? 'active' : ''}`} href="#"
           onClick={() => onChangeTab(TABS.table)}>{TAB_TITLES.table}</a>
      </li>
      <li className="nav-item me-1">
        <a className={`nav-link d-flex align-items-center justify-content-center ${activeTab === TABS.charts ? 'active' : ''}`} href="#"
           onClick={() => onChangeTab(TABS.charts)}>{TAB_TITLES.charts}</a>
      </li>
      <li className="nav-item">
        <a className={`nav-link d-flex align-items-center justify-content-center ${activeTab === TABS.decomposition ? 'active' : ''}`} href="#"
           onClick={() => onChangeTab(TABS.decomposition)}>{TAB_TITLES.decomposition}</a>
      </li>
    </ul>
  </>
}
export default Tabs;
