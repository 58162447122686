import React, { useMemo } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "highcharts/highcharts-more"
import {
  formatCompletionTreeCount,
  formatPercentage,
  formatTreeCompletion
} from "../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { BASE_CHART_OPTIONS, filterChartsData, xAxisByMonth } from "./TotalDecisions";
import { isBlank } from "../../../helpers/common";
import { columnBgColor, DEFAULT_CELL, DEFAULT_COLUMN, Table, WHITE_COLUMN } from "../../../common/Table";
import { StatisticSection } from "../PageContent";
import { CHART_COLORS, POINT_HEADER, SERIES_NAME_ROW } from "../../../models/d_sight/metric_chart_helpers";

export const filterStatisticPercentageData = (total, data) =>
  parseInt(total) > 0 ?
    (parseFloat(data) * 100) :
    0

export const TreeCompletionName = ({ learn }) =>
  <div>
    <div className="icon no-pointer mb-2">
      <div className="d-flex justify-content-center align-items-center bg-light text-gray rounded-circle mx-auto">
        <i className="fas fa-clipboard-check fa-lg" />
      </div>
    </div>
    <h3 className="mb-0">{formatTreeCompletion(learn.statistics?.all_statistics)} </h3>
    <div className="text-gray lh-sm">Tree completion</div>
  </div>

export const TreeCompletionChart = ({ learn }) => {
  const dataByMonth = filterChartsData(learn.statistics.by_month)
  const data = dataByMonth.map(hash =>
    filterStatisticPercentageData(hash.statistics.total_drivers, hash.statistics.tree_completion)
  )
  const chartOptions = {
    ...BASE_CHART_OPTIONS,
    colors: CHART_COLORS,
    xAxis: xAxisByMonth(dataByMonth),
    yAxis: {
      title: {
        text: null
      },
      labels: {
        format: '{text}%',
        style: {
          fontSize: '0.75rem',
          color: '#1F2F3F'
        }
      },
      max: 100
    },
    title: {
      text: 'Tree completion',
      align: 'left',
      style: {
        fontSize: '1.125rem',
        color: '#1F2F3F',
        fontWeight: 500
      }
    },
    tooltip: {
      headerFormat: `${POINT_HEADER}<table>`,
      pointFormat: `<tr>${SERIES_NAME_ROW}<td style="padding:0"><b>{point.y:.0f}%</b></td></tr>`,
      footerFormat: '</table>',
      shared: true,
      style: {
        color: '#1F2F3F',
      },
      useHTML: true
    },
    series: [{
      name: 'Tree completion',
      data,
      lineWidth: 3,
      zoneAxis: 'x',
      zones: []
    }]
  }
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}

const prepareTableRowData = (hash) => ({
  question: hash?.driver,

  driverType: hash?.statistics?.driver_type,
  dataSources: parseInt(hash?.statistics?.data_sources_size),
  completionCount: parseInt(hash?.statistics?.completion_count),
  formattedCompletionCount: formatCompletionTreeCount({
    current_driver_count: hash?.statistics?.completion_count,
    total_count: hash?.statistics?.total_drivers
  }),

  completionRate: hash?.statistics?.completion_rate,
  formattedCompletionRate: formatPercentage(hash?.statistics?.completion_rate),

  avgConfidence: hash?.statistics?.avg_confidence,
  formattedAvgConfidence: formatPercentage(hash?.statistics?.avg_confidence),

  successWhenCompleted: hash?.statistics?.success_when_completed,
  formattedSuccessWhenCompleted: formatPercentage(hash?.statistics?.success_when_completed),

  successWhenIncomplete: hash?.statistics?.success_when_incomplete,
  formattedSuccessWhenIncomplete: formatPercentage(hash?.statistics?.success_when_incomplete),
})

const columns = [
  {
    Header: 'Question',
    accessor: 'question',
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.question}</div>
  },
  {
    Header: 'Driver type',
    accessor: 'driverType',
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.driverType}</div>
  },
  {
    Header: 'Data sources',
    accessor: 'dataSources',
    sortDescFirst: true,
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.dataSources}</div>
  },
  {
    Header: 'Completion count',
    accessor: 'completionCount',
    sortDescFirst: true,
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${DEFAULT_COLUMN}`}>{row.original.formattedCompletionCount}</div>
  },
  {
    Header: 'Completion rate',
    accessor: 'completionRate',
    sortDescFirst: true,
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${columnBgColor(row.original.completionRate)}`}>{row.original.formattedCompletionRate}</div>
  },
  {
    Header: 'Avg confidence',
    accessor: 'avgConfidence',
    sortDescFirst: true,
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${columnBgColor(row.original.avgConfidence)}`}>{row.original.formattedAvgConfidence}</div>
  },
  {
    Header: 'Success when completed',
    accessor: 'successWhenCompleted',
    sortDescFirst: true,
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${columnBgColor(row.original.successWhenCompleted)}`}>{row.original.formattedSuccessWhenCompleted}</div>
  },
  {
    Header: 'Success when incomplete',
    accessor: 'successWhenIncomplete',
    sortDescFirst: true,
    cellClassName: WHITE_COLUMN,
    Cell: ({ row }) => <div className={`${DEFAULT_CELL} ${columnBgColor(row.original.successWhenIncomplete)}`}>{row.original.formattedSuccessWhenIncomplete}</div>
  }
]

const initialState = { sortBy: [{ id: 'completionCount', desc: true }] }

export const TreeCompletionDetails = ({ learn, isIndividualDecision }) => {
  if (!isIndividualDecision) return null;

  const data = useMemo(() =>
      learn.statistics?.all_statistics?.tree_completion_details?.map(h => prepareTableRowData(h))
    , [learn])

  if (isBlank(data)) return null;

  return <StatisticSection>
    <div>
      <h3 className="title">Tree completion details</h3>
      <div className={`answers-table-max-h overflow-auto`}>
        <Table columns={columns} data={data} initialState={initialState} />
      </div>
    </div>
  </StatisticSection>
}
