import React, {useMemo, useState} from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import Decision from "../../models/decision";
import Modal from "react-bootstrap/Modal";
import {
  isOpenEndedCallback,
  onChangeResponseTypeCallback
} from "../../tree_wizard/steps_wizard/steps/FramingDecisionStep";
import {
  submitEntryCallback,
  buildRecommendationChoices,
  buildResponseType,
  buildRecommendationChoicesEntry
} from "./entry_modal/helpers";
import { ModalDoneFooter } from "../../common/modals";
import DescriptionSection from "./entry_modal/DescriptionSection";
import ResponseTypeSection from "./entry_modal/ResponseTypeSection";
import RateCompareChoicesSection from "./entry_modal/RateCompareChoicesSection";
import ChoicesSection from "./entry_modal/ChoicesSection";
import DecisionAssignToBlock from "./helpers/DecisionAssignToBlock";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import { reloadContacts } from "../../store/contacts/actions";
import {totalUsersVotes} from "../../helpers/user_helpers";
import { userVotesChoicesCallback } from "../../helpers/decision_votes_helpers";
import AiInstructionsSection from "./helpers/AiInstructionsSection";

export const recommendationUpdateData = (
  decision, description, rateCompareChoices, responseType, choices, isOpenEnded,
  assignedToUser = '', ignoreDecisionChoicesCheck = false, aiInstructions = ''
) => {
  const decisionData = {
    description,
    rate_compare_choices: rateCompareChoices,
    ai_instructions: aiInstructions
  }
  if(isBlank(new Decision(decision).decisionChoices) || ignoreDecisionChoicesCheck) {
    decisionData.choice_entry_widget_type = responseType
  }
  const data = {
    decision: decisionData,
    decision_recommendation: {
      choice_entry_widget_type: responseType,
      assigned_collaborator: assignedToUser
    }
  }
  if (!isOpenEnded()) data.choices = choices
  return data;
}

const RecommendationEntryModal = ({
                                    show, onClose, decision,
                                    key = '', updateRecommendation, reloadContacts
                                  }) => {
  const recommendation = decision.recommendation;
  if (isBlank(recommendation)) return null;

  const [description, setDescription] = useState(decision.description);
  const [responseType, setResponseType] = useState(buildResponseType(recommendation));
  const [choices, setChoices] = useState(buildRecommendationChoicesEntry(recommendation));
  const [assignedToUser, setAssignedToUser] = useState(recommendation.assigned_collaborator_email)
  const [aiInstructions, setAiInstructions] = useState(decision.ai_instructions || '');
  const [submitted, setSubmitted] = useState(false);

  const disabledSubmit = () => isBlank(description);
  const isOpenEnded = isOpenEndedCallback(responseType);
  const onChangeResponseType = onChangeResponseTypeCallback(() => buildRecommendationChoices(recommendation), isOpenEnded, setChoices, setResponseType);

  const [rateCompareChoices, setRateCompareChoices] = useState(isPresent(decision.rate_compare_choices))

  const decisionObj = new Decision(decision)
  const usersChoicesVotes = useMemo(() => userVotesChoicesCallback(decision), [decision.choices]);
  const disabledResponse = useMemo(() => totalUsersVotes(usersChoicesVotes) > 0, [decision.choices]);

  const isRateCompareFlagHidden = () => {
    if (!decisionObj.isTreeRecommendation) return true;

    return isOpenEnded()
  }

  const submissionCallback = submitEntryCallback(updateRecommendation,
    recommendationUpdateData(decision, description, rateCompareChoices, responseType, choices, isOpenEnded, assignedToUser, false, aiInstructions),
    setSubmitted, onClose, _, [reloadContacts]
  );

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Edit decision' }} />
        <DescriptionSection {...{ description, setDescription, submitted, className: 'mb-3' }} />
        <>
          <ResponseTypeSection {...{ responseType, onChangeResponseType, submitted, disabledResponse }} />
          <RateCompareChoicesSection {...{ rateCompareChoices, setRateCompareChoices, hidden: isRateCompareFlagHidden() }}/>
          <ChoicesSection {...{ choices, setChoices, submitted, hidden: isOpenEnded() }} />
          <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, className: 'mt-3' }} />
          <AiInstructionsSection {...{aiInstructions, setAiInstructions, className: 'mt-3'}} />
        </>
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={submissionCallback} disabled={disabledSubmit() || submitted}/>
  </Modal>
};

const mapStateToProps = ({ modal }) => ({ decision: modal.decision })
export default connect(mapStateToProps, { reloadContacts })(RecommendationEntryModal);
