import { arrayToSentence, isBlank, isPresent } from "./common";

export function driversToArray(drivers) {
  const result = [];
  const addDriverToArray = (driver) => {
    result.push({ ...driver, driver: {...driver.driver}  });
    if (driver.children && driver.children.length > 0) {
      driver.children.forEach(d => addDriverToArray(d))
    }
  }
  drivers.forEach(d => addDriverToArray(d))
  result.forEach(h => {
    if (h.driver?.parent_id !== null) {
      h.driver.parent = result.find(d => d.children.some(c => c.driver.slug === h.driver.slug))?.driver
    }
  })
  return result;
}

export function getParentsDrivers(drivers) {
  return drivers.filter(d => !d.driver?.parent_id && d.driver?.question)
}

export const RATING_SCALES_DATA = window.TreeLayoutConstants.driver.rating_scales_data;
export const DEFAULT_RATING_SCALES_DATA = window.TreeLayoutConstants.driver.default_rating_scales_data;
export const MIN_DRIVERS_FOR_AI_ANALYSIS = window.TreeLayoutConstants.driver.min_drivers_for_ai_analysis;

export function calculateRatingScore (rating, startScale, endScale) {
  return Math.round((rating - startScale) / (endScale - startScale) * 100)
}

export const MIN_DRIVERS_FOR_GPT_REQUEST = 2

const fullName = (user) => {
  const firstName = user?.first_name || '';
  const lastName = user?.last_name || '';

  return `${firstName} ${lastName}`.trim();
};

export const assignedByUsersToString = (drivers) => {
  if (isBlank(drivers)) return '';

  const uniqueUsers = drivers.reduce((uniqueUserMap, d) => {
    const assignedByUser = d.driver.assigned_by_user;
    if (isPresent(assignedByUser)) {
      const fullNameStr = fullName(assignedByUser)
      uniqueUserMap.set(fullNameStr, assignedByUser);
    }
    return uniqueUserMap;
  }, new Map());

  const uniqueUserArray = Array.from(uniqueUsers.values());
  const fullNames = uniqueUserArray.map(user => fullName(user));

  return arrayToSentence(fullNames)
};

export const filterHashEmptyValues = (data) => Object.fromEntries(
  Object.entries(data).filter(([_, value]) => isPresent(value))
);

export const TREE_MODES = { view: 'Decision tree', edit: 'Edit decision tree', assign: 'Assign pending items' }

export const isChoicesDifferent = (array1, array2) => {
  if (array1.length !== array2.length) return true;

  for (let index = 0; index < array1.length; index++) {
    const choice1 = array1[index];
    const choice2 = array2[index];

    // Check if choices are not equal based on slug and description
    if (choice1.slug !== choice2.slug || choice1.description.toLowerCase().trim() !== choice2.description.toLowerCase().trim()) {
      return true; // Differences found, return true
    }
  }

  return false; // No differences found, return false
};

export const isEditedChoices = (array1, array2) => {
  if (array1.length !== array2.length) return true;

  for (let index = 0; index < array1.length; index++) {
    const choice1 = array1[index];
    const choice2 = array2[index];

    if (choice1.description.toLowerCase().trim() !== choice2.description.toLowerCase().trim()) return true;
  }
  return false;
};

export function parseFormattedNumber(formattedNumber) {
  if (isBlank(formattedNumber)) return NaN;

  const numericString = formattedNumber.replace(/[^0-9.-]/g, '');
  const parsedNumber = parseFloat(numericString);

  return isNaN(parsedNumber) ? NaN : parsedNumber;
}
