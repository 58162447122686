import { isBlank, isPresent } from "../../helpers/common";
import {
  CMUS_GROUP_KEY_SEPARATOR,
  SCENARIO_ROW_ID_SEPARATOR
} from "../../forecast_simulator_scenario/helpers/ag_grid_vars";

export const groupRowsByCmus = (rows) =>
  rows.reduce((acc, row) => {
    if (isBlank(acc[row.cmusGroupKey])) acc[row.cmusGroupKey] = [];
    acc[row.cmusGroupKey].push(row);
    return acc;
  }, {})

export class ForecastScenarioRow {
  constructor({ id, attributes }, scenario, selectedDriver = null) {
    this.id = id;
    this.attributes = attributes;
    this.scenario = scenario;
    this.config = scenario.config;
    this.selectedDriver = selectedDriver;
  }

  get timePeriod() {
    return this.config.dataTimeScale.timePeriods.find(period => period.id === this.attributes.tp_id)
  }

  isInTimePeriod(timePeriod) {
    if (this.timePeriod.startDate < timePeriod.startDate) return false;
    if (this.timePeriod.endDate > timePeriod.endDate) return false;

    return true;
  }

  get cmus() {
    return this.attributes.cmus;
  }

  get cmusGroupKey() {
    return [
      ...this.cmus,
      this.selectedDriver?.id
    ].filter(isPresent).join(CMUS_GROUP_KEY_SEPARATOR);
  }

  get cmusSortKey() {
    return this.config.cmuColumns.filter(cmuColumn =>
      this.cmus.some(cmuId => Object.keys(cmuColumn.values).includes(cmuId.toString()))
    ).map(cmuColumn => this.fetchCmu(cmuColumn)).filter(isPresent).join('-')
  }

  get cmusDriverId() {
    return SCENARIO_ROW_ID_SEPARATOR + [
      this.cmus.filter(isPresent).join(','),
      this.selectedDriver?.id
    ].filter(isPresent).join(SCENARIO_ROW_ID_SEPARATOR)
  }

  comparisonCmusDriverId(key) {
    return `${key}${this.cmusDriverId}`
  }

  fetchDriverData(column) {
    const resultArray = this.attributes.data[column.id.toString()];
    if (resultArray == null) return { impact: {} }

    return {
      base: resultArray[0],
      forecast: resultArray[1],
      coefficient: resultArray[2],
      impact: resultArray[3]
    }
  }

  fetchCmu(cmuColumn) {
    const cmuId = this.cmus.find(cmu => isPresent(cmuColumn.values[cmu]))
    return cmuColumn.values[cmuId];
  }
}

export class TableRow {
  constructor(rowData) {
    this.rowData = rowData
  }

  fetchData(key) {
    return this.rowData[key];
  }
}
