import React from 'react';
import DetailsItem from "../../tree_view/navigation/DetailsItem";
import MembersItem from "./MembersItem";
import HelpTemplateItem from "./HelpTemplateItem";
import DecisionSetItem from "./DecisionSetItem";
import DataSourcesItem from "../../tree_view/navigation/DataSourcesItem";
import MoreActionsItem from "../../tree_view/navigation/MoreActionsItem";

const NavigationItems = ({
                           set, isManager, isMember, membersInvitesLength,
                           preview = false,
                           isBlankDataSources = false, isTemplateSet = false, isSetMember = false,
                           ...opts
                         }) => {
  const showMoreActionsItem = isTemplateSet || isManager || isMember;

  return <>
    <div className="sidebar-top">
      <ul className="list-unstyled m-0 p-3">
        <DetailsItem/>
        {preview && membersInvitesLength === 0 ? null : <MembersItem {...{membersInvitesLength}}/>}
        {(preview || !isSetMember) && isBlankDataSources ? null : <DataSourcesItem/>}
        <DecisionSetItem {...opts} />
        {
          showMoreActionsItem && <MoreActionsItem/>
        }
      </ul>
    </div>
    {preview ? null :
      <div className="sidebar-bottom">
        <ul className="list-unstyled m-0 p-3">
          <HelpTemplateItem/>
        </ul>
      </div>
    }
  </>
}

export const SidebarTreeNavigationTemplateSet = ({ ...opts }) =>
  <div id="sidebar-navigation" className="tree-sidebar bg-light-gray d-none d-lg-block">
    <NavigationItems {...opts} />
  </div>

export default SidebarTreeNavigationTemplateSet;
