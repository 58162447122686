import {
  BENCHMARK_KEY, BM_VS_YA_KEY,
  DEFAULT_SIM_VS_VALUE, EDITABLE_COLUMN_KEYS, FACT_COMPARISON_HEADERS, FACTS_HEADER, ROW_DRIVER_ID_KEY,
  SIMULATED_FACT_KEY,
  SCENARIO_ROW_ID_SEPARATOR,
  SIM_VS_BENCHMARK_KEY,
  SIM_VS_YA_KEY, SUB_FACT_HEADER,
  VALUE_SALES_FACT,
  BENCHMARK_HEADER, SIMULATED_FACT_HEADER, FACTS_GR_COL_ID, N_A_AG_GRID_VALUE, CELL_TYPES
} from "./ag_grid_vars";
import { isBlank, isPresent, uniqArray, uniqueBy } from "../../helpers/common";
import {
  calcCAGR,
  calcRootBySimVsRootValue,
  calcRootFromSimVs,
  calcSimVsValue,
  calcYARootFromPrevSimVsYa, calcYTD_YTG, validatedResult
} from "./ag_grid_formulas";
import { hideOverlay } from "./custom_loading_overlay";
import { genYearAgoPeriod } from "../../models/forecast/ForecastTImeScale";
import { aggregatedByCmu } from "../../models/forecast/aggregastion";
import { ForecastScenarioRow } from "../../models/forecast/ForecastScenarioRow";
import { fetchFromCacheStore } from "../../helpers/cookie_helpers";

export const getScenarioFiltersJson = (forecastScenario) => {
  try {
    const filterModel = fetchFromCacheStore(`forecast-scenario-filters-${forecastScenario.local_id}`);
    return JSON.parse(filterModel);
  } catch (e) {
    return false;
  }
};

export const aggregatedFact = (config, forecastScenario, filterModelData) => {
  filterModelData = filterModelData ? filterModelData : getScenarioFiltersJson(forecastScenario);
  if(isBlank(filterModelData) || isBlank(filterModelData[FACTS_GR_COL_ID]))
    return forecastScenario.findFactByDriverName(VALUE_SALES_FACT);

  const displayNames = config.allFactsColumns.map(column => column.displayName);
  const sortedArray = filterModelData[FACTS_GR_COL_ID].values.filter(isPresent).sort((a, b) => {
    return displayNames.indexOf(extractDriverName(a)) - displayNames.indexOf(extractDriverName(b));
  });
  const firstElement = sortedArray[0];
  return forecastScenario.findFactByDriverName(extractDriverName(firstElement));
};

export const simAggValueKeyByPeriodAndSuffix = (params, periodId, periodAgGridSuffixes) => {
  return Object.keys(params.node.aggData).find(key =>
    periodAgGridSuffixes.some(suffix => key === `${periodId}_${suffix}`)
  );
}

const isValidParams = (params) => {
  return params && params.node && params.node.aggData;
};

export const getAggValueByKey = (aggData, key) => {
  if (Array.isArray(aggData[key]) && isPresent(aggData[key][0])) {
    return aggData[key][0].toNumber() || null;
  }
  if (aggData[key]) {
    return aggData[key].toNumber() || null;
  }
  return null;
};

const getAggValueByPeriodId = (aggData, periodId) => {
  if (Array.isArray(aggData[periodId]) && isPresent(aggData[periodId][0])) {
    return aggData[periodId][0].toNumber() || null;
  }
  if (aggData[periodId]) {
    return aggData[periodId].toNumber() || null;
  }
  return null;
};

export const simAggValue = (params, periodId, periodAgGridSuffixes = []) => {
  if (!isValidParams(params)) {
    return null;
  }

  if (isPresent(periodAgGridSuffixes)) {
    const foundKey = simAggValueKeyByPeriodAndSuffix(params, periodId, periodAgGridSuffixes);
    if (isPresent(foundKey) && params.node.aggData.hasOwnProperty(foundKey)) {
      return getAggValueByKey(params.node.aggData, foundKey);
    }
  }

  if (params.node.aggData.hasOwnProperty(periodId)) {
    return getAggValueByPeriodId(params.node.aggData, periodId);
  }

  return null;
};

export const getPeriodAgGridSuffixes = (params) => {
  if(isBlank(params.node.aggData)) return [];

  const keys = Object.keys({ ...params.node.aggData });
  return uniqArray(keys.map(key => key.split('_')[1]).filter(isPresent));
}

export const findDriverColumn = (rowDriverId, forecastScenario, config = null) => {
  const { driverId } = parseRowCellId(rowDriverId)
  return (config || forecastScenario.config).allFactsColumns.find(column => column.id === Number(driverId));
}

export const parseRowCellId = (rowCellId) => {
  const [comparisonKey, cmus, driverId, periodId] = rowCellId.split(SCENARIO_ROW_ID_SEPARATOR);
  return { comparisonKey, cmus, driverId, periodId };
}

export const rowCellIdKey = (params) => `${params.node.id}${SCENARIO_ROW_ID_SEPARATOR}${params.colDef.colId}`;

export const extractDriverName = (driverWithMeasure = '') => {
  driverWithMeasure = driverWithMeasure || '';
  return driverWithMeasure.split(',')[0];
}

export const buildNewEditedCells = (forecastScenario, editedCells, list, timeScale, additionalCellAttrs = {}) => {
  return list.map(params => {
    const cell = { ...findOrBuildCell(forecastScenario, editedCells, params, timeScale) };
    const value = isPresent(params.newValue) ? params.newValue : params.node.value;
    const cellData = {
      ...cell,
      value,
      prev_value: isPresent(params.node.prevValue) ? params.node.prevValue : params.oldValue,
      edited: isEditedCell(cell, value)
    }
    return Object.assign({}, cellData, additionalCellAttrs)
  })
}

export const findOrBuildCell = (forecastScenario, editedCells, params, timeScale) => {
  const cellId = rowCellIdKey(params);
  const foundCell = editedCells.find(cellData => cellData.id === cellId && timeScale === cellData.timeScale);
  if(foundCell) return foundCell;

  const period = forecastScenario.allTimeScalePeriods(timeScale).find(period => period.id === params.colDef.colId);
  const relatedPeriodIds = forecastScenario.config.allIntersectedPeriodsIdsByPeriod(period);
  return {
    id: cellId,
    timeScale: timeScale,
    nodeId: params.node.id,
    periodId: period.id,
    relatedPeriodIds,
    field: params.colDef.field,
    cell_type: CELL_TYPES.edited,
    run_model_is_run: false,
    default_value: isPresent(params.node.prevValue) ? params.node.prevValue : params.oldValue
  };
};

export const updateCells = (gridRef, editedCells, forecastScenario, runModelCells, list, timeScale, updateTableCells, callback, delUpdateData = []) => {
  const newEditedCells = buildNewEditedCells(forecastScenario, editedCells, list, timeScale, { need_recalculation: true });
  let allEditedCells = [];
  if(forecastScenario.isEditableTimeScale) {
    const { recalculatedCells, delayedUpdateData  } = recalculateCells(forecastScenario, gridRef.current?.api, editedCells, newEditedCells, timeScale);
    delUpdateData.push(...delayedUpdateData);
    allEditedCells = uniqueBy([...recalculatedCells, ...newEditedCells, ...editedCells], 'id');
  } else {
    allEditedCells = uniqueBy([...newEditedCells, ...editedCells], 'id');
  }
  const calcRunModelCells = calcRunModelCellsFunc(gridRef, forecastScenario, newEditedCells, runModelCells);
  const table_cells = [...allEditedCells, ...calcRunModelCells];
  updateTableCells(forecastScenario.local_id, { table_cells, bulk: true }, (status) => {
    hideOverlay(gridRef.current?.api);
      if(status) {
        if(delUpdateData.length > 0) runDelayedUpdateData(gridRef, delUpdateData);
        callback();
      }
  });
};

export const runModelCellsByRowId = (runModelCells, rowId) =>
  runModelCells.filter(cellData => cellData.nodeId === rowId);

const filterRunModelCells = (runModelCells, newCells) => {
  return runModelCells.filter(cellData => !newCells.some(newCell => newCell.id === cellData.id));
}

const buildRunModelCell = (runModelCells, cellData, nodeId, periodId) => {
  const prefix = 'row-group-';
  const id = `${prefix}${nodeId}|${periodId}`;
  return {
    id,
    nodeId: `${prefix}${nodeId}`,
    periodId: Number(periodId),
    relatedPeriodIds: cellData.relatedPeriodIds,
    timeScale: cellData.timeScale,
    field: cellData.field,
    edited: cellData.edited,
    cell_type: CELL_TYPES.run_model,
    request_run_model_at: cellData.edited ? new Date().toISOString() : null,
    run_model_at: cellData.edited ? null : runModelCells.find(cell => cell.id === id)?.run_model_at
  };
};

const buildNewRunModelCellsFromEditedCells = (runModelCells, editedCells, forecastScenario) => {
  let list = [];
  editedCells.forEach(cellData => {
    const { cmus, periodId } = parseRowCellId(cellData.id);
    const convertedCmus = cmus.split(',').map(Number);
    let rowIds = [];
    let currentString = '';

    convertedCmus.forEach(id => {
      for (let obj of forecastScenario.config.cmuColumns) {
        if (obj.values[id]) {
          currentString += `${obj.attributes.name}-${obj.values[id]}`;
          rowIds.push(currentString);
          currentString += '-';
          break;
        }
      }
    });
    rowIds.forEach(nodeId => {
      list.push(buildRunModelCell(runModelCells, cellData, nodeId, periodId));
    });
    forecastScenario.config.outputColumns.forEach(column => {
      const salesFactNodeId = `${rowIds[rowIds.length-1]}-${FACTS_HEADER}-${forecastScenario.driverWithMeasure(column)}`;
      list.push(buildRunModelCell(runModelCells, cellData, salesFactNodeId, periodId));
    });
  });
  return list;
};

export const calcRunModelCellsFunc = (gridRef, forecastScenario, editedCells, runModelCells) => {
  if(editedCells.length === 0) return [];

  const newCells = buildNewRunModelCellsFromEditedCells(runModelCells, editedCells, forecastScenario);
  return uniqueBy([...newCells, ...runModelCells], 'id');
};

export const performUpdateCells = (gridRef, groupedDelayedUpdateData) => {
  if(isBlank(groupedDelayedUpdateData)) return;

  Object.entries(groupedDelayedUpdateData).forEach(([nodeId, data]) => {
    const rowNode = gridRef.current?.api?.getRowNode(nodeId);
    if(rowNode)
      gridRef.current.api.applyTransaction({ update: [{ ...rowNode.data, ...data }] });
  });
}

export const runDelayedUpdateData = (gridRef, delayedUpdateData) => {
  const groupedDelayedUpdateData = delayedUpdateData.reduce((acc, row) => {
    if (isBlank(acc[row.node.id])) acc[row.node.id] = {};
    acc[row.node.id][row.key] = row.value
    return acc;
  }, {})
  performUpdateCells(gridRef, groupedDelayedUpdateData);
}

const isNothingToChange = (newEditedCells, list) => isBlank(newEditedCells) && isBlank(list);

export const onCellValueChanged = ({ forecastScenario, newEditedCells, list, gridRef, editedCells, updateTableCells, timeScale, runModelCells, callback = () => {} }) => {
  if(isNothingToChange(newEditedCells, list)) {
    hideOverlay(gridRef.current.api);
    callback();
    return;
  }
  newEditedCells = isPresent(newEditedCells) ? newEditedCells : buildNewEditedCells(forecastScenario, editedCells, list, timeScale);
  const { recalculatedCells, delayedUpdateData } = recalculateCells(forecastScenario, gridRef.current?.api, editedCells, newEditedCells, timeScale);
  const allEditedCells = uniqueBy([...recalculatedCells, ...newEditedCells, ...editedCells], 'id');
  const calcRunModelCells = calcRunModelCellsFunc(gridRef, forecastScenario, newEditedCells, runModelCells)
  const table_cells = [...allEditedCells, ...calcRunModelCells];
  updateTableCells(forecastScenario.local_id, { table_cells, bulk: true }, (status) => {
    if (status) {
      hideOverlay(gridRef.current?.api);
      callback();
      runDelayedUpdateData(gridRef, delayedUpdateData);
    } else {
      hideOverlay(gridRef.current?.api);
    }
  });
};


// Functions related to cells recalculation
const findNodeInGroupRows = (groupRows, key) => groupRows.find(row => row.id.includes(key));

const prepareCellReCalcData = (api, editedCell) => {
  const node = api.getRowNode(editedCell.nodeId);
  const periodId = editedCell.periodId;
  const colDefField = editedCell.field;
  const groupRows = node.key ? node.childrenAfterGroup : node.parent.childrenAfterGroup;
  const rootNode = groupRows.find(row => row.childIndex === 0);
  const isSimVsBmEdited = node.id.includes(SIM_VS_BENCHMARK_KEY);
  const isSimVsYaEdited = node.id.includes(SIM_VS_YA_KEY);
  return {
    periodId,
    colDefField,
    rootNode,
    isSimVsBmEdited,
    isSimVsYaEdited,
    benchmarkNode: findNodeInGroupRows(groupRows, BENCHMARK_KEY),
    simVsBmNode: findNodeInGroupRows(groupRows, SIM_VS_BENCHMARK_KEY),
    simVsYaNode: findNodeInGroupRows(groupRows, SIM_VS_YA_KEY),
    isRootNodeEdited: !isSimVsBmEdited && !isSimVsYaEdited && node.id === rootNode.id
  }
}

const performSimVsBmEdited = (forecastScenario, editedCells, rootNode, benchmarkNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale) => {
  const prevRootValue = rootNode.data[colDefField];
  const prevSimVsYaValue = simVsYaNode.data[colDefField];
  const newRootValue = calcRootFromSimVs(benchmarkNode.data[colDefField], editedCell.value);
  const yARootValue = calcYARootFromPrevSimVsYa(prevRootValue, prevSimVsYaValue);
  const newSimVsYaValue = calcSimVsValue(newRootValue, yARootValue);
  addToReCalcCells(forecastScenario, recalculatedCells, editedCells, rootNode, prevRootValue, colDefField, periodId, newRootValue, timeScale);
  addToReCalcCells(forecastScenario, recalculatedCells, editedCells, simVsYaNode, prevSimVsYaValue, colDefField, periodId, newSimVsYaValue, timeScale);
  delayedUpdateData.push({ node: rootNode, key: colDefField, value: newRootValue });
  delayedUpdateData.push({ node: simVsYaNode, key: colDefField, value: newSimVsYaValue });
}

const performSimVsYaEdited = (forecastScenario, editedCells, rootNode, benchmarkNode, simVsBmNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale) => {
  const prevRootValue = rootNode.data[colDefField];
  const prevSimVsYaValue = editedCell.prev_value;
  const prevSimVsBmValue = simVsBmNode.data[colDefField];
  const benchmarkRootValue = benchmarkNode.data[colDefField];
  const yARootValue = calcYARootFromPrevSimVsYa(prevRootValue, prevSimVsYaValue);
  const newRootValue = calcRootFromSimVs(yARootValue, editedCell.value);
  const newSimVsBmValue = calcSimVsValue(newRootValue, benchmarkRootValue);
  addToReCalcCells(forecastScenario, recalculatedCells, editedCells, rootNode, prevRootValue, colDefField, periodId, newRootValue, timeScale);
  addToReCalcCells(forecastScenario, recalculatedCells, editedCells, simVsBmNode, prevSimVsBmValue, colDefField, periodId, newSimVsBmValue, timeScale);
  delayedUpdateData.push({ node: rootNode, key: colDefField, value: newRootValue });
  delayedUpdateData.push({ node: simVsBmNode, key: colDefField, value: newSimVsBmValue });
}

const performRootNodeEdited = (forecastScenario, editedCells, rootNode, benchmarkNode, simVsBmNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale) => {
  const prevRootValue = editedCell.prev_value;
  const benchmarkRootValue = benchmarkNode.data[colDefField];
  const prevSimVsBmValue = simVsBmNode.data[colDefField];
  const prevSimVsYaValue = simVsYaNode.data[colDefField];
  const yARootValue = calcYARootFromPrevSimVsYa(prevRootValue, prevSimVsYaValue);
  const newRootSimVsYaValue = calcRootBySimVsRootValue(editedCell.value, yARootValue);
  const newRootSimVsBmValue = calcRootBySimVsRootValue(editedCell.value, benchmarkRootValue);
  addToReCalcCells(forecastScenario, recalculatedCells, editedCells, simVsBmNode, prevSimVsBmValue, colDefField, periodId, newRootSimVsBmValue, timeScale);
  addToReCalcCells(forecastScenario, recalculatedCells, editedCells, simVsYaNode, prevSimVsYaValue, colDefField, periodId, newRootSimVsYaValue, timeScale);
  delayedUpdateData.push({ node: simVsYaNode, key: colDefField, value: newRootSimVsYaValue });
  delayedUpdateData.push({ node: simVsBmNode, key: colDefField, value: newRootSimVsBmValue });
}

const isEditedCell = (cellData, value) => {
  if(cellData.run_model_is_run) return true;

  const numericCellValue = typeof value === 'number' ? value : parseFloat(value);
  const roundedCellValue = parseFloat(numericCellValue.toFixed(5));
  const numericDefaultValue = typeof cellData.default_value === 'number' ? cellData.default_value : parseFloat(cellData.default_value);
  const roundedDefaultValue = parseFloat(numericDefaultValue.toFixed(5));
  return roundedCellValue !== roundedDefaultValue;
};

const addToReCalcCells = (forecastScenario, recalculatedCells, editedCells, node, prevValue, colDefField, periodId, value, timeScale) => {
  const cellData = { ...findOrBuildCell(forecastScenario, editedCells, { node: {...node, prevValue }, colDef: { field: colDefField, colId: periodId } }, timeScale) }
  recalculatedCells.push({
    ...cellData,
    edited: isEditedCell(cellData, value),
    value
  });
}

const recalculateCells = (forecastScenario, api, editedCells, newEditedCells, timeScale) => {
  const recalculatedCells = [];
  let delayedUpdateData = [];
  newEditedCells.forEach(editedCell => {
    const node = api.getRowNode(editedCell.nodeId);
    if(isBlank(node)) return;

    const {
      periodId,
      colDefField,
      rootNode,
      benchmarkNode,
      simVsBmNode,
      simVsYaNode,
      isSimVsBmEdited,
      isSimVsYaEdited,
      isRootNodeEdited
    } = prepareCellReCalcData(api, editedCell);

    if(isBlank(benchmarkNode) && isBlank(simVsBmNode) && isBlank(simVsYaNode) && isRootNodeEdited) {
      return;
    }
    if(isSimVsBmEdited) {
      performSimVsBmEdited(forecastScenario, editedCells, rootNode, benchmarkNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale);
    }
    if(isSimVsYaEdited) {
      performSimVsYaEdited(forecastScenario, editedCells, rootNode, benchmarkNode, simVsBmNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale);
    }
    if(isRootNodeEdited) {
      performRootNodeEdited(forecastScenario, editedCells, rootNode, benchmarkNode, simVsBmNode, simVsYaNode, colDefField, periodId, editedCell, recalculatedCells, delayedUpdateData, timeScale);
    }
  });
  return { recalculatedCells, delayedUpdateData };
};

// Functions related to creation of comparison rows

export const rowCmus = (node) => {
  const rowDriverId = node.allLeafChildren[0].data[ROW_DRIVER_ID_KEY];
  return parseRowCellId(rowDriverId)?.cmus?.split(',')?.filter(isPresent)?.map(Number) || []
}

export const calculateScopedCmusYearAgoChanges = ({
                                                    currentValue, scenario,
                                                    period, cmus, driverColumn
                                                  }) => {
  const prevYearPeriod = genYearAgoPeriod({ period, allowBlank: true });
  if (isBlank(prevYearPeriod)) return DEFAULT_SIM_VS_VALUE;
  const prevYearValue = scenario.aggregateBy({
    cmus,
    period: prevYearPeriod,
    driver: driverColumn
  })
  if (isBlank(prevYearValue)) return DEFAULT_SIM_VS_VALUE;

  if (driverColumn.measure === '%') return currentValue - prevYearValue;

  return calcSimVsValue(currentValue, prevYearValue);
}

const buildBenchMarkCAGRData = (rowData, forecastScenario, header, periods) => {
  const endingValue = rowData[periods[periods.length - 1].name];
  const startingValue = rowData[periods[0].name];
  rowData[header] = calcAndFormatCAGR(endingValue, startingValue, periods);
};

const buildBenchmarkYTD_YTGData = (rowData, header, periods, periodsToCompare) => {
  const periodsData = periods.map(period => rowData[period.name]);
  const periodsToCompareData = periodsToCompare.map(period => rowData[period.name]);
  rowData[header] = calcAndFormatYTD_YTG(periodsData, periodsToCompareData);
};

const buildBenchmarkData = (config, rowData, params, periods, forecastScenario, forecastBenchmarkScenario, cmus) => {
  const driverColumn = findDriverColumn(rowData[ROW_DRIVER_ID_KEY], forecastBenchmarkScenario, config);
  periods.forEach(period => {
    rowData[period.name] = forecastBenchmarkScenario.aggregateBy(
      { cmus, period, driver: driverColumn }
    );
  });
  if(forecastScenario.showCAGR) {
    buildBenchMarkCAGRData(rowData, forecastScenario, forecastScenario.CAGRHistoricalHeader, forecastScenario.historicalCAGRPeriods);
    buildBenchMarkCAGRData(rowData, forecastScenario, forecastScenario.CAGRForecastedHeader, forecastScenario.forecastedCAGRPeriods);
  }
  if(forecastScenario.showYTG) {
    buildBenchmarkYTD_YTGData(rowData, forecastScenario.YTDHeader, forecastScenario.YTDPeriods, forecastScenario.YTDPreYearPeriods);
    buildBenchmarkYTD_YTGData(rowData, forecastScenario.YTGHeader, forecastScenario.YTGPeriods, forecastScenario.YTGPrevYearPeriods);
  }
 };

const buildSimVsBenchmarkData = (rowData, params, periods, benchmarkRowData, periodAgGridSuffixes) => {
  return periods.forEach(period => {
    const benchmarkValue = benchmarkRowData[period.name];
    const simValue = simAggValue(params, period.id, periodAgGridSuffixes);
    rowData[period.name] = calcSimVsValue(simValue, benchmarkValue);
  });
}

const buildSimVsYearAgoData = (config, rowData, params, periods, forecastScenario, cmus, periodAgGridSuffixes) => {
  const driverColumn = findDriverColumn(rowData[ROW_DRIVER_ID_KEY], forecastScenario, config)
  return periods.forEach(period => {
    const currentValue = simAggValue(params, period.id, periodAgGridSuffixes);
    rowData[period.name] = calculateScopedCmusYearAgoChanges({
      currentValue, scenario: forecastScenario,
      period, cmus, driverColumn
    })
  });
}

const buildBenchmarkVsYearAgoData = (config, rowData, params, periods, benchmarkRowData, forecastBenchmarkScenario, cmus) => {
  const driverColumn = findDriverColumn(rowData[ROW_DRIVER_ID_KEY], forecastBenchmarkScenario, config);
  return periods.forEach(period => {
    const currentValue = benchmarkRowData[period.name];
    rowData[period.name] = calculateScopedCmusYearAgoChanges({
      currentValue, scenario: forecastBenchmarkScenario,
      period, cmus, driverColumn
    })
  });
}

const buildUniqRowDriverId = (rowData, childRow, data, params) => {
  if(rowData[FACTS_HEADER]) {
    return `${data.key}${params.node.allLeafChildren[0].data[ROW_DRIVER_ID_KEY]}`
  }
  return `${data.key}${childRow.data[ROW_DRIVER_ID_KEY]}${SCENARIO_ROW_ID_SEPARATOR}${params.node.id}`
}

const setDefaultCAGRData = (groupedData, forecastScenario) => {
  if(forecastScenario.showCAGR) {
    groupedData[forecastScenario.CAGRForecastedHeader] = N_A_AG_GRID_VALUE;
    groupedData[forecastScenario.CAGRHistoricalHeader] = N_A_AG_GRID_VALUE;
  }
};

const setDefaultYTD_YTGData = (groupedData, forecastScenario) => {
  if(forecastScenario.showYTG) {
    groupedData[forecastScenario.YTDHeader] = N_A_AG_GRID_VALUE;
    groupedData[forecastScenario.YTGHeader] = N_A_AG_GRID_VALUE;
  }
};

const buildGroupedColumnsData = (params, forecastScenario) => {
  const groupedData = {};
  setDefaultCAGRData(groupedData, forecastScenario);
  setDefaultYTD_YTGData(groupedData, forecastScenario);
  const indexOfClicked = forecastScenario.groupFields.indexOf(params.node.field) >= 0 ?
    forecastScenario.groupFields.indexOf(params.node.field) :
    forecastScenario.groupFields.length;
  forecastScenario.groupFields.forEach((field, index) => {
    if(index <= indexOfClicked) {
      groupedData[field] = params.node.allLeafChildren[0].data[field];
    } else {
      groupedData[field] = '';
    }
  })
  if(params.node.field === FACTS_HEADER) {
    groupedData[FACTS_HEADER] = params.node.key;
  }
  return groupedData;
}

const buildCAGRData = (rowData, params, forecastScenario) => {
  if(!forecastScenario.showCAGR) return;

  rowData[forecastScenario.CAGRHistoricalHeader] = calculateCAGR({rowNode: params.node}, forecastScenario, forecastScenario.historicalCAGRPeriods);
  rowData[forecastScenario.CAGRForecastedHeader] = calculateCAGR({rowNode: params.node}, forecastScenario, forecastScenario.forecastedCAGRPeriods);
};

const buildYTD_YTGData = (rowData, params, forecastScenario) => {
  if(!forecastScenario.showYTG) return;

  rowData[forecastScenario.YTDHeader] = calculateYTD_YTG({rowNode: params.node}, forecastScenario, forecastScenario.YTDPeriods, forecastScenario.YTDPreYearPeriods);
  rowData[forecastScenario.YTGHeader] = calculateYTD_YTG({rowNode: params.node}, forecastScenario, forecastScenario.YTGPeriods, forecastScenario.YTGPrevYearPeriods);
};

const buildSimulatedData = (rowData, params, periods, forecastScenario, periodAgGridSuffixes) => {
  periods.forEach(period => {
    rowData[period.name] = simAggValue(params, period.id, periodAgGridSuffixes);
  });
  buildCAGRData(rowData, params, forecastScenario);
  buildYTD_YTGData(rowData, params, forecastScenario);
};

export const createComparisonRows = (config, isOutputGroup, params, forecastScenario, forecastBenchmarkScenario, periods) => {
  let benchmarkRowData = {};
  const cmus = rowCmus(params.node).slice(0, params.node.level + 1);
  const childRow = params.node.allLeafChildren[0];
  const periodAgGridSuffixes = getPeriodAgGridSuffixes(params);
  const groupData = buildGroupedColumnsData(params, forecastScenario);

  return FACT_COMPARISON_HEADERS.filter(header => isOutputGroup || (header.key !== SIMULATED_FACT_KEY)).map(data => {
    let rowData = { ...groupData };
    rowData[ROW_DRIVER_ID_KEY] = buildUniqRowDriverId(rowData, childRow, data, params);
    rowData[FACTS_HEADER] = rowData[FACTS_HEADER] || '';
    rowData[SUB_FACT_HEADER] = data.name;
    switch (data.key) {
      case SIMULATED_FACT_KEY:
        buildSimulatedData(rowData, params, periods, forecastScenario, periodAgGridSuffixes);
        break;
      case BENCHMARK_KEY:
        buildBenchmarkData(config, rowData, params, periods, forecastScenario, forecastBenchmarkScenario, cmus);
        benchmarkRowData = rowData;
        break;
      case SIM_VS_BENCHMARK_KEY:
        buildSimVsBenchmarkData(rowData, params, periods, benchmarkRowData, periodAgGridSuffixes);
        break;
      case SIM_VS_YA_KEY:
        buildSimVsYearAgoData(config, rowData, params, periods, forecastScenario, cmus, periodAgGridSuffixes);
        break;
      case BM_VS_YA_KEY:
        buildBenchmarkVsYearAgoData(config, rowData, params, periods, benchmarkRowData, forecastBenchmarkScenario, cmus);
        break;
      default:
        return null;
    }
    return rowData;
  });
};

export const relatedCell = (editedCells, nodeId, periodId) =>
    editedCells.find(cell => cell.nodeId === nodeId && cell.periodId === periodId)

// Functions related to opened groups(expand/collapse/scroll Facts)

const isEditableComparisonRow = (row) => EDITABLE_COLUMN_KEYS.some(key => row[ROW_DRIVER_ID_KEY].includes(key))

const createEditedCellsForRows = (forecastScenario, editedCells, params, newRows, timeScale) => {
  const benchmarkRow = newRows.find(row => row[ROW_DRIVER_ID_KEY].includes(BENCHMARK_KEY));
  const list = newRows.flatMap(row => {
    if(isEditableComparisonRow(row)) {
      return editedCells.filter(editedCell => row[ROW_DRIVER_ID_KEY].includes(editedCell.nodeId)).map(editedCell => {
        const benchmarkRootValue = benchmarkRow[editedCell.field];
        const rootValue = editedCell.value;
        const defaultRootValue = editedCell.default_value
        const newValue = row[editedCell.field];
        let prevValue = ''
        if(row[ROW_DRIVER_ID_KEY].includes(SIM_VS_BENCHMARK_KEY)) {
          prevValue = calcSimVsValue(defaultRootValue, benchmarkRootValue);
        }
        if(row[ROW_DRIVER_ID_KEY].includes(SIM_VS_YA_KEY)) {
          const yARootValue = calcYARootFromPrevSimVsYa(rootValue, newValue);
          prevValue = calcSimVsValue(defaultRootValue, yARootValue);
        }
        return {
          node: { id: row[ROW_DRIVER_ID_KEY], prevValue },
          colDef: { field: editedCell.field, colId: editedCell.periodId },
          edited: isEditedCell(editedCell, newValue),
          newValue
        };
      });
    }
  }).filter(isPresent);
  return buildNewEditedCells(forecastScenario, editedCells, list, timeScale, { run_model_at: new Date().toISOString() });
};

const editedCellAlreadyPresent = (editedCells, params, forecastScenario) => {
  return editedCells.some(cell => cell.nodeId === params.node.allLeafChildren[0].id && cell.timeScale === forecastScenario.timeScale)
}

const updateDataHashOnOpen = (params, outputGroup, forecastScenario, forecastBenchmarkScenario, editedCells, newRows, expanded, needUpdateCells = false) => {
  let updateData = {
    opened_group: {
      id: params.node.id,
      benchmark_scenario_id: forecastBenchmarkScenario.local_id,
      output: outputGroup,
      added_rows: newRows,
      expanded
    }
  }
  if (needUpdateCells && editedCellAlreadyPresent(editedCells, params, forecastScenario))
    updateData.edited_cells = uniqueBy([
      ...editedCells,
      ...createEditedCellsForRows(forecastScenario, editedCells, params, newRows, forecastScenario.timeScale)
    ], 'id');
  return updateData;
};

export const updateRowsOnOpen = (params, forecastScenario, isOutputGroup, forecastBenchmarkScenario, newRows, editedCells, runModelCells, expanded, updateOpenedGroups, updateTableCells, needUpdateCells = false, callback = (_status) => {}) => {
  const { opened_group, edited_cells } = updateDataHashOnOpen(params, isOutputGroup, forecastScenario, forecastBenchmarkScenario, editedCells, newRows, expanded, needUpdateCells);
  updateOpenedGroups(forecastScenario.local_id, { opened_group }, (status) => {
    if(status) {
      if(isPresent(edited_cells)) {
        const table_cells = [...runModelCells, ...edited_cells];
        updateTableCells(forecastScenario.local_id, { table_cells, bulk: true }, (status) => {
          callback(status);
        });
      } else {
        callback(status);
      }
    } else {
      callback(status);
    }
  });
};

export const disableHighlights = () => {
  const quartzElement = document.querySelector('.ag-theme-quartz');
  if (isBlank(quartzElement)) return;
  if (quartzElement.classList.contains('ag-theme-quartz-no-highlights')) return;

  quartzElement.classList.add('ag-theme-quartz-no-highlights');
};


export const enableHighlights = (timeout = 100) => {
  setTimeout(() => {
    const quartzElement = document.querySelector('.ag-theme-quartz');
    if (isBlank(quartzElement)) return;
    quartzElement.classList.remove('ag-theme-quartz-no-highlights');
  }, timeout);
 };

export const parseComparisonRow = (params, forecastScenario, config) => {
  if (params.node.data.sub_fact.includes(BENCHMARK_HEADER)) {
    return parseRow(params.node.data[ROW_DRIVER_ID_KEY], forecastScenario, config);
  } else {
    return { decimal: 1 };
  }
}

export const parseRow = (rowDriverId, forecastScenario, config) => {
  const driverColumn = findDriverColumn(rowDriverId, forecastScenario, config);
  return { decimal: driverColumn.decimal }
}

export const parseFormatData = (params, forecastScenario, config) => {
  try {
    if (isPresent(params.node?.data?.sub_fact) && params.node.data.sub_fact !== SIMULATED_FACT_HEADER) return parseComparisonRow(params, forecastScenario, config);
    const rowDriverId = params.node?.data?.rowDriverId || params.rowNode.allLeafChildren[0].data.rowDriverId;

    return parseRow(rowDriverId, forecastScenario, config);
  } catch (e) {
    console.error('Error while parsing format data', e)
  }
  return { decimal: 0 };
}

export const valueFormatter = (value, { metric = null, decimal = 2 }, space = '') => {
  const numericValue = typeof value === 'number' ? value : parseFloat(value);
  const roundedValue = numericValue.toFixed(decimal);
  if (isBlank(metric) && isBlank(space)) return roundedValue;

  return [
    metric.includes('$') ? '$' : null,
    roundedValue,
    metric?.replace('$', '')
  ].filter(isPresent).join(space)
};

export const refreshAggregations = (api, forecastScenario) => {
  api.refreshClientSideRowModel('aggregate');

  // NOTE: to recalculate CAGR values we need to run refreshClientSideRowModel twice
  if(forecastScenario.showCAGR || forecastScenario.showYTG) {
    setTimeout(() => api.refreshClientSideRowModel('aggregate'), 100);
  }
};

// Helpers for aggregation section
const getDriverIds = (config, aggFact) => {
  const drivers = uniqueBy([...config.outputColumns, aggFact], 'id');
  return drivers.map(driver => driver.id.toString());
};

const processLeafChildren = (params, leafChildren, aggFact, driverIds, colField) => {
  let values = [];
  let allRows = [];
  let prepareRowAttrs = {};

  leafChildren.forEach((leaf, index) => {
    const driverName = extractDriverName(leaf.data[FACTS_HEADER]);
    const subFact = leaf.data[SUB_FACT_HEADER];
    const value = leaf.data[colField];
    const driverId = parseRowCellId(leaf.data[ROW_DRIVER_ID_KEY]).driverId;

    if (driverName === aggFact.displayName && (isBlank(subFact) || subFact === SIMULATED_FACT_HEADER)) {
      values.push(leaf);
    }
    if (isPresent(params.values) && typeof params.values[index] === 'object' && params.values[index] !== null) {
      allRows.push(params.values[index].allRows);
      allRows = allRows.flat();
    }
    if (driverIds.includes(driverId)) {
      prepareRowAttrs[driverId.toString()] = [value];
    }
  });

  return { values, allRows, prepareRowAttrs };
};

const aggregateValues = (values, colField) => {
  let allValues = [];

  values.forEach((data) => {
    const value = data.data[colField];
    if (typeof value === 'object' && value !== null) {
      allValues.push(value.allValues).flat();
    } else if (typeof value === 'number') {
      allValues.push({ value });
    }
  });

  return allValues;
};

const prepareRowAttributes = (prepareRowAttrs, allRows, convertedCmus, aggFact, forecastScenario) => {
  if (isPresent(prepareRowAttrs) && !allRows.some(row => row.cmusGroupKey === [...convertedCmus, aggFact.id].join('_'))) {
    allRows.push(new ForecastScenarioRow({ attributes: { cmus: convertedCmus, data: prepareRowAttrs } }, forecastScenario, aggFact));
  }
};

const isNotAvailableData = (values, params) => {
  return values.some(value => value.data[params.colDef.field] === undefined);
};
export const getAggregatedValues = (config, aggFact, filteredAllLeafChildren, params, period, forecastScenario) => {
  const driverIds = getDriverIds(config, aggFact);
  const { cmus } = parseRowCellId(filteredAllLeafChildren[0].data[ROW_DRIVER_ID_KEY]);
  const convertedCmus = cmus.split(',').map(Number);
  const { values, allRows, prepareRowAttrs } = processLeafChildren(params, filteredAllLeafChildren, aggFact, driverIds, params.colDef.field);

  if (isNotAvailableData(values, params)) return { allValues: undefined, allRows: undefined, aggregatedValue: undefined };

  prepareRowAttributes(prepareRowAttrs, allRows, convertedCmus, aggFact, forecastScenario);

  const allValues = aggregateValues(values, params.colDef.field);
  const aggregatedValue = aggregatedByCmu(allRows, aggFact, period, config, allValues);
  return { allValues, allRows, aggregatedValue };
};

// YTD/YTG calculations helpers
const formatValue = (value) => typeof value === 'number' ? `${value.toFixed(1)}%` : value;

export const buildAggPeriodData = (params, period, periodAgGridSuffixes, forecastScenario, aggFact, filteredAllLeafChildren) => {
  const result = simAggValue({ node: params.rowNode }, period.id, periodAgGridSuffixes);
  if(isPresent(result)) return result;

  const config = forecastScenario.config;
  return getAggregatedValues(config, aggFact, filteredAllLeafChildren, { ...params, colDef: { field: period.name } }, period, forecastScenario).aggregatedValue?.value;
};
export const buildLeafPeriodData = (filteredAllLeafChildren, period, params, forecastScenario) => {
  const result = filteredAllLeafChildren[0].data[period.name];
  if(isPresent(result)) return result;

  return forecastScenario.agGridPreparedData.find(rowData =>
    rowData[ROW_DRIVER_ID_KEY] === filteredAllLeafChildren[0].data[ROW_DRIVER_ID_KEY]
  )?.[period.name];
};

const calcForAggGroups = (params, periods, periodsToCompare, forecastScenario, aggFact, filteredAllLeafChildren) => {
  const periodAgGridSuffixes = getPeriodAgGridSuffixes({ node: params.rowNode });
  const periodsData = periods.map(period => buildAggPeriodData(params, period, periodAgGridSuffixes, forecastScenario, aggFact, filteredAllLeafChildren));
  const periodsDataToCompare = periodsToCompare.map(period => buildAggPeriodData(params, period, periodAgGridSuffixes, forecastScenario, aggFact, filteredAllLeafChildren));
  return calcAndFormatYTD_YTG(periodsData, periodsDataToCompare);
};

const calcForLeafGroups = (params, periods, periodsToCompare, filteredAllLeafChildren, forecastScenario) => {
  const periodsData = periods.map(period => buildLeafPeriodData(filteredAllLeafChildren, period, params, forecastScenario));
  const periodsDataToCompare = periodsToCompare.map(period => buildLeafPeriodData(filteredAllLeafChildren, period, params, forecastScenario));
  return calcAndFormatYTD_YTG(periodsData, periodsDataToCompare);
};

export const calcAndFormatYTD_YTG = (periodsData, periodsDataToCompare) => {
  return formatValue(validatedResult(calcYTD_YTG(periodsData, periodsDataToCompare)));
};

export const calculateYTD_YTG = (params, forecastScenario, periods, periodsToCompare) => {
  const filteredAllLeafChildren = filterLeafChildren(params.rowNode.allLeafChildren);
  if (isBlank(filteredAllLeafChildren[0]?.data)) return undefined;

  const aggFact = aggregatedFact(forecastScenario.config, forecastScenario) || forecastScenario.findFactByDriverName(VALUE_SALES_FACT);
  const driverName = params.rowNode.leafGroup ? extractDriverName(filteredAllLeafChildren[0].data[FACTS_HEADER]) : aggFact.displayName;
  const driver = forecastScenario.findFactByDriverName(driverName);
  if (driver.isPercentage) return N_A_AG_GRID_VALUE;

  if (!params.rowNode.leafGroup && isPresent(params.rowNode.aggData)) {
    return calcForAggGroups(params, periods, periodsToCompare, forecastScenario, aggFact, filteredAllLeafChildren);
  } else {
    return calcForLeafGroups(params, periods, periodsToCompare, filteredAllLeafChildren, forecastScenario);
  }
};

export const filterLeafChildren = (allLeafChildren) => {
  return allLeafChildren.filter(leaf =>
    !leaf.group && (isBlank(leaf.data[SUB_FACT_HEADER]) || leaf.data[SUB_FACT_HEADER] === SIMULATED_FACT_HEADER)
  );
};

// CAGR calculations helpers
const calcCAGRforAggGroups = (params, firstPeriod, lastPeriod, periods, forecastScenario, aggFact, filteredAllLeafChildren) => {
  const periodAgGridSuffixes = getPeriodAgGridSuffixes({ node: params.rowNode });
  const startingValue = buildAggPeriodData(params, firstPeriod, periodAgGridSuffixes, forecastScenario, aggFact, filteredAllLeafChildren);
  const endingValue = buildAggPeriodData(params, lastPeriod, periodAgGridSuffixes, forecastScenario, aggFact, filteredAllLeafChildren);
  return calcAndFormatCAGR(endingValue, startingValue, periods);
};

const calcCAGRforLeafGroups = (params, firstPeriod, lastPeriod, periods, filteredAllLeafChildren, forecastScenario) => {
  const startingValue = buildLeafPeriodData(filteredAllLeafChildren, firstPeriod, params, forecastScenario);
  const endingValue = buildLeafPeriodData(filteredAllLeafChildren, lastPeriod, params, forecastScenario);
  return calcAndFormatCAGR(endingValue, startingValue, periods);
};

export const calcAndFormatCAGR = (endingValue, startingValue, periods) => {
  return formatValue(validatedResult(calcCAGR(endingValue, startingValue, periods.length)));
};

export const calculateCAGR = (params, forecastScenario, periods) => {
  const filteredAllLeafChildren = filterLeafChildren(params.rowNode.allLeafChildren);

  if (isBlank(filteredAllLeafChildren[0]?.data)) return undefined;

  const aggFact = aggregatedFact(forecastScenario.config, forecastScenario) || forecastScenario.findFactByDriverName(VALUE_SALES_FACT);
  const driverName = params.rowNode.leafGroup ? extractDriverName(filteredAllLeafChildren[0].data[FACTS_HEADER]) : aggFact.displayName;
  const driver = forecastScenario.findFactByDriverName(driverName);
  if (driver.isPercentage) return N_A_AG_GRID_VALUE;

  const firstPeriod = periods[0];
  const lastPeriod = periods[periods.length - 1];

  if (!params.rowNode.leafGroup && isPresent(params.rowNode.aggData)) {
    return calcCAGRforAggGroups(params, firstPeriod, lastPeriod, periods, forecastScenario, aggFact, filteredAllLeafChildren);
  } else {
    return calcCAGRforLeafGroups(params, firstPeriod, lastPeriod, periods, filteredAllLeafChildren, forecastScenario);
  }
};
