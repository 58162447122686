import { useEffect } from "react";
import { isPresent } from "./common";

export const checkLoadingEffect = (
  store, loadingAction,
  { condition = () => true, another_stores = [], another_conditions = [] } = {}
) => {
  useEffect(() => {
    if (!store.loaded && !store.loading && condition([...another_stores, ...another_conditions])) loadingAction();
  }, [store.loaded, ...another_stores.map(store => store.loaded), ...another_conditions])
}

export const isStoreLoading = (store, key = '') =>
  store[[key, 'loading'].filter(isPresent).join('_')] || !store[[key, 'loaded'].filter(isPresent).join('_')]
export const isStoresLoading = (...args) => args.some(store => isStoreLoading(store))

export const checkSearchChangeEffect = (loading, setLoading, searchQuery, loadingAction) => {
  useEffect(() => {
    if (!loading) {
      const timeoutId = setTimeout(() => {
        loadingAction({ search: searchQuery })
      }, 500);
      return () => {
        clearTimeout(timeoutId);
      }
    }
    setLoading(false);
  }, [searchQuery]);
}

export const resetErrorsEffect = (setErrors, initialValue, column) => {
  useEffect(() => {
    setErrors(initialValue)
  }, [column])
}
