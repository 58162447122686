import React, { useRef } from "react";
import { useWorker } from "react-hooks-worker";
import { AgGridReact } from 'ag-grid-react';
import { agGridExcelInit } from "../helpers/ag_grid_excel";
import { isBlank } from "../../helpers/common";

const exportFileWorker = () => {
  return new Worker(
    new URL('../workers/forecastScenarioDataProcessor.worker.js', import.meta.url),
    { type: 'module', name: 'forecastScenarioDataProcessor' }
  );
}

const ExportAgGridTable = ({ forecast_simulator_scenario, forecastScenario, forecastBenchmarkScenario, ...opts }) => {
  const excelGridRef = useRef();
  const { result: exportRows } = useWorker(exportFileWorker, {
    scenario: forecast_simulator_scenario.scenario,
    config: forecast_simulator_scenario.config,
    allowWorkerRun: true,
    gridReady: true
  });

  const agGridExcelOpts = agGridExcelInit({
    forecast_simulator_scenario,
    forecastScenario,
    forecastBenchmarkScenario,
    excelGridRef,
    exportRows,
    ...opts
  });

  if(isBlank(agGridExcelOpts)) return null;

  return <div className="row excel-ag-grid hidden">
    <div className="col ag-theme-quartz ag-quartz-styles">
      <AgGridReact {...{...agGridExcelOpts}} ref={excelGridRef}/>
    </div>
  </div>
}
export default ExportAgGridTable;