import React from 'react';
import {Image, Text, View} from '@react-pdf/renderer';
import imagesStyles from '../../styles/images'
import { isPresent } from "../../../helpers/common";
import { v4 as uuidv4 } from 'uuid'
import styles from "../../styles/common";
import {userInitials} from "../../../helpers/user_helpers";
import { isDefaultAvatarUrl } from "../../../common/UserAvatarImage";

export const protectedImageSrc = (uri) => ({
  uri: `${uri}?noCache=${uuidv4()}`,
  method: 'GET', headers: { "Cache-Control": "no-cache" }
})

const UserImage = ({user, children, viewStyles=[]}) => {
  return <View style={viewStyles}>
    { isPresent(user.avatar_url) ?
      isDefaultAvatarUrl(user.avatar_url) ?
        <View style={imagesStyles.user_avatar_initials}>
          <Text style={[styles.avatarFont, styles.pt1]}>{ userInitials(user) }</Text>
        </View> :
        <Image src={protectedImageSrc(user.avatar_url)} style={imagesStyles.user_avatar_image} /> :
      null }
    {children}
  </View>
}
export default UserImage;
