import { isBlank, isPresent, uniqueBy } from "../helpers/common";

const MANAGER_ROLE = 'manager';
const SET_USER_ROLE = 'set_user';
const SHARED_VISITOR_ROLE = 'shared_visitor';
const VISITOR_ROLE = 'visitor';
export const ADMISSIONS = {
  manager: 'set_manager',
  set_user: 'set_user',
  visitor: 'visitor'
}

export const calcSequenceIndexByIndex = (sections, index, withoutSectionObjs = []) =>
  sections.reduce((sum, sub_section, sub_index) =>
      sub_index < index ? sum + (sub_section.templates || sub_section.decisions).length : sum
    , withoutSectionObjs.length
  )

export const calcSequenceNumber = ({ objects_sequence }, startIndex, index) =>
  objects_sequence === OBJECT_SEQUENCES.sequence_all ? startIndex + index + 1 :
    objects_sequence === OBJECT_SEQUENCES.per_section ? index + 1 :
      false

export const OBJECT_SEQUENCES = window.TreeLayoutConstants.sets_context.objects_sequences;

export default class DecisionSet {
  constructor(decision_set, user) {
    this.decision_set = decision_set;
    this.user = user;
  }

  get setUsers() {
    const findUserByInvite = (invite) => this.decision_set.users.find((u) => u.email === invite.email)
    return [...this.decision_set.invites.map(i => findUserByInvite(i))].filter(isPresent)
  }

  get collaborators() {
    return uniqueBy([this.getManager, ...this.setUsers].filter(isPresent), 'email')
  }

  get getManager() {
    return this.decision_set.users.find((u) => u.email === this.decision_set.user_email)
  }

  get decisions() {
    return this.decision_set?.decisions;
  }

  get sections() {
    return this.decision_set.sections;
  }

  get users() {
    return this.decision_set.users;
  }

  decisionsInSection(section) {
    return this.decisions.filter(d => d.section_slug === section.slug);
  }

  get decisionsWithoutSection() {
    return this.decisions.filter(d => isBlank(d.section_slug));
  }

  get slug() { return this.decision_set.slug }
  get name() { return this.decision_set.name }
  get isVisitor() { return this.userRole === VISITOR_ROLE }
  get isSharedVisitor() { return this.userRole === SHARED_VISITOR_ROLE }
  get isSetUser() { return this.userRole === SET_USER_ROLE }
  get isManager() { return this.userRole === MANAGER_ROLE }
  get isSetMember() { return this.isManager || this.isSetUser; }
  get isSetViewer() { return isPresent(this.decision_set?.access_admission); }
  get isMember() {
    return isPresent(this.decision_set.users.find((u) => u.email === this.user.email));
  }

  get userRole() {
    if (this.isSetViewer) {
      switch (this.decision_set.access_admission?.role) {
        case ADMISSIONS.set_user: return SET_USER_ROLE;
        case ADMISSIONS.manager: return MANAGER_ROLE;
        case ADMISSIONS.visitor: return SHARED_VISITOR_ROLE;
      }
    }
    if (this.decision_set.user_email === this.user.email) return MANAGER_ROLE;
    if (this.decision_set.user.email === this.user.email) return MANAGER_ROLE;
    if (this.decision_set.invites?.some(i => i.email === this.user.email)) return SET_USER_ROLE;

    return VISITOR_ROLE;
  }

  get totalDecisionsNumber() {
    return this.decision_set.all_decisions_count;
  }

  get completedDecisionsNumber() {
    return this.decision_set.completed_decisions_count || this.decisions?.filter(decision => decision.completed)?.length || 0;
  }

  get completed() {
    return this.completedDecisionsNumber === this.totalDecisionsNumber;
  }
}
