import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { Template } from "../../models/template";
import { ModalDoneFooterP0 } from "../../common/modals";
import { submitTemplateSetUsers } from "../../store/template_set/actions";
import CreateAdmissionsInput from "../../template_view/modals/admissions/CreateAdmissionsInput";
import ViewAdmissionsInput from "../../template_view/modals/admissions/ViewAdmissionsInput";

export const shownTemplateSetUsersEntry = (modal) => modal.type === "TemplateSetUsersModal" && modal.shown;
export const openTemplateSetUsersEntry = (openModal) => () => openModal({type: 'TemplateSetUsersModal'})

const TemplateSetUsersModal = ({
                                 template_set,
                                 shown = false, onClose = () => {},
                                 submitTemplateSetUsers
                                }) => {
  const inputRef = useRef({
    resets: {}
  })
  const obj = new Template(template_set)

  const [submit, setSubmit] = useState(false)

  // callbacks
  useEffect(() => {
    if (template_set.loaded) {
      Object.values(inputRef.current.resets).forEach(callback => callback())
    }
  }, [template_set.loaded])

  const close = () => onClose()
  const reset = () => {
    Object.values(inputRef.current.resets).forEach(callback => callback())
    setSubmit(false);
    close();
  }
  const submitData = () => {
    setSubmit(true)
    const data = {
      identifiers: inputRef.current.identifiers(),
      decision_admission_identifiers: inputRef.current.decision_admission_identifiers()
    };
    submitTemplateSetUsers(data, success => {
      setSubmit(false)
      if (success) close();
    })
  }

  return <Modal size="md" backdrop="static" show={shown} onHide={reset}>
    <Modal.Body>
      <CloseIcon onClose={reset} />
      <h2>Edit decision flow users</h2>
      <CreateAdmissionsInput {...{ ref: inputRef,
        template_admissions: obj.template_admissions,
        createObjTitle: 'a decision flow', createFromTitle: 'this template'
      }} />
      <ViewAdmissionsInput {...{ ref: inputRef,
        decision_admissions: obj.decision_admissions,
        viewObjsTitle: 'the decision flow and decisions in it', viewFromTitle: ''
      }} />
    </Modal.Body>
    <ModalDoneFooterP0 disabled={submit} onClose={submitData} />
  </Modal>
}
const mapStateToProps = ({ template_set }) => ({ template_set });
export default connect(mapStateToProps, { submitTemplateSetUsers })(TemplateSetUsersModal);
