import React, { useState } from 'react';
import { connect } from "react-redux";
import { getSectionSlugFromParam } from "../../../helpers/set_helpers";
import { isPresent} from "../../../helpers/common";
import { changeDecisionInSetSharingSettings } from "../../../helpers/alert_helpers";
import {Link, useNavigate} from "react-router-dom";
import { forceReloadHomepageDecisions } from "../../../store/homepage/actions";
import { updateDecisionDecisionSet } from "../../../store/decision_set/actions";
import NullSearchResults from "../helpers/NullSearchResults";
import DecisionTile from "../tiles/DecisionTile";
import DecisionSetTile from "../tiles/DecisionSetTile";

const DecisionInSetActions = ({ decision, handleAddToSet }) =>
  <div>
    <Link to={`/decisions/${decision.slug}/tree_viewer`} target="_blank">
      <div className="btn btn-secondary btn-dsight d-inline-flex justify-content-center align-items-center py-1 w-100 mb-2">
        <span>View decision</span>
      </div>
    </Link>
    <div onClick={() => handleAddToSet(decision)} className="btn btn-primary btn-dsight d-inline-flex justify-content-center align-items-center py-1 w-100 mb-2 pointer">
      <i className="fas fa-plus fa-lg me-2" />
      <span>Add to decision flow</span>
    </div>
  </div>

const List = ({
                home, decisions,
                showNullResults, resetAllFilters, updateDecisionDecisionSet, forceReloadHomepageDecisions,
                isDecisionSet = true,
                isListLine = false,
                decision_set_slug, decision_set
              }) => {
  const [submit, setSubmit] = useState(false)
  const navigate = useNavigate();

  if (showNullResults) return <NullSearchResults {...{ home, resetAllFilters }} />;

  const handleAddToSet = (decision) => {
    if (submit) return false;

    const section_slug = getSectionSlugFromParam(document);
    const attributes = {
      ...isPresent(section_slug) && { section_slug: section_slug },
      decision_id: decision.slug,
      decision_set_slug: decision_set_slug,
    };

    const confirmCallback = (confirmed) => {
      if (!confirmed) return;

      setSubmit(true)
      updateDecisionDecisionSet(attributes, () => {
        setSubmit(false)
        navigate(`/decision_sets/${decision_set_slug}?left_side_bar_open=set_decisions`)
      });
      forceReloadHomepageDecisions();
    }

    changeDecisionInSetSharingSettings(`The decision will be visible to anyone who can see "${decision_set.name}"`, confirmCallback)
  };

  return decisions.map(({ type = 'Decision', ...data }) =>
    type === 'Decision' ?
      <DecisionTile {...{ decision: data, isDecisionSet, section: 'search', isListLine }}
                    key={`search-tab-decision-tile-${data.slug}`}>
        {
          isPresent(decision_set_slug) && <DecisionInSetActions {...{ decision: data, handleAddToSet }} />
        }
      </DecisionTile> :
      <DecisionSetTile {...{ decision_set: data, isListLine, section: 'search' }}
                       key={`search-tab-decision-set-tile-${data.slug}`} />
  )
}
const mapStateToProps = ({ home }) => ({ home });
export default connect(mapStateToProps, { forceReloadHomepageDecisions, updateDecisionDecisionSet })(List);
