import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { resetSidebars, setSetSummarySidebarOpen } from "../../../store/sidebar/actions";
import { openModal } from "../../../store/modals/actions";
import { openTemplateSetUsersEntry } from "../../modals/TemplateSetUsersModal";
import {deleteSetCallback, deleteSetTemplateCallback} from "../../helpers";
import { isSummaryPending } from "../../../helpers/set_helpers";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";
import { resetTemplateSetData } from "../../../store/template_set/common_actions";
import { forceReloadHomepageDecisions, forceReloadHomepageTemplates } from "../../../store/homepage/actions";
import { removeDecisionSet } from "../../../store/decision_set/actions";
import {removeTemplateSet, removeTemplateSetAndTemplates} from "../../../store/template_set/actions";
import SetDownloadCSV from "../../header/SetDownloadCSV";
import ShareDropdown from "../../../decision_set_view/components/ShareDropdown";

export const SetMoreActionsSection = ({
                                        set, setTitle, homeTab, preview = false, isManager = true,
                                        removeDecisionSet, resetDecisionSet, forceReloadHomepageDecisions,
                                        isTemplateSet = false, resetSidebars, openModal, setSetSummarySidebarOpen,
                                        removeTemplateSet, resetTemplateSet, forceReloadHomepageTemplates, deleteAllTemplateSet
                                      }) => {
  if (preview) return null;

  const navigate = useNavigate();
  const deleteDecisionHandler = deleteSetCallback(removeDecisionSet,
    [resetDecisionSet, forceReloadHomepageDecisions, resetSidebars],
    { navigate, homeTab, setTitle }
  );
  const deleteTemplateHandler = deleteSetTemplateCallback(removeTemplateSet,
    [resetTemplateSet, forceReloadHomepageTemplates, resetSidebars],
    { navigate, homeTab, setTitle }, deleteAllTemplateSet
  );

  return (
    <React.Fragment>
    <div className="px-3 pb-3">
      {
        (!isTemplateSet && isManager) &&
        <ShareDropdown dropdownClassName="sharing-button" title={"Share decision flow"}
                       btnClassName="btn btn-secondary w-100 mb-2" btnId="share-details-dropdown" />
      }
      {
        isTemplateSet &&
          <Button onClick={openTemplateSetUsersEntry(openModal)} className="btn btn-secondary w-100 mb-2">
            Edit decision flow users
          </Button>
      }
      {
        !isTemplateSet && isSummaryPending(set) &&
          <Button onClick={setSetSummarySidebarOpen} className="btn btn-secondary w-100 mb-2">
            Create a flow summary
          </Button>
      }
      {
        !isTemplateSet &&
        <SetDownloadCSV isButton={true} />
      }
      {
        isManager && <Button onClick={isTemplateSet ? deleteTemplateHandler : deleteDecisionHandler} className="btn-light text-danger w-100 mb-0">
          {isTemplateSet ? 'Delete decision flow template' : 'Ungroup decision flow'}
        </Button>
      }
    </div>
  </React.Fragment>
  )
}
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = (dispatch) => ({
  resetSidebars: () => dispatch(resetSidebars()),
  openModal: (data) => dispatch(openModal(data)),
  setSetSummarySidebarOpen: () => dispatch(setSetSummarySidebarOpen(true)),
  forceReloadHomepageDecisions: () => dispatch(forceReloadHomepageDecisions()),
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  removeDecisionSet: (callback) => dispatch(removeDecisionSet(callback)),
  removeTemplateSet: (callback) => dispatch(removeTemplateSet(callback)),
  resetDecisionSet: () => dispatch(resetDecisionSetData()),
  resetTemplateSet: () => dispatch(resetTemplateSetData()),
  deleteAllTemplateSet: (callback) => dispatch(removeTemplateSetAndTemplates(callback)),

});
export default connect(mapStateToProps, mapDispatchToProps)(SetMoreActionsSection);
