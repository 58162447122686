import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from "react-redux";
import { isMobile } from 'react-device-detect';
import { isBlank, isPresent } from "../helpers/common";
import Button from 'react-bootstrap/Button';
import EntryPoint from "../EntryPoint";
import { Routes } from "../routes";
import SignInHeader from "./header";
import Container from 'react-bootstrap/Container';
import LoginPage from './LoginPage';
import { validateUserData } from '../utils/Api';
import UserPasswordInput from '../common/UserPasswordInput';
import {skipRegistration} from "../store/sign_in/actions";
import {useNavigate} from "react-router-dom";

const PasswordRow = ({
                       ssoUser, errors, submit,
                       password, setPassword
                    }) => {
  const [isPassword, setIsPassword] = useState(isMobile);
  if(ssoUser) return null;

  return (
    <div className="row">
      <div className="col">
        <div className="mb-3">
        <h3 htmlFor="user-password" className="fw-bold">Password</h3>
          <UserPasswordInput
            id="user-password"
            name="user[password]"
            placeholder="Create a password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isPassword={isPassword}
            setIsPassword={setIsPassword}
            readOnly={submit}
            autoComplete={'off'}
          />
         <div className={`mt-1 ${ errors['password'] || errors['base'] ? 'text-danger' : 'text-muted' }`}>
            Your password must be between 6 and 128 characters long, contain at least one symbol,
            and avoid repeating the same character more than twice in a row.
          </div>
        </div>
      </div>
    </div>
  );
};

const CompleteProfile = ({skipRegistration}) => {
  const formRef = useRef(null);
  const inviteSlug = EntryPoint.instance.objectSlug;
  const initialFirstName = EntryPoint.instance.pre_fill_data?.first_name || '';
  const initialLastName = EntryPoint.instance.pre_fill_data?.last_name || '';
  const email = EntryPoint.instance.pre_fill_data?.email;
  const inviteCreatePassword = EntryPoint.instance.pre_fill_data?.invite_create_password;
  const ssoUser = EntryPoint.instance.pre_fill_data?.sso_user;
  const selectedOrgId = EntryPoint.instance.pre_fill_data?.selected_org_id;
  const [showLogin, _] = useState(EntryPoint.instance.pre_fill_data?.show_login_page);
  const allowSkip = EntryPoint.instance.pre_fill_data?.allow_skip_registration;
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [submit, setSubmit] = useState(false)
  const [isSkipDisabled, setIsSkipDisabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      const {instance} = EntryPoint
      if(isPresent(instance.flash['errors'])) {
        setErrors(JSON.parse(instance.flash['errors']));
      }
      delete instance.flash['errors'];
    }, 500)
  }, [])

  const isDataChanged = useMemo(() =>
    initialFirstName !== firstName || initialLastName !== lastName || ssoUser ||
    (!ssoUser && isPresent(password))
    , [initialLastName, initialFirstName,  firstName, lastName, password])

  const isBlankMainData = useMemo(() =>
    isBlank(firstName.trim()) || isBlank(lastName.trim()) || (!ssoUser && isBlank(password))
    , [initialLastName, initialFirstName, firstName, lastName, password])


  const authenticityToken = useMemo(() =>
      document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    , [])

  const onSubmit = useCallback(_ => {
    setSubmit(true)
  }, [])

  const submitForm = useCallback((e) => {
    e.preventDefault();
    onSubmit();
    validateUserData({ firstName, lastName, password, email, ssoUser }).then(response => {
      const { data, ok } = response;
      if(ok) {
        formRef.current.submit();
      } else {
        setErrors(data['errors']);
        setSubmit(false);
      }
    })
    return false;
  }, [firstName, lastName, password])

  const onSkip = () => {
    setIsSkipDisabled(true);
    skipRegistration((success, decision_slug) => {
      if (success) {
        navigate(`/decisions/${decision_slug}/assigned_drivers`);
        navigate(0)
      } else {
        setIsSkipDisabled(false);
      }
    });
  };

  const changeFirstName = (value) => {
    setErrors({ ...errors, first_name: '' })

    if (isBlank(value.trim())) setErrors({ ...errors, first_name: 'Please enter your first name.' })

    setFirstName(value)
  }

  const changeLastName = (value) => {
    setErrors({ ...errors, last_name: '' })

    if (isBlank(value.trim())) setErrors({ ...errors, last_name: 'Please enter your last name.' })

    setLastName(value)
  }

  if(showLogin) return <LoginPage selectedOrgId={selectedOrgId} disableEmail={true} />

  return <Fragment>
    <SignInHeader />
    <Container>
      <div className="container home my-3 mx-auto">
        <div className="mx-auto profile-container bg-white p-3 rounded">
          <div className="header text-center">
            <h2>Welcome! Please complete your profile.</h2>
          </div>
          <form action={inviteCreatePassword ? Routes.invite_create_password : Routes.user_registration} method={`post`}
                acceptCharset={`UTF-8`} encType="multipart/form-data" onSubmit={submitForm} ref={formRef}>
            <input type={`hidden`} name={'_method'} value={inviteCreatePassword ? 'patch' : 'post'}/>
            <input type={`hidden`} name={'authenticity_token'} value={authenticityToken}/>
            <input type={`hidden`} name={'user[invite_slug]'} value={inviteSlug}/>
            {
              ssoUser ?
                <input type={`hidden`} name={'user[ssoUser]'} value={ssoUser}/> :
                <input type={`hidden`} name={'user[password_confirmation]'} value={password}/>
            }
            <input type={`hidden`} name={'user[password_confirmation]'} value={password}/>
            <input type={`hidden`} name={'user[email]'} value={email}/>
            <div className="row mt-3">
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <h3 htmlFor="user-first-name" className="fw-bold">First name</h3>
                  <input id="user-first-name" name="user[first_name]"
                         value={firstName}
                         className={`form-control rounded ${errors['first_name'] ? 'is-invalid' : ''}`}
                         placeholder={`Enter your first name`} maxLength={30}
                         readOnly={submit}
                         onChange={(e) => {
                           changeFirstName(e.target.value)
                         }}/>
                  <span className={`${errors['first_name'] ? 'd-block mt-1 text-danger' : 'd-none'}`}>
                    {errors['first_name']}
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <h3 htmlFor="user-last-name" className="fw-bold">Last name</h3>
                  <input id="user-last-name" name="user[last_name]"
                         value={lastName}
                         className={`form-control rounded ${errors['last_name'] ? 'is-invalid' : ''}`}
                         placeholder={`Enter your last name`} maxLength={30}
                         readOnly={submit}
                         onChange={(e) => {
                           changeLastName(e.target.value)
                         }}/>
                  <span className={` ${errors['last_name'] ? 'd-block mt-1 text-danger' : 'd-none'}`}>
                    {errors['last_name']}
                  </span>
                </div>
              </div>
            </div>
            <PasswordRow password={password} setPassword={setPassword} submit={submit} errors={errors}
                         ssoUser={ssoUser}/>
            <div className="row">
              <div className="col-12">
                <Button type="submit" className={`w-100 btn-primary`}
                        disabled={isBlankMainData || !isDataChanged || submit}
                        onClick={submitForm}>Done</Button>
              </div>
            </div>
            {
              allowSkip && <div className='text-center mt-3'>
                <a className={`${isSkipDisabled ? 'text-secondary' : ''}`}
                   style={{cursor: isSkipDisabled ? 'not-allowed' : 'pointer'}}
                   onClick={!isSkipDisabled ? onSkip : null}>
                  Skip this step
                </a>
              </div>
            }
          </form>
        </div>
      </div>
    </Container>
  </Fragment>
}

export default connect(null, {skipRegistration})(CompleteProfile);
