import { LOAD_DECISIONS_FAILED, LOAD_DECISIONS_STARTED, RESET_DECISIONS_DATA, UPDATE_DECISIONS_DATA } from "./types";

const initialState = {
    loaded: false,
    loading: false,
    error: '',
    slug: '',
    description: '',
    collaboration_context: '',
    expected_results: '',
    final_decision_reasons: '',
    reason: '',
    decided_at: '',
    rate_support: false,
    sharable: false,
    rate_compare_choices: false,
    choice_entry_widget_type: '',
    created_at: '',
    closed_at: '',
    followups: [],
    feedbacks: [],
    driver_comments: [],
    decision_tags: [],
    invites: [],
    users: [],
    user_supports: [],
    user_approvals: [],
    category_options: [],
    selected_categories: [],
    choices: [],
    user: {},
    deciding_user: {},
    recommendation: {},
    flow_enum: '',
    flow_enum_type: '',
    final_decision_ranked: false,
    due_date: '',
    playbook: false,
    from_template: false,
    decision_set_slug: '',
    decision_set_name: '',
    historical_decided_at: '',
    expected_opportunity: '',
    expected_investment: '',
    assigned_collaborator_email: '',
    assigned_decider_email: '',
    is_decision_user: false,
    next_steps_description: '',
    considered_choices: [],
    default_user_avatar_url: '',
    poll_due_date: '',
    poll_notes: '',
    show_as_completed: false,
    chat_gpt_queries: [],
    read_only: false,
    allow_collaborators_edit_tree: false,
    allow_collaborators_edit_collaborators: false,
    ai_instructions: ''
};

export function decisionReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case UPDATE_DECISIONS_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case LOAD_DECISIONS_STARTED: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: true
            }
        }
        case LOAD_DECISIONS_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        case RESET_DECISIONS_DATA: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }
}
