import React, {useMemo, useState} from 'react';
import {
  buildNewDriver,
  duplicateDriverData,
  findDriverIn,
  saveDriversCallback
} from "../steps_wizard/steps/helpers/tree_builder_step";
import EditDriverDetailsModal from "../../tree_editor/modals/DriverDetailsModal";
import HistoricalDriverDetailsModal from "../../tree_editor/modals/HistoricalDriverDetailsModal";
import { isBlank, isPresent } from "../../helpers/common";
import {DEFAULT_RATING_SCALE, DriverChoiceEntryType, NUMBER_FORMAT} from "../../models/driver";
import * as moment from "moment";
import {buildDriverChoices} from "../../tree_view/modals/entry_modal/helpers";
import {getAssigneeName} from "../../tree_editor/modals/DriverAssignModal";
import {stepKeyWithInvites, wizardStepDataBy} from "../../helpers/wizard_helpers";
import {userAlreadyAddedAsAssignee} from "../../helpers/user_helpers";
import {onDriversInviteCallback} from "../../tree_editor/modals/ZTreeDriverDetailsModal";

const sortByLevelOrder = (a, b) => {
  const aLevelOrder = a.driver.level_order || Infinity;
  const bLevelOrder = b.driver.level_order || Infinity;
  return (aLevelOrder < bLevelOrder) ? -1 : 1
}
const reArrangeDriversByLevelOrder = (parentDriverData, levelOrder, newLevelOrder) => {
  if(levelOrder === newLevelOrder || newLevelOrder < 1 || newLevelOrder > parentDriverData.children.length) return false;
  const driverData = parentDriverData.children.find((driverData) => driverData.driver.level_order === newLevelOrder);
  driverData.driver.level_order = levelOrder;
  return true;
}

const updateDriverDataCallback = (drivers, setDrivers) => (slug, inputValue, assignTo, notes = '', explanation = '',
                                                           driverTypeSlug = '', levelOrder = 1, answer = '',
                                                           ratingScale = DEFAULT_RATING_SCALE.value, ratingLabels = '',
                                                           dataSourcesSlugs = [], dueDate = null, choices = [],
                                                           driverResponseType = '', numberFormat = NUMBER_FORMAT.DEFAULT,
                                                           aiInstructions = '', isDriversInvite = false,
                                                           driverReferences = [], weight = null ) => {
  const newDrivers = [...drivers]
  const [driverData, parentDriversData] = findDriverIn(slug, { children: newDrivers })

  if (isPresent(driverData)) {
    reArrangeDriversByLevelOrder(parentDriversData, driverData.driver.level_order, levelOrder)
    driverData.driver.question = inputValue;
    driverData.driver.assign_to_user = assignTo;
    driverData.driver.driver_type_slug = driverTypeSlug;
    driverData.driver.notes = notes;
    driverData.driver.explanation = explanation;
    driverData.driver.level_order = levelOrder;
    driverData.driver.answer = answer;
    driverData.driver.rating_scale = ratingScale;
    driverData.driver.rating_labels =  ratingLabels;
    driverData.driver.due_date = dueDate;
    driverData.driver_sources_slugs = dataSourcesSlugs;
    driverData.driver.choice_entry_widget_type = driverResponseType;
    driverData.driver.driver_response_choices = choices;
    driverData.driver.number_format = numberFormat;
    driverData.driver.ai_instructions = aiInstructions;
    driverData.driver.is_drivers_invite = isDriversInvite;
    driverData.driver.referenced_drivers = driverReferences;
    driverData.driver.weight = weight;
    if (isBlank(driverData.children)) driverData.children.push(buildNewDriver(driverData))

      if (isPresent(parentDriversData)) {
        const { children } = parentDriversData
        if (isPresent(children) && isPresent(children[children.length - 1]?.driver?.question)) {
          children.push(buildNewDriver(parentDriversData, children.length+1))
        }
        children.sort(sortByLevelOrder)
      }
      setDrivers(newDrivers);
    }
    return newDrivers;
  }

const onBuilderFormSubmitCallback = (saveDrivers, updateDriverData, updateDecisionData, setDecisionDescription) => (slug, inputValue, assignTo, notes, explanation, driverTypeSlug, levelOrder, isDecision, answer, ratingScale, ratingLabels, dataSourcesSlugs, dueDate, choices, driverResponseType, numberFormat, aiInstructions, isDriversInvite, driverReferences) => {
  if (isDecision) {
    setDecisionDescription(inputValue)
    updateDecisionData({ description: inputValue })
  } else {
    return updateDriverData(slug, inputValue, assignTo, notes, explanation, driverTypeSlug, levelOrder, answer, ratingScale, ratingLabels, dataSourcesSlugs, dueDate, choices, driverResponseType, numberFormat, aiInstructions, isDriversInvite, driverReferences)
  }
}

export default ({
                  tree, orgDriverTypes = {}, collaborators = [], title, isTemplate, submitStep, submitDrivers,
                  modal, driverData, modalDrivers, isHistoricalDecision = false,
                  onClose, updateTreeData, wizard
                }) => {
  const availableDriverTypes = useMemo(() => orgDriverTypes.available_types, [orgDriverTypes]);

  const [drivers, setDrivers] = useState(modalDrivers)
  const [inputValue, setInputValue] = useState(driverData.driver.question || '')
  const [answer, setAnswer] = useState(driverData.driver.answer || '')
  const [notes, setNotes] = useState(driverData.driver.notes || '')
  const [explanation, setExplanation] = useState(driverData.driver.explanation || '')
  const [ratingScale, setRatingScale] = useState(driverData.driver.rating_scale || DEFAULT_RATING_SCALE.value)
  const [ratingLabels, setRatingLabels] = useState(driverData.driver.rating_labels || {})
  const initDate = isPresent(driverData?.driver.due_date) ? moment(driverData?.driver.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);
  const defaultHistoricalDriverType = availableDriverTypes?.find((driverType) => driverType.default_historical_driver_type);
  const showDefaultDriverType = isBlank(driverData.driver.driver_type_slug) && isPresent(defaultHistoricalDriverType)
    && isHistoricalDecision
  const [driverTypeSlug, setDriverTypeSlug] = useState(showDefaultDriverType ? defaultHistoricalDriverType.slug : (driverData.driver.driver_type_slug || ''));
  const [assignedToUser, setAssignedToUser] = useState(driverData.driver.assign_to_user);
  const levelOrder = driverData.driver.level_order || 1
  const dataSourcesSlugs = driverData.driver_sources_slugs || [];
  const [driverResponseType, setDriverResponseType] = useState(driverData.driver.choice_entry_widget_type || DriverChoiceEntryType.OPEN);
  const [driverChoices, setDriverChoices] = useState(buildDriverChoices(driverData?.driver));
  const [numberFormat, setNumberFormat] = useState(driverData.driver.number_format || NUMBER_FORMAT.DEFAULT);
  const [isDriversInvite, setIsDriversInvite] = useState(null);
  const [aiInstructions, setAiInstructions] = useState(driverData.driver.ai_instructions || '');
  const [driverReferences, setDriverReferences] = useState(driverData.driver.referenced_drivers || []);

  const stepData = wizardStepDataBy(wizard, stepKeyWithInvites(wizard)) || {}
  const wizardCollaborators = stepData?.collaborators || []
  const isExistedCollaborator = wizard.user_email === assignedToUser || wizardCollaborators.some(email => email === assignedToUser)
  const userName = getAssigneeName(assignedToUser, collaborators);
  const isExistedAssignee = userAlreadyAddedAsAssignee(assignedToUser, drivers);

  const saveDrivers = saveDriversCallback(updateTreeData, drivers)
  const updateDriverData = updateDriverDataCallback(drivers, setDrivers)
  const onFormSubmit = onBuilderFormSubmitCallback(saveDrivers, updateDriverData)

  const onSubmitDrivers = (updatedDrivers) => {
    submitDrivers(updatedDrivers)
    if(tree.loaded) updateTreeData({drivers: updatedDrivers})
  }

  const onSubmitModal = () => {
    const saveStepDataAction = (isDriversInvite) => {
      const updatedDrivers = onFormSubmit(
        modal.slug, inputValue, assignedToUser, notes, explanation, driverTypeSlug, levelOrder, false, answer,
        ratingScale, ratingLabels, dataSourcesSlugs, dueDate, driverChoices, driverResponseType, numberFormat,
        aiInstructions, isDriversInvite, driverReferences
      )

      return submitStep({drivers: updatedDrivers, finish_later: true},
        success => {
          if(success && tree.loaded) updateTreeData({drivers: updatedDrivers})
        }
      )
    }

    onDriversInviteCallback({
      userName,
      assignedToUser,
      isExistedCollaborator,
      isExistedAssignee,
      updateAction: saveStepDataAction,
      onCloseModal: onClose,
      isTemplate,
      userCanEditCollaborators: true
    });
  }
  const onChangeDriverType = ({ value }) => setDriverTypeSlug(value);
  const options = {
    question: inputValue, setQuestionValue: setInputValue, driverData,
    notes, setNotes, driverTypeSlug, onChangeDriverType, answer, setAnswer,
    onClose, onSubmitModal, title, explanation, setExplanation, setDriverTypeSlug, onSubmitDrivers,
    ratingScale, setRatingScale, ratingLabels, setRatingLabels, dueDate, setDueDate, choices: driverChoices, setChoices: setDriverChoices,
    driverResponseType, setDriverResponseType, numberFormat, setNumberFormat, aiInstructions, setAiInstructions,
    isDriversInvite, setIsDriversInvite, driverReferences, setDriverReferences,
    show: true
  }

  if(isHistoricalDecision) {
    return <HistoricalDriverDetailsModal {...options}/>
  } else {
    return <EditDriverDetailsModal
      {...options}
      {...{ collaborators, isTemplate, isHistoricalDecision, assignedToUser, setAssignedToUser, setDriverTypeSlug, drivers: tree.drivers }}
      isWizard={true}
    />
  }
}
