import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetLearnData } from "../../../store/learn/actions";
import { resetSidebars, setLearnFiltersSidebarOpen } from "../../../store/sidebar/actions";
import { isBlank, successActions } from "../../../helpers/common";
import { redirectToHomeCallback } from "../../../helpers/decision_helpers";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../../common/dropdowns";

export const HamburgerDropdown = ({
                                    sidebar, learn,
                                    setLearnFiltersSidebarOpen, resetLearnData, resetSidebars
                                  }) => {
  const navigate = useNavigate();
  const processGoToHomepage = () => {
    successActions(true, [resetSidebars, resetLearnData])
    redirectToHomeCallback(true, navigate);
  }
  const processPreviewDecision = () => navigate(`/templates/${learn.current_template}/preview`)

  return <HamburgerMenuIconBtn>
    {
      isBlank(learn.current_template) ? null : <BaseHamburgerBtn onSelect={processPreviewDecision} title="Preview decision" />
    }
    <BaseHamburgerBtn onSelect={() => setLearnFiltersSidebarOpen(!sidebar.filtersSidebar)} className={`d-none`} title="Filters" />
    <BaseHamburgerBtn onSelect={processGoToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ sidebar, learn }) => ({ sidebar, learn });
export default connect(mapStateToProps, { resetLearnData, setLearnFiltersSidebarOpen, resetSidebars })(HamburgerDropdown);
