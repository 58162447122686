import React from "react";
import {openModal} from "../store/modals/actions";
import {connect} from "react-redux";
import {isVisitor} from "../helpers/user_helpers";

const EditAssignBtn = ({ assignUser, openModal, modalType, current_user, isDSight = false, assignDriverEntry}) => {
  if (isDSight || assignUser || (assignDriverEntry && isVisitor(current_user))) return null

  return (
  <button onClick={() => openModal({type: modalType, decision: {}})} className='border-0 bg-transparent rounded-circle sm-avatar p-0 bg-secondary'>
    <i className="fas fa-lg fa-user text-primary"/>  </button>
  )
}

const mapStateToProps = ({ modal, current_user }) => ({ modal, current_user });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(EditAssignBtn);
