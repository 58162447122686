import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import SlidingPanel from "react-sliding-side-panel";
import { setTreeSidebarOpen } from "../../store/sidebar/actions";
import CloseIcon from "../../common/CloseIcon";
import { updateDecisionData } from "../../store/decision/common_actions";
import { updateTreeData } from "../../store/tree/common_actions";
import { saveWizardStepData } from "../../store/wizard/actions";
import WizardTreeEditor from "../../tree_editor/wizard/WizardTreeEditor";
import { Footer, Header } from "../../tree_editor/tree_view/PanelTreeEditor";
import {
  buildDrivers, fetchContacts,
  onCopyDriverCallback,
  onRemoveDriverCallback
} from "../steps_wizard/steps/helpers/tree_builder_step";
import {TREE_MODES} from "../../helpers/drivers_helpers";

export const WizardTreePanel = ({
                                  stepRef, tree, sidebar, wizard, contactsData, openSideBar,
                                  isTemplate = false
                                }) => {
  const [drivers, setDrivers] = useState(buildDrivers({ tree, wizard }))
  const onRemoveDriver = onRemoveDriverCallback(drivers, setDrivers);
  const onCopyDriver = onCopyDriverCallback(drivers, setDrivers);

  useEffect(() => {
    setDrivers(buildDrivers({ tree, wizard }))
  }, [tree.drivers])

  if(!wizard.loaded) return null;

  const onClose = () => {
    openSideBar(false);
  }

  const collaborators = isTemplate ? [] : fetchContacts(wizard, contactsData, true)

  return <SlidingPanel
    type="left"
    isOpen={sidebar.treeSidebar}
    panelContainerClassName="sidebar-panel-wrapper tree-panel-wrapper decision-tree-panel-width left-side-panel-position tree-page"
    panelClassName="sidebar-panel-wrapper-content tree-panel-content h-auto"
    size={50}>
    <div className="mt-2 me-3 float-end">
      <CloseIcon onClose={onClose} />
    </div>
    <div className="py-2 px-3">
      <Header treeMode={TREE_MODES.edit} />
      <WizardTreeEditor collaborators={collaborators}
                        ref={stepRef}
                        isTemplate={isTemplate}
                        zTreeId={'decisionTreePanel'}
                        onRemoveDriver={onRemoveDriver}
                        onCopyDriver={onCopyDriver}
                        drivers={drivers}
                        setDrivers={setDrivers}
                        inSidebar={true} />
      <Footer onClose={onClose} />
    </div>
  </SlidingPanel>
}

const mapStateToProps = ({ tree, sidebar, wizard, contacts }) => ({
  tree, sidebar, wizard, contactsData: contacts
});
const mapDispatchToProps = (dispatch) => ({
  openSideBar: (options) => {
    dispatch(setTreeSidebarOpen(options, TREE_MODES.edit));
  },
  updateDecisionData: (data) => {
    dispatch(updateDecisionData(data))
  },
  updateTreeData: (data) => {
    dispatch(updateTreeData(data))
  },
  saveWizardStepData: (step, data, callback) => {
    dispatch(saveWizardStepData(step, data, callback));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(WizardTreePanel);
