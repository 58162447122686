import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../../common/Loader";
import {
  analysisInitCallbacks,
  BreadcrumbDelimiter,
  GoalBreadcrumb,
  mapDispatchToProps,
  mapStateToProps,
  Wrapper
} from "../index";
import Analysis, { AnalysisGoal } from "../../../models/d_sight/Analysis";
import { METRICS_NODE } from "../sidebar/tree_details/TreeDetails";
import ContributingMetricContent from "./ContributingMetricContent";
import { analysisLink } from "../../../helpers/dsight_helpers";
import { isStoresLoading } from "../../../helpers/callbacks_helpers";

export const DSightAnalysisMetrics = ({
                                        analysis, d_sight_goal, ...opts
                                      }) => {
  analysisInitCallbacks({ analysis, d_sight_goal, ...opts })

  if (isStoresLoading(analysis, d_sight_goal)) {
    return <Wrapper>
      <Loader/>
    </Wrapper>;
  } else {
    const analysisObj = new Analysis(analysis, null, null, d_sight_goal.selected_demographic)
    const goalObj = new AnalysisGoal(d_sight_goal.goal_chain)

    const breadcrumbs = <>
      <GoalBreadcrumb {...{ ...opts, d_sight_goal, goalObj }} />
      <BreadcrumbDelimiter/>
      <Link to={analysisLink(analysisObj.link)}>
        {analysisObj.name}
      </Link>
      <BreadcrumbDelimiter/>
      <span>Metrics</span>
    </>

    return <Wrapper breadcrumbs={breadcrumbs} currentTreeNode={METRICS_NODE}>
      <ContributingMetricContent/>
    </Wrapper>;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DSightAnalysisMetrics);
