import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { isBlank, isPresent } from "../../helpers/common";
import DriverDetailsModal, { modalDriverData } from "../../tree_editor/modals/DriverDetailsModal";
import { openModal, transferDataAndCloseModal } from "../../store/modals/actions";
import Driver, {
  DEFAULT_RATING_SCALE,
  DriverChoiceEntryType, NUMBER_FORMAT
} from "../../models/driver";
import { driversToArray } from "../../helpers/drivers_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../channels/tree_channel";
import {
  isDriverInEditModeByOthers,
  isDriverInEditModeByUser,
  performEditDriverDetailsAction,
  treeChannelIsConnected
} from "../../helpers/channel_helpers";
import { editingDriverAlert } from "../accordion_tree/shared/helpers";
import Decision from "../../models/decision";
import { saveSideBarData } from "../../store/sidebar/common_actions";
import { fetchContacts } from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import * as moment from "moment";
import {buildDriverChoices} from "./entry_modal/helpers";
import { updateDriver } from "../../store/sidebar/actions";
import {collectCollaborationInvites} from "../../helpers/decision_helpers";
import {checkLoadingEffect} from "../../helpers/callbacks_helpers";
import {
  collaboratorCanEditCollaborators,
  isDecider,
  isExistingCollaborator,
  userAlreadyAddedAsAssignee
} from "../../helpers/user_helpers";
import {getAssigneeName} from "../../tree_editor/modals/DriverAssignModal";
import {onDriversInviteCallback} from "../../tree_editor/modals/ZTreeDriverDetailsModal";
import {loadContacts} from "../../store/contacts/actions";
import {isDSightDecision} from "../../helpers/home_decision_helpers";

export const showModal = (modal, slug) => modal.type === "DriverDetailsModal" && modal.shown && modal.slug === slug;
export const openDriverEntryModal = (decision, slug, {drivers, data_sources}, openModal, channels, user) => {
  if(isDriverInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, slug, user))
    return editingDriverAlert(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, slug)

  performEditDriverDetailsAction(user, slug, true)
  openModal({
    decision, slug,
    drivers, data_sources,
    type: 'DriverDetailsModal'
  })
};

const DriverEntryModal = ({
                            key = null, driverData= null, isTemplate,
                            decision, modal, channels, user, contactsData, assignDriverEntry = false,
                            transferDataAndCloseModal, updateDriver, driversData, loadContacts
                          }) => {
  if (isBlank(driverData)) return null;

  const slug = driverData.driver.slug;
  const [inputValue, setInputValue] = useState(driverData.driver.question);
  const [assignedToUser, setAssignedToUser] = useState(driverData.driver.assign_to_user);
  const [notes, setNotes] = useState(driverData.driver.notes || '');
  const [driverTypeSlug, setDriverTypeSlug] = useState(driverData.driver.driver_type_slug);
  const [driverResponseType, setDriverResponseType] = useState(driverData.driver.choice_entry_widget_type || DriverChoiceEntryType.OPEN);
  const [ratingScale, setRatingScale] = useState(driverData.driver.rating_scale || DEFAULT_RATING_SCALE.value)
  const [ratingLabels, setRatingLabels] = useState(driverData.driver.rating_labels || {})
  const initDate = isPresent(driverData?.driver.due_date) ? moment(driverData?.driver.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);
  const [choices, setChoices] = useState(buildDriverChoices(driverData?.driver));
  const [numberFormat, setNumberFormat] = useState(driverData.driver.number_format || NUMBER_FORMAT.DEFAULT);
  const [aiInstructions, setAiInstructions] = useState(driverData.driver.ai_instructions || '');
  const invites = isPresent(decision.invites) ? collectCollaborationInvites(decision) : []
  const [driverReferences, setDriverReferences] = useState(driverData.driver.referenced_drivers || []);
  const driverObj = new Driver(driverData.driver, driverData.driver_sources_slugs, [], new Decision(decision));

  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));

  const collaborators = fetchContacts({}, contactsData, true, true)

  const userName = getAssigneeName(assignedToUser, collaborators);
  const isExistedCollaborator = isExistingCollaborator(assignedToUser, invites, decision.user);
  const isExistedAssignee = userAlreadyAddedAsAssignee(assignedToUser, driversData);
  const userCanEditCollaborators = isDecider(user) || collaboratorCanEditCollaborators(user, decision)

  const onCloseModal = (onDone = false) => {
    if(treeChannelIsConnected() && isDriverInEditModeByUser(channels, TREE_CHANNEL_ACTIONS.edit_driver_details, user)) {
      performEditDriverDetailsAction(user, slug, false)
    }
    transferDataAndCloseModal()
    if (!onDone) {
      setInputValue(driverData.driver.question)
      setAssignedToUser(driverData.driver.assign_to_user)
      setNotes(driverData.driver.notes || '')
      setDriverTypeSlug(driverData.driver.driver_type_slug)
      setDriverResponseType(driverData.driver.choice_entry_widget_type || DriverChoiceEntryType.OPEN)
      setRatingScale(driverData.driver.rating_scale || DEFAULT_RATING_SCALE.value)
      setRatingLabels(driverData.driver.rating_labels || {})
      setDueDate(initDate)
      setChoices(buildDriverChoices(driverData?.driver))
      setNumberFormat(driverData.driver.number_format || NUMBER_FORMAT.DEFAULT)
      setAiInstructions(driverData.driver.ai_instructions || '')
      transferDataAndCloseModal()
    }
  }

  const onSubmitModal = () => {
    const updateAction = (isInvite) => updateDriver({
      slug, notes, due_date: dueDate, assign_to_user: assignedToUser,
      ...(
          isPresent(driverObj.insightsData) ? {} :
            {
              inputValue,
              driver_type: driverTypeSlug,
              choice_entry_widget_type: driverResponseType,
              rating_scale: ratingScale,
              rating_labels: ratingLabels,
              number_format: numberFormat,
              ai_instructions: aiInstructions,
              driver_references: driverReferences,
              ...(isTemplate ? {} : { drivers_invite: isInvite }),
              choices
            }
        )}
      );

    if (isDSightDecision(decision)) {
      updateAction(false);
      return onCloseModal(true);
    }

    onDriversInviteCallback({
      userName,
      assignedToUser,
      isExistedCollaborator,
      isExistedAssignee,
      updateAction,
      onCloseModal,
      isTemplate,
      userCanEditCollaborators
    });
  }

  useEffect(() => {
    if (!showModal(modal, slug)) {
      setNotes(driverData.driver.notes || '');
      setAssignedToUser(driverData.driver.assign_to_user);
      setDueDate(initDate);
    }
  }, [showModal(modal, slug), driverData]);

  useEffect(() => {
    setChoices(buildDriverChoices(driverData?.driver))
  }, [driverData?.driver.driver_response_choices.length])

  return <DriverDetailsModal key={key || `driver-details-modal-${slug}`}
                             title={isPresent(driverObj.insightsData) ? 'Assign driver' : 'Edit driver'}
                             collaborators={isTemplate ?
                               fetchContacts({}, contactsData, false, false):
                               fetchContacts({}, contactsData, true)}
                             onSubmitModal={onSubmitModal}
                             driverData={modalDriverData(modal)}
                             question={inputValue}
                             setQuestionValue={setInputValue}
                             notes={notes}
                             driverTypeSlug={driverTypeSlug}
                             setDriverTypeSlug={setDriverTypeSlug}
                             setNotes={setNotes}
                             show={showModal(modal, slug)}
                             assignedToUser={assignedToUser}
                             setAssignedToUser={setAssignedToUser}
                             onClose={onCloseModal}
                             isTemplate={isTemplate}
                             isDSight={isPresent(driverObj.insightsData)}
                             ratingScale={ratingScale}
                             setRatingScale={setRatingScale}
                             ratingLabels={ratingLabels}
                             setRatingLabels={setRatingLabels}
                             dueDate={dueDate}
                             setDueDate={setDueDate}
                             driverResponseType={driverResponseType}
                             setDriverResponseType={setDriverResponseType}
                             choices={choices}
                             setChoices={setChoices}
                             allowNew={!isTemplate}
                             numberFormat={numberFormat}
                             setNumberFormat={setNumberFormat}
                             aiInstructions={aiInstructions}
                             setAiInstructions={setAiInstructions}
                             driverReferences={driverReferences}
                             setDriverReferences={setDriverReferences}
                             assignDriverEntry={assignDriverEntry}
  />
}
const mapStateToProps = ({ modal, tree, decision, channels, current_user, contacts }, ownProps) => {
  const driverData = ownProps.driverData || driversToArray(modal.drivers).find((driverData) => driverData.driver.slug === modal.slug)
  return { contactsData: contacts,
    modal, tree, decision, channels,
    driverData, user: current_user,
    driversData: isPresent(tree.drivers) ? driversToArray(tree.drivers).filter(d => d.driver.question) : []
  }
};
export default connect(mapStateToProps, {
  openModal, transferDataAndCloseModal, saveSideBarData,
  updateDriver, loadContacts
})(DriverEntryModal);
