import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "highcharts/highcharts-more"
import "highcharts/modules/funnel";
import { BASE_CHARTS_OPTIONS, basesChartHash } from "../../../../../models/d_sight/metric_chart_helpers";
import ChartSourcesLabels from "../ChartSourcesLabels";
import { TimePeriodDropdown } from "../TimePeriodDropdown";

const FUNNEL_COLORS = ['#F3F9FD', '#D6ECF9', '#B9DEF3', '#9ED0ED', '#84C2E6', '#6BB4DD', '#52A5D4', '#3B96CA', '#2487BF'];

const BASE_FUNNEL_CHART_OPTIONS = {
  ...BASE_CHARTS_OPTIONS,
  chart: {
    type: 'funnel',
    spacing: [0, 0, 0, 0],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },

  colors: FUNNEL_COLORS,

  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b> ({point.y:,0f}{point.measure})',
        softConnector: true,
        style: {
          color: '#1F2F3F',
          fontSize: '0.875rem',
          fontWeight: 'normal',
          lineHeight: '12px',
          textOverflow: 'none'
        }
      },
      states: {
        hover: {
          color: '#2487BF'
        },
        inactive: {
          opacity: 0.5
        }
      },
      center: ['40%', '45%'],
      neckWidth: '30%',
      neckHeight: '0%',
      width: '80%',
      height: '95%'
    },
    funnel: {
      borderWidth: 1
    }
  },

  tooltip: {
    pointFormat: '({point.y:.0f}{point.measure})&nbsp;<span style="color:#8a949f">{point.nValText}</span>',
    valueDecimals: 1,
    headerFormat: '<span style="font-weight:bold">{point.key}</span> ',
    style: { color: '#1F2F3F' },
    useHTML: true
  },

  responsive: {
    rules: [{
      condition: {
        maxWidth: 500
      },
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              inside: true
            },
            center: ['50%', '50%'],
            width: '100%'
          }
        }
      }
    }]
  }
}

export default ({ metric, selectedSurvey, data, sourceLabels, chartData, ...opts }) => {
  const chartOptions = {
    ...BASE_FUNNEL_CHART_OPTIONS,

    series: [{
      name: '',
      data: data.map(({metric, mainTargetValue, mainTargetBasesValue}) =>
        ({
          name: metric.name,
          measure: metric.measure,
          y: mainTargetValue,
          ...basesChartHash(mainTargetBasesValue)
        })
      )
    }],
  };

  return <div className="row mb-3">
    <div className="col">
      <div className={'h-100 bg-white rounded p-3'}>
        <TimePeriodDropdown {...{ ...opts, selectedSurvey, header: metric.name }} />
        <div className="overflow-scroll" style={{width: 'calc(100% + 22px)'}}>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
        {
          sourceLabels ? <ChartSourcesLabels chartData={chartData} surveys={[selectedSurvey] } hideSignificanceLegend={true} isFunnel={true}/> : null
        }
      </div>
    </div>
  </div>
}
