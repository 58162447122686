import React, { useRef, useState, useEffect } from 'react';
import { connect } from "react-redux";
import {isDeciderReadOnly, isVisitor, userName} from "../../helpers/user_helpers";
import { formatMessageText } from "../../helpers/discussion_helpers";
import { deleteUserDriverComment } from "../../store/modals/actions";
import { updateTreeData } from "../../store/tree/common_actions";
import EditCommentRow, { mapModalSidebarDriverToProps } from "./EditCommentRow";
import { BaseDropdownBtn } from "../../common/BaseHamburgerBtn";
import { BtnDropdownToggle } from "../../common/dropdowns";
import { isPresent } from "../../helpers/common";
import UserAvatarImage from "../../common/UserAvatarImage";

const CommentActionsBlock = ({
                               comment, user,
                               shownDropdown, setShownDropdown,
                               onEditComment, onRemoveComment
                             }) => {
  if (comment.user_email !== user.email) return null;
  if (isVisitor(user) || isDeciderReadOnly(user)) return null;

  return  <div className="user-comment-actions">
    <div className="icon source-menu my-auto">
      <BtnDropdownToggle id={`driver-comment-${comment.id}`} show={shownDropdown} onToggle={(isOpen) => { setShownDropdown(isOpen) }}
                         bsPrefix="btn-link px-1 pb-0 shadow-none">
        <BaseDropdownBtn eventKey={comment.id} onSelect={() => onEditComment(comment)} title="Edit" />
        <BaseDropdownBtn eventKey={comment.id} onSelect={() => onRemoveComment(comment)} title="Delete" bsPrefix="text-danger" />
      </BtnDropdownToggle>
    </div>
  </div>
}

const EditCommentForm = React.forwardRef(({
                                            user, comment, displayEditCommentForm,
                                            driver, onClose, lastOpenedField
                                          }, ref) => {
  if ((isDeciderReadOnly(user) || isVisitor(user)) || comment.user_email !== user.email) return null;
  if (!displayEditCommentForm) return null;

  return <EditCommentRow {...{ ref, comment, driver, onClose, lastOpenedField }} />
})

const CommentRow = ({
                      innerRef, tree, driver,
                      comment, user,
                      deleteUserDriverComment,
                      lastOpenedField, setLastOpenedField,
                      avatarSize = 'md',
                      updateTreeData
                   }) => {
  const [displayEditCommentForm, setDisplayEditCommentForm] = useState(false)
  const [shownDropdown, setShownDropdown] = useState(false)
  const ref = useRef(null);

  useEffect(() => {
    if(isPresent(tree.scroll_to_driver_comment)) {
      const [driverSlug, commentId] = tree.scroll_to_driver_comment.split('-');
      if (driverSlug === driver.slug && commentId === comment.id.toString()) {
        setTimeout(() => {
          const selectedEl = document.getElementById(`comment-row-${comment.id}`);
          if (selectedEl) {
            selectedEl.scrollIntoView({ behavior: 'smooth' });
          }
          updateTreeData({ scroll_to_driver_comment: '' })
        }, 100)
      }
    }
  }, [tree.scroll_to_driver_comment])

  const onClose = () => {
    setDisplayEditCommentForm(false)
  }
  const isCurrentUserComment = comment.user_email === user.email;
  const onSuccessCallback = (success) => { if (success) onClose(); }
  const onRemoveComment = (comment) => deleteUserDriverComment(driver, comment, onSuccessCallback)
  const onEditComment = () => {
    setDisplayEditCommentForm(true)
    setLastOpenedField(comment.id.toString())
  }

  useEffect(() => {
    if (lastOpenedField === comment.id.toString() && !displayEditCommentForm && innerRef) {
      innerRef.current = null
    }
  }, [displayEditCommentForm, lastOpenedField]);

  if (innerRef && displayEditCommentForm && lastOpenedField === comment.id.toString()) {
    innerRef.current = {
      onDone: (resolve) => {
        if (displayEditCommentForm && lastOpenedField === comment.id.toString() && isPresent(ref.current)) {
          ref.current.updateComment(resolve)
        } else {
          resolve(true)
        }
      },
      onClose
    }
  }

  return <div className="comment-row" id={`comment-row-${comment.id}`}>
    <div className="d-flex mt-2">
      <div className="align-self-end me-2">
        <div className="icon">
          <UserAvatarImage user={comment.user} size={avatarSize} />
        </div>
      </div>
      <div className={`message-group w-100 ${isCurrentUserComment ? 'current-user-message' : ''}`}
           hidden={displayEditCommentForm} onClick={() => setShownDropdown(true)}>
        <div className="message-body rounded-top rounded-end p-2">
          <div className="header-row pb-1 d-flex">
            <div>{isCurrentUserComment ? 'You' : userName(comment.user)}</div>
          </div>
          <div className="message-text">{formatMessageText(comment)}
            <span className={'text-muted'} hidden={!comment.is_edited}> (edited) </span>
          </div>
          <CommentActionsBlock
            {...{
              comment, user,
              shownDropdown, setShownDropdown,
              onEditComment, onRemoveComment
            }}
          />
        </div>
      </div>

      <EditCommentForm
        {...{
          user, comment, displayEditCommentForm, ref, driver, onClose, lastOpenedField
        }}
      />
    </div>
  </div>
}
const mapStateToProps = ({ decision, tree, current_user, ...opts }, { driver = null }) => ({
  ...mapModalSidebarDriverToProps({ ...opts, driver }),
  tree,
  users: decision.users || [],
  default_user_avatar_url: tree.default_user_avatar_url,
  user: current_user
});
const mapDispatchToProps = (dispatch) => ({
  deleteUserDriverComment: (driver, comment, callback) => {
    dispatch(deleteUserDriverComment(driver, comment), callback)
  },
  updateTreeData: (data) => dispatch(updateTreeData(data))
});
const wrapper = React.forwardRef((props, ref) => <CommentRow {...props} innerRef={ref} />)
export default connect(mapStateToProps, mapDispatchToProps, null,{ forwardRef: true })(wrapper);
