import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ACCEPT_FILE_TYPES, DEFAULT_MAX_FILES_SIZE, MAX_FILE_SIZE, Type } from "../../models/data_source";
import { Tooltip } from 'react-tooltip';
import { isBlank, isPresent } from "../../helpers/common";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 7,
  borderColor: '#60B8D5',
  borderStyle: 'dashed',
  backgroundColor: '#D0EEFF',
  color: '#607483',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const StyledDropzone = ({
                          hiddenInput = false, maxFiles = DEFAULT_MAX_FILES_SIZE,
                          onCreateSource, driver = { slug: null }, isWizard = false,
                          driverModal = false, isTemplate = false, dataSourceType = null,
                          onStartUpload, onProcessUpload, onFinishUpload, fromDecisionPanel = false,
                          fromRecommendationPanel = false, isReplaceFile = false,
                          formWrapper
                        }) => {
  const [openTooltip, setOpenTooltip] = useState(false)
  const tipRef = useRef(null);
  if (isBlank(dataSourceType)) {
    dataSourceType = isTemplate ? Type.TEMPLATE_DATA_SOURCE : Type.DECISION_DATA_SOURCE;
  }

  const handleFileRejections = () => {
    setOpenTooltip(true)
    tipRef.current.open()
    setTimeout(() => {
      setOpenTooltip(false)
      tipRef.current.close()
    }, 5000);
  }
  const handleAcceptedFiles = (acceptedFiles) => {
    const uploadingSources = onStartUpload(acceptedFiles)
    uploadingSources.forEach((uploadingSourceData) => {
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0);
          onProcessUpload(uploadingSources, uploadingSourceData.uploadingSource, progress)
        },
        cancelToken: uploadingSourceData.cancelTokenSource.token
      };
      const formData = new FormData();
      if (formWrapper) {
        formData.append(formWrapper, uploadingSourceData.file);
      } else {
        formData.append(isTemplate ? 'template_data_source' : 'decision_data_source', uploadingSourceData.file);
      }
      formData.append('type', dataSourceType);
      if (isPresent(driver.slug)) {
        formData.append(isTemplate ? 'template_driver_slug' : 'driver_slug', driver.slug);
      }
      if (driverModal) {
        formData.append('modal', 'true');
      }
      if (fromDecisionPanel) {
        formData.append('hide_in_decision_panel', 'false');
      }
      if (fromRecommendationPanel) {
        formData.append('hide_in_recommendation_panel', 'false');
      }
      onCreateSource(
        formData,
        config, (status) => onFinishUpload(uploadingSources, status, uploadingSourceData.uploadingSource),
        (isTemplate || isWizard)
      );
    })
  }

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileRejections.length > 0 ? handleFileRejections() : handleAcceptedFiles(acceptedFiles)
  }, [])

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: ACCEPT_FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    maxFiles: maxFiles,
    onDrop: onDrop
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  const errorMessage = (isReplaceFile) => {
    if (isPresent(fileRejections)) {
      const errorIntro = isReplaceFile ? `<p class="fw-bold text-center mb-0"><span style="color: #EE5952;">Oops!</span> The file can not be attached <br>because` :
        `<p class="fw-bold text-center mb-0"><span style="color: #EE5952;">Oops!</span> One or more files can not <br>be attached because`;
      if (fileRejections[0].errors[0].code === 'file-too-large') {
        return `${errorIntro} it exceeds <br>the size limit of 100MB.</p>`
      } else if (fileRejections[0].errors[0].code === 'file-invalid-type') {
        return `${errorIntro} it is an unsupported <br>file type.</p>`
      }
    }
  };

  return (
    <>
      <Tooltip id="dropfiles-error-message" effect="solid" className="rounded" isOpen={openTooltip} ref={tipRef} />

      <span data-tooltip-id="dropfiles-error-message"
            data-tooltip-html={errorMessage(isReplaceFile)}
            data-tooltip-variant="light"
            data-tooltip-border={true}
            data-tooltip-border-color="#ced4da"
      >
        {hiddenInput ?
          <input className={'d-none'} {...getInputProps()} /> :
          <div className="pointer" {...getRootProps({ style })} >
            <input {...getInputProps()} />
            <div className="upload-icon text-primary mb-2"><i className="fas fa-cloud-upload-alt fa-3x"/></div>
            <p className="mb-0">Drop a file here or <span className="text-primary">browse</span>.</p>
          </div>
        }
      </span>
    </>
  );
};
export default StyledDropzone;
