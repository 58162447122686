import React, { useState } from 'react';
import { connect } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { forceReloadHomepageTemplates, reloadTemplatesData } from "../../../store/homepage/actions";
import { resetSidebars, setTreeSidebarOpen } from "../../../store/sidebar/actions";
import { resetTree } from "../../../store/tree/common_actions";
import { openModal } from "../../../store/modals/actions";
import TemplateUsersModal from "../../modals/TemplateUsersModal";
import { createNewTemplate, deleteTemplate, removeFromTemplateSet } from "../../../store/template/actions";
import { resetTemplate } from "../../../store/template/common_actions";
import { isBlank, isPresent } from "../../../helpers/common";
import { deleteTemplateCallback, duplicateTemplateCallback, removeFromTemplateSetCallback } from "../../header/helpers";
import { resetTemplateSetData } from "../../../store/template_set/common_actions";
import {Template} from "../../../models/template";
import SettingsSection from "./SettingsSection";

export const MoreActionsSection = ({
                                     template, isDSight = false,
                                     removeFromTemplateSet, deleteTemplate, createNewTemplate,
                                     resetTemplateSet, reloadTemplatesData,
                                     forceReloadHomepageTemplates, resetSidebars, resetTree, resetTemplate,
                                     openModal, setTreeSidebarOpen
                                  }) => {
  const navigate = useNavigate();
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [shownTemplateUsersModal, setShownTemplateUsersModal] = useState(false)
  const templateSetSlug = template.template_set_slug ? template.template_set_slug : ''
  const duplicateTemplate = duplicateTemplateCallback(
    createNewTemplate, template,
    [resetSidebars, resetTree, resetTemplateSet, reloadTemplatesData],
    navigate, searchParams, setSearchParams
  )
  const deleteTemplateHandler = deleteTemplateCallback(
    deleteTemplate,
    [forceReloadHomepageTemplates, resetSidebars, resetTree, resetTemplate],
    navigate, location, templateSetSlug
  )
  const removeFromSetHandler = removeFromTemplateSetCallback(template.slug, removeFromTemplateSet, navigate, template, [forceReloadHomepageTemplates, resetSidebars, resetTree, resetTemplateSet])
  const templateObj = new Template(template)

  return <React.Fragment>
    <div className="px-3 bp-3">
      <Button onClick={() => openModal({type: 'TemplateUsersModal'})} className="btn btn-secondary w-100 mb-2" hidden={isPresent(template.template_set_slug)}>
        Edit template users
      </Button>
      <Button onClick={removeFromSetHandler} className="btn btn-secondary w-100 mb-2" hidden={isBlank(template.template_set_slug)}>
        Remove from decision flow
      </Button>
      <Button onClick={() => openModal({type: 'TemplatePollNotesModal'})} className="btn btn-secondary w-100 mb-2" hidden={isDSight || templateObj.isOpenEnded}>
        Edit decision poll notes
      </Button>
      <Button onClick={duplicateTemplate} className="btn btn-secondary w-100 mb-2" hidden={isPresent(template.template_set_slug) || isDSight}>
        Duplicate template
      </Button>
      <Button onClick={deleteTemplateHandler} className="btn-light text-danger w-100 mb-2" hidden={isDSight}>
        Delete template
      </Button>
      {
        !isDSight && <SettingsSection />
      }
    </div>
    <div className="modals">
      <TemplateUsersModal shown={shownTemplateUsersModal} onClose={() => setShownTemplateUsersModal(false)} />
    </div>
  </React.Fragment>
}
const mapStateToProps = ({ template }) => ({ template });
const mapDispatchToProps = (dispatch) => ({
  deleteTemplate: (callback) => dispatch(deleteTemplate(callback)),
  removeFromTemplateSet: (slug, callback) => dispatch(removeFromTemplateSet(slug, callback)),
  createNewTemplate: (data, callback) => dispatch(createNewTemplate(data, callback)),
  forceReloadHomepageTemplates: () => dispatch(forceReloadHomepageTemplates()),
  resetSidebars: () => dispatch(resetSidebars()),
  resetTree: () => dispatch(resetTree()),
  resetTemplate: () => dispatch(resetTemplate()),
  resetTemplateSet: () => dispatch(resetTemplateSetData()),
  reloadTemplatesData: () => dispatch(reloadTemplatesData()),
  openModal: (data) => dispatch(openModal(data)),
  setTreeSidebarOpen: (flag, mode) => dispatch(setTreeSidebarOpen(flag, mode))
});
export default connect(mapStateToProps, mapDispatchToProps)(MoreActionsSection);
