import { useState, useEffect } from 'react';
import { sha256 } from 'js-sha256';
import Cookies from 'js-cookie';

export const generateCookieName = (email, section) => {
  const hashedEmail = sha256(email);
  return `${section}-${hashedEmail}`;
}

export const useSectionVisibility = (email, section, defaultVisibility = true) => {
  const [isSectionVisible, setIsSectionVisible] = useState(() => {
    const cookieName = generateCookieName(email, section);
    const visibilityFromCookie = fetchFromCacheStore(cookieName);
    return visibilityFromCookie ? visibilityFromCookie === 'true' : defaultVisibility;
  });

  useEffect(() => {
    const cookieName = generateCookieName(email, section);
    const currentVisibility = fetchFromCacheStore(cookieName) === 'true';
    if (currentVisibility !== isSectionVisible) {
      saveInCacheStore(cookieName, isSectionVisible, { expires: 90 });
    }
  }, [isSectionVisible, email, section]);

  return [isSectionVisible, setIsSectionVisible];
}

export const fetchFromCacheStore = (key) => {
  if (localStorage && localStorage.getItem(key) != null) {
    return localStorage.getItem(key);
  }
  const valueFromCookie = Cookies.get(key)
  if (valueFromCookie != null && localStorage) {
    localStorage.setItem(key, valueFromCookie);
    Cookies.remove(key)
  }
  return valueFromCookie;
}
export const cleanCacheStore = (key) => {
  if (localStorage && localStorage.getItem(key) != null) {
    return localStorage.removeItem(key);
  }
  Cookies.remove(key)
}
export const saveInCacheStore = (key, value, opts = {}) => {
  if (localStorage) {
    localStorage.setItem(key, value);
    return;
  }
  Cookies.set(key, value, opts);
}

export const fetchFromSessionCacheStore = (key) => {
  if (sessionStorage && sessionStorage.getItem(key) != null) {
    return sessionStorage.getItem(key);
  }
  const valueFromCookie = Cookies.get(key)
  if (valueFromCookie != null && localStorage) {
    sessionStorage.setItem(key, valueFromCookie);
    Cookies.remove(key)
  }
  return valueFromCookie;
}
export const cleanSessionCacheStore = (key) => {
  if (sessionStorage && sessionStorage.getItem(key) != null) {
    return sessionStorage.removeItem(key);
  }
  Cookies.remove(key)
}
export const saveInSessionCacheStore = (key, value, opts = {}) => {
  if (sessionStorage) {
    sessionStorage.setItem(key, value);
    return;
  }
  Cookies.set(key, value, opts);
}
