import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "highcharts/highcharts-more"
import "highcharts/modules/funnel";
import {
  basesChartHash,
  CHART_COLORS,
  chartBasesValueFor,
  chartValueFor, findProcessDataFor,
  hashWithSignificance
} from "../../../../../models/d_sight/metric_chart_helpers";
import ChartSourcesLabels from "../ChartSourcesLabels";
import { TimePeriodDropdown } from "../TimePeriodDropdown";
import { baseCompOptions } from "../brand_funnel/helpers";
import { significanceHashData } from "../brand/helpers";

const dataRowWithSignificance = (lastSurvey, node_id, mainTarget, { metric, process_data }) => {
  const brandProcessData = findProcessDataFor(process_data, node_id, lastSurvey.node_index)
  const mainBrandProcessData = findProcessDataFor(process_data, mainTarget, lastSurvey.node_index)
  const result = {
    y: chartValueFor(lastSurvey, brandProcessData, metric),
    ...basesChartHash(chartBasesValueFor(lastSurvey, brandProcessData))
  }

  if (node_id === mainTarget) return { ...result, sigUp: '', sigDown: '' };

  return hashWithSignificance(result, significanceHashData(lastSurvey, brandProcessData, mainBrandProcessData, metric))
}

export default ({
                  d_sight_goal, selectedSurvey, data, brands, sourceLabels, chartData, metric,
                  measure = null,
                  ...opts
                }) => {
  const series = brands.map(node_id => {
    const name = d_sight_goal.nodes.find(hash => hash.id === node_id)?.display_name
    const brandMetricsData = data.map(hash => dataRowWithSignificance(selectedSurvey, node_id, brands[0], hash))
    return {
      name, custom: { measure: (measure || metric.measure) },
      data: brandMetricsData
    }
  });

  const chartOptions = {
    ...baseCompOptions({ measure: (measure || metric.measure) }),

    colors: CHART_COLORS,

    xAxis: {
      categories: data.map(h => h.metric.name),
      crosshair: true,
      labels: {
        style: {
          fontSize: '0.75rem',
          color: '#1F2F3F'
        }
      }
    },

    series
  };

  return <div className="row mb-3">
    <div className="col">
      <div className={'h-100 bg-white rounded p-3'}>
        <TimePeriodDropdown {...{ ...opts, selectedSurvey, header: 'Vs. competitors' }} />
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        {
          sourceLabels && <ChartSourcesLabels chartData={chartData} surveys={[selectedSurvey]} />
        }
      </div>
    </div>
  </div>
}
