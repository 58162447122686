import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import ChartValuesType, { CHART_VALUES_TYPES } from "./components/ChartValuesType";
import MetricsSelect from "./components/MetricSelect";
import { Loader } from "../../common/Loader";
import { updateViewOptions } from "../../store/forecast_simulator_scenario/actions";
import PeriodSelectors from "./components/PeriodSelectors";
import DecompositionChartBlock, { fetchDecompositionData } from "./components/DecompositionChartBlock";
import { generatePromisesCallbacks, isBlank, isPresent } from "../../helpers/common";
import NoChartsDataPlaceholder from "./components/NoChartsDataPlaceholder";
import { checkAllNonChartsComponentsRendered } from "../helpers/helpers";
import {isStoreLoading} from "../../helpers/callbacks_helpers";
import { generateCustomPeriod, genYearAgoPeriod } from "../../models/forecast/ForecastTImeScale";

const THRESHOLD = 0.0001;
const valueForDifferenceCalc = (value) => parseFloat(value.toFixed(10))

const generateLoadDecompDataPromise = ({
                                         controller, config,
                                         fromPeriod, toPeriod,
                                         metric, chartValuesType,
                                         cmusGroups,
                                         scenario, setResult,
                                         rows = false,
                                         setFrom = () => {},
                                         setWarning = () => {},
                                       }) => {
  return new Promise((resolve, reject) => {
    // fetching data from resource two and changing some states
    controller.signal.addEventListener('abort', () => reject());
    const yearAgoPeriod =
      fromPeriod.timeScale.isYear ?
        genYearAgoPeriod({ period: fromPeriod }) :
        generateCustomPeriod({
          fromPeriod: genYearAgoPeriod({ period: fromPeriod }),
          toPeriod: genYearAgoPeriod({ period: toPeriod }),
          timeScale: fromPeriod.timeScale
        })
    const chartPeriod = generateCustomPeriod({
      fromPeriod, toPeriod, timeScale: fromPeriod.timeScale,
      namePeriod: toPeriod
    })
    setResult(
      fetchDecompositionData({
        scenario, config,
        yearAgoPeriod, chartPeriod,
        fromPeriod, toPeriod,
        metric, chartValuesType,
        cmusGroups,
        setFrom, setWarning,
        rows: scenario.fetchScopedRows({ periods: [yearAgoPeriod, chartPeriod], rows }),
      })
    )
    resolve(true)
  });
}

const ScenarioForecastDecomposition = ({
                                         forecast_simulator_scenario,
                                         config, timeScale,
                                         forecastScenario, forecastBenchmarkScenario,
                                         updateViewOptions,
                                         simulatedDecompData, setSimulatedDecompData,
                                         benchmarkDecompData, setBenchmarkDecompData,
                                         filteredSimulationScenarioRows, filteredBenchmarkScenarioRows
                                       }) => {
  if (isStoreLoading(forecast_simulator_scenario, 'scenario') || isStoreLoading(forecast_simulator_scenario, 'benchmark')) return <Loader />

  const decompTimeScale = (
    config.timeScales.find(ts => ts.key === forecastScenario?.timeScale).isUsedForDecomp ?
      forecastScenario.timeScale :
      config.timeScales.find(ts => ts.isUsedForDecomp)?.key
  ) || timeScale

  const controller = new AbortController();
  useEffect(() => {
    return generatePromisesCallbacks({ setLoading, controller })
  }, []);

  const view_options = forecast_simulator_scenario?.scenario?.view_options
  const [selectedDecompMetric, setDecompMetric] = useState(view_options?.selectedDecompMetric)
  const [from, setFrom] = useState(view_options?.decompFrom || config.getTimeScaleBy(decompTimeScale).fromPeriod.start_date)
  const [to, setTo] = useState(view_options?.decompTo || config.getTimeScaleBy(decompTimeScale).toPeriod.end_date)
  const [warning, setWarning] = useState(null)
  const [chartValuesType, setChartValuesType] = useState(view_options?.chartValuesType || CHART_VALUES_TYPES.percent)
  const [nonChartsComponentsRendered, setNonChartsComponentsRendered] = useState(false);
  const [loading, setLoading] = useState(forecast_simulator_scenario.view_loading);
  const [disabled, setDisabled] = useState(false);

  const savePeriods = (view_options) => {
    if (disabled) return;

    setDisabled(true)
    updateViewOptions(view_options, (status) => {
      if(status) {
        if (view_options.decompFrom) setFrom(view_options.decompFrom)
        if (view_options.decompTo) setTo(view_options.decompTo)
      }
      setDisabled(false)
    })
  }
  const saveFrom = (decompFrom) => {
    if (view_options?.decompFrom === decompFrom) return;

    savePeriods({ decompFrom })
  }
  const saveTo = (decompTo) => {
    if (view_options?.decompTo === decompTo) return;

    savePeriods({ decompTo })
  }

  const {
    fromPeriod, toPeriod
  } = useMemo(() => {
    const periods = forecastScenario?.allTimeScalePeriods(decompTimeScale) || [];
    const fromPeriod = periods.find(p => p.isWithinTimePeriod(from));
    let toPeriod = periods.find(p => p.isWithinTimePeriod(to));
    if(isPresent(fromPeriod) && isPresent(toPeriod) && fromPeriod.id === toPeriod.id) {
      toPeriod = periods.find(p => p.startDate > fromPeriod.startDate)
    }
    return { fromPeriod, toPeriod }
  }, [from, to, decompTimeScale])

  const metric = useMemo(() => {
    return config.outputColumns.find(output => output.id === selectedDecompMetric) || {}
  }, [selectedDecompMetric])

  const { cmusGroups } = forecastScenario.simulationScopesData

  useEffect(() => {
    const updatePeriods = {}
    if (fromPeriod.start_date !== from) updatePeriods.decompFrom = fromPeriod.start_date
    if (toPeriod.end_date !== to) updatePeriods.decompTo = toPeriod.end_date
    if (isPresent(updatePeriods)) {
      savePeriods(updatePeriods)
    }
  }, [decompTimeScale, [fromPeriod.id, toPeriod.id].join('-')]);

  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows,
          setWarning
        }),
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [`${from}-${to}-${fromPeriod.id}-${toPeriod.id}`, metric.id, chartValuesType])

  useEffect(() => {
    if (!nonChartsComponentsRendered) return;
    if (isPresent(simulatedDecompData)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows,
          setWarning
        }),
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [nonChartsComponentsRendered])

  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows,
          setWarning
        })
      ]
    })
  }, [forecastScenario.id, filteredSimulationScenarioRows]);
  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [forecastBenchmarkScenario.id, filteredBenchmarkScenarioRows]);

  useEffect(() => {
    if (nonChartsComponentsRendered || isBlank(metric)) return;
    if (isPresent(simulatedDecompData)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastScenario, setResult: setSimulatedDecompData, rows: filteredSimulationScenarioRows,
          setWarning
        }),
        () => generateLoadDecompDataPromise({
          controller, config,
          fromPeriod, toPeriod,
          metric, chartValuesType,
          cmusGroups,
          scenario: forecastBenchmarkScenario, setResult: setBenchmarkDecompData, rows: filteredBenchmarkScenarioRows
        })
      ]
    })
  }, []);

  const differenceChartsData = useMemo(() => {
    if (isBlank(simulatedDecompData?.chartsData) || isBlank(benchmarkDecompData?.chartsData)) return [];
    if (simulatedDecompData.chartsData.length !== benchmarkDecompData.chartsData.length) return []

    return simulatedDecompData.chartsData.map(({ y, ...hash }, index) => {
      const difference = y == null ? null : valueForDifferenceCalc(y) - valueForDifferenceCalc(benchmarkDecompData.chartsData[index].y)
      return {
        ...hash,
        y: Math.abs(difference) > THRESHOLD ? difference : 0
      }
    })
  }, [simulatedDecompData, benchmarkDecompData])

  const minCap = simulatedDecompData?.minCap > benchmarkDecompData?.minCap ? benchmarkDecompData?.minCap : simulatedDecompData?.minCap
  const maxCap = simulatedDecompData?.maxCap < benchmarkDecompData?.maxCap ? benchmarkDecompData?.maxCap : simulatedDecompData?.maxCap

  return <>
    <div className="position-sticky top-72 bg-white z-1 w-100 pt-3">
      <div className="container-xxl">
        <div className="row justify-content-center">
          <div className="col-7 col-lg-3 px-1 mb-2">
            <MetricsSelect {...{
              selectedDecompMetric, setDecompMetric, config, timeScale: decompTimeScale, loading: loading
            }} />
          </div>
          <PeriodSelectors  {...{
            to, setTo: saveTo,
            from, setFrom: saveFrom,
            options: config.periodOptions(decompTimeScale),
            disabled: loading || disabled,
            nextOptionsLimit: fromPeriod.timeScale.nextOptionsLimit
          }} />
          <div className="col-auto px-1 mb-2">
            <ChartValuesType {...{
              chartValuesType, setChartValuesType,
              loading: loading
            }} />
          </div>
        </div>
      </div>
    </div>
    {
      (checkAllNonChartsComponentsRendered(nonChartsComponentsRendered, setNonChartsComponentsRendered) || loading) && <Loader />
    }
    { nonChartsComponentsRendered && !loading && isPresent(simulatedDecompData) ?
      (
        isBlank(simulatedDecompData.chartsData) && isPresent(cmusGroups) ? <NoChartsDataPlaceholder /> :
          <>
            <DecompositionChartBlock
              {...simulatedDecompData}
              {...{
                fromPeriod, toPeriod, scenario: forecastScenario, metric, chartValuesType, showPlaceholder: true,
                minCap, maxCap
              }} />
            {
              forecastBenchmarkScenario && <>
                <DecompositionChartBlock
                  {...benchmarkDecompData}
                  {...{
                    fromPeriod, toPeriod, scenario: forecastBenchmarkScenario, metric, chartValuesType,
                    minCap, maxCap
                  }} />
                <DecompositionChartBlock {...{
                  showTotals: false, title: 'Difference', metric, chartValuesType, chartsData: differenceChartsData,
                  startValue: simulatedDecompData?.startValue
                }} />
              </>
            }
          </>
      ) : null
    }
  </>

}

const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });

export default connect(mapStateToProps, { updateViewOptions })(ScenarioForecastDecomposition);
