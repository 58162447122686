import {
  addSetInvitesRequest,
  createSetData,
  isStartedSetDecisionRequest,
  loadSetData,
  removeSetInviteRequest,
  removeSetRequest,
  reOrderSetObjectsData,
  startSetDecisionRequest,
  updateSetRequest,
  updateTemplateDataRequest,
  updateTreeDecisionSetRequest,
  createSetSectionRequest,
  updateSetSectionRequest,
  removeSetSectionRequest,
  dragSetSectionRequest,
  removeDecisionFromSetRequest,
  destroySetDataSource,
  createSetDataSource,
  replaceSetDataSource,
  updateSetDataSource,
  createDecisionReportData,
  destroyDecisionReportData,
  copySetSectionRequest,
  addSourceToDecisionRequest,
  addSourceToFlowRequest,
  transferSet,
  updateSetSummaryRequest
} from "../../utils/Api";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { forceReloadHomepageDecisions, loadHomepageSuccess, updateHomepageData } from "../homepage/actions";
import { BASE_SECTION } from "../homepage/reducers";
import EntryPoint from "../../EntryPoint";
import { loadTreeFailure, loadTreeSuccess } from "../tree/common_actions";
import { dispatchTreeDriversDataSources } from "../tree/actions";
import { updateDecisionSetData, updateSetsFailure } from "./common_actions";
import { isPresent } from "../../helpers/common";

export const loadDecisionSet = ({ scope = null, slug = null, decision_slug = null  } = {}) => (dispatch) => {
  const controllerName = scope || EntryPoint.instance.controllerName
  const objectSlug = slug || EntryPoint.instance.objectSlug

  loadSetData({ controllerName, objectSlug, decision_slug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

    const { data } = response;
    const { decision_set, share_data, no_results_image_url, default_user_avatar_url } = data;
    const user = decision_set.users.find(u => u.email === decision_set.user_email)
    const summary_entered_by = decision_set.users.find(u => u.email === decision_set.summary_entered_by_email)
    dispatch(updateDecisionSetData({ ...decision_set, share_data, user, summary_entered_by, loaded: true }));
    dispatch(loadHomepageSuccess({ no_results_image_url }));
    dispatch(loadTreeSuccess({ default_user_avatar_url }));
  })
}

export const createDecisionSet = (data, callback) => (dispatch) => {
  createSetData({ controllerName: 'decision_sets', data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

    const { data } = response;
    const { decision_set, share_data } = data;
    const user = decision_set.users.find(u => u.email === decision_set.user_email)
    dispatch(updateDecisionSetData({ ...decision_set, share_data, user, loaded: true }));
    callback(decision_set.slug, true)
  })
}

export const updateDecisionSet = (data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dispatch(updateHomepageData({ decision_sets: { ...BASE_SECTION, loading: true }}))
  updateSetRequest({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { decision_set } = data;
    dispatch(updateDecisionSetData({ ...decision_set, loaded: true }));
    callback(true)
  })
}

export const updateDecisionSetSummary = (data, callback) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dispatch(updateHomepageData({ decision_sets: { loading: true }}))
  updateSetSummaryRequest({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { decision_set } = data;
    const summary_entered_by = getState().decision_set.users.find(u => u.email === decision_set.summary_entered_by_email)
    dispatch(updateDecisionSetData({ ...decision_set, summary_entered_by, loaded: true, loading: false }));
    callback(true)
  })
}

export const createDecisionSetSection = (data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dispatch(updateDecisionSetData({ loading: true }));
  createSetSectionRequest({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data: { sections } } = response;
    dispatch(updateDecisionSetData({ sections, loading: false }));
    callback(true)
  })
}

export const updateDecisionSetSection = (slug, data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  updateSetSectionRequest({ controllerName, objectSlug, slug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data: { sections } } = response;
    dispatch(updateDecisionSetData({ sections }));
    callback(true)
  })
}

export const copyDecisionSetSection = (data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dispatch(updateDecisionSetData({ loaded: false }));
  copySetSectionRequest({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data: { decisions, accessible_decisions, sections } } = response;
    dispatch(updateDecisionSetData({ ...{ decisions, accessible_decisions, sections }, loaded: true }));
    callback(true)
  })
}

export const removeDecisionSetSection = (slug, callback) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  removeSetSectionRequest({ controllerName, objectSlug, slug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const sections = getState().decision_set.sections.filter(s => s.slug !== slug)
    const decisions = getState().decision_set.decisions.map(d => d.section_slug !== slug ? d : {...d, section_slug: null})
    dispatch(updateDecisionSetData({ sections, decisions }));
    callback(true)
  })
}

export const dragDecisionSetSection = (slug, data, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dragSetSectionRequest({ controllerName, objectSlug, slug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { sections } = data;
    dispatch(updateDecisionSetData({ sections }));
    callback(true)
  })
}

export const updateDecisionDecisionSet = (data, callback) => (dispatch) => {
  const isAddedToSet = isPresent(data.decision_set_slug);

  updateTreeDecisionSetRequest(data).then(response => {
    if (isResponseFailed(response)) return callback(response);

    const { data: { decisions, accessible_decisions, sections } } = response;
    if (isAddedToSet) {
      dispatch(updateDecisionSetData({ ...{ decisions, accessible_decisions, sections, added_decision_slug: data.decision_id }, loaded: true }));
    } else {
      dispatch(updateDecisionSetData({ ...{ decisions, accessible_decisions, sections }, loaded: true }));
    }
    dispatch(forceReloadHomepageDecisions());
    callback();
  });
};

export const removeDecisionSet = (callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  removeSetRequest({ controllerName, objectSlug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    dispatch(updateDecisionSetData({ loaded: false }));
    callback(true)
  })
}

export const addDecisionToSet = (templateSlug, setSlug, callback) => (dispatch, getState) => {
  dispatch(updateDecisionSetData({ loading: true }));
  updateTemplateDataRequest(templateSlug, { template_set_slug: setSlug, add_to_set: true }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { decision } = data;
    const setDecisions = [...getState().decision_set.decisions, decision]
    dispatch(updateDecisionSetData({ ...getState().decision_set, decisions: setDecisions, loading: false }));
    callback(true)
  })
}

export const removeDecisionFromSet = (decisionSlug) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  dispatch(updateDecisionSetData({ loading: true, loaded: false }));
  removeDecisionFromSetRequest({ controllerName, objectSlug, slug: decisionSlug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

    const decisions = getState().decision_set.decisions.filter(({ slug }) => slug !== decisionSlug );
    const sections = getState().decision_set.sections.map((section) => {
      return { ...section, decisions: section.decisions.filter(({ slug }) => slug !== decisionSlug ) }
    })
    dispatch(updateDecisionSetData({ ...getState().decision_set, decisions, sections, loading: false, loaded: true }));
  })
}

export const reorderDecisions = (data = {}, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  reOrderSetObjectsData({ controllerName, objectSlug, data: { decision_set: data } }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { decision_set } = data;
    dispatch(updateDecisionSetData({ ...decision_set }));
    callback(true)
  })
}

export const addSetInvites = (invites, callback) => (dispatch) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  addSetInvitesRequest({ controllerName, objectSlug, invites }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);

    const { data } = response;
    const { decision_set } = data;
    dispatch(updateDecisionSetData({ ...decision_set }));
    callback(true)
  })
}

export const removeSetInvite = (slug) => (dispatch, getState) => {
  const { controllerName, objectSlug } = EntryPoint.instance;
  removeSetInviteRequest({ controllerName, objectSlug, slug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

    const invites = getState().decision_set.invites.filter(i => i.slug !== slug)
    dispatch(updateDecisionSetData({ invites }));
  })
}

export const transferFlowTo = (new_manager_email) => (dispatch) => {
  const { objectSlug, controllerName } = EntryPoint.instance;
  transferSet({ controllerName, objectSlug, new_manager_email }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

    const { data } = response;
    const { decision_set } = data;
    const user = decision_set.users.find(u => u.email === decision_set.user_email)
    dispatch(updateDecisionSetData({ ...decision_set, user }));
  })
}

const setScopeParams = ({ scope = null, setSlug = null, ...opts  }) => {
  const controllerName = scope || EntryPoint.instance.controllerName
  const objectSlug = setSlug || EntryPoint.instance.objectSlug

  return { controllerName, objectSlug, ...opts }
}
const alreadyStartedResponse = (dispatch, callback, response) => {
  if (isResponseFailed(response)) {
    if (response?.data['status'] === 'already_started') {
      return callback(false, { already_started: true })
    }
    return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback);
  }

  callback(true)
}

export const startDecision = (opts, callback = () => {}) => (dispatch) => {
  startSetDecisionRequest(setScopeParams(opts)).then(response => {
    alreadyStartedResponse(dispatch, callback, response)
  })
}

export const isDecisionStarted = (opts, callback = () => null) => (dispatch) => {
  isStartedSetDecisionRequest(setScopeParams(opts)).then(response => {
    alreadyStartedResponse(dispatch, callback, response)
  })
}

// Data Sources
export function destroySource(slug, data) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    destroySetDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data: { data_sources } } = response;
      dispatch(updateDecisionSetData({ data_sources }));
    })
  }
}
export function createSource(data, config = {}, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    createSetDataSource({ controllerName, objectSlug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback)

      const { data: { data_sources } } = response;
      dispatch(updateDecisionSetData({ data_sources }));
      callback(true);
    })
  }
}
export function replaceSource(slug, data, config = {}, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    replaceSetDataSource({ controllerName, objectSlug, slug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure }, callback)

      const { data: { data_sources } } = response;
      dispatch(updateDecisionSetData({ data_sources }));
      callback(true);
    })
  }
}
export function updateSource(slug, data) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    updateSetDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data: { data_sources } } = response;
      dispatch(updateDecisionSetData({ data_sources }));
    })
  }
}

export function addSourceToDecision(slug, setSlug, data, updateOnlySources) {
  return (dispatch, getState) => {
    addSourceToDecisionRequest({ objectSlug: setSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
    })
  }
}

export function addSourceToFlow(slug, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    addSourceToFlowRequest({ controllerName, objectSlug, slug }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data: {data_sources} } = response;
      dispatch(updateDecisionSetData({ data_sources }));
      callback(true);
    })
  }
}

// Attach Report to Decision
export function attachReport(reportSlug, data) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    createDecisionReportData({ controllerName, objectSlug, reportSlug }, data).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data: { data_sources } } = response;
      dispatch(updateDecisionSetData({ data_sources }));
    })
  }
}

// Detach Report to Decision
export function detachReport(reportSlug, data) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    destroyDecisionReportData({ controllerName, objectSlug, reportSlug }, data).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSetsFailure });

      const { data: { data_sources } } = response;
      dispatch(updateDecisionSetData({ data_sources }));
    })
  }
}

export function saveUploadingSources(sources) {
  return (dispatch) => {
    dispatch(updateDecisionSetData({ uploading_sources: sources }));
  }
}

