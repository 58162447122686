import React, { useState } from 'react';
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Goal } from "../../../models/d_sight/goal";
import {isBlank, isPresent, protocolHost} from "../../../helpers/common";
import { TABS_URLS } from "../../../helpers/home_helpers";
import { RightAlignedSemaphoreIcon } from "../../../dsight/analysis/AnalysisContent";
import { BtnDropdownToggleInlineShadowText } from "../../../common/dropdowns";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../common/CloseIcon";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { ChoiceEntryStyle } from "../../../tree_wizard/styles/choice_entry_styles";
import { skipAnalysisRecord } from "../../../models/d_sight/Analysis";
import { resetDSightGoal } from "../../../store/d_sight_goal/actions";
import { saveInCacheStore } from "../../../helpers/cookie_helpers";

const LeverBlock = ({ lever }) => {
  if (skipAnalysisRecord(lever)) return null;

  return <div className="mt-1">
    {
      isPresent(lever.templateSlug) ?
        <Link to={`/dsight/templates/${lever.templateSlug}/analysis`}>
          {lever.name}
        </Link> :
        <span className="ps-2">
          {lever.name}
        </span>
    }
    <RightAlignedSemaphoreIcon semaphore={lever.semaphore} />
  </div>
}

const SelectDSightCategory = ({
                               selectedProduct, products, goals, resetDSightGoal
                             }) => {
  if (products.length < 2) return null;

  const navigate = useNavigate();
  const changeCategory = (value) => {
    const goal = goals.find(g => g.id === parseInt(value))

    if (goal) {
      saveInCacheStore('selected-dsight-product', goal.id)
      navigate(`/homepage/${TABS_URLS.dsight}/${goal.id}`);
      resetDSightGoal()
    }
  }

  return <div className="pt-1 ms-auto">
    <div className="d-inline-flex me-1">Category: </div>
    <BtnDropdownToggleInlineShadowText id="product-dropdown" bsPrefix="pe-0" onSelect={changeCategory}
                                       title={products.find((p) => p.value === selectedProduct)?.label || ''}>
      {products.map((p) =>
        <BaseDropdownBtn key={`product-${p.value}`} eventKey={p.value} title={p.label} className={p.value === selectedProduct ? 'active' : ''} />
      )}
    </BtnDropdownToggleInlineShadowText>
  </div>
}

const PptReportModal = ({ selectedProduct, shown, close }) => {
  const [selectedReport, setSelectedReport] = useState(selectedProduct.ppt_reports[0]);
  const host = protocolHost();

  return <Modal size="md" backdrop="static" show={shown} onHide={close}>
    <Modal.Body>
      <CloseIcon onClose={close}/>
      <h2>Download PPT Report</h2>
      <p className="text-muted">Select which report to download.</p>
      <div className="mb-3">
        <Select value={{value: selectedReport.id, label: selectedReport.filename, url: selectedReport.url}}
                placeholder="Select a report"
                onChange={(report) => setSelectedReport(selectedProduct.ppt_reports.find((ppt_report) => ppt_report.id === report.value))}
                components={{IndicatorSeparator: () => null}}
                styles={ChoiceEntryStyle}
                options={selectedProduct.ppt_reports.map(ppt_report => ({
                  value: ppt_report.id,
                  url: selectedReport.url,
                  label: ppt_report.filename
                }))}/>
      </div>
      <Button onClick={() => window.open(host + selectedReport.url)} className="w-100 mt-3">Download report</Button>
    </Modal.Body>
  </Modal>
}
const PptReportButton = ({ selectedProduct }) => {
  const [showModal, setShowModal] = useState(false);
  const openPptReportModal = () => setShowModal(true)
  const closeModal = () => setShowModal(false)

  if(selectedProduct.ppt_reports.length > 1) {
    return <>
        <Button onClick={openPptReportModal} className="btn btn-secondary mt-2 mt-xl-3 py-2 w-100">
          <i className="fas fa-download me-1"/>
          <span className="align-text-top">Download PPT report</span>
      </Button>
      <PptReportModal selectedProduct={selectedProduct} shown={showModal} close={closeModal} />
    </>
  } else {
    return <div className="mt-2 mt-xl-3 w-100">
      <a href={selectedProduct.ppt_reports[0].url} target="_blank">
        <div className="btn btn-secondary py-2 w-100">
          <i className="fas fa-download me-1"/>
          <span className="align-text-top">Download PPT report</span>
        </div>
      </a>
    </div>
  }
}

export const GoalSection = ({ goal, templates, hideGoalLink= true, products }) => {
  const object = new Goal(goal, templates)
  const selectedProduct = products.find(p => p.value === goal.id)
  const noPptReports = () => isBlank(selectedProduct?.ppt_reports)

  return <div className="d-flex flex-column flex-xl-row mb-3">
    <div className="w-100">
      <div className="bg-white rounded h-100 p-3">
        <h3 className="d-inline-block me-3">{object.goal}</h3>
        {
          hideGoalLink ? null :
            <>
              <Link to={`/homepage/${TABS_URLS.dsight}/${object.id}`} className={`ms-2`}>
                <i className="fas fa-external-link-alt" />
              </Link>
            </>
        }
        <div className="goals-list">
          { object.orderedInsights().map(i =>
            <div key={`goal-section-${goal.data_set_slug}-insight-${i.id}`} className="mt-2">
              <div className="dsights-icon--primary h-17">{i.insight}</div>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="d-flex flex-column mt-2 mt-xl-0 ms-0 ms-xl-3 sidepanel-minwidth">
      <div className={`flex-fill d-flex flex-column bg-white rounded p-3`}>
        <h3>Levers</h3>
        <div className="m-0 flex-fill">
          { object.levers.map(l => <LeverBlock lever={l} key={`goal-section-${goal.data_set_slug}-lever-${l.name}`} />) }
        </div>
        <div className="mt-3 fw-bold">
          <Link to={`/dsight/goals/${object.id}/metrics`}>
            See all metrics
          </Link>
        </div>
      </div>
      {noPptReports() ? null : <PptReportButton selectedProduct={selectedProduct} />}
    </div>
  </div>
}

export const GoalsSection = ({ selectedGoal, ...opts }) =>
  <div>
    <div className="row">
      <div className="col d-flex">
        <h1>Goal</h1>
        <SelectDSightCategory {...opts} />
      </div>
    </div>
    <GoalSection goal={selectedGoal}
                 key={`goal-section-${selectedGoal.data_set_slug}`}
                 {...opts}
    />
  </div>

const mapStateToProps = ({ home }) => ({
  home,
  templates: (home.dsight.data?.templates || [])
});
export default connect(mapStateToProps, { resetDSightGoal })(GoalsSection);
