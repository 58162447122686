import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { isCollaborator, isDecider } from "../../../helpers/user_helpers";
import Button from 'react-bootstrap/Button';
import RateBuyInModal from "../../modals/RateBuyInModal";
import { useSearchParams } from 'react-router-dom'
import { isBlank } from "../../../helpers/common";
import Decision from "../../../models/decision";

export const RateBuyInSection = ({ user, decision }) => {
  if (!isDecider(user) && !isCollaborator(user)) return null;

  const [showRateBuyInModal, setShowRateBuyInModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const decisionObj = new Decision(decision)

  useEffect(() => {
    if (searchParams.get('modal_open') === 'buy-in') {
      if(decision.rate_support) setShowRateBuyInModal(true);
      searchParams.delete('modal_open')
      setSearchParams(searchParams);
    }
  }, []);

  return <div className={`px-3 ${!decisionObj.isDisplayBuyIn ? 'mb-2' : ''}`}>
    <Button onClick={() => setShowRateBuyInModal(true)} className="btn btn-primary w-100 mb-2" hidden={isBlank(decision.rate_support)}>
      Rate your level of support
    </Button>
    <div className="modals">
      <RateBuyInModal shown={showRateBuyInModal} onClose={() => setShowRateBuyInModal(false)} />
    </div>
  </div>
};
const mapStateToProps = ({ current_user, decision}) => ({
  decision, user: current_user
});
export default connect(mapStateToProps, {})(RateBuyInSection);
