import React, { useState, useEffect } from 'react';
import { deleteConversation, updateConversation } from "../../../../../utils/Api";
import { useParams, useNavigate } from "react-router-dom";
import { deleteConversationWarning } from "../../../../../helpers/alert_helpers";
import { EditIcon } from "../../../../../common/EditIcon";
import CloseIcon from "../../../../../common/CloseIcon";
import CheckIcon from "../../../../../common/CheckIcon";
import OutsideClickDetector from "./OutsideClickDetector";
import { setSavedChats, useSavedChatsStore } from "../stores/saved-chats.store";
import { isEsc, isEnter, isNumpadEnter } from "../../../../../helpers/keys_helpers"

const SavedChat = ({conversation}) => {
  const [initialDescription, setInitialDescription] = useState(conversation.description);
  const { id: conversationId } = conversation;
  const { goal_id, conversation_id } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(initialDescription);
  const conversations = useSavedChatsStore((state) => state.conversations);

  useEffect(() => {
    if (!isEditing) return;

    const listener = (event) => {
      if (isEnter(event.keyCode) || isNumpadEnter(event.keyCode)) {
        event.preventDefault();
        handleSubmit();
      }
      if (isEsc(event.keyCode)) {
        event.preventDefault();
        handleCancel();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [isEditing, description]);

  const handleSubmit = () => {
    updateConversation(goal_id, conversationId, {description}).then((res) => {
      if(res.status === 200) {
        setIsEditing(false);
        setInitialDescription(description)
      }
    });
  }

  const handleCancel = () => {
    setDescription(initialDescription);
    setIsEditing(false);
  }

  const handleDelete = () => {
    deleteConversationWarning().then(confirmed => {
      if(!confirmed) return;

      deleteConversation(goal_id, conversationId).then((res) => {
        if(res.status !== 200) return;
        const updatedConversations = conversations.filter((c) => {
          return c.id !== conversationId;
        });
        setSavedChats(updatedConversations);
        if(conversation_id === conversationId) {
          navigate(`/ask_cloverpop/goals/${goal_id}/conversations`, { replace: true });
        }
      });
    })
  }

  const handleEdit = (e) => {
    !isEditing && setIsEditing(true);
  }

  const handleClick = () => {
    navigate(`/ask_cloverpop/goals/${goal_id}/conversations/${conversationId}`);
  }

  const renderEdit = () => (
    <OutsideClickDetector onOutsideClick={handleCancel}>
      <input
        className="form-control"
        key={`edit-${conversationId}`}
        type="text"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        autoFocus={true} />
      <div className="d-flex pe-2 ms-2 me-4">
        <CheckIcon onClick={handleSubmit} iconClassNames="save-chat"/>
      </div>
    </OutsideClickDetector>
  )

  const renderDescription = () => (
    <>
      <div className="w-100 p-2 bg-secondary rounded border-top-start-90 pointer text-truncate"
           onClick={handleClick}
           style={{height: '48px'}}>
        {description}
      </div>
      <div className="d-flex ms-2">
        <EditIcon  onClick={handleEdit} />
        <CloseIcon onClose={handleDelete} danger={true} classParams="ms-2"/>
      </div>
    </>
  )

  return (
    <div key={conversationId}>
      <div className="d-flex align-items-center mb-2">
      { isEditing ? renderEdit() : renderDescription() }
      </div>
    </div>
  );
};

export default SavedChat;
