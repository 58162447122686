import React from 'react';
import { useNavigate } from "react-router-dom";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../../common/dropdowns";
import { goToHomepageCallback } from "../../../tree_wizard/header/helpers";

export const HamburgerDropdown = ({ }) => {
  const navigate = useNavigate();
  const goToHomepage = goToHomepageCallback([], navigate)

  return <HamburgerMenuIconBtn>
    <BaseHamburgerBtn onSelect={goToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

export default HamburgerDropdown;
