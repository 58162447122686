import { useEffect } from "react";
import { isBlank, isPresent } from "../../helpers/common";
import { hideOverlay, MESSAGES, showOverlayWithMessage } from "./custom_loading_overlay";
import { SCENARIO_ROW_ID_SEPARATOR } from "./ag_grid_vars";
import { createComparisonRows } from "./common";
import { visiblePeriodsScope } from "./period_helpers";
const successRunModelCallback = (gridRef, setRunModelActive, updateScenarioData,
                                 updateCompDataCallback = () => {}) => {
  setRunModelActive(false);
  updateCompDataCallback();
  hideOverlay(gridRef.current.api);
  updateScenarioData({ run_model: false, run_model_new_rows: [], run_model_row_ids_to_update: [] });
}

const resetNotVisibleGroupRows = (groupData, forecastScenario) => {
  return groupData.added_rows.map(row => {
    forecastScenario.allTimeScalePeriods().forEach(period => {
      row[period.name] = undefined;
    });
    return row;
  });
};

const recalculateOpenedGroupsFromAnotherTimeScale = (gridRef, forecastScenario, forecastBenchmarkScenario, runModelRowsIds, editedCellsRowIds) => {
  const editedCellsParentRowsIds = editedCellsRowIds.map(rowId => gridRef.current.api.getRowNode(rowId)?.parent?.id).filter(isPresent);
  const periodsScope = forecastScenario.isAnnualTimeScale ? forecastScenario.allTimeScalePeriods() : visiblePeriodsScope(gridRef.current, forecastScenario);
  return forecastScenario.openedGroups.map(group => {
    if (!runModelRowsIds.includes(group.id) && !editedCellsParentRowsIds.includes(group.id)) return group;

    const groupData = { ...group };
    const node = gridRef.current.api.getRowNode(groupData.id);
    if(node) {
      groupData.added_rows = createComparisonRows(forecastScenario.config, group.output, { node }, forecastScenario, forecastBenchmarkScenario, periodsScope);
    } else {
      groupData.added_rows = resetNotVisibleGroupRows(groupData, forecastScenario);
    }
    return groupData;
  });
};

const updateCompDataCallback = (gridRef, updatedOpenedGroups, output = false) => {
  const allRowsToUpdate = output ?
    updatedOpenedGroups.filter(group => group.output).flatMap(group => group.added_rows) :
    updatedOpenedGroups.flatMap(group => group.added_rows);
  gridRef.current.api.applyTransaction({ update: allRowsToUpdate });
}

export const completeRunModelCells = ({ forecastScenario,
                                 forecastBenchmarkScenario,
                                 editedCells,
                                 runModelCells,
                                 gridRef,
                                 setRunModelActive,
                                 updateScenarioData,
                                 runModelRowsIds,
                                 editedCellsRowIds,
                                 updateOpenedGroups,
                                 updateTableCells
                               }) => {
  const updatedOpenedGroups = recalculateOpenedGroupsFromAnotherTimeScale(gridRef, forecastScenario, forecastBenchmarkScenario, runModelRowsIds, editedCellsRowIds);
  updateOpenedGroups(forecastScenario.local_id, { opened_groups: updatedOpenedGroups, bulk: true }, (status) => {
    if(status) {
      const table_cells = [
        ...editedCells.map(cellData => ({ ...cellData, run_model_is_run: true, need_recalculation: false })),
        ...runModelCells.filter(cell => isPresent(cell.request_run_model_at) || isPresent(cell.run_model_at)).map(cell => ({
          ...cell,
          run_model_at: new Date().toISOString(),
          request_run_model_at: ''
        }))
      ];
      updateTableCells(forecastScenario.local_id, { table_cells, bulk: true }, (status) => {
        if(status) {
          successRunModelCallback(gridRef, setRunModelActive, updateScenarioData, () => updateCompDataCallback(gridRef, updatedOpenedGroups));
        }
      });
    }
  });
};

export const handleRunModelEffect = ({
                                       gridRef,
                                       preparedRows,
                                       editedCells,
                                       runModelCells,
                                       forecastScenario,
                                       forecastBenchmarkScenario,
                                       updateCachedRows,
                                       setRunModelActive,
                                       updateScenarioData,
                                       runModelRowsIds,
                                       editedCellsRowIds,
                                       updateOpenedGroups,
                                       updateTableCells,
                                       rowsDataWithEditedCells = () => {}
                                     }) => {
  const runModelRows = rowsDataWithEditedCells(preparedRows);
  gridRef.current.api.applyTransaction({ update: runModelRows });
  updateCachedRows(preparedRows);
  completeRunModelCells({
    forecastScenario,
    forecastBenchmarkScenario,
    editedCells,
    runModelCells,
    gridRef,
    setRunModelActive,
    updateScenarioData,
    runModelRowsIds,
    editedCellsRowIds,
    updateOpenedGroups,
    updateTableCells
  });
}

export const useRunModelEffect = ({
                                    gridRef,
                                    gridReady,
                                    forecast_simulator_scenario,
                                    forecastScenario,
                                    updateScenarioData = () => {},
                                    triggerWorkerRun = () => {}
                                  }) => {
  useEffect(() => {
    if(gridReady && forecast_simulator_scenario.run_model) {
      showOverlayWithMessage(gridRef.current.api, updateScenarioData, MESSAGES.updating_scenario);
      forecastScenario.updateScenarioRows(forecast_simulator_scenario.run_model_new_rows);
      triggerWorkerRun();
    }
  }, [forecast_simulator_scenario.run_model])
}

export const prepareDataForRunModel = (editedCells, forecastScenario) => {
  const cmusList = [];
  const rowsToUpdate = editedCells.filter(cellData => cellData.edited).map(cellData => {
    const [simVsBmPrefix, cmus, driverId, periodId] = cellData.id.split(SCENARIO_ROW_ID_SEPARATOR);
    if(isPresent(simVsBmPrefix)) return null;
    const convertedCmus = cmus.split(',').map(Number);
    cmusList.push(convertedCmus);
    const row = forecastScenario.findRowBy(convertedCmus, periodId, driverId);
    return { rowId: row.id, driverId: driverId, value: cellData.value }
  }).filter(isPresent)
  const uniqCmusGroups = Array.from(new Set(cmusList.map(JSON.stringify)), JSON.parse);
  return {
    driversData: rowsToUpdate.reduce((acc, row) => {
      const { rowId, driverId, value } = row;
      if (isBlank(acc[rowId])) acc[rowId] = {};
      acc[rowId][driverId] = { value };
      return acc;
    }, {}),
    cmusList: uniqCmusGroups
  }
}
