import React from "react";
import { connect } from "react-redux";
import { isDecisionStarted, startDecision } from "../../store/decision_set/actions";
import { Link, useNavigate } from "react-router-dom";
import BubbleTooltip from "../../bubble_tooltip/BubbleTooltip";
import { safeDescription } from "../../helpers/decision_helpers";
import { alreadyStartedTemplateAlert } from "../../helpers/alert_helpers";
import { isDSightDecision } from "../../helpers/home_decision_helpers";
import { openModal } from "../../store/modals/actions";
import { openStartUnstartedDecisionModal } from "../modals/StartUnstartedDecisionModal";
import Alerts from "../../../js/alerts";
import { resetDecisionSetData } from "../../store/decision_set/common_actions";
import DecisionSet from "../../models/decision_set";

const UnstartedViewerText = () =>
  <h5 className="rounded-pill bg-light w-100 mt-unstarted-pill">
    <span className="text-dark">Unstarted</span>
  </h5>

const UnstartedTemplateTileWrapper = ({ decision, children }) =>
  <div className="position-relative col col-md-6 col-lg-4 col-xl-3 homepage_tile mb-3">
    <BubbleTooltip object_hash={decision} isDecisionSet={true}/>
    <div className="text-dark text-center">
      <div className="tile-content bg-white pt-3 px-2 pb-2">
        <h4 className="decision-title mb-3">{safeDescription(decision)}</h4>
        {children}
      </div>
    </div>
  </div>

const UnstartedDSightTemplateTile = ({ decision_set, decision, startDecision, startedCallback }) => {
  const navigate = useNavigate()

  const onStartDecisionClick = () => {
    startDecision({
      slug: decision.slug,
      scope: 'decision_sets',
      setSlug: decision_set.slug
    }, (status, { already_started = false } = {}) => {
      if (status) {
        navigate(`/dsight/decisions/${decision.slug}/tree_viewer`)
      } else if (already_started) {
        startedCallback()
      }
    })
  }

  const setObj = new DecisionSet(decision_set)

  return <UnstartedTemplateTileWrapper decision={decision}>
    {
      setObj.isSharedVisitor ? <UnstartedViewerText /> :
        <>
          <Link
            to={`/dsight/templates/${decision.template_slug}/preview?decision_set=${decision_set.slug}&decision_slug=${decision.slug}`}>
            <div className="btn btn-secondary py-1 w-100 mb-2">
              <span>Preview decision</span>
            </div>
          </Link>
          <div className="btn btn-primary py-1 w-100 pointer" onClick={onStartDecisionClick}>
            <i className="fas fa-plus fa-lg me-2"/>
            <span>Start decision</span>
          </div>
        </>
    }
  </UnstartedTemplateTileWrapper>
}

export const accessAlert = () => {
  Alerts.error({
    title: 'Sorry, you don’t have permission to start this decision!',
    text: 'Please contact your organization administrator to be added as a template user.'
  })
}

const PreviewWrapper = ({ children, hasAccess, decision, decision_set }) =>
  hasAccess ?
    <Link to={`/templates/${decision.template_slug}/preview?decision_set=${decision_set.slug}&decision_slug=${decision.slug}`}>{children}</Link> :
    <div onClick={accessAlert}>{children}</div>

const UnstartedTemplateTile = ({
                                 decision_set, decision,
                                 openModal, isDecisionStarted, resetDecisionSetData,
                                 hasAccess = true,
                                 ...opts
                               }) => {
  const startedCallback = () => {
    alreadyStartedTemplateAlert()
    resetDecisionSetData()
  }
  if (isDSightDecision(decision)) return <UnstartedDSightTemplateTile {...{ ...opts, decision, decision_set, startedCallback }} />

  const onStartDecisionClick = () => {
    isDecisionStarted({
      slug: decision.slug,
      scope: 'decision_sets',
      setSlug: decision_set.slug
    }, (status, { already_started = false } = {}) => {
      if (status) {
        openStartUnstartedDecisionModal({
          openModal, decision, template: { ...decision, slug: decision.template_slug }
        })
      } else if (already_started) {
        startedCallback()
      }
    })
  }
  const setObj = new DecisionSet(decision_set)

  return <UnstartedTemplateTileWrapper decision={decision}>
    {
      setObj.isSharedVisitor ? <UnstartedViewerText /> :
        <>
          <PreviewWrapper {...{ hasAccess, decision, decision_set }}>
            <div className={`btn btn-secondary ${hasAccess ? '' : 'disabled'} h-36 align-center py-1 w-100 mb-2`}>
              <span>Preview decision</span>
            </div>
          </PreviewWrapper>
          <div className={`btn btn-primary ${hasAccess ? '' : 'disabled'} h-36 align-center py-1 w-100 pointer`}
               onClick={hasAccess ? onStartDecisionClick : accessAlert}>
            <i className="fas fa-plus fa-lg me-2"/>
            <span>Create decision</span>
          </div>
        </>
    }
  </UnstartedTemplateTileWrapper>
}

const mapStateToProps = ({ decision_set }) => ({ decision_set });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
  isDecisionStarted: (data, callback) => dispatch(isDecisionStarted(data, callback)),
  startDecision: (data, callback) => dispatch(startDecision(data, callback)),
  resetDecisionSetData: () => dispatch(resetDecisionSetData())
});
export default connect(mapStateToProps, mapDispatchToProps)(UnstartedTemplateTile);
