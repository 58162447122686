import React, { useMemo } from 'react';
import { connect } from "react-redux";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "highcharts/highcharts-more"
import "highcharts/modules/solid-gauge";
import UserAvatarImage from "../../../common/UserAvatarImage";
import Decision from "../../../models/decision";
import { userName } from "../../../helpers/user_helpers";

const MAX_USER_SUPPORT_IMAGES = 5;

const UserSupportImages = ({ decisionObj }) => {
  const userSupports = decisionObj.sortedUserSupports;
  if (userSupports.length > MAX_USER_SUPPORT_IMAGES) {
    const displayUsers = userSupports.slice(0, MAX_USER_SUPPORT_IMAGES)
    const hiddenUsers = userSupports.slice(MAX_USER_SUPPORT_IMAGES)
    return [
      ...displayUsers.map((s) => <UserAvatarImage key={`user-support-${s.user_email}`} user={s.user} />),
      <div key={`${decisionObj.decision.slug}-collaborators-others`} className="d-inline-block bg-secondary rounded-circle text-center p-1" title={hiddenUsers.map(u => userName(u)).join(',')}>
        +{hiddenUsers.length}
      </div>
    ]
  } else {
    return userSupports.map((s) => <UserAvatarImage key={`user-support-${s.user_email}`} user={s.user} />)
  }
}

const genDataLabel = (maxValueInFormat) =>
  '<div class="flex-container">' +
    '<div class="participants-support-label">' +
      '<div class="label-value text-dark">{y:.1f}</div>' +
      `<div class="fw-normal text-muted white-space-nowrap pb-2">out of ${maxValueInFormat}</div>` +
    '</div>' +
  '</div>'

export const BuyInSection = ({ decision, isLegacy = false }) => {
  const decisionObj = new Decision(decision)
  if (!decisionObj.isDisplayBuyIn) return null;

  const maxValueInFormat = isLegacy ? '10' : '5';

  const supportChartOptions = useMemo(() =>
    ({
      chart: {
        type: 'solidgauge',
        height: 110,
        margin: [0, 0, 0, 0]
      },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '170%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      tooltip: {
        enabled: false
      },
      // the value axis
      yAxis: {
        stops: [
          [0.2, '#F0584D'],
          [0.4, '#FBB03F'],
          [0.6, '#FFD940'],
          [0.8, '#9CD673'],
          [0.81, '#39D4A6']
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickPixelInterval: 700,
        tickWidth: 0,
        title: { y: -70 },
        labels: { enabled: false },
        min: 0,
        max: 5
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 1,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        data: [decisionObj.avgSupport],
        dataLabels: {
          format: genDataLabel(maxValueInFormat)
        }
      }]
    })
  , [decisionObj.avgSupport])

  return <div className="px-3 mb-3">
    <h3>Buy-in</h3>
    <div className="d-flex flex-wrap center-container-block p-0">
      <div className="support-widget-col me-3">
        <HighchartsReact
          highcharts={Highcharts}
          options={supportChartOptions}
        />
      </div>
      <div className="user-supports mt-3 align-self-end">
        <div>
          <UserSupportImages {...{ decisionObj }} />
        </div>
        <div className={`text-muted`}>
          {decisionObj.supportRatingsText}
        </div>
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ decision }) => ({ decision });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(BuyInSection);
