import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { ModalBodyWithClose, ModalDoneFooter } from "../../common/modals";
import { setSetSummarySidebarOpen } from "../../store/sidebar/actions";
import { isPresent } from "../../helpers/common";
import { EditIcon } from "../../common/EditIcon";
import UserAvatarImage from "../../common/UserAvatarImage";
import * as moment from "moment";
import { RichTextSection } from "../../common/RichTextEditor";
import { userName } from "../../helpers/user_helpers";

export const showSummaryDetailsModal = (modal) => modal.type === "SummaryDetailsModal" && modal.shown;
export const openSummaryDetailsModal = ({ openModal, ...opts }) => openModal({ ...opts, type: 'SummaryDetailsModal' })

const SummarySection = ({ name, summary }) =>
  isPresent(summary) &&
  <div className='modal-summary-details-section mb-3'>
    <h3 className="mb-2">{name}</h3>
    <RichTextSection text={summary} isRichText={true}/>
  </div>

const EnteredBySection = ({ summary_entered_at, summary_entered_by }) =>
<div className="d-flex align-items-center">
  <UserAvatarImage user={summary_entered_by} />
  <div className="text-gray ms-2">
    Entered by {userName(summary_entered_by)} {moment(summary_entered_at).format('DD MMM, yyyy')}
  </div>
</div>

const SummaryDetailsModal = ({
                               shown, onClose, decision_set, setSetSummarySidebarOpen,
                               isManager = false, isSetUser = false,
                             }) => {
  const onEditClick = () => {
    onClose()
    setSetSummarySidebarOpen(true)
  }

  return <Modal backdrop="static" size="lg" show={shown} onHide={onClose}>
    <ModalBodyWithClose bsPrefix="pb-0" onClose={onClose}
                        headerChildren={
      (isManager || isSetUser) && <EditIcon onClick={onEditClick} classNames={'mx-1'} />
    }
                        header="Decision flow summary">
      <div className='modal-summary-details-block'>
        <SummarySection {...decision_set} />
        {
          decision_set.sections.map(section =>
            <SummarySection {...section} key={`summary-section-${section.slug}`} />
          )
        }
        <div className="mb-3">
          <EnteredBySection {...decision_set} />
        </div>
      </div>
    </ModalBodyWithClose>
    <ModalDoneFooter onClose={onClose} />
  </Modal>
}
const mapStateToProps = ({ modal, decision_set }) => ({ decision_set, modal });
export default connect(mapStateToProps, { setSetSummarySidebarOpen })(SummaryDetailsModal);
