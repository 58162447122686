import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import { FramingDecisionForm } from "../steps_wizard/steps/FramingDecisionStep";
import { WIZARD_STEPS } from "../../helpers/wizard_helpers";
import { saveTemplateWizardStepData } from "../../store/wizard/actions";
import { ModalBodyWithClose, ModalDoneFooterP0 } from "../../common/modals";

export const showTemplateDecisionDetailsModal = (modal) => modal.type === "TemplateDecisionDetailsModal" && modal.shown

export const TemplateDecisionDetailsModal = ({
                                               shown = false, wizard, template,
                                               onClose, saveTemplateWizardStepData,
                                               ...opts
                                            }) => {
  if (isBlank(shown) || isBlank(template)) return null;

  const [submit, setSubmit] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const modalRef = useRef({})

  const submitData = () => {
    setSubmit(true)
    saveTemplateWizardStepData(WIZARD_STEPS.tree_framing.key, {
      wizard: {
        step_index: wizard.step_index
      },
      choices: modalRef.current.isOpenEnded() ? [] : modalRef.current.choices,
      template: {
        description: modalRef.current.description,
        choice_entry_widget_type: modalRef.current.responseType,
        rate_compare_choices: modalRef.current.rateCompareChoices,
        ai_instructions: modalRef.current.aiInstructions,
      },
      decision_title: modalRef.current.titleNotes,
      next_step: true,
      finish_later: true
    }, success => {
      setSubmit(false)
      if (success) {
        onClose();
      }
    })
  }

  return <Modal size="lg" backdrop="static" show={shown} onHide={onClose}>
    <ModalBodyWithClose bsPrefix="categories-modal" onClose={onClose} header="Edit decision">
      <FramingDecisionForm submitState={submit}
                           isTemplate={true}
                           stepRef={modalRef}
                           wizard={wizard}
                           template={template}
                           onChangeDisabled={setDisabled}
                           {...opts} />
    </ModalBodyWithClose>
    <ModalDoneFooterP0 disabled={disabled} onClose={submitData} />
  </Modal>
}

const mapStateToProps = ({tree, modal, wizard, playbook_notes}) => ({
  tree, modal, wizard, playbook_notes,
  template: modal.decision
})
export default connect(mapStateToProps, { saveTemplateWizardStepData })(TemplateDecisionDetailsModal);
