import React from 'react';
import { connect } from "react-redux";
import { isBlank } from "../../../helpers/common";
import NAElement from "./NAElement";
import { CategoryBlockRow } from "../../../decision_categories/CategoryBlockRow";
import { decisionSelectedCategories } from "../../../models/decision";
import { CategorizationPBSection } from "../../../template_view/side_panel/sections/PlaybookNotesSection";

export const CategoriesSection = ({ template, org_categories, playbook_notes }) => {
  const HeaderElement = () => <h3>Categories</h3>
  const selectedCategories = decisionSelectedCategories(org_categories, template.selected_categories)

  if (isBlank(selectedCategories) && isBlank(playbook_notes?.categorization)) {
    return <div className="px-3 pb-3">
      <HeaderElement />
      <NAElement />
    </div>;
  }

  return <div className="px-3 pb-3">
    <HeaderElement />
    <CategorizationPBSection playbook_notes={playbook_notes} sidePanelStyle={true} showNA={false} />
    <div className="d-flex">
      <div className="d-table me-auto">
        {selectedCategories.map(category =>
          <CategoryBlockRow category={category} selectedCategories={template.category_options} key={`category-block-row-${category.slug}`} />
        )}
      </div>
    </div>
  </div>
}
const mapStateToProps = ({ template, org_categories, playbook_notes }) => ({
  template, org_categories, playbook_notes
});
export default connect(mapStateToProps)(CategoriesSection);
