import React  from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import parse from 'html-react-parser';
import {calculateRatingScore, RATING_SCALES_DATA} from "../../../helpers/drivers_helpers";
import { MAX_SCALE, MIN_SCALE } from "../../../models/DriverChoiceRating";

export const RatingOverlayTrigger = ({ rating }) => {
  const startScale = RATING_SCALES_DATA[rating.driver.driver?.rating_scale]?.start_scale || MIN_SCALE
  const endScale = RATING_SCALES_DATA[rating.driver.driver?.rating_scale]?.end_scale || MAX_SCALE
  const score = calculateRatingScore(rating.rating, startScale, endScale)

  if (!rating.hasEvaluation) return <span className="fw-bolder">{score}</span>

  return <OverlayTrigger
    trigger="click"
    key={`driver-${rating.driverSlug}`}
    placement={'auto'}
    rootClose
    overlay={
      <Popover id={`popover-${rating.driverSlug}`}>
        <Popover.Body className="p-2 pb-0" style={{maxHeight: 350, overflow: 'auto'}}>
          <div>{parse(rating.evaluation)}</div>
        </Popover.Body>
      </Popover>
    }
  >
    <a className="text-primary pointer">
      {rating.isNA ? <span>&#8212;</span> : <span className="fw-bolder">{score}</span>}
    </a>
  </OverlayTrigger>
}
