import { isPresent } from "./common";
import {
  SLACK_FLOWS,
  MS_TEAMS_FLOWS,
  LEGACY_FLOWS,
  LEGACY_SLACK_MS_TEAMS_FLOWS,
  SLACK_MS_TEAMS_FLOWS
} from "./decision_flows";

export const ORG_USER_ROLES = {
  admin: 'admin',
  member: 'member',
  guest: 'guest'
}

export const TABS = {
  decisions_center: 'decisions',
  create_decision: 'create_decisions',
  templates: 'templates',
  users: 'users',
  groups: 'groups',
  search_decisions: 'search_decisions',
  categories: 'categories',
  dsight: 'dsight',
  learn: 'learn',
  goals: 'goals',
  report_insights: 'report_insights',
  forecast_simulator: 'forecast_simulator'
}
export const TABS_URLS = {
  decisions_center: 'decisions',
  create_decision: 'create_decisions',
  templates: 'templates',
  search_decisions: 'search',
  users: 'users',
  groups: 'groups',
  categories: 'categories',
  dsight: 'dsight',
  learn: 'learn',
  report_insights: 'report_insights',
  forecast_simulator: 'forecast_simulator'
}
export const ALL_GROUP_SLUG = 'all'

export const isRequestAccessPage = (home) => isPresent(home?.request_access_decision_slug)
export const isDecisionsCenter = (home) => isPresent(home?.current_tab) && home?.current_tab === TABS.decisions_center
export const isCreateDecisionCenter = (home, current_user) => isPresent(home?.current_tab) && home?.current_tab === TABS.create_decision && !hideCreateDecisionTab(current_user)
export const isUsersCenter = (home, current_user) => isPresent(home?.current_tab) && home?.current_tab === TABS.users && !hideSettingsTab(current_user)
export const isDSightTab = (home, current_user, current_org) => isPresent(home?.current_tab) && (home?.current_tab === TABS.dsight || home?.current_tab === TABS.goals) && !hideDSightTab(current_user, current_org)
export const isLearningTab = (home, current_user) => isPresent(home?.current_tab) && (home?.current_tab === TABS.learn) && !hideLearningTab(current_user)
export const isForecastSimulatorTab = (home, current_user, current_org) => isPresent(home?.current_tab) && (home?.current_tab === TABS.forecast_simulator) && !hideForecastSimulatorTab(current_org, current_user)
export const isReportInsightsTab = (home, current_user, current_org) => isPresent(home?.current_tab) && (home?.current_tab === TABS.report_insights) && !hideReportInsightsTab(current_user, current_org)
export const isGroupsCenter = (home, current_user) => isPresent(home?.current_tab) && home?.current_tab === TABS.groups && !hideGroupsTab(current_user)
export const isTemplatesCenter = (home, current_user) => isPresent(home?.current_tab) && home?.current_tab === TABS.templates && !hideSettingsTab(current_user)
export const isCategoriesCenter = (home, current_user) => isPresent(home?.current_tab) && home?.current_tab === TABS.categories && !hideSettingsTab(current_user)
export const isSettingsTab = (home, current_user) => isUsersCenter(home, current_user) || isTemplatesCenter(home, current_user) || isCategoriesCenter(home, current_user)
export const isSearchDecisions = (home) => isPresent(home?.current_tab) && home?.current_tab === TABS.search_decisions
export const isDecisionSetPage = (location) => location?.pathname.includes('/decision_sets/')
export const isTemplateSetPage = (location) => location?.pathname.includes('/template_sets/')

export const isOutOrgVisitor = (user, org) => user.org_slug !== org.slug
export const isAdmin = (user) => user.org_role === ORG_USER_ROLES.admin || isPresent(user.permissions?.admin)
export const isGuest = (user) => user.org_role === ORG_USER_ROLES.guest || isPresent(user.permissions?.guest)
export const isBypassSSO = (user) => isPresent(user.bypass_sso)
export const isMember = (user) => user.org_role === ORG_USER_ROLES.member || !(isAdmin(user) || isGuest(user))
export const isDecider = (user) => !isGuest(user)

export const hideDSightTab = (current_user, current_org) => !isDecider(current_user) || !current_org?.dsight
export const hideSettingsTab = (current_user) => !isAdmin(current_user)
export const hideGroupsTab = (current_user) => !isAdmin(current_user) && !isMember(current_user)
export const hideReportInsightsTab = (current_user, current_org) => !current_org.show_report_insights || !isAdmin(current_user) && !isMember(current_user)
export const hideLearningTab = (current_user) => !isAdmin(current_user) && !isMember(current_user)
export const hideForecastSimulatorTab = (current_org, current_user) => !current_org.forecast_simulator || !isAdmin(current_user) && !isMember(current_user)
export const hideUsersTab = (current_user) => !isAdmin(current_user)
export const hideCreateDecisionTab = (current_user) => !isDecider(current_user)
export const isPlaybookBubbleTab = (home, current_user, current_org, location) => isCreateDecisionCenter(home, current_user) || isTemplatesCenter(home, current_user) || isDSightTab(home, current_user, current_org) || isTemplateSetPage(location) || isDecisionSetPage(location)
export const isDSightOrg = (current_org) => current_org?.dsight
export const isSlackFLow = (object) => SLACK_FLOWS.includes(object.flow_enum)
export const isMsTeamsFlow = (object) => MS_TEAMS_FLOWS.includes(object.flow_enum)
export const isLegacyFlow = (object) => LEGACY_FLOWS.includes(object.flow_enum)
export const isLegacySlackMsTeamsFlow = (object) => LEGACY_SLACK_MS_TEAMS_FLOWS.includes(object.flow_enum)
export const isSlackMsTeamsFlow = (object) => SLACK_MS_TEAMS_FLOWS.includes(object.flow_enum)
