import React from 'react';
import { connect } from "react-redux";
import { Route, Routes } from 'react-router';
import { BrowserRouter } from "react-router-dom";
import { Routes as RoutesLinks } from '../routes';
import AppLayout from "../layout";
import Homepage from "../homepage";
import Profile from "../profiile";
import TreeView from "../tree_view";
import TreeWizard from "../tree_wizard";
import TemplatePreview from "../template_preview";
import TemplateWizard from "../template_wizard";
import ReportView from "../report_view";
import ReportWizard from "../report_wizard";
import { loadCurrentUser } from "../store/current_user/actions";

import DSightTreeView from "../dsight/tree_view";
import DSightTemplatePreview from "../dsight/template_preview";

import LoginPage from "../sign_in/LoginPage";
import LoginLink from "../sign_in/LoginLink";
import CompleteUserProfile from "../sign_in/CompleteProfile";
import ResetPassword from "../sign_in/ResetPassword";
import TemplateView from "../template_view";
import TemplateSetView from "../template_set_view";
import TemplateSetAddTemplateView from "../template_set_add_template_view";
import TemplateSetPreview from "../template_set_preview";
import DecisionSetView from "../decision_set_view";
import DecisionSetAddDecisionView from "../decision_set_add_decision_view";
import DecisionSetSearchView from "../decision_set_search_view";
import ChangePassword from "../sign_in/ChangePassword";
import Loader from "./Loader";
import DSightTemplateView from "../dsight/template_view";

import DSightAnalysis from "../dsight/analysis";
import DSightAnalysisInsight from "../dsight/analysis/analysis_insight";
import DSightAnalysisInsightKpi from "../dsight/analysis/analysis_insight_kpi";
import DSightAnalysisMetrics from "../dsight/analysis/analysis_metrics";
import LearnDetails from "../learn/details";
import LearnTemplateDetails from "../learn/template_details";
import DSightMetrics from "../dsight/metrics";
import AskCloverpop from "../../decision_tree/homepage/tabs/dsight/AskCloverpop/AskCloverpop";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";
import DecisionState from "../decision_state";
import SlackSuccess from "../slack_bone/success";
import SlackBone from "../slack_bone";
import ForecastSimulatorScenario from "../forecast_simulator_scenario";
import QuickPollPage from ".././quick_poll_entry_page/QuickPollPage";
import AssignedDriversView from "../assigned_drivers_view";

const SignedUserRoutes = () =>
  <Routes>
    <Route exact path={RoutesLinks.root} element={<Homepage />} />
    <Route path={RoutesLinks.homepage_tab_item} element={<Homepage />} />
    <Route exact path={RoutesLinks.homepage_tab} element={<Homepage />} />
    <Route exact path={RoutesLinks.homepage} element={<Homepage />} />
    <Route path={RoutesLinks.profile} element={<Profile />} />

    <Route exact path={RoutesLinks.dsight_template_view} element={<DSightTemplateView />} />
    <Route exact path={RoutesLinks.dsight_template_preview} element={<DSightTemplatePreview />} />

    <Route exact path={RoutesLinks.dsight_metrics} element={<DSightMetrics />} />
    <Route exact path={RoutesLinks.dsight_metric} element={<DSightMetrics />} />

    <Route exact path={RoutesLinks.dsight_analysis} element={<DSightAnalysis />} />
    <Route exact path={RoutesLinks.dsight_analysis_metric} element={<DSightAnalysisMetrics />} />
    <Route exact path={RoutesLinks.dsight_analysis_metrics} element={<DSightAnalysisMetrics />} />
    <Route exact path={RoutesLinks.dsight_analysis_insight} element={<DSightAnalysisInsight />} />
    <Route exact path={RoutesLinks.dsight_analysis_insight_kpi} element={<DSightAnalysisInsightKpi />} />

    <Route exact path={RoutesLinks.forecast_simulator_scenario} element={<ForecastSimulatorScenario />} />

    <Route exact path={RoutesLinks.dsight_tree_view} element={<DSightTreeView />} />
    <Route path={RoutesLinks.template_preview} element={<TemplatePreview />} />
    <Route path={RoutesLinks.template_wizard} element={<TemplateWizard />} />
    <Route exact path={RoutesLinks.template_view} element={<TemplateView />} />
    <Route path={RoutesLinks.tree_view} element={<TreeView />} />
    <Route path={RoutesLinks.tree_wizard} element={<TreeWizard />} />
    <Route path={RoutesLinks.decision_state} element={<DecisionState />} />

    <Route exact path={RoutesLinks.template_set_view} element={<TemplateSetView />} />
    <Route exact path={RoutesLinks.template_set_add_decision_view} element={<TemplateSetAddTemplateView />} />
    <Route path={RoutesLinks.template_set_preview} element={<TemplateSetPreview />} />
    <Route path={RoutesLinks.decision_set_add_decision_view} element={<DecisionSetAddDecisionView />} />
    <Route path={RoutesLinks.decision_set_search_view} element={<DecisionSetSearchView />} />
    <Route path={RoutesLinks.decision_set_view} element={<DecisionSetView />} />
    <Route exact path={RoutesLinks.learn_template_details} element={<LearnTemplateDetails />} />
    <Route path={RoutesLinks.learn_details} element={<LearnDetails />} />
    <Route path={RoutesLinks.report_wizard} element={<ReportWizard />} />
    <Route path={RoutesLinks.report_view} element={<ReportView />} />
    <Route exact path={RoutesLinks.dsight_ask_cloverpop_conversation} element={<AskCloverpop />} />
    <Route path={RoutesLinks.dsight_ask_cloverpop} element={<AskCloverpop />} />
    <Route path={RoutesLinks.assigned_drivers} element={<AssignedDriversView />} />

    <Route exact path={RoutesLinks.slack_bone} element={<SlackBone />} />
    <Route exact path={RoutesLinks.slack} element={<SlackBone />} />
    <Route exact path={RoutesLinks.slack_success} element={<SlackSuccess />} />
  </Routes>

const NonAuthUserRoutes = () =>
  <Routes>
    <Route exact path={RoutesLinks.root} element={<LoginPage />} />
    <Route path={RoutesLinks.sign_in} element={<LoginPage />} />
    <Route path={RoutesLinks.login_link} element={<LoginLink />} />
    <Route path={RoutesLinks.reset_password} element={<ResetPassword />} />
    <Route exact path={RoutesLinks.submit_reset_password} element={<ChangePassword />} />
    <Route path={RoutesLinks.change_reset_password} element={<ChangePassword />} />
    <Route path={RoutesLinks.tree_view} element={<TreeView />} />
    <Route path={RoutesLinks.dsight_tree_view} element={<DSightTreeView />} />
    <Route path={RoutesLinks.invites_landing_page} element={<CompleteUserProfile />} />
    <Route path={RoutesLinks.quick_poll_entry} element={<QuickPollPage />} />

    <Route exact path={RoutesLinks.slack_bone} element={<SlackBone />} />
    <Route exact path={RoutesLinks.slack} element={<SlackBone />} />
    <Route exact path={RoutesLinks.slack_success} element={<SlackSuccess />} />

    <Route exact path={RoutesLinks.dsight_analysis} element={<DSightAnalysis />} />
    <Route exact path={RoutesLinks.dsight_analysis_metric} element={<DSightAnalysisMetrics />} />
    <Route exact path={RoutesLinks.dsight_analysis_metrics} element={<DSightAnalysisMetrics />} />
    <Route exact path={RoutesLinks.dsight_analysis_insight} element={<DSightAnalysisInsight />} />
    <Route exact path={RoutesLinks.dsight_analysis_insight_kpi} element={<DSightAnalysisInsightKpi />} />
  </Routes>

export const Content = ({ current_user, signIn, loadCurrentUser }) => {
  checkLoadingEffect(current_user, loadCurrentUser)

  return <AppLayout>
      {
          current_user.loaded ?
            <BrowserRouter>
                {signIn.signedIn ? <SignedUserRoutes /> : <NonAuthUserRoutes /> }
            </BrowserRouter> :
            <Loader />
      }
  </AppLayout>
};

const mapStateToProps = ({ current_user, signIn }) => ({ current_user, signIn });
export default connect(mapStateToProps, { loadCurrentUser })(Content);
