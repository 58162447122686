import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { ChoiceEntryStyle } from "../../tree_wizard/styles/choice_entry_styles";
import { uniqAnalysisRecords } from "../../models/d_sight/Analysis";
import { isBlank, isPresent } from "../../helpers/common";
import { useParams } from "react-router-dom";

export const generateChartSwitchState = ({
                                           metrics, navigate,
                                           allMetricsLink, generateMetricLink
                                         }) => {
  const params = useParams()
  const uniqMetrics = uniqAnalysisRecords(metrics).filter(m => m.idDisplayInSelect)
  const [metricId, setMetricId] = useState(params?.metric_id || uniqMetrics[0].id);
  const [metric, setMetric] = useState(null);

  useEffect(() => {
    if (isPresent(params?.metric_id)) {
      const findMetric = uniqMetrics.find(m => m.id === parseInt(params?.metric_id))
      if (isBlank(findMetric)) {
        navigate(allMetricsLink);
      } else {
        setMetric(findMetric)
        setMetricId(findMetric.id)
      }
    } else {
      setMetric(metrics[0])
      setMetricId(metrics[0].id)
    }
  }, [params?.metric_id])

  const onChangeMetric = ({ value }) => {
    const newMetric = metrics.find(m => m.id === value);
    setMetricId(newMetric.id)
    setMetric(newMetric)
    navigate(generateMetricLink(newMetric.link));
  }

  return {
    metricId, metric, uniqMetrics,
    onChangeMetric
  }
}

const onMenuOpen = () => {
  setTimeout(()=> {
    const selectedEl = document.getElementsByClassName("MetricsSelect__option--is-selected")[0];
    if(selectedEl){
      selectedEl.scrollIntoView({behavior:'smooth', block:'nearest', inline: 'start'});
    }
  },30);
}

export default ({
                  uniqMetrics, metricId, metric,
                  children,
                  onChangeMetric
                }) =>
  <div className={'h-100 bg-white rounded p-3'}>
    <div className={`mb-3`}>
      <Select onChange={onChangeMetric}
              defaultValue={uniqMetrics.find(m => m.id === parseInt(metricId))?.hash}
              onMenuOpen={onMenuOpen}
              components={{ IndicatorSeparator:() => null }}
              options={uniqMetrics.map(m => m.hash)}
              className ={"MetricsSelect"}
              classNamePrefix={"MetricsSelect"}
              value={metric?.hash}
              styles={ChoiceEntryStyle} />
    </div>
    {children}
  </div>
