import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {isBlank} from "../../helpers/common";
import Modal from "react-bootstrap/Modal";
import {ModalDoneFooter} from "../../common/modals";
import DecisionAssignToBlock from "./helpers/DecisionAssignToBlock";
import ModalHeaderTitle from "../../common/ModalHeaderTitle";
import {updateRecommendation} from "../../store/modals/actions";
import {submitEntryCallback} from "./entry_modal/helpers";

export const showRecommendationAssign = (modal) => modal.type === "RecommendationAssignModal" && modal.shown;

const recommendationUpdateData = (assignedToUser = '') => {
  return {
    decision_recommendation: {
      assigned_collaborator: assignedToUser
    }
  };
}

export const RecommendationAssignModal = ({
                              show, onClose, decision,
                              key = '', updateRecommendation
                            }) => {

  const recommendation = decision.recommendation;

  const [submit, setSubmit] = useState(false)
  const [assignedToUser, setAssignedToUser] = useState(recommendation?.assigned_collaborator_email);
  const submissionCallback = submitEntryCallback(updateRecommendation,
    recommendationUpdateData(assignedToUser), setSubmit, onClose
  );

  useEffect(() => {
    if (!show && recommendation) {
      setAssignedToUser(recommendation.assigned_collaborator_email || '');
    }
  }, [show, recommendation]);

  if (isBlank(recommendation)) return null;

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <form>
        <ModalHeaderTitle {...{ onClose, title: 'Assign decision' }} />
        <DecisionAssignToBlock {...{ assignedToUser, setAssignedToUser, className: 'mt-3' }} />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={submissionCallback} />
  </Modal>
};

const mapStateToProps = ({ modal, decision }) => ({ modal, decision })
export default connect(mapStateToProps, {updateRecommendation})(RecommendationAssignModal);
