export const cleanExpiredCollapsedDrivers = (storage_key) => {
  const collapsedDrivers = JSON.parse(localStorage.getItem(storage_key)) || {};
  const now = Date.now();

  // Remove expired entries
  Object.keys(collapsedDrivers).forEach((decisionSlug) => {
    collapsedDrivers[decisionSlug] = collapsedDrivers[decisionSlug].filter(
      (entry) => entry.expiresAt > now
    );

    // Remove the decisionSlug if it's empty
    if (collapsedDrivers[decisionSlug].length === 0) {
      delete collapsedDrivers[decisionSlug];
    }
  });

  // Save cleaned data back to localStorage
  localStorage.setItem(storage_key, JSON.stringify(collapsedDrivers));
}
