import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import "highcharts/highcharts-more"
import { FOLLOWUP_EXPECTATIONS } from "../../../models/decision";
import { isBlank } from "../../../helpers/common";
import { formatPercentage } from "../../../homepage/tabs/learning/AllDecisionsStatisticsPanel";
import { filterChartsData, xAxisByMonth } from "./TotalDecisions";
import { POINT_HEADER } from "../../../models/d_sight/metric_chart_helpers";

const CHART_COLORS = ['#009988', '#62a99c', '#97b8b1', '#de7355', '#CC3311', '#33BBEE', '#0077BB'];
const BASE_CHART_OPTIONS = {
  chart: {
    type: 'column',
    spacing: [5, 0, 0, 5],
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  credits: { enabled: false },
  yAxis: {
    title: {
      text: null
    },
    labels: {
      format: '{text}%',
      style: {
        fontSize: '0.75rem',
        color: '#1F2F3F'
      }
    },
    max: 100
  },
  tooltip: {
    headerFormat: `${POINT_HEADER}<table>`,
    pointFormat: '<tr><td style="padding:0"><span style="color:{series.color}">{series.name}</span>:&nbsp;{point.y} <b>({point.percentage:.0f}%)</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    style: {
      color: '#1F2F3F',
    },
    useHTML: true
  },
  plotOptions: {
    column: {
      stacking: 'percent'
    }
  },
  legend: {
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '400',
      fontSize: '0.875rem'
    },
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    x: 0,
    y: 0
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 600,
      },
      chartOptions: {
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          layout: 'horizontal'
        }
      }
    }]
  },
}

const RESULT_SUCCESS_NUMBER = 3


const SORTED_FOLLOWUP_EXPECTATIONS = [ ...FOLLOWUP_EXPECTATIONS ].sort((a, b) => {
  if (a.learnChartOrder > b.learnChartOrder) return 1;
  if (a.learnChartOrder < b.learnChartOrder) return -1;
  return 0;
})

const ResultBubble = ({ all_statistics, hash }) => {
  if(isBlank(all_statistics.decision_results)) return null;

  const { percentage } = all_statistics.decision_results[hash.value]
  return <div className={'d-flex align-items-center mb-1'}>
    <div className={`icon`}>
      <div className={`d-flex align-items-center justify-content-center rounded-circle ${
        hash.number >= RESULT_SUCCESS_NUMBER ? 'bg-success--lighten' : 'bg-danger--lighten'
      }`}>
        {formatPercentage(percentage)}
      </div>
    </div>
    <div className="ms-2">
      { hash.formatLabel }
    </div>
  </div>
}

export const DecisionsResultsName = ({ learn }) =>
  <div>
    <h3>Decision results</h3>
    {
      SORTED_FOLLOWUP_EXPECTATIONS.map(hash =>
        <ResultBubble all_statistics={learn.statistics?.all_statistics} key={`decision-results-${hash.number}`} hash={hash} />)
    }
  </div>

export const DecisionsResultsChart = ({ learn }) => {
  const dataByMonth = filterChartsData(learn.statistics.by_month)
  const series = SORTED_FOLLOWUP_EXPECTATIONS.map(hash => ({
    name: hash.formatLabel,
    data: dataByMonth.map(monthData => monthData.statistics?.decision_results[hash.value]?.count || 0)
  }))
  const chartOptions = {
    ...BASE_CHART_OPTIONS,
    colors: CHART_COLORS,
    title: {
      text: 'Results over time',
      align: 'left',
      style: {
        fontSize: '1.125rem',
        color: '#1F2F3F',
        fontWeight: 500
      }
    },
    xAxis: xAxisByMonth(dataByMonth),
    series
  }
  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}
