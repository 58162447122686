import React from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetDSightsData, startNewDecision } from "../../../store/homepage/actions";
import { resetSidebars, setDSightTreeViewerOpen } from "../../../store/sidebar/actions";
import { resetAnalysis } from "../../../store/analysis/actions";
import Analysis from "../../../models/d_sight/Analysis";
import { startDecisionFromTemplate } from './index'
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import { HamburgerMenuIconBtn } from "../../../common/dropdowns";
import { goToHomepageCallback } from "../../../tree_wizard/header/helpers";

export const HamburgerDropdown = ({
                                    analysis, sidebar, preview = false,
                                    startNewDecision, resetAnalysis, resetSidebars, openSideBar, resetDSightsData
                                  }) => {
  const analysisObject = new Analysis(analysis)
  const navigate = useNavigate();
  const goToHomepage = goToHomepageCallback([resetAnalysis, resetSidebars], navigate)
  const triggerTreeSidebar = () => openSideBar(!sidebar.dSightTreeSidebar)
  const previewTemplate = () => navigate(`/dsight/templates/${analysis.slug}/preview`)
  const startDecision = () => startDecisionFromTemplate(startNewDecision, analysis, analysisObject, navigate, resetDSightsData)

  return <HamburgerMenuIconBtn>
    <BaseHamburgerBtn onSelect={triggerTreeSidebar} title="Tree" />
    {
      preview ? null :
        <>
          <BaseHamburgerBtn onSelect={previewTemplate} title="Preview decision" />
          <BaseHamburgerBtn onSelect={startDecision} title="Start decision" />
        </>
    }
    <BaseHamburgerBtn onSelect={goToHomepage} title="Go to homepage" />
  </HamburgerMenuIconBtn>
}

const mapStateToProps = ({ analysis, sidebar }) => ({ analysis, sidebar });
const mapDispatchToProps = (dispatch) => ({
  startNewDecision: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  resetAnalysis: () => dispatch(resetAnalysis()),
  resetSidebars: () => dispatch(resetSidebars()),
  openSideBar: (options) => { dispatch(setDSightTreeViewerOpen(options)); },
  resetDSightsData: () => { dispatch(resetDSightsData()) }
});
export default connect(mapStateToProps, mapDispatchToProps)(HamburgerDropdown);
