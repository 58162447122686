import React, { Fragment } from 'react';
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
// import { Link } from 'react-router-dom';
// import EntryPoint from "../../EntryPoint";
import { loadOrgCategories } from "../../store/org_categories/actions";
import { pdf } from '@react-pdf/renderer';
import { PdfTreeViewDocument } from "../../pdf/tree_view";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import { DOCUMENT_FORMAT } from "../../pdf/helpers/common";
import Button from "react-bootstrap/Button";

const tryDownloadPdf = async (DecisionPDF, decision) => {
  try {
    const blob = await pdf(DecisionPDF).toBlob()
    if (blob) {
      const dataURL = window.URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.href = dataURL;
      tempLink.setAttribute('download', `${decision.description}.pdf`);
      tempLink.click();
      return blob;
    } else {
      console.error('Failed to generate PDF blob.');
    }
  } catch (e) {
    console.error('An error occurred during PDF rendering:', e);
  }
}

export const DownloadPDF = ({
                              tree, decision, current_org, org_categories, org_driver_types,
                              loadOrgCategories, isButton = false
                            }) => {
  checkLoadingEffect(org_categories, loadOrgCategories)

  const downloadPDF = () => {
    const DecisionPDF = <PdfTreeViewDocument decision={decision}
                                             org={current_org}
                                             org_categories={org_categories}
                                             drivers={tree.drivers}
                                             org_driver_types={org_driver_types}
                                             format={DOCUMENT_FORMAT}
                                             data_sources={tree.data_sources} />;
    tryDownloadPdf(DecisionPDF, decision).then(response => {
      console.log('tryDownloadPdf-response', response)
    }).catch((error) => {
      console.error('An error occurred during PDF rendering:', error);
    })
  }


  return <Fragment>
    {/*<Dropdown.Item as={Link} to={`/decisions/${EntryPoint.instance.objectSlug}/tree_viewer/pdf`} target={'_blank'}>*/}
    {/*  Preview decision PDF*/}
    {/*</Dropdown.Item>*/}
    {
      isButton ?
        <Button onClick={downloadPDF} className="btn btn-secondary w-100 mb-2">
          Download decision PDF
        </Button>:
        <Dropdown.Item as="button" onClick={downloadPDF}>Download decision PDF</Dropdown.Item>
    }

  </Fragment>
}
const mapStateToProps = ({ tree, decision, current_org, org_categories, org_driver_types }) => ({
  tree, decision, current_org, org_categories, org_driver_types
});
const mapDispatchToProps = (dispatch) => ({
  loadOrgCategories: () => {
    dispatch(loadOrgCategories());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(DownloadPDF);
